import React from "react";
import { useSelector } from "react-redux";
import { reduxForm, Field, FieldArray, formValueSelector } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Alert, Row, Button, Col } from "antd";
import { useTranslation } from "react-i18next";

import { required, email, phoneNumber, maxLength, password } from "utils/formValidations";
import { renderSelect, renderInput } from "components/FormItem";

import LocationFields from "./LocationFields";

const StyledForm = styled(Form)`
  padding: 20px 40px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 35px;
`;

const pinCodeLength = maxLength(4);
const emailUserRoles = [
  "access_location_admin", "access_orders", "access_statistics", "access_finance",
  "access_menu", "access_warehouse", "access_users", "access_marketing",
  "access_callcenter", "access_courier",
];

const EmployeeForm = ({
  error,
  handleSubmit,
  initialValues = {},
  pristine,
  submitting,
  locations,
  accessRoles,
  deleteButton,
}) => {
  const { t } = useTranslation();
  const access_role_id = useSelector((state) => formValueSelector("employeeForm")(state, "access_role_id"));
  const selectedRole = accessRoles.find((role) => role.id === access_role_id);

  const requireLogin = selectedRole && emailUserRoles.some((access) => selectedRole[access]);
  const validatePassword = initialValues.id ? [password] : [required, password];

  const accessRoleOptions = accessRoles.map(({ name, id }) => ({
    label: name === "salempos_generated_owner" ? t("const:owner") : name,
    value: id,
  }));

  return (
    <StyledForm onSubmit={handleSubmit}>
      {error && <Alert type="error" message={error} />}
      <StyledRow>
        <Col xs={24} md={20} lg={16} xl={12}>
          <Field
            name="name"
            label={t("access.Employee.Form.FirstNameAndLastName.Label")}
            validate={required}
            component={renderInput}
          />

          {requireLogin && (
            <Field
              name="email"
              label={t("access.Employee.Form.Email.Label")}
              validate={[required, email]}
              component={renderInput}
            />
          )}

          {requireLogin && (
            <Field
              name="password"
              label={t("access.Employee.Form.Password.Label")}
              validate={validatePassword}
              component={renderInput}
            />
          )}

          {selectedRole && selectedRole.access_cashier && (
            <Field
              name="pin_code"
              label={t("access.Employee.Form.PinCode.Label")}
              validate={[required, pinCodeLength]}
              component={renderInput}
            />
          )}

          <Field
            name="phone_number"
            label={t("access.Employee.Form.PhoneNumber.Label")}
            validate={[required, phoneNumber]}
            component={renderInput}
          />
          <Field
            name="access_role_id"
            label={t("access.Employee.Form.AccessRole.Label")}
            placeholder={t("access.Employee.Form.AccessRole.Placeholder")}
            validate={required}
            component={renderSelect}
            options={accessRoleOptions}
          />

          <FieldArray
            name="user_locations"
            locations={locations}
            component={LocationFields}
          />
        </Col>
      </StyledRow>

      <Row>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            {t("Add")}
          </Button>
          {deleteButton}
        </Col>
      </Row>
    </StyledForm>
  );
};

EmployeeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  locations: PropTypes.array,
  accessRoles: PropTypes.array,
  deleteButton: PropTypes.element,
};

export default reduxForm({
  form: "employeeForm",
})(EmployeeForm);
