import React from "react";
import { Switch, Route } from "react-router-dom";

import Suppliers from "./Suppliers";
import NewSupplier from "./NewSupplier";
import EditSupplier from "./EditSupplier";

const Container = () => (
  <Switch>
    <Route path="/warehouse/suppliers/new" component={NewSupplier} />
    <Route path="/warehouse/suppliers/:id" component={EditSupplier} />
    <Route path="/warehouse/suppliers" component={Suppliers} />
  </Switch>
);

export default Container;
