/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, notification, Typography, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { formatPrice } from "utils/helpers";

import PrepmealDetailsPanel from "../../prepmeals/components/PrepmealDetailsPanel";
import IngredientDetailsPanel from "../../ingredients/components/IngredientDetailsPanel";
import ModifierDetailsPanel from "../../modifiers/components/ModifierDetailsPanel";

import { TECH_CARD_PRODUCTS_QUERY } from "../sharedMenuQueries";

const formatProcessDuration = (t, duration) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  return [
    minutes > 0 && `${minutes} ${t("menu.TechCardProductsTable.ProcessDuration.min")}`,
    seconds > 0 && `${seconds} ${t("menu.TechCardProductsTable.ProcessDuration.sec")}`,
  ].filter((v) => v).join(" ");
};

const TechCardProductsTable = ({ techCardId }) => {
  const { t } = useTranslation();
  const [selectedPrepmeal, setSelectedPrepmeal] = useState();
  const [selectedIngredient, setSelectedIngredient] = useState();
  const [selectedModifierGroup, setSelectedModifierGroup] = useState();

  const { data, loading } = useQuery(TECH_CARD_PRODUCTS_QUERY, {
    variables: { id: techCardId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const techCard = data?.tech_cards_by_pk;

  const products = techCard?.tech_card_products.map(({ tech_card, product, ...tcp }) => {
    const value = tech_card || product;
    const bruttoWeight = tcp.quantity * value.unit_weight * 1000;
    return {
      ...tcp,
      value,
      type: tech_card ? "prepmeals" : "ingredients",
      bruttoWeight,
      nettoWeight: bruttoWeight * (1 - tcp.cold_loss),
      outputWeight: bruttoWeight * (1 - tcp.cold_loss) * (1 - tcp.hot_loss),
    };
  });

  const totalBrutto = (products ?? []).reduce((acc, p) => acc + p.bruttoWeight, 0);
  const totalNetto = (products ?? []).reduce((acc, p) => acc + p.nettoWeight, 0);
  const totalOutput = (products ?? []).reduce((acc, p) => acc + p.outputWeight, 0);
  const totalCost = (products ?? []).reduce((acc, p) => acc + p.cost, 0);

  const showFooter = techCard?.process_description || techCard?.process_duration > 0
    || data?.menu_item_modifier_group.length > 0;

  return (
    <>
      <Table
        size="small"
        rowKey="id"
        bordered
        loading={loading}
        dataSource={products}
        pagination={false}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell>
              {t("menu.TechCardProductsTable.Columns.Summary")}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {totalBrutto.toFixed(0)} {UNIT_LABELS_BY_VALUE.g}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {totalNetto.toFixed(0)} {UNIT_LABELS_BY_VALUE.g}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {totalOutput.toFixed(0)} {UNIT_LABELS_BY_VALUE.g}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(totalCost)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
        footer={showFooter && (() => (
          <>
            {techCard?.process_duration > 0 && (
              <div>
                <Typography.Text strong>
                  {t("menu.TechCardProductsTable.ProcessDuration.Title")}:&nbsp;
                </Typography.Text>
                {formatProcessDuration(t, techCard?.process_duration)}
              </div>
            )}
            {techCard?.process_description && (
              <div>
                <Typography.Text strong>
                  {t("menu.TechCardProductsTable.ProcessDescription")}:&nbsp;
                </Typography.Text>
                {techCard?.process_description}
              </div>
            )}
            {data?.menu_item_modifier_group.length > 0 && (
              <div>
                <Typography.Text strong>
                  {t("menu.TechCardProductsTable.Modifiers")}:&nbsp;
                </Typography.Text>
                {data.menu_item_modifier_group.map(({ modifier_group: mg }, idx, arr) => (
                  <>
                    <Typography.Link onClick={() => setSelectedModifierGroup(mg)}>
                      {mg.name}
                    </Typography.Link>
                    {" "}({mg.modifier_items.map((item) => item.name).join(", ")})
                    {idx === arr.length - 1 ? "." : ", "}
                  </>
                ))}
              </div>
            )}
          </>
        ))}
      >
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.Ingredient")}
          dataIndex="value"
          width="20%"
          render={(record, { type }) => (
            <Typography.Link
              onClick={() => {
                (type === "prepmeals" ? setSelectedPrepmeal : setSelectedIngredient)(record);
              }}
            >
              {record.name}{type === "prepmeals" && `, ${t("ShortPrepmeal")}`}
            </Typography.Link>
          )}
        />
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.BruttoUnit")}
          dataIndex="quantity"
          render={(quantity, { value }) => `${quantity} ${UNIT_LABELS_BY_VALUE[value.unit]}`}
        />
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.BruttoWeight")}
          dataIndex="bruttoWeight"
          render={(bruttoWeight) => `${(bruttoWeight).toFixed(0)} ${UNIT_LABELS_BY_VALUE.g}`}
        />
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.ColdProcessingLoss")}
          dataIndex="cold_loss"
          width="12%"
          render={(cold_loss) => `${cold_loss * 100}%`}
        />
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.NettoWeight")}
          dataIndex="nettoWeight"
          render={(nettoWeight) => `${(nettoWeight).toFixed(0)} ${UNIT_LABELS_BY_VALUE.g}`}
        />
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.HotProcessingLoss")}
          dataIndex="hot_loss"
          width="12%"
          render={(hot_loss) => `${hot_loss * 100}%`}
        />
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.OutputWeight")}
          dataIndex="outputWeight"
          render={(outputWeight) => `${(outputWeight).toFixed(0)} ${UNIT_LABELS_BY_VALUE.g}`}
        />
        <Table.Column
          title={t("menu.TechCardProductsTable.Columns.Cost")}
          dataIndex="cost"
          render={formatPrice}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedPrepmeal?.name}
            &nbsp;
            <Link to={`/menu/prepmeals/${selectedPrepmeal?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedPrepmeal}
        onClose={() => setSelectedPrepmeal(null)}
        placement="bottom"
        height="60%"
      >
        {selectedPrepmeal && (
          <PrepmealDetailsPanel t={t} prepmealId={selectedPrepmeal.id} />
        )}
      </Drawer>
      <Drawer
        title={(
          <>
            {selectedIngredient?.name}
            &nbsp;
            <Link to={`/menu/ingredients/${selectedIngredient?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedIngredient}
        onClose={() => setSelectedIngredient(null)}
        placement="bottom"
        height="60%"
      >
        {selectedIngredient && (
          <IngredientDetailsPanel t={t} ingredientId={selectedIngredient.id} />
        )}
      </Drawer>
      <Drawer
        title={(
          <>
            {selectedModifierGroup?.name}
            &nbsp;
            <Link to={`/menu/modifiers/${selectedModifierGroup?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedModifierGroup}
        onClose={() => setSelectedModifierGroup(null)}
        placement="bottom"
        height="60%"
      >
        {selectedModifierGroup && (
          <ModifierDetailsPanel t={t} modifierGroupId={selectedModifierGroup.id} />
        )}
      </Drawer>
    </>
  );
};

TechCardProductsTable.propTypes = {
  techCardId: PropTypes.number.isRequired,
};

export default TechCardProductsTable;
