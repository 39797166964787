import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { DesktopOutlined, DownOutlined, FileOutlined, LoadingOutlined, ShopOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Steps, Button, Card } from "antd";
import styled from "styled-components";

import { UNIT_OPTIONS } from "constants/index";

import i18n from "../../../i18n";
import LocationForm from "./LocationForm";
import ItemForm from "./ItemForm";
import CashierContent from "./CashierContent";

const { Step } = Steps;

const Container = styled.div`
  width: 100%;
  padding-top: 20px;
  position: relative;
`;

const STEPS = [
  {
    title: i18n.t("onboarding.WizardForm.Steps.Location.Title"),
    icon: <ShopOutlined />,
  },
  {
    title: i18n.t("onboarding.WizardForm.Steps.Item.Title"),
    icon: <FileOutlined />,
  },
  {
    title: i18n.t("onboarding.WizardForm.Steps.Cashier.Title"),
    icon: <DesktopOutlined />,
  },
  {
    title: i18n.t("onboarding.WizardForm.Steps.Finishing.Title"),
    icon: <DownOutlined />,
  },
];

const LastContent = ({ t, refetch }) => (
  <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
    <h2>{t("onboarding.Welcome")}</h2>
    <Form.Item>
      <Button type="primary" onClick={() => refetch()}>
        {t("onboarding.Buttons.Done")}
      </Button>
    </Form.Item>
  </div>
);

LastContent.propTypes = {
  refetch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const WizardForm = ({
  t,
  onCreateLocation,
  onCreateItem,
  refetch,
}) => {
  const [current, setCurrent] = useState(0);

  const renderForm = (index) => {
    if (index === 0) {
      return (
        <LocationForm
          t={t}
          onSubmit={(values) => onCreateLocation(values)
            .then(() => setCurrent(current + 1))}
        />
      );
    } if (index === 1) {
      return (
        <ItemForm
          t={t}
          onSubmit={(values) => onCreateItem(values)
            .then(() => setCurrent(current + 1))}
          initialValues={{
            unit: UNIT_OPTIONS[1].value,
          }}
        />
      );
    } if (index === 2) {
      return (
        <CashierContent
          onNext={() => setCurrent(current + 1)}
          t={t}
        />
      );
    }
    return <LastContent t={t} refetch={refetch} />;
  };

  return (
    <Container>
      <Steps current={current}>
        {STEPS.map((item, index) => (
          <Step
            key={item.title}
            title={item.title}
            icon={index === current ? <LoadingOutlined /> : item.icon}
          />
        ))}
      </Steps>
      <Card style={{ marginTop: 20 }}>
        {renderForm(current)}
      </Card>
    </Container>
  );
};

WizardForm.propTypes = {
  t: PropTypes.func.isRequired,
  onCreateLocation: PropTypes.func.isRequired,
  onCreateItem: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};

export default WizardForm;
