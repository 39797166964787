import React from "react";
import { Switch, Route } from "react-router-dom";

import General from "./general";
import Locations from "./locations";
import Check from "./check";
import DeliveryZones from "./deliveryZones";
import PaymentMethods from "./paymentMethods";

const Container = () => (
  <Switch>
    <Route path="/settings/general" component={General} />
    <Route path="/settings/locations" component={Locations} />
    <Route path="/settings/check" component={Check} />
    <Route path="/settings/payment-methods" component={PaymentMethods} />
    <Route path="/settings/delivery-zones" component={DeliveryZones} />
  </Switch>
);

export default Container;
