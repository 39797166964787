/* eslint-disable no-case-declarations */
import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import "twix";
import PropTypes from "prop-types";

import { getPeriods } from "utils/helpers";
import { LINE_CHART, CHART_OPTIONS } from "constants/index";

const formatPeriod = ({ from, to }) => {
  if (from.format("MM") === to.format("MM")) {
    return `${from.format("D")}-${to.format("D")} ${from.format("MMM")}`;
  }
  return `${from.format("D MMM")} - ${to.format("D MMM")}`;
};

const generateLineChart = (data, periodType) => {
  const from = data[0].day;
  const to = data[data.length - 1].day;

  switch (periodType) {
    case "day":
      const days = moment.twix(from, to).toArray("days");
      const valueByDays = days.map((day) => ({
        day,
        value: data.reduce(
          (acc, el) => (day.isSame(el.day, "day") ? acc + el.value : acc),
          0,
        ),
      }));
      return {
        labels: valueByDays.map(({ day }) => day.format("LL")),
        data: valueByDays.map(({ value }) => value),
      };
    case "week":
    case "month":
      const periods = moment.twix(from, to).toArray(periodType);
      const chartArrayData = getPeriods(periods, periodType).map((period) => ({
        label: formatPeriod(period),
        value: data.reduce(
          (acc, el) =>
            (moment(el.day).isBetween(period.from, period.to)
              ? acc + el.value
              : acc),
          0,
        ),
      }));

      return {
        labels: chartArrayData.map(({ label }) => label),
        data: chartArrayData.map(({ value }) => value),
      };
    default:
      return {
        labels: [],
        data: [],
      };
  }
};

const LineChart = ({ periodType, data }) => {
  const dataLines = data.length > 0 ? generateLineChart(data, periodType) : [];

  return (
    <Line data={LINE_CHART(dataLines)} height={60} options={CHART_OPTIONS()} />
  );
};

LineChart.propTypes = {
  data: PropTypes.array,
  periodType: PropTypes.string.isRequired,
};

export default LineChart;
