import React from "react";
import { Route, Switch } from "react-router-dom";

import Categories from "./Categories";
import NewCategory from "./NewCategory";
import EditCategory from "./EditCategory";

const Container = () => (
  <Switch>
    <Route path="/menu/categories/new" component={NewCategory} />
    <Route path="/menu/categories/:id" component={EditCategory} />
    <Route path="/menu/categories" component={Categories} />
  </Switch>
);

export default Container;
