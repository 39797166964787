/* eslint-disable no-case-declarations */
import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import "twix";
import PropTypes from "prop-types";

import { CHART_OPTIONS } from "constants/index";
import colors from "theme/colors";
import { getPeriods } from "utils/helpers";

const formatPeriod = ({ from, to }) => {
  if (from.format("MM") === to.format("MM")) {
    return `${from.format("D")}-${to.format("D")} ${from.format("MMM")}`;
  }
  return `${from.format("D MMM")} - ${to.format("D MMM")}`;
};

const generateLinesChart = ({ posByDay, callcenterByDay }, periodType) => {
  let from;
  let to;
  if (posByDay.length === 0 && callcenterByDay.length > 0) {
    from = callcenterByDay[0].day;
    to = callcenterByDay[callcenterByDay.length - 1].day;
  } else if (callcenterByDay.length === 0 && posByDay.length > 0) {
    from = posByDay[0].day;
    to = posByDay[posByDay.length - 1].day;
  } else {
    from = moment.min(moment(posByDay[0].day), moment(callcenterByDay[0].day));
    to = moment.max(
      moment(posByDay[posByDay.length - 1].day),
      moment(callcenterByDay[callcenterByDay.length - 1].day),
    );
  }

  switch (periodType) {
    case "day":
      const days = moment.twix(from, to).toArray("days");
      const valueByDays = days.map((day) => ({
        day,
        posValue: posByDay.reduce(
          (acc, el) => (day.isSame(el.day, "day") ? acc + el.value : acc),
          0,
        ),
        callcenterValue: callcenterByDay.reduce(
          (acc, el) => (day.isSame(el.day, "day") ? acc + el.value : acc),
          0,
        ),
      }));
      return {
        labels: valueByDays.map(({ day }) => day.format("LL")),
        dataLinesByPos: valueByDays.map(({ posValue }) => posValue),
        dataLinesByCallcenter: valueByDays.map(({ callcenterValue }) => callcenterValue),
      };
    case "week":
    case "month":
      const periods = moment.twix(from, to).toArray(periodType);
      const chartArrayData = getPeriods(periods, periodType).map((period) => ({
        label: formatPeriod(period),
        posValue: posByDay.reduce(
          (acc, el) =>
            (moment(el.day).isBetween(period.from, period.to)
              ? acc + el.value
              : acc),
          0,
        ),
        callcenterValue: callcenterByDay.reduce(
          (acc, el) =>
            (moment(el.day).isBetween(period.from, period.to)
              ? acc + el.value
              : acc),
          0,
        ),
      }));
      return {
        labels: chartArrayData.map(({ label }) => label),
        dataLinesByPos: chartArrayData.map(({ posValue }) => posValue),
        dataLinesByCallcenter: chartArrayData.map(({ callcenterValue }) => callcenterValue),
      };
    default:
      return {
        labels: [],
        dataLinesByPos: [],
        dataLinesByCallcenter: [],
      };
  }
};

const LINES_CHART = ({ t, labels, dataLinesByPos, dataLinesByCallcenter }) => ({
  labels,
  datasets: [
    {
      fill: true,
      label: t("statistics.Sales.AreaChartBySource.Pos"),
      lineTension: 0,
      borderWidth: 2,
      borderColor: colors.primaryColor,
      borderCapStyle: "round",
      borderJoinStyle: "miter",
      pointBorderColor: colors.white,
      pointBackgroundColor: colors.primaryColor,
      backgroundColor: colors.primaryColor,
      pointBorderWidth: 1,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
      data: dataLinesByPos,
    },
    {
      fill: true,
      label: t("statistics.Sales.AreaChartBySource.Callcenter"),
      lineTension: 0,
      borderWidth: 2,
      borderColor: colors.secondaryColor,
      backgroundColor: colors.secondaryColor,
      borderCapStyle: "round",
      borderJoinStyle: "miter",
      pointBorderColor: colors.white,
      pointBackgroundColor: colors.secondaryColor,
      pointBorderWidth: 1,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
      data: dataLinesByCallcenter,
    },
  ],
});

const LinesChart = ({ t, periodType, data }) => {
  const dataLines = data.posByDay.length > 0 || data.callcenterByDay.length > 0
    ? generateLinesChart(data, periodType) : [];

  return (
    <Line
      data={LINES_CHART({ t, ...dataLines })}
      height={60}
      options={CHART_OPTIONS({
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: { autoSkip: true, maxTicksLimit: 5, maxRotation: 0 },
            },
          ],
          yAxes: [{ stacked: true, ticks: { min: 0 } }],
        },
        plugins: {
          filler: {
            propagate: true,
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, tooltipData) {
              let label = tooltipData.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              label += Math.round(tooltipItem.yLabel * 100) / 100;
              return label;
            },
          },
        },
      })}
    />
  );
};

LinesChart.propTypes = {
  data: PropTypes.object,
  periodType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default LinesChart;
