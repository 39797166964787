import React from "react";
import { Switch, Route } from "react-router-dom";

import Sales from "./sales";
import Shops from "./shops";
import Products from "./products";
import Payments from "./payments";
import IncomingOrders from "./incomingOrders";
import Receipts from "./receipts";
import Taxes from "./taxes";
import Delivery from "./delivery";
import Employees from "./employees";

const Container = () => (
  <Switch>
    <Route path="/statistics/sales" component={Sales} />
    <Route path="/statistics/locations" component={Shops} />
    <Route path="/statistics/products" component={Products} />
    <Route path="/statistics/abc-analysis" component={Products} />
    <Route path="/statistics/payments" component={Payments} />
    <Route path="/statistics/incoming-orders" component={IncomingOrders} />
    <Route path="/statistics/receipts" component={Receipts} />
    <Route path="/statistics/taxes" component={Taxes} />
    <Route path="/statistics/delivery" component={Delivery} />
    <Route path="/statistics/employees" component={Employees} />
  </Switch>
);

export default Container;
