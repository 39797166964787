import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Form } from "@ant-design/compatible";
import { Alert, Button } from "antd";

import { required } from "utils/formValidations";
import {
  renderInput,
  renderCheckbox,
  renderInputNumber,
  renderSelect,
} from "components/FormItem";
import i18n from "i18n";

const buttonLayout = {
  wrapperCol: {
    sm: { offset: 10, span: 14 },
  },
};

const numberDisplayOptions = Object.entries(i18n.t(
  "settings.Check.Form.NumberDisplayType.Options", { returnObjects: true },
)).map(([key, value]) => ({ label: value, key }));

const CheckForm = ({ t, error, pristine, submitting, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    {error && <Alert type="error" message={error} />}
    <Field
      name="name"
      label={t("settings.Check.Form.CompanyName")}
      validate={required}
      component={renderInput}
    />
    <Field
      name="field_top"
      label={t("settings.Check.Form.TopLabel")}
      component={renderInput}
    />
    <Field
      name="field_bottom"
      label={t("settings.Check.Form.BottomLabel")}
      component={renderInput}
    />
    <Field
      name="number_display_type"
      label={t("settings.Check.Form.NumberDisplayType.Label")}
      component={renderSelect}
      options={numberDisplayOptions}
    />
    <Field
      name="font_size"
      type="number"
      label={t("settings.Check.Form.FontSize")}
      validate={required}
      component={renderInputNumber}
    />
    <Field
      name="print_delivery_info"
      label={t("settings.Check.Form.AddDeliveryInfo")}
      component={renderCheckbox}
    />
    <Field
      name="print_order_notes"
      label={t("settings.Check.Form.PrintOrderNotes")}
      component={renderCheckbox}
    />
    <Form.Item {...buttonLayout}>
      <Button
        type="primary"
        htmlType="submit"
        disabled={pristine || submitting}
      >
        {t("Save")}
      </Button>
    </Form.Item>
  </Form>
);

CheckForm.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "checkForm",
})(CheckForm);
