import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PageHeader, Spin, notification, Button } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  TERMINALS_QUERY,
  TERMINAL_QUERY,
  UPDATE_TERMINAL_MUTATION,
  DELETE_TERMINAL_MUTATION,
} from "./terminalQueries";
import TerminalForm from "./components/TerminalForm";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditTerminal = ({ history, match }) => {
  const terminalId = match.params.id;

  const { t } = useTranslation();
  const { loading, data } = useQuery(TERMINAL_QUERY, {
    variables: { id: terminalId },
    onError: notification.error,
  });
  const [updateTerminal] = useMutation(UPDATE_TERMINAL_MUTATION);
  const [deleteTerminal] = useMutation(DELETE_TERMINAL_MUTATION, {
    refetchQueries: [{ query: TERMINALS_QUERY }],
  });

  if (loading) return <Loader />;

  const handleSubmit = ({ __typename, id, type, ...formData }) =>
    updateTerminal({ variables: { id: terminalId, data: formData } })
      .then(() => history.goBack())
      .catch(notification.error);

  const handleDelete = () =>
    deleteTerminal({ variables: { id: terminalId } })
      .then(() => history.goBack())
      .catch(notification.error);

  const deleteButton = (
    <Button type="danger" onClick={handleDelete}>
      {t("Delete")}
    </Button>
  );

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("access.Terminal.EditPage.Title")}
      />
      <TerminalForm
        initialValues={data && data.terminals[0]}
        locations={data ? data.locations : []}
        workshops={data ? data.workshops : []}
        onSubmit={handleSubmit}
        deleteButton={deleteButton}
        disableTypeChange
      />
    </div>
  );
};

EditTerminal.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditTerminal;
