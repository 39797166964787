import React, { useState, useMemo } from "react";
import { Radio, Card } from "antd";
import { HorizontalBar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { round } from "lodash";

import { BAR_CHART, CHART_OPTIONS, PAYMENT_METHOD_TYPE } from "constants/index";

const barOptions = {
  ...CHART_OPTIONS(),
  scales: {
    yAxes: [
      {
        stacked: true,
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          min: 0,
        },
      },
    ],
  },
};

const valueByType = (values, type) =>
  round(type === "averageCheck" ? values.revenue / values.orders_count : values[type], 2);

const PaymentMethodBar = ({ t, data, paymentMethods }) => {
  const [type, setType] = useState("revenue");
  const paymentMethodStats = useMemo(
    () => ({
      labels: data.paymentMethodStats.map((pm) => {
        const foundPaymentMethod = paymentMethods
          .find((paymentMethod) => paymentMethod.id === pm.payment_method_id);
        return foundPaymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM
          ? foundPaymentMethod.name : t(`const:payment_method_name.${foundPaymentMethod.type}`);
      }),
      data: data.paymentMethodStats.map((pm) => valueByType(pm, type)),
    }),
    [data, type],
  );

  return (
    <Card
      title={t("statistics.Sales.HorizontalBarCharts.PaymentMethodBar.Title")}
      extra={(
        <Radio.Group
          size="small"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <Radio.Button value="revenue">
            {t("statistics.Sales.HorizontalBarCharts.revenue")}
          </Radio.Button>
          <Radio.Button value="orders_count">
            {t("statistics.Sales.HorizontalBarCharts.orders")}
          </Radio.Button>
          <Radio.Button value="averageCheck">
            {t("statistics.Sales.HorizontalBarCharts.averageCheck")}
          </Radio.Button>
        </Radio.Group>
      )}
    >
      <HorizontalBar
        data={BAR_CHART(paymentMethodStats)}
        options={barOptions}
        height={22 * data.paymentMethodStats.length}
      />
    </Card>
  );
};

PaymentMethodBar.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
};

export default PaymentMethodBar;
