import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PageHeader, Spin, notification } from "antd";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import EmployeeForm from "./components/EmployeeForm";
import { NEW_USER_QUERY } from "./employeesQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const NewEmployee = ({ history }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(NEW_USER_QUERY);

  if (loading) return <Loader />;

  const handleSubmit = (values) =>
    axios.post("/admin_api/users", values)
      .then(() => history.goBack())
      .catch((err) => notification.error(err.message));

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("access.Employee.AddPage.Title")}
      />
      <EmployeeForm
        locations={data.locations}
        accessRoles={data.access_roles}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

NewEmployee.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewEmployee;
