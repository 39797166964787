import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Table, Button, notification, Input, Space, Row, Col,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useQueryParam, StringParam } from "use-query-params";

import Page from "components/Page";

import { sortByAlphabet } from "utils/sorts";

import { INGREDIENT_CATEGORIES_QUERY } from "./ingredientCategoryQueries";

const IngredientCategories = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useQueryParam("search", StringParam);

  const { data, loading } = useQuery(INGREDIENT_CATEGORIES_QUERY, {
    onError: notification.error,
  });

  const ingredientCategories = useMemo(() => (data?.product_categories ?? [])
    .filter(({ name }) => (search && search.length > 0
      ? name.toLowerCase().includes(search) : true)), [data, search]);

  return (
    <Page
      title={t("menu.IngredientCategories.Index.Title")}
      subTitle={data?.product_categories.length.toString()}
      extra={(
        <Link to="/menu/ingredient-categories/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          style={{ width: 200 }}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
        />
      </Space>
      <Row>
        <Col md={24} lg={18} xl={15}>
          <Table
            rowKey="id"
            pagination={false}
            loading={loading}
            dataSource={ingredientCategories}
          >
            <Table.Column
              title={t("menu.IngredientCategories.Columns.Name")}
              dataIndex="name"
              sorter={sortByAlphabet("name")}
            />
            <Table.Column
              dataIndex="id"
              width={100}
              render={(id) => (
                <Space size="small">
                  <Link to={`/menu/ingredient-categories/${id}`}>{t("EditShort")}</Link>
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Page>
  );
};

IngredientCategories.propTypes = {};

export default IngredientCategories;
