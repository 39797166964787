import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { notification, Spin, Button, Modal, Card } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { parseFormErrors } from "utils/formErrors";

import Page from "components/Page";
import NewCategoryPane from "components/NewCategoryPane";
import ProductForm from "./components/ProductForm";
import ConvertToTechCardForm from "./components/ConvertToTechCardForm";

import {
  PRODUCT_QUERY,
  PRODUCT_FORM_DATA_QUERY,
  UPDATE_PRODUCT_MUTATION,
  CONVERT_TO_TECH_CARD_MUTATION,
} from "./productQueries";
import { WORKSHOPS_QUERY } from "../workshops/workshopQueries";
import { SELECT_OPTIONS_QUERY } from "../sets/setQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const EditProduct = ({ history, match }) => {
  const productId = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toggleArchived, setToggleArchived] = useState(false);
  const [isCreatingCategory, setCreatingCategory] = useState(false);
  const [isConvertingToTechCard, setConvertingToTechCard] = useState(false);

  const { loading, data } = useQuery(PRODUCT_QUERY, {
    variables: { id: productId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const workshopsQuery = useQuery(WORKSHOPS_QUERY, {
    onError: notification.error,
  });
  const formDataQuery = useQuery(PRODUCT_FORM_DATA_QUERY, {
    onError: notification.error,
  });
  const [updateProduct] = useMutation(UPDATE_PRODUCT_MUTATION, {
    refetchQueries: [
      { query: SELECT_OPTIONS_QUERY },
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });
  const [convertToTechCard] = useMutation(CONVERT_TO_TECH_CARD_MUTATION);

  if (loading || formDataQuery.loading || workshopsQuery.loading) return <Spin />;

  const locations = formDataQuery.data ? formDataQuery.data.locations : [];

  const handleSubmit = ({
    menu_items, limited_location_availability = [], is_weighted, name, id, archived_at, ...rest
  }) => {
    const menuItems = {
      data: menu_items.map((menu_item) => ({
        id: menu_item.id,
        name: menu_items.length > 1 ? menu_item.name : name,
        price: parseFloat(menu_item.price),
        limited_location_availability: limited_location_availability?.length === 0
          ? null : limited_location_availability,
        archived_at: menu_item.archived_at,
        product: {
          data: {
            name: menu_items.length > 1 ? `${name} ${menu_item.name}` : name,
            cost: parseFloat(menu_item.cost),
            unit: is_weighted ? "kg" : "pieces",
            unit_weight: is_weighted ? 1 : 0,
          },
        },
      })),
    };
    const archiveProduct = archived_at ? null : moment().format();
    return updateProduct({
      variables: {
        id: productId,
        data: {
          menu_items: menuItems,
          name,
          ...rest,
          archived_at: toggleArchived ? archiveProduct : archived_at,
        },
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  const mig = data?.menu_item_groups_by_pk;
  const initialMenuItemGroup = mig && {
    ...mig,
    limited_location_availability: mig.menu_items[0].limited_location_availability,
    is_weighted: mig.menu_items[0].product.unit === "kg",
    menu_items: mig.menu_items.map(({ product, ...rest }) => ({
      ...rest, cost: product.cost,
    })),
  };

  const hasDeliveries = data?.location_products_aggregate.aggregate.count > 0;

  const handleConvertToTechCard = ({ workshop_id }) =>
    convertToTechCard({
      variables: { id: productId, workshop_id },
    })
      .then((res) => res.data.convertToTechCard)
      .then((techCards) => {
        setConvertingToTechCard(false);
        if (techCards.length > 1) {
          history.push(`/menu/tech-cards?search=${encodeURIComponent(mig?.name.toLowerCase())}`);
        } else {
          history.push(`/menu/tech-cards/${techCards[0].id}`);
        }
      })
      .catch(parseFormErrors);

  const archivingOrUnarchivingButton = (
    <Button
      className="button"
      type="danger"
      htmlType="submit"
      onClick={() => setToggleArchived(true)}
    >
      {initialMenuItemGroup?.archived_at ? t("Unarchiving") : t("Archiving")}
    </Button>
  );

  return (
    <Page showBackIcon title={t("menu.Products.EditPage.Title")}>
      <Card>
        <ProductForm
          t={t}
          menuCategories={formDataQuery.data ? formDataQuery.data.menu_categories : []}
          locations={locations}
          initialValues={initialMenuItemGroup}
          hasDeliveries={hasDeliveries}
          onSubmit={handleSubmit}
          onCategoryCreate={() => setCreatingCategory(true)}
          onConvertToTechCard={() => setConvertingToTechCard(true)}
          archivingOrUnarchivingButton={archivingOrUnarchivingButton}
        />
      </Card>
      <NewCategoryPane
        isOpen={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
      />
      <Modal
        title={t("menu.Products.ConvertToTechCardModal.Title")}
        visible={isConvertingToTechCard}
        okText={t("menu.Products.ConvertToTechCardModal.Form.Submit")}
        cancelText={t("Cancel")}
        onOk={() => dispatch(submit("convertToTechCardForm"))}
        onCancel={() => setConvertingToTechCard(false)}
      >
        {t("menu.Products.ConvertToTechCardModal.Body")}
        <ConvertToTechCardForm
          t={t}
          workshops={workshopsQuery.data ? workshopsQuery.data.workshops : []}
          onSubmit={handleConvertToTechCard}
        />
      </Modal>
    </Page>
  );
};

EditProduct.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditProduct;
