import gql from "graphql-tag";

export const CHECK_SETTINGS_QUERY = gql`
  query GetCheckSetting @hasura {
    check_settings {
      id
      name
      font_size
      field_top
      field_bottom
      number_display_type
      print_delivery_info
      print_order_notes
    }
  }
`;

export const UPDATE_CHECK_SETTINGS = gql`
  mutation UpdateCheckSetting($id: Int!, $data: check_settings_set_input!) @hasura {
    update_check_settings(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
        name
        font_size
        field_top
        field_bottom
        number_display_type
        print_delivery_info
        print_order_notes
      }
    }
  }
`;
