import React from "react";
import { Route, Switch } from "react-router-dom";

import Modifiers from "./Modifiers";
import NewModifier from "./NewModifier";
import EditModifier from "./EditModifier";

const Container = () => (
  <Switch>
    <Route path="/menu/modifiers/new" component={NewModifier} />
    <Route path="/menu/modifiers/:id" component={EditModifier} />
    <Route path="/menu/modifiers" component={Modifiers} />
  </Switch>
);

export default Container;
