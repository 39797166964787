import gql from "graphql-tag";

export const FINANCE_CATEGORIES_QUERY = gql`
  query GetCategories {
    financeCategories {
      id
      name
      allow_expenses
      allow_income
    }
  }
`;

export const FINANCE_CATEGORY_QUERY = gql`
  query GetCategory($id: Int!) {
    financeCategory(category_id: $id) {
      id
      name
      allow_expenses
      allow_income
    }
  }
`;

export const CREATE_FINANCE_CATEGORY_MUTATION = gql`
  mutation CreateFinanceCategory($data: FinanceCategoryInput!) {
    createFinanceCategory(data: $data) {
      id
      name
      allow_expenses
      allow_income
    }
  }
`;

export const UPDATE_FINANCE_CATEGORY_MUTATION = gql`
  mutation EditCategory($id: Int!, $data: FinanceCategoryUpdateInput!) {
    updateFinanceCategory(category_id: $id, data: $data) {
      id
      name
      allow_expenses
      allow_income
    }
  }
`;
