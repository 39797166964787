import React from "react";
import { Switch, Route } from "react-router-dom";

import Taxes from "./Taxes";

const Container = () => (
  <Switch>
    <Route path="/statistics/taxes" component={Taxes} />
  </Switch>
);

export default Container;
