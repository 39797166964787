import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";

import { renderTimePicker } from "components/FormItem";
import { required } from "utils/formValidations";

const RowContainer = styled(Row)`
  margin-bottom: 4px;

  .center-cell {
    font-size: 20px;
    margin: auto;
  }

  .flex-col {
    display: flex;
    flex-directions: row;
    align-items: center;
  }
`;

const DeleteButton = styled(Button)`
  margin-right: 10px;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  style: { marginTop: 0, marginBottom: 0 },
};

const format = (seconds) => moment().startOf("day").add(seconds, "seconds");

const normalize = (value) => Math.abs(moment().startOf("day").diff(new Date(value), "seconds"));

const TimeWorkPromotionFields = ({ fields }) => {
  if (fields.length === 0) fields.push({ start: 0 });

  return (
    <div>
      {fields.map((active_hour, index) => (
        <RowContainer type="flex" justify="start" key={index} gutter={12}>
          <Col xs={14} lg={9} className="flex-col">
            <Field
              name={`${active_hour}.start`}
              formatTime="HH:mm"
              format={format}
              normalize={normalize}
              component={renderTimePicker}
              formItemProps={formItemLayout}
              validate={required}
            />
            <span className="center-cell">-</span>
            <Field
              name={`${active_hour}.end`}
              formatTime="HH:mm"
              format={format}
              normalize={normalize}
              component={renderTimePicker}
              formItemProps={formItemLayout}
              validate={required}
            />
          </Col>
          <Col xs={10} lg={6}>
            {fields.length > 1 && (
              <DeleteButton type="danger" onClick={() => fields.remove(index)}>
                <DeleteOutlined />
              </DeleteButton>
            )}
            {fields.length - 1 === index && (
              <Button onClick={() => fields.push({ start: 0 })}>
                <PlusOutlined />
              </Button>
            )}
          </Col>
        </RowContainer>
      ))}
    </div>
  );
};

TimeWorkPromotionFields.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default TimeWorkPromotionFields;
