import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button, Divider, Alert } from "antd";

import { renderInput, renderCheckbox } from "components/FormItem";
import colors from "theme/colors";
import { required } from "utils/formValidations";

import Payroll from "./Payroll";
import { ACCESS_CHECKBOXS } from "../constants";

const StyledForm = styled(Form)`
  padding: 20px 40px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledDivider = styled(Divider)`
  background-color: ${colors.lightGrey};
`;

const BorderRow = styled(Row)`
  border-bottom: 1px solid ${colors.lightGrey};
`;

const buttonsLayout = {
  wrapperCol: {
    sm: { span: 17, offset: 7 },
  },
};

const formItemCheckboxs = {
  wrapperCol: {
    sm: { span: 24, offset: 0 },
  },
  style: {
    marginTop: 0,
    marginBottom: 0,
  },
};

const formItem = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
  labelAlign: "left",
  colon: false,
  style: {
    marginTop: 8,
    marginBottom: 8,
  },
};

const AccessRoleForm = ({
  handleSubmit,
  pristine,
  t,
  submitting,
  error,
  initialValues,
}) => (
  <StyledForm onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}
    <StyledRow>
      <Col span={16}>
        <Field
          name="name"
          disabled={initialValues.is_owner}
          label={t("access.AccessRoles.Form.TitleOfAccessRole")}
          component={renderInput}
          validate={required}
        />
      </Col>
    </StyledRow>

    <BorderRow>
      <h2>{t("access.AccessRoles.Form.AccessRights")}</h2>
    </BorderRow>
    <StyledRow>
      <Col span={16}>
        <Form.Item label={t("access.AccessRoles.Form.AccessSections")} {...formItem}>
          {ACCESS_CHECKBOXS.map((item) => (
            <Field
              key={item.value}
              name={item.value}
              component={renderCheckbox}
              checkboxLabel={item.label}
              formItemProps={formItemCheckboxs}
            />
          ))}
        </Form.Item>
      </Col>
    </StyledRow>

    <Payroll t={t} />

    <StyledDivider />
    <Row>
      <Col span={16}>
        <Form.Item {...buttonsLayout}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            {t("Save")}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  </StyledForm>
);

AccessRoleForm.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default reduxForm({
  form: "accessRoleForm",
})(AccessRoleForm);
