import React from "react";
import { Switch, Route } from "react-router-dom";

import Categories from "./categories";
import Modifiers from "./modifiers";
import TechCards from "./techCards";
import Sets from "./sets";
import Workshops from "./workshops";
import Ingredients from "./ingredients";
import IngredientCategories from "./ingredientCategories";
import Products from "./products";
import Prepmeals from "./prepmeals";

const Container = () => (
  <Switch>
    <Route path="/menu/products" component={Products} />
    <Route path="/menu/tech-cards" component={TechCards} />
    <Route path="/menu/sets" component={Sets} />
    <Route path="/menu/ingredients" component={Ingredients} />
    <Route path="/menu/prepmeals" component={Prepmeals} />
    <Route path="/menu/categories" component={Categories} />
    <Route path="/menu/modifiers" component={Modifiers} />
    <Route path="/menu/ingredient-categories" component={IngredientCategories} />
    <Route path="/menu/workshops" component={Workshops} />
  </Switch>
);

export default Container;
