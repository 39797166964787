import gql from "graphql-tag";

export const SALES_QUERY = gql`
  query getStats(
    $filters: FiltersInput!,
    $delivery_zone_id: Int
  ) {
    salesStatsByDay(filters: $filters, type: delivery, delivery_zone_id: $delivery_zone_id) {
      day
      revenue
      orders_count
    }
    salesStatsByWeekday(filters: $filters, type: delivery, delivery_zone_id: $delivery_zone_id) {
      weekday
      revenue
      orders_count
    }
    salesStatsByHour(filters: $filters, type: delivery, delivery_zone_id: $delivery_zone_id) {
      hour
      revenue
      orders_count
    }
  }
`;

export const SALES_PAGE_QUERY = gql`
  query getLocations @hasura {
    locations {
      id
      name
    }
  }
`;

export const DELIVERY_ZONE_QUERY = gql`
  query getDeliveryZone($id: Int!) @hasura {
    delivery_zones_by_pk(id: $id) {
      id
      name
    }
  }
`;
