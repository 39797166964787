import gql from "graphql-tag";

export const MODIFIER_GROUPS_QUERY = gql`
  query GetModifierGroups @hasura {
    modifier_groups(order_by: {id: asc}) {
      id
      name
      description
      max_selection
      archived_at
      modifier_items(order_by: {id: asc}, where: { archived_at: { _is_null: true } }) {
        id
        name
      }
    }
  }
`;

export const MODIFIER_GROUP_QUERY = gql`
  query GetModifierGroup($id: Int!) @hasura {
    modifier_groups_by_pk(id: $id) {
      id
      name
      archived_at
      description
      max_selection
      min_selection
      modifier_items(order_by: {id: asc}, where: { archived_at: { _is_null: true } }) {
        id
        name
        price
        quantity
        product_id
        tech_card_id
        tech_card {
          id
          name
          unit
        }
        product {
          id
          name
          unit
        }
        cost
        default_value
        archived_at
      }
    }
  }
`;

export const TECH_CARDS_USING_MODIFIER_GROUP_QUERY = gql`
  query GetTechCardsUsingModifierGroup($modifierGroupId: Int!) @hasura {
    modifier_groups_by_pk(id: $modifierGroupId) {
      menu_item_modifier_groups(
        order_by: {id: asc}
        where: {menu_item: {menu_item_group: {archived_at: {_is_null: true}}}}
      ) {
        menu_item {
          id
          name
          menu_item_group_id
          tech_card {
            id
            is_set
          }
        }
      }
    }
  }
`;

export const SELECT_OPTIONS_QUERY = gql`
  query @hasura {
    products(where: {category_id: {_is_null: false}}) {
      id
      name
      cost
      category_id
      unit
      unit_weight
    }
    tech_cards(where: {_not: {menu_items: {}}}) {
      id
      name
      cost
      unit
      unit_weight
      output_quantity
      product {
        id
        cost
      }
    }
    product_categories {
      id
      name
    }
  }
`;

export const CREATE_MODIFIER_MUTATION = gql`
  mutation CreateModifier($data: modifier_groups_insert_input!) @hasura {
    insert_modifier_groups_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_MODIFIER_GROUP_MUTATION = gql`
  mutation UpdateModifierGroup($id: Int!, $data: ModifierGroupInput!) {
    updateModifierGroup(modifier_group_id: $id, data: $data) {
      id
    }
  }
`;
