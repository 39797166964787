import gql from "graphql-tag";

export const GENERAL_SETTINGS_QUERY = gql`
  query GeneralSettings @hasura {
    merchants {
      id
      company_name
    }
    common_settings {
      id
      currency_code
      timezone
      shift_offset
      service_percent
    }
  }
`;

export const COMPANY_SETTINGS_MUTATION = gql`
  mutation updateMerchant($id: Int!, $company_name: String!) @hasura {
    update_merchants(_set: {company_name: $company_name}, where: {id: {_eq: $id}}) {
      returning {
        id
        company_name
      }
    }
  }
`;

export const GENERAL_SETTINGS_MUTATION = gql`
  mutation updateCommonSettings($merchant_id: Int!, $data: common_settings_set_input!) @hasura {
    update_common_settings( where: { merchant_id: {_eq: $merchant_id} }, _set: $data,) {
      returning {
        id
        timezone
        currency_code
        shift_offset
        service_percent
      }
    }
  }
`;
