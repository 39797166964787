import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  notification, Col, Row, List, Spin, Button, Badge, Popover, Typography,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import { FilterOutlined } from "@ant-design/icons";

import ConnectData from "connect.container";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import { SOURCE_OPTIONS } from "constants/index";
import colors from "theme/colors";

import OrderCard from "./components/OrderCard";
import OrderDetailsTabs from "./OrderDetailsTabs";
import FiltersForm from "./components/FiltersForm";
import { ORDERS_QUERY } from "./ordersQueries";

const Orders = ({ match }) => {
  const orderId = parseInt(match.params.orderId, 10);

  const { t } = useTranslation();
  const { locations, settings } = ConnectData.useContainer();

  const [filter, setFilter] = useState(null);
  const [formFilters, setFormFilters] = useState({});
  const [filtersFormVisible, setFiltersFormVisible] = useState(false);
  const [offset, setOffset] = useQueryParam("offset", withDefault(NumberParam, 0));

  const [getOrders, { data, loading, fetchMore }] = useLazyQuery(ORDERS_QUERY, {
    onError: notification.error,
  });

  useEffect(_.throttle(() => {
    if (filter?.time) {
      getOrders({
        variables: {
          filter: {
            time: {
              _gte: filter.time.from.clone().add(settings.shift_offset, "s"),
              _lt: filter.time.to.clone().add(settings.shift_offset, "s"),
            },
            ...(filter.locationId ? { location_id: { _eq: filter.locationId } } : {}),
            ...(filter.source ? { source: { _eq: filter.source } } : {}),
            ...formFilters,
          },
          offset,
          limit: 50,
        },
      });
    }
  }, 200), [filter, formFilters]);

  useEffect(() => {
    if (data && offset > 0) {
      fetchMore({
        variables: { offset },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          orders: [
            ...previousResult.orders,
            ...fetchMoreResult.orders,
          ],
        }),
      });
    }
  }, [offset]);

  const hasMorePages = data
    ? data.orders_aggregate.aggregate.count > data.orders.length : false;

  return (
    <Page contentPadding={0}>
      <Row justify="space-between" style={{ margin: 12 }}>
        <Col>
          <FilterPanelWithQuery
            locations={locations}
            sourceOptions={SOURCE_OPTIONS}
            onFilter={(values) => { setOffset(0); setFilter(values); }}
          />
          &nbsp;&nbsp;
          <Typography.Text type="secondary">
            {t("orders.OrdersCount", { count: data?.orders_aggregate.aggregate.count ?? 0 })}
          </Typography.Text>
        </Col>
        <Col>
          <Popover
            content={(
              <FiltersForm
                t={t}
                onFilter={(f) => { setFormFilters(f); setFiltersFormVisible(false); }}
              />
            )}
            trigger="click"
            placement="bottomRight"
            visible={filtersFormVisible}
            onVisibleChange={setFiltersFormVisible}
          >
            <Button icon={<FilterOutlined />}>
              {t("Filters")}
              &nbsp;&nbsp;
              <Badge
                count={Object.keys(formFilters).length}
                offset={[0, -3]}
                style={{ backgroundColor: colors.primaryColor }}
              />
            </Button>
          </Popover>
        </Col>
      </Row>
      <Row style={{ backgroundColor: "white", borderTop: "1px solid #e8e8e8" }}>
        <Col style={{ width: "370px", height: "calc(100vh - 121px)", overflow: "scroll" }}>
          <InfiniteScroll
            loadMore={() => setOffset(data?.orders.length ?? 0)}
            hasMore={hasMorePages}
            initialLoad={false}
            loader={(
              <Row style={{ padding: "20px" }} type="flex" justify="center" key="spin">
                <Spin />
              </Row>
            )}
            useWindow={false}
          >
            <List
              style={{ borderRight: "1px solid #e8e8e8", height: "calc(100vh - 121px)" }}
              rowKey="id"
              itemLayout="vertical"
              dataSource={data?.orders}
              loading={loading}
              renderItem={(order) => (
                <OrderCard
                  t={t}
                  isActive={orderId === order.id}
                  order={order}
                />
              )}
            />
          </InfiniteScroll>
        </Col>
        <Col style={{ flex: 1, height: "calc(100vh - 121px)", overflow: "scroll" }}>
          <Route path="/orders/:id" component={OrderDetailsTabs} />
        </Col>
      </Row>
    </Page>
  );
};

Orders.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Orders;
