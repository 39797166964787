import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageHeader, notification, Spin, Button } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import PromotionForm from "./components/PromotionForm";
import {
  PROMOTION_QUERY,
  DELETE_PROMOTION_MUTATION,
  UPDATE_PROMOTION_MUTATION,
} from "./promotionQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;

  .buttons-layout {
    margin-right: 10px;
  }
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditPromotion = ({ history, match }) => {
  const promotionId = parseInt(match.params.id, 10);
  const { t } = useTranslation();

  const { loading, data } = useQuery(PROMOTION_QUERY, {
    variables: { id: promotionId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const [updatePromotion] = useMutation(UPDATE_PROMOTION_MUTATION);
  const [deletePromotion] = useMutation(DELETE_PROMOTION_MUTATION);

  if (loading) return <Loader />;

  const handleDelete = () =>
    deletePromotion({ variables: { id: promotionId } })
      .then(() => history.goBack())
      .catch(notification.error);

  const deleteButton = (
    <Button
      type="danger"
      className="buttons-layout"
      onClick={handleDelete}
    >
      Удалить
    </Button>
  );

  const handleUpdate = ({ id, ...values }) =>
    updatePromotion({ variables: { id, data: values } })
      .then(() => history.goBack())
      .catch(notification.error);

  const handleSubmit = (values) => {
    const {
      discount,
      bonus_items_max_count,
      promotion_conditions,
      promotion_products,
      ...rest
    } = values;

    if (values.result_type === "product") {
      return handleUpdate({
        ...rest,
        bonus_items_max_count: parseInt(bonus_items_max_count, 10),
        promotion_conditions: {
          data: promotion_conditions.map(({ min_count, min_price, ...rest_values }) => ({
            min_count: parseInt(min_count, 10),
            min_price: parseInt(min_price, 10),
            ...rest_values,
          })),
        },
        promotion_products: { data: promotion_products },
      });
    }
    return handleUpdate({
      ...rest,
      discount: parseFloat(discount),
      promotion_conditions: {
        data: promotion_conditions.map(({ min_count, min_price, ...rest_values }) => ({
          min_count: parseInt(min_count, 10),
          min_price: parseInt(min_price, 10),
          ...rest_values,
        })),
      },
    });
  };

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("marketing.Promotion.EditPage.Title")}
      />
      <PromotionForm
        initialValues={data ? data.promotions[0] : []}
        t={t}
        onSubmit={handleSubmit}
        deleteButton={deleteButton}
        menuItems={data ? data.menu_items : []}
      />
    </div>
  );
};

EditPromotion.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditPromotion;
