import React from "react";
import { Route, Switch } from "react-router-dom";

import Sales from "./Sales";

const Container = () => (
  <Switch>
    <Route path="/statistics/sales" component={Sales} />
  </Switch>
);

export default Container;
