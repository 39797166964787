import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Spin, notification } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Page from "components/Page";
import { parseFormErrors } from "utils/formErrors";

import WorkshopForm from "./components/WorkshopForm";
import { WORKSHOP_QUERY, UPDATE_WORKSHOP_MUTATION } from "./workshopQueries";

const EditWorkshop = ({ history, match }) => {
  const { t } = useTranslation();
  const workshopId = parseInt(match.params.id, 10);

  const { loading, data } = useQuery(WORKSHOP_QUERY, {
    variables: { id: workshopId },
    onError: notification.error,
  });
  const [updateWorkhsop] = useMutation(UPDATE_WORKSHOP_MUTATION);

  if (loading) return <Spin />;

  const handleSubmit = ({ __typename, id, ...values }) =>
    updateWorkhsop({ variables: { id: workshopId, data: values } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("menu.Workshops.EditPage.Title")}>
      <Row>
        <Col xs={24} md={18} lg={14}>
          <Card>
            <WorkshopForm
              t={t}
              initialValues={data && data.workshops[0]}
              onSubmit={handleSubmit}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

EditWorkshop.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditWorkshop;
