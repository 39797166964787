import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button, Alert } from "antd";

import { renderInput } from "components/FormItem";
import { required } from "utils/formValidations";

const StyledForm = styled(Form)`
  padding: 20px 40px;
`;

const PasswordForm = ({
  handleSubmit, t, submitting, reset, onSubmit, error,
}) => {
  const submitAndReset = (data) => onSubmit(data).then(reset);

  return (
    <StyledForm onSubmit={handleSubmit(submitAndReset)}>
      {error && <Alert message={error} type="error" />}

      <Row>
        <Col xs={24} md={20} lg={16} xl={12}>
          <Field
            name="current_password"
            label={t("account.PasswordForm.CurrentPassword")}
            placeholder={t("account.PasswordForm.CurrentPassword")}
            type="password"
            component={renderInput}
          />
          <Field
            name="password"
            label={t("account.PasswordForm.NewPassword")}
            placeholder={t("account.PasswordForm.NewPassword")}
            type="password"
            component={renderInput}
            validate={required}
          />
          <Field
            name="confirm_password"
            label={t("account.PasswordForm.ConfirmPassword")}
            placeholder={t("account.PasswordForm.ConfirmPassword")}
            type="password"
            component={renderInput}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 5 }} xs={24}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            {t("Save")}
          </Button>
        </Col>
      </Row>
    </StyledForm>
  );
};

PasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
};

const validate = (values) => {
  if (values.password !== values.confirm_password) {
    return { confirm_password: "Passwords don't match" };
  }
  return {};
};

export default reduxForm({
  form: "passwordForm",
  validate,
})(PasswordForm);
