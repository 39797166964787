/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { useEvent } from "react-use";
import { Form } from "@ant-design/compatible";
import { notification, Spin, Button } from "antd";
import { Trans } from "react-i18next";

import { CASHIER_QUERY, ORDERS_QUERY } from "../onboardingQueries";

const CashierContent = ({ t, onNext }) => {
  const { data, loading } = useQuery(CASHIER_QUERY, {
    onError: notification.error,
    fetchPolicy: "no-cache",
  });
  const orderQuery = useQuery(ORDERS_QUERY, {
    onError: notification.error,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (orderQuery.data !== orderQuery.previousData && orderQuery.data.orders.length > 0) {
      onNext();
    }
  }, [orderQuery.data]);

  const onKeyDown = useCallback(() => {
    orderQuery.refetch();
  }, []);

  useEvent("focus", onKeyDown);

  if (loading) return (<div className="content loading"><Spin /></div>);

  return (
    <div className="content">
      <h4>
        <Trans
          i18nKey="onboarding.CashierContent.TopText"
          components={{ a: <a /> }}
          values={{
            webUrl: "pos.salempos.com",
            desktopUrl: "desktop.salempos.com",
            locationCode: data.locations[0].code,
            password: data.terminals[0].password,
          }}
        />
      </h4>
      <div>
        <ul>
          <li>
            {t("onboarding.CashierContent.LocationCode", { value: data.locations[0].code })}
          </li>
          <li>
            {t("onboarding.CashierContent.TerminalPassword", { value: data.terminals[0].password })}
          </li>
          <li>
            {t("onboarding.CashierContent.PinCode", { value: data.users[0].pin_code })}
          </li>
        </ul>
      </div>
      <div>
        <Button
          onClick={() => {
            window.open(`https://pos.salempos.com?locationCode=${data.locations[0].code}&password=${data.terminals[0].password}`);
          }}
        >
          {t("onboarding.CashierContent.OpenCashierButton")}
        </Button>
      </div>
      <Form.Item wrapperCol={{ offset: 10 }}>
        <Button
          onClick={onNext}
          type="primary"
        >
          {t("onboarding.Buttons.Next")}
        </Button>
      </Form.Item>
    </div>
  );
};

CashierContent.propTypes = {
  t: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default CashierContent;
