/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Row, Col } from "antd";
import styled from "styled-components";

import {
  renderInput,
  renderSelect,
  renderTextarea,
  renderDatePicker,
  renderRadioGroup,
} from "components/FormItem";
import colors from "theme/colors";
import { required } from "utils/formValidations";

import { getAccountName } from "../../accounts/constants";
import { getCategoryName } from "../../categories/constants";
import {
  CURRENCY_SYMBOLS,
  TRANSACTION_TYPES,
  TRANSACTION_BUTTONS,
} from "../constants";

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.span`
  margin-right: 8px;
  margin-left: 8px;
  font-weight: 700;
  font-size: 18px;
  color: ${colors.grey};
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  style: {
    marginTop: -8,
    marginBottom: -8,
  },
};

const formItemProps = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const accountOptions = (accounts) =>
  accounts.map((item) => ({
    label: getAccountName(item),
    value: item.id,
  }));

const categoryOptions = (categories) =>
  categories.map((item) => ({
    label: getCategoryName(item.name),
    value: item.id,
  }));

const renderExpenseFields = ({ t, currency, financeAccounts, financeCategories }) => (
  <>
    <Field
      name="amount"
      label={t("finance.Transaction.Form.Amount")}
      component={renderInput}
      prefix={<MinusOutlined />}
      suffix={currency && CURRENCY_SYMBOLS[currency]}
      type="number"
      min={0}
      validate={required}
      formItemProps={formItemProps}
    />

    <Field
      name="account_id"
      component={renderSelect}
      label={t("finance.Transaction.Form.FromAccount")}
      options={accountOptions(financeAccounts)}
      validate={required}
      formItemProps={formItemProps}
    />

    <Field
      name="category_id"
      component={renderSelect}
      label={t("finance.Transaction.Form.Category")}
      options={categoryOptions(financeCategories)}
      validate={required}
      formItemProps={formItemProps}
    />
  </>
);

renderExpenseFields.propTypes = {
  t: PropTypes.func.isRequired,
  currency: PropTypes.string,
  financeAccounts: PropTypes.array.isRequired,
  financeCategories: PropTypes.array.isRequired,
};

const renderIncomeFields = ({
  currency, t, financeAccounts, financeCategories,
}) => (
  <>
    <Field
      name="amount"
      label={t("finance.Transaction.Form.Amount")}
      component={renderInput}
      prefix={<PlusOutlined />}
      suffix={currency && CURRENCY_SYMBOLS[currency]}
      type="number"
      min={0}
      validate={required}
      formItemProps={formItemProps}
    />

    <Field
      name="account_id"
      component={renderSelect}
      label={t("finance.Transaction.Form.ToAccount")}
      options={accountOptions(financeAccounts)}
      validate={required}
      formItemProps={formItemProps}
    />

    <Field
      name="category_id"
      component={renderSelect}
      label={t("finance.Transaction.Form.Category")}
      options={categoryOptions(financeCategories)}
      validate={required}
      formItemProps={formItemProps}
    />
  </>
);

renderIncomeFields.propTypes = {
  currency: PropTypes.string,
  t: PropTypes.func.isRequired,
  financeAccounts: PropTypes.array.isRequired,
  financeCategories: PropTypes.array.isRequired,
};

const renderTransferFields = ({ t, financeAccounts }) => (
  <>
    <Row type="flex" align="middle">
      <Col
        className="ant-col ant-form-item-label ant-form-item-label-left"
        sm={10}
        xs={24}
      >
        <label className="ant-form-item-no-colon">
          {t("finance.Transaction.Form.Amount")}
        </label>
      </Col>
      <StyledCol sm={8} xs={14}>
        <Field
          name="amount"
          component={renderInput}
          type="number"
          min={0}
          prefix={<MinusOutlined />}
          formItemProps={formItemLayout}
          validate={required}
        />
        <Text>→</Text>
      </StyledCol>
      <StyledCol sm={6} xs={10}>
        <Field
          name="transfer_to_amount"
          component={renderInput}
          type="number"
          min={0}
          prefix={<PlusOutlined />}
          formItemProps={formItemLayout}
          validate={required}
        />
      </StyledCol>
    </Row>

    <Field
      name="account_id"
      component={renderSelect}
      label={t("finance.Transaction.Form.FromAccount")}
      options={accountOptions(financeAccounts, t)}
      validate={required}
      formItemProps={formItemProps}
    />

    <Field
      name="transfer_to_account_id"
      component={renderSelect}
      label={t("finance.Transaction.Form.ToAccount")}
      options={accountOptions(financeAccounts, t)}
      validate={required}
      formItemProps={formItemProps}
    />
  </>
);

renderTransferFields.propTypes = {
  t: PropTypes.func.isRequired,
  currency: PropTypes.string,
  currencyTo: PropTypes.string,
  financeAccounts: PropTypes.array.isRequired,
};

const TransactionForm = ({ transactionFormData, change, t }) => {
  const typeState = useSelector((state) =>
    formValueSelector("transactionForm")(state, "type"));

  useEffect(() => {
    change("category_id", null);
    change("transfer_to_account_id", null);
    change("transfer_to_amount", null);
  }, [typeState]);

  return (
    <Form>
      <Field
        items={TRANSACTION_BUTTONS}
        label={t("finance.Transaction.Form.Operation")}
        name="type"
        component={renderRadioGroup}
        optionType="button"
        formItemProps={formItemProps}
      />

      {TRANSACTION_TYPES.EXPENSE === typeState
        && renderExpenseFields({ t, ...transactionFormData })}
      {TRANSACTION_TYPES.INCOME === typeState
        && renderIncomeFields({ t, ...transactionFormData })}
      {TRANSACTION_TYPES.TRANSFER === typeState
        && renderTransferFields({ t, ...transactionFormData })}

      <Field
        name="time"
        component={renderDatePicker}
        showTime
        label={t("Date")}
        validate={required}
        formItemProps={formItemProps}
      />

      <Field
        name="description"
        component={renderTextarea}
        label={t("Description")}
        formItemProps={formItemProps}
      />
    </Form>
  );
};

TransactionForm.propTypes = {
  transactionFormData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "transactionForm",
  enableReinitialize: true,
})(TransactionForm);
