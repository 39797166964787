import React, { useEffect } from "react";
import styled from "styled-components";
import { Form, Row, Col, Typography } from "antd";
import { Field } from "redux-form";
import PropTypes from "prop-types";

import { renderInput } from "components/FormItem";
import { required } from "utils/formValidations";
import { singletonCurrency } from "utils/helpers";

const priceFieldsLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  style: { marginTop: 4, marginBottom: 4, textAlign: "center" },
};

const PriceFieldsRow = styled(Row)`
  .ant-form-item-label {
    padding: 0;
  }

  .sign {
    font-size: 22px;
    font-weight: 300;
  }
`;

const parsePrice = (value) => {
  const price = parseFloat(value);
  return price || price === 0 ? parseFloat(price.toFixed(2)) : "";
};

const PriceFields = ({ prefix, changeField, disableCost, modifier, t }) => {
  useEffect(() => {
    const { price, cost } = modifier;

    if ((price || price === 0) && (cost || cost === 0)) {
      const markup = parseFloat(((price / cost - 1) * 100).toFixed(2));
      changeField(`${prefix}.markup`, markup !== Infinity ? markup : 100);
    } else {
      changeField(`${prefix}.markup`, 0);
    }
  }, [modifier.price, modifier.cost]);

  return (
    <PriceFieldsRow align="bottom">
      <Col span={8}>
        <Field
          label={t("menu.PriceFields.Cost")}
          name={`${prefix}.cost`}
          component={renderInput}
          type="number"
          step={0.01}
          validate={required}
          format={(v) => (v || v === 0 ? parseFloat(v.toFixed(2)) : "")}
          parse={parsePrice}
          disabled={disableCost}
          addonAfter={singletonCurrency.getCurrency()}
          formItemProps={priceFieldsLayout}
        />
      </Col>
      <Col span={1}>
        <Form.Item {...priceFieldsLayout}>
          <Typography.Text className="sign">+</Typography.Text>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Field
          label={t("menu.PriceFields.Markup")}
          name={`${prefix}.markup`}
          component={renderInput}
          formItemProps={priceFieldsLayout}
          disabled
          addonAfter="%"
        />
      </Col>
      <Col span={1}>
        <Form.Item {...priceFieldsLayout}>
          <Typography.Text className="sign">=</Typography.Text>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Field
          label={t("menu.PriceFields.Price")}
          name={`${prefix}.price`}
          type="number"
          step={0.01}
          component={renderInput}
          validate={required}
          parse={parsePrice}
          addonAfter={singletonCurrency.getCurrency()}
          formItemProps={priceFieldsLayout}
        />
      </Col>
    </PriceFieldsRow>
  );
};

PriceFields.propTypes = {
  t: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  disableCost: PropTypes.bool,
  modifier: PropTypes.object.isRequired,
  changeField: PropTypes.func.isRequired,
};

export default PriceFields;
