import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field, reduxForm, FieldArray } from "redux-form";
import { Form } from "@ant-design/compatible";
import { Button, Alert, Space, Divider } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import {
  renderSelect,
  renderTextarea,
  renderSelectGroup,
  renderDatePicker,
  errorLayout,
} from "components/FormItem";
import { required } from "utils/formValidations";

import { categoryForProduct, PRODUCT_CATEGORY_TYPE_LABELS } from "../../shared/utils";

import ProductFields from "./ProductFields";

const buttonsLayout = {
  wrapperCol: {
    md: { span: 15, offset: 6 },
    xs: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const InventoryForm = ({
  t,
  handleSubmit,
  onComplete = null,
  submitting,
  isCompleting,
  pristine,
  initialValues = {},
  change: changeField,
  optionsData,
  error,
}) => {
  const locationOptions = (optionsData?.locations ?? [])
    .map((item) => ({ value: item.id, label: item.name }));

  const categoryGroups = useMemo(() => {
    if (!optionsData) return [];

    const valueForCategory = ({ type, id }) => {
      switch (type) {
        case "prepmeals": return { type };
        case "ingredients": return { type, category_id: id };
        default: return { type, menu_category_id: id };
      }
    };

    const categories = optionsData.products.reduce((acc, product) => {
      const category = categoryForProduct(product, optionsData);
      const categoryValue = JSON.stringify(valueForCategory(category));

      return acc.some((c) => c.value === categoryValue)
        ? acc : acc.concat({ ...category, label: category.name, value: categoryValue });
    }, []);

    return Object.keys(PRODUCT_CATEGORY_TYPE_LABELS).map((type) => ({
      label: PRODUCT_CATEGORY_TYPE_LABELS[type],
      value: type,
      children: categories.filter((c) => c.type === type),
    })).filter((c) => c.children.length > 0);
  }, [optionsData]);

  const handleSelectAllCategories = () => {
    const categories = categoryGroups.flatMap((cg) => cg.children).map((c) => c.value);
    changeField("inventory_categories", categories);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        formItemProps={formItemLayout}
        name="datetime"
        label={t("warehouse.Inventories.Form.DateAndTime.Label")}
        component={renderDatePicker}
        showTime
        placeholder={t("warehouse.Inventories.Form.DateAndTime.Placeholder")}
        disabled={!!initialValues.id}
        validate={required}
      />
      <Field
        formItemProps={formItemLayout}
        name="location_id"
        label={t("warehouse.Inventories.Form.Warehouse.Label")}
        component={renderSelect}
        style={{ width: 250 }}
        placeholder={t("warehouse.Inventories.Form.Warehouse.Placeholder")}
        options={locationOptions}
        disabled={!!initialValues.id}
        validate={required}
      />
      <Field
        formItemProps={formItemLayout}
        name="inventory_categories"
        label={t("warehouse.Inventories.Form.Category.Label")}
        placeholder={t("warehouse.Inventories.Form.Category.Placeholder")}
        component={renderSelectGroup}
        mode="multiple"
        options={categoryGroups}
        disabled={!!initialValues.id}
        validate={required}
        allowClear
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <LinkContainer onMouseDown={(e) => e.preventDefault()}>
              <Button
                type="link"
                onClick={handleSelectAllCategories}
              >
                {t("warehouse.Inventories.Form.Category.SelectAll")}
              </Button>
            </LinkContainer>
          </div>
        )}
      />
      <Field
        formItemProps={formItemLayout}
        name="description"
        label={t("warehouse.Inventories.Form.Description")}
        component={renderTextarea}
        disabled={initialValues.completed}
      />

      {initialValues.id && (
        <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
          <FieldArray
            t={t}
            name="inventory_counts"
            component={ProductFields}
            inventory={initialValues}
            isCompleted={initialValues.completed}
          />
        </Form.Item>
      )}

      {error && (
        <Form.Item {...errorLayout}>
          <Alert type="error" message={error} />
        </Form.Item>
      )}

      <Form.Item {...buttonsLayout}>
        {initialValues.id ? (
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={pristine || initialValues.completed}
              loading={submitting}
            >
              {t("Save")}
            </Button>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              disabled={!pristine || initialValues.completed}
              onClick={onComplete}
              loading={isCompleting}
            >
              {t("Conduct")}
            </Button>
          </Space>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={pristine}
            loading={submitting}
          >
            {t("Start")}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

InventoryForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isCompleting: PropTypes.bool,
  onComplete: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  optionsData: PropTypes.object,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default reduxForm({
  form: "inventoryForm",
  enableReinitialize: true,
})(InventoryForm);
