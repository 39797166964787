export const salary = [
  {
    id: 1,
    employee: "Виктор",
    job_position: "Официант",
    rate_monthly: "0",
    hours: "-",
    shift: "-",
    shift_sales: "0",
    total: "0",
  },
  {
    id: 2,
    employee: "Марат",
    job_position: "Официант",
    rate_monthly: "0",
    hours: "-",
    shift: "-",
    shift_sales: "0",
    total: "0",
  },
  {
    id: 3,
    employee: "Анель",
    job_position: "Официант",
    rate_monthly: "0",
    hours: "-",
    shift: "-",
    shift_sales: "0",
    total: "0",
  },
  {
    id: 4,
    employee: "Александр",
    job_position: "Официант",
    rate_monthly: "0",
    hours: "-",
    shift: "-",
    shift_sales: "0",
    total: "0",
  },
  {
    id: 5,
    employee: "Яна",
    job_position: "Официант",
    rate_monthly: "0",
    hours: "-",
    shift: "-",
    shift_sales: "0",
    total: "0",
  },
  {
    id: 6,
    employee: "Марат",
    job_position: "Маркетолог",
    rate_monthly: "150 000,00",
    hours: "-",
    shift: "-",
    shift_sales: "0",
    total: "155 000,00",
  },
  {
    id: 7,
    employee: "Жанель",
    job_position: "Маркетолог",
    rate_monthly: "150 000,00",
    hours: "-",
    shift: "-",
    shift_sales: "0",
    total: "155 000,00",
  },
];
