import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Spin, notification } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Page from "components/Page";
import { parseFormErrors } from "utils/formErrors";

import CategoryForm from "./components/CategoryForm";
import { EDIT_CATEGORY_QUERY, UPDATE_CATEGORY_MUTATION } from "./categoryQueries";

const EditCategory = ({ match, history }) => {
  const { t } = useTranslation();
  const menuCategoryId = parseInt(match.params.id, 10);

  const [updateCategory] = useMutation(UPDATE_CATEGORY_MUTATION);
  const { data, loading } = useQuery(EDIT_CATEGORY_QUERY, {
    variables: { id: menuCategoryId },
    onError: notification.error,
  });

  const handleSubmit = ({ id, ...object }) =>
    updateCategory({ variables: { id: menuCategoryId, object } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  if (loading) {
    return <Spin />;
  }

  return (
    <Page showBackIcon title={t("menu.MenuCategories.EditPage.Title")}>
      <Row>
        <Col xs={24} md={18} lg={14}>
          <Card>
            <CategoryForm
              t={t}
              initialValues={data.menu_categories_by_pk}
              onSubmit={handleSubmit}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

EditCategory.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditCategory;
