import React from "react";
import { Switch, Route } from "react-router-dom";

import IncomingOrders from "./IncomingOrders";

const Container = () => (
  <Switch>
    <Route path="/statistics/incoming-orders" component={IncomingOrders} />
  </Switch>
);

export default Container;
