import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Drawer, notification, Table, Tabs, Typography } from "antd";
import { useQuery } from "@apollo/client";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { formatPrice } from "utils/helpers";

import PrepmealDetailsPanel from "pages/menu/prepmeals/components/PrepmealDetailsPanel";
import TechCardDetailsPanel from "pages/menu/techCards/components/TechCardDetailsPanel";
import IngredientDetailsPanel from "pages/menu/ingredients/components/IngredientDetailsPanel";

import {
  PRODUCTION_PRODUCTS_QUERY, PRODUCTION_PRODUCT_SPENDINGS_QUERY,
} from "../productionQueries";

import { PRODUCT_CATEGORY_TYPE_SINGULAR_LABELS } from "../../shared/utils";

const ProductsTable = ({ t, products, loading }) => {
  const [selectedTechCard, setSelectedTechCard] = useState();
  const [selectedIngredient, setSelectedIngredient] = useState();

  const productsWithType = products?.map((pp) => {
    if (pp.product.tech_card) {
      return {
        ...pp, type: pp.product.tech_card.menu_items.length === 0 ? "prepmeals" : "tech_cards",
      };
    }
    return { ...pp, type: "ingredients" };
  });

  const handleProductSelect = (product) => {
    if (product.tech_card) {
      setSelectedTechCard(product.tech_card);
    } else {
      setSelectedIngredient(product);
    }
  };

  return (
    <>
      <Table
        rowKey="id"
        pagination={false}
        size="small"
        loading={loading}
        dataSource={productsWithType}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={4}>
              {t("SummaryTotal")}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(products?.reduce((acc, pp) => acc + pp.cost * pp.quantity, 0))}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      >
        <Table.Column
          title={t("Item")}
          width="20%"
          dataIndex={["product", "name"]}
          render={(name, { product }) => (
            <Typography.Link onClick={() => handleProductSelect(product)}>
              {name}
            </Typography.Link>
          )}
        />
        <Table.Column
          title={t("Type")}
          dataIndex="type"
          render={(type) => PRODUCT_CATEGORY_TYPE_SINGULAR_LABELS[type]}
        />
        <Table.Column
          title={t("Quantity")}
          dataIndex="quantity"
          render={(quantity, { product }) => `${quantity} ${UNIT_LABELS_BY_VALUE[product.unit]}`}
        />
        <Table.Column
          title={t("Cost")}
          dataIndex="cost"
          render={formatPrice}
        />
        <Table.Column
          title={t("Amount")}
          dataIndex="cost"
          render={(cost, { quantity }) => formatPrice(cost * quantity)}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedTechCard?.name}
            &nbsp;
            <Link to={selectedTechCard?.menu_items.length === 0
              ? `/menu/prepmeals/${selectedTechCard?.id}`
              : `/menu/tech-cards/${selectedTechCard?.menu_items[0]?.menu_item_group_id}`}
            >
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedTechCard}
        onClose={() => setSelectedTechCard(null)}
        placement="bottom"
        height="60%"
      >
        {selectedTechCard && (selectedTechCard.menu_items.length === 0 ? (
          <PrepmealDetailsPanel t={t} prepmealId={selectedTechCard.id} />
        ) : (
          <TechCardDetailsPanel t={t} techCardId={selectedTechCard.id} />
        ))}
      </Drawer>
      <Drawer
        title={(
          <>
            {selectedIngredient?.name}
            &nbsp;
            <Link to={`/menu/ingredients/${selectedIngredient?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedIngredient}
        onClose={() => setSelectedIngredient(null)}
        placement="bottom"
        height="60%"
      >
        {selectedIngredient && (
          <IngredientDetailsPanel t={t} ingredientId={selectedIngredient.id} />
        )}
      </Drawer>
    </>
  );
};

ProductsTable.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      cost: PropTypes.number.isRequired,
      product: PropTypes.object.isRequired,
    }),
  ).isRequired,
};

const ProductionProductsTable = ({ t, productionId }) => {
  const { data, loading } = useQuery(PRODUCTION_PRODUCTS_QUERY, {
    variables: { productionId }, onError: notification.error,
  });
  return (
    <ProductsTable t={t} loading={loading} products={data?.production_products} />
  );
};

ProductionProductsTable.propTypes = {
  t: PropTypes.func.isRequired,
  productionId: PropTypes.number.isRequired,
};

const ProductionProductSpendingsTable = ({ t, productionId }) => {
  const { data, loading } = useQuery(PRODUCTION_PRODUCT_SPENDINGS_QUERY, {
    variables: { productionId }, onError: notification.error,
  });
  return (
    <ProductsTable t={t} loading={loading} products={data?.production_product_spendings} />
  );
};

ProductionProductSpendingsTable.propTypes = {
  t: PropTypes.func.isRequired,
  productionId: PropTypes.number.isRequired,
};

const ProductionDetailsPanel = ({ t, productionId }) => (
  <Tabs size="small" centered>
    <Tabs.TabPane tab={t("warehouse.Productions.DetailsPanel.ProductsTab.Title")} key="products">
      <ProductionProductsTable t={t} productionId={productionId} />
    </Tabs.TabPane>
    <Tabs.TabPane tab={t("warehouse.Productions.DetailsPanel.SpendingsTab.Title")} key="spendings">
      <ProductionProductSpendingsTable t={t} productionId={productionId} />
    </Tabs.TabPane>
  </Tabs>
);

ProductionDetailsPanel.propTypes = {
  t: PropTypes.func.isRequired,
  productionId: PropTypes.number.isRequired,
};

export default ProductionDetailsPanel;
