import gql from "graphql-tag";

export const ORDERS_QUERY = gql`
  query Orders($filter: orders_bool_exp, $offset: Int!, $limit: Int!) @hasura {
    orders(
      where: $filter,
      order_by: { time: desc },
      offset: $offset,
      limit: $limit,
    ) {
      id
      list_user
      list_price
      type
      time
      status
      status_updated_at
      source
      number
      deleted_at
      location {
        id
        name
      }
      delivery_info {
        id
        address {
          id
          street
          house
          flat
        }
      }
      order_payments {
        id
        type
        amount
        user_id
        created_at
        user {
          id
          name
        }
        payment_method {
          name
          type
        }
      }
    }
    orders_aggregate(where: $filter) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

export const FILTERS_FORM_QUERY = gql`
  query OrdersFiltersFormQuery @hasura {
    payment_methods  {
      id
      name
      type
    }
  }
`;

export const ORDER_DETAILS_QUERY = gql`
  query Orders($orderId: bigint!) @hasura {
    orders_by_pk(id: $orderId) {
      id
      list_user
      list_table_name
      list_price
      type
      time
      status
      status_updated_at
      deleted_at
      source
      number
      notes
      location {
        id
        name
      }
      customer {
        id
        name
        phone_number
        orders_aggregate {
          aggregate {
            count(columns: id)
          }
        }
      }
      delivery_info {
        id
        updated_at
        address {
          id
          street
          house
          flat
          notes
        }
      }
      courier {
        id
        name
      }
      order_payments {
        id
        type
        amount
        created_at
        user {
          id
          name
        }
        payment_method {
          id
          name
          type
        }
      }
      order_lines {
        id
        price
        count
        notes
        display_name
        total_cost
        total_price
        status
        status_updated_at
        created_at
        modifiers
        order_line_cancel {
          reason
          user {
            id
            name
          }
        }
        order_line_status_updates(order_by: {id: asc}) {
          id
          status
          created_at
          user_id
          user {
            id
            name
          }
          terminal {
            name
          }
        }
      }
      order_cancel {
        reason
        notes
        source
        user {
          id
          name
        }
      }
      order_price_updates(order_by: {id: asc}) {
        id
        message
        order_lines_total
        discount
        delivery_price
        service_percent
        list_price
        created_at
      }
      order_status_updates(order_by: {id: asc}) {
        status
        created_at
        user_id
        user {
          id
          name
        }
      }
    }
  }
`;

export const ORDER_SPENDINGS_QUERY = gql`
  query OrderSpendingsQuery($orderId: bigint!) @hasura {
    order_lines(
      where: {order_id: {_eq: $orderId}, order_line_product_spendings: {}}
      order_by: {id: asc}
    ) {
      id
      count
      unit
      display_name
      modifiers
      order_line_product_spendings {
        id
        quantity
        product {
          id
          name
          unit
          tech_card {
            id
            name
          }
        }
      }
    }
  }
`;
