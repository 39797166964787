import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row, Col, Table, Button, Input, notification, Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useQueryParam, StringParam } from "use-query-params";
import FoodIcon from "assets/food-icons";

import Page from "components/Page";

import { MENU_CATEGORIES_QUERY, UPDATE_CATEGORY_POSITION } from "./categoryQueries";
import CategoryDnD from "./components/CategoryDnD";

const Categories = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [isChangingOrder, setIsChangingOrder] = useState(false);
  const [categoriesOrdered, setCategoriesOrdered] = useState();

  const { data, loading } = useQuery(MENU_CATEGORIES_QUERY, {
    onError: notification.error,
  });

  const menuCategories = useMemo(() => (data?.menu_categories ?? [])
    .filter(({ name }) => (search && search.length > 0
      ? name.toLowerCase().includes(search) : true)), [data, search]);

  useEffect(() => { setCategoriesOrdered(data?.menu_categories ?? []); }, [data, isChangingOrder]);

  const [
    updateCategoryPosition,
    { loading: updatingPosition },
  ] = useMutation(UPDATE_CATEGORY_POSITION, {
    refetchQueries: [{ query: MENU_CATEGORIES_QUERY }],
    awaitRefetchQueries: true,
  });

  const handleSavePositions = () => {
    updateCategoryPosition({
      variables: {
        data: categoriesOrdered.map((c, index) => ({
          id: c.id,
          position: index,
        })),
      },
    })
      .then(() => setIsChangingOrder(false))
      .catch((e) => notification.error(e.message));
  };

  return (
    <Page
      title={t("menu.MenuCategories.Index.Title")}
      subTitle={data?.menu_categories.length.toString()}
      extra={isChangingOrder ? (
        <>
          <Button type="primary" onClick={handleSavePositions} loading={updatingPosition}>
            {t("Save")}
          </Button>
          <Button onClick={() => setIsChangingOrder(false)} disabled={updatingPosition}>
            {t("Cancel")}
          </Button>
        </>
      ) : (
        <>
          <Button onClick={() => setIsChangingOrder(true)}>
            {t("menu.MenuCategories.Index.ChangeOrder")}
          </Button>
          <Link to="/menu/categories/new">
            <Button type="primary">{t("Add")}</Button>
          </Link>
        </>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          style={{ width: 200 }}
          value={search}
          disabled={isChangingOrder}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
        />
      </Space>
      <Row>
        <Col md={24} lg={18} xl={15}>
          {isChangingOrder ? (
            <CategoryDnD
              categories={categoriesOrdered}
              onCategoryOrderChange={setCategoriesOrdered}
            />
          ) : (
            <Table
              rowKey="id"
              pagination={false}
              loading={loading}
              dataSource={menuCategories}
            >
              <Table.Column
                title={t("menu.MenuCategories.Columns.Name")}
                dataIndex="name"
                render={(_, record) => (
                  <Row align="middle">
                    <FoodIcon name={record.icon || "dinner"} stroke="black" style={{ width: 30 }} />
                    &nbsp;&nbsp;
                    {record.name}
                  </Row>
                )}
              />
              <Table.Column
                dataIndex="id"
                width={100}
                render={(id) => (
                  <Space size="small">
                    <Link to={`/menu/categories/${id}`}>{t("EditShort")}</Link>
                  </Space>
                )}
              />
            </Table>
          )}
        </Col>
      </Row>
    </Page>
  );
};

Categories.propTypes = {};

export default Categories;
