export const incomingOrders = [
  {
    id: 1,
    app: "Мобильное приложение",
    location_id: "Кофейня",
    waiter: "Дарья",
    reserved: "Зарезервировано",
    change: "23.09.2019",
    paid: "Оплачено",
    amount: "1 900",
    status: "Выполнено",
  },
  {
    id: 2,
    app: "Мобильное приложение",
    location_id: "Кафе на полянке",
    waiter: "Марат",
    reserved: "Незарезервировано",
    change: "20.09.2019",
    paid: "Неоплачено",
    amount: "2 158",
    status: "Выполнено",
  },
  {
    id: 3,
    app: "Мобильное приложение",
    location_id: "Кофейня",
    waiter: "Сауле",
    reserved: "Зарезервировано",
    change: "30.09.2019",
    paid: "Неоплачено",
    amount: "3 955",
    status: "Невыполнено",
  },
  {
    id: 4,
    app: "Мобильное приложение",
    location_id: "Кофейня",
    waiter: "Марат",
    reserved: "Зарезервировано",
    change: "05.09.2019",
    paid: "Оплачено",
    amount: "925",
    status: "Выполнено",
  },
  {
    id: 5,
    app: "Мобильное приложение",
    location_id: "Кафе на полянке",
    waiter: "Дарья",
    reserved: "Незарезервировано",
    change: "01.10.2019",
    paid: "Неоплачено",
    amount: "6 813",
    status: "Выполнено",
  },
];
