import React from "react";
import { Switch, Route } from "react-router-dom";

import NewCategory from "./NewCategory";
import EditCategory from "./EditCategory";
import Categories from "./Categories";

const Container = () => (
  <Switch>
    <Route path="/finance/categories/new" component={NewCategory} />
    <Route path="/finance/categories/:id" component={EditCategory} />
    <Route path="/finance/categories" component={Categories} />
  </Switch>
);

export default Container;
