import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button, Input, Table, notification, Space, Drawer,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { NumberParam, useQueryParam, StringParam, withDefault } from "use-query-params";

import Page from "components/Page";

import { formatPrice } from "utils/helpers";
import { sortByFnValue, sortByValue, sortByAlphabet } from "utils/sorts";
import { UNIT_LABELS_BY_VALUE } from "constants/index";

import PrepmealDetailsPanel from "./components/PrepmealDetailsPanel";

import { PREPMEALS_QUERY } from "./prepmealQueries";

const Prepmeals = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 20));
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [selectedPrepmeal, setSelectedPrepmeal] = useState(null);

  const { data, loading } = useQuery(PREPMEALS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  const prepmeals = useMemo(() => (data?.tech_cards ?? [])
    .filter(({ name }) => (search && search.length > 0
      ? name.toLowerCase().includes(search) : true)), [data, search]);

  return (
    <Page
      title={t("menu.Prepmeals.Index.Title")}
      subTitle={data?.tech_cards.length.toString()}
      headerContent={t("menu.Prepmeals.Index.Description")}
      extra={(
        <Link to="/menu/prepmeals/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          style={{ width: 200 }}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
        />
      </Space>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        loading={loading}
        dataSource={prepmeals}
      >
        <Table.Column
          title={t("menu.Prepmeals.Columns.Name")}
          dataIndex="name"
          render={(_, record) => (
            <Link to={`/menu/prepmeals/${record.id}`}>
              {record.name}
            </Link>
          )}
          sorter={sortByAlphabet("name")}
        />
        <Table.Column
          title={t("menu.Prepmeals.Columns.Output")}
          dataIndex="output_quantity"
          render={(output, { unit }) => `${output} ${UNIT_LABELS_BY_VALUE[unit]}`}
          sorter={sortByFnValue((record) => record.output_quantity * record.unit_weight)}
        />
        <Table.Column
          title={t("menu.Prepmeals.Columns.UnitWeight")}
          dataIndex="unit_weight"
          render={(unitWeight, { unit }) =>
            (unit !== "kg" ? `${unitWeight.toFixed(3)} ${UNIT_LABELS_BY_VALUE.kg}` : "-")}
          sorter={sortByValue("unit_weight")}
        />
        <Table.Column
          title={t("menu.Prepmeals.Columns.CostPrice")}
          dataIndex="cost"
          render={formatPrice}
          sorter={sortByValue("cost")}
        />
        <Table.Column
          title={t("menu.Prepmeals.Columns.IsProduced")}
          dataIndex="product_id"
          render={(product_id) => t(product_id ? "Yes" : "No")}
          sorter={sortByValue("product_id")}
        />
        <Table.Column
          dataIndex="id"
          render={(_, record) => (
            <Button type="link" size="small" onClick={() => setSelectedPrepmeal(record)}>
              {t("Details")}
            </Button>
          )}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedPrepmeal?.name}
            &nbsp;
            <Link to={`/menu/prepmeals/${selectedPrepmeal?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedPrepmeal}
        onClose={() => setSelectedPrepmeal(null)}
        placement="bottom"
        height="60%"
      >
        {selectedPrepmeal && (
          <PrepmealDetailsPanel t={t} prepmealId={selectedPrepmeal.id} />
        )}
      </Drawer>
    </Page>
  );
};

Prepmeals.propTypes = {};

export default Prepmeals;
