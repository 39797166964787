import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Form } from "@ant-design/compatible";
import { Button, Alert, Divider } from "antd";

import { renderInput, renderSelect, buttonLayout } from "components/FormItem";
import { required, requiredText } from "utils/formValidations";
import { singletonCurrency } from "utils/helpers";
import { UNIT_OPTIONS, UNIT_LABELS_BY_VALUE } from "constants/index";

import NewCategoryPane from "./NewCategoryPane";

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const parsePrice = (value) => {
  const price = parseFloat(value);
  return price || price === 0 ? parseFloat(price.toFixed(2)) : "";
};

const parseQuantity = (value) => {
  const price = parseFloat(value);
  return price || price === 0 ? parseFloat(price.toFixed(3)) : "";
};

const IngredientForm = ({
  t,
  handleSubmit,
  pristine,
  submitting,
  error,
  ingredientCategories,
  hasDeliveries = false,
  change: changeField,
}) => {
  const [isCreatingCategory, setCreatingCategory] = useState(false);
  const formSelector = formValueSelector("ingredientForm");
  const unit = useSelector((state) => formSelector(state, "unit"));

  const categoriesOptions = ingredientCategories
    .map(({ id, name }) => ({ value: id, label: name }));

  useEffect(() => {
    if (unit === "kg") {
      changeField("unit_weight", 1);
    }
  }, [unit]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {error && <Alert message={error} type="error" />}
        <Field
          name="name"
          label={t("menu.Ingredients.Form.Name")}
          component={renderInput}
          validate={requiredText}
        />
        <Field
          name="category_id"
          label={t("menu.Ingredients.Form.Category.Label")}
          component={renderSelect}
          style={{ width: 250 }}
          options={categoriesOptions}
          placeholder={t("menu.Ingredients.Form.Category.Placeholder")}
          validate={required}
          showSearch
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "2px 0 4px" }} />
              <LinkContainer onMouseDown={(e) => e.preventDefault()}>
                <Button type="link" onClick={() => setCreatingCategory(true)}>
                  {t("menu.Ingredients.Form.Category.DropdownButton")}
                </Button>
              </LinkContainer>
            </div>
          )}
        />
        <Field
          name="cost"
          label={t("menu.Ingredients.Form.Cost.Label")}
          placeholder={t("menu.Ingredients.Form.Cost.Placeholder")}
          extra={t("menu.Ingredients.Form.Cost.Help")}
          addonAfter={singletonCurrency.getCurrency()}
          component={renderInput}
          style={{ width: 150 }}
          type="number"
          step={0.01}
          parse={parsePrice}
          validate={required}
        />
        <Field
          name="unit"
          label={t("menu.Ingredients.Form.Unit.Label")}
          component={renderSelect}
          style={{ width: 150 }}
          options={UNIT_OPTIONS}
          disabled={hasDeliveries}
          extra={hasDeliveries
            ? t("menu.Ingredients.Form.Unit.DisabledHelp")
            : t("menu.Ingredients.Form.Unit.Help")}
          placeholder={t("menu.Ingredients.Form.Unit.Placeholder")}
          validate={required}
        />
        <Field
          name="unit_weight"
          label={t("menu.Ingredients.Form.UnitWeight.Label")}
          addonAfter={UNIT_LABELS_BY_VALUE.kg}
          component={renderInput}
          style={{ width: 150 }}
          disabled={unit === "kg"}
          type="number"
          step={0.001}
          parse={parseQuantity}
          validate={required}
        />
        <Form.Item {...buttonLayout}>
          <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
      <NewCategoryPane
        isOpen={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
      />
    </>
  );
};

IngredientForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  ingredientCategories: PropTypes.array.isRequired,
  hasDeliveries: PropTypes.bool,
};

export default reduxForm({
  form: "ingredientForm",
  initialValues: {
    cost: 0,
    unit: UNIT_OPTIONS[0].value,
    unit_weight: 0,
  },
})(IngredientForm);
