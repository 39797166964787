import React, { useMemo } from "react";
import moment from "moment";
import {
  Table, Input, Select, Row, Col, Space, notification,
} from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryParam, NumberParam, StringParam, withDefault } from "use-query-params";
import { useQuery, useLazyQuery } from "@apollo/client";

import ConnectData from "connect.container";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import { formatPrice, singletonCurrency } from "utils/helpers";
import { sortByValue } from "utils/sorts";
import { UNIT_LABELS_BY_VALUE } from "constants/index";
import colors from "theme/colors";

import { MENU_ITEMS_QUERY, MENU_ITEMS_SALES_STATS_QUERY } from "./productQueries";

const MARK_COLORS = {
  A: colors.backgroundGreen, B: colors.backgroundYellow, C: colors.backgroundRed,
};

const getMenuItemLink = (menuItem) => {
  if (menuItem.tech_card) {
    return menuItem.tech_card.is_set
      ? `/menu/sets/${menuItem.menu_item_group.id}`
      : `/menu/tech-cards/${menuItem.menu_item_group.id}`;
  }
  return `/menu/products/${menuItem.menu_item_group.id}`;
};

const ABCAnalysis = () => {
  const { t } = useTranslation();
  const { locations } = ConnectData.useContainer();

  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 100));
  const [categoryId, setCategoryId] = useQueryParam("category_id", NumberParam);
  const [search, setSearch] = useQueryParam("search", StringParam);

  const { data, loading } = useQuery(MENU_ITEMS_QUERY, {
    onError: notification.error,
  });
  const [getSalesMenuItems, salesDataQuery] = useLazyQuery(MENU_ITEMS_SALES_STATS_QUERY, {
    onError: notification.error,
  });

  const handleFilter = ({ time, locationId }) => {
    getSalesMenuItems({
      variables: {
        filters: {
          from: time.from.format(), to: time.to.format(), location_id: locationId,
        },
      },
    });
  };

  const productsWithABC = useMemo(() => {
    if (!salesDataQuery.data || !data) {
      return [];
    }
    const startDate = moment(salesDataQuery.variables.filters.from);

    const { totalCount, totalRevenue, totalProfit } = salesDataQuery.data.salesStatsByMenuItem
      .reduce((acc, { count, revenue, profit }) => ({
        totalCount: acc.totalCount + count,
        totalRevenue: acc.totalRevenue + revenue,
        totalProfit: acc.totalProfit + profit,
      }), { totalCount: 0, totalRevenue: 0, totalProfit: 0 });

    const productsWithSales = data.menu_items.reduce((acc, menuItem) => {
      const archivedAt = menuItem.archived_at || menuItem.menu_item_group.archived_at;
      if (archivedAt && startDate.isAfter(archivedAt)) { return acc; }

      const foundSaleMenuItem = salesDataQuery.data.salesStatsByMenuItem
        .find((saleMenuItem) => menuItem.id === saleMenuItem.menu_item_id)
        ?? { count: 0, cost: 0, revenue: 0, profit: 0 };

      return acc.concat({
        ...menuItem,
        ...foundSaleMenuItem,
        value: menuItem.product || menuItem.tech_card,
        count_percent: (foundSaleMenuItem.count / totalCount) || 0,
        revenue_percent: (foundSaleMenuItem.revenue / totalRevenue) || 0,
        profit_percent: (foundSaleMenuItem.profit / totalProfit) || 0,
      });
    }, []);

    const getMark = (percent, accPercent) => {
      if (percent === 0 && accPercent === 0) {
        return "-";
      }
      if (accPercent <= 0.8 || percent > 0.8) {
        return "A";
      } if (accPercent <= 0.95) {
        return "B";
      }
      return "C";
    };

    const splitToABC = (products, percentKey) => {
      const { ABCById: splitResult } = products
        .sort((lhs, rhs) => rhs[percentKey] - lhs[percentKey])
        .reduce(({ acc: prevAcc, ABCById }, product) => {
          const acc = prevAcc + product[percentKey];
          return { acc, ABCById: { ...ABCById, [product.id]: getMark(product[percentKey], acc) } };
        }, { acc: 0, ABCById: {} });
      return splitResult;
    };

    const countABCById = splitToABC(productsWithSales, "count_percent");
    const revenueABCById = splitToABC(productsWithSales, "revenue_percent");
    const profitABCById = splitToABC(productsWithSales, "profit_percent");

    return productsWithSales
      .sort((lhs, rhs) => rhs.revenue_percent - lhs.revenue_percent)
      .map((product, index) => ({
        ...product,
        rank: index + 1,
        count_mark: countABCById[product.id],
        revenue_mark: revenueABCById[product.id],
        profit_mark: profitABCById[product.id],
      }));
  }, [data, salesDataQuery.data]);

  const filteredProducts = useMemo(() => productsWithABC
    .filter(({ name, menu_item_group: mig }) => {
      if (search && search.length > 0 && !name.toLowerCase().includes(search)) {
        return false;
      }
      return !categoryId || categoryId === mig.category_id;
    }), [productsWithABC, categoryId, search]);

  return (
    <Page>
      <Row style={{ marginBottom: 12 }} gutter={[8, 8]} justify="space-between">
        <Col>
          <Space wrap>
            <FilterPanelWithQuery
              locations={locations}
              onFilter={handleFilter}
              initialData={{ time: { value: "month" } }}
            />
            <Select
              placeholder={t("Category")}
              style={{ width: 200 }}
              value={categoryId}
              onChange={(value) => setCategoryId(value)}
            >
              <Select.Option value={null}>{t("AllCategories")}</Select.Option>
              {data?.menu_categories.map((category) => (
                <Select.Option value={category.id} key={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Input.Search
            placeholder={t("QuickSearch")}
            style={{ width: 200 }}
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
          />
        </Col>
      </Row>

      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          pageSizeOptions: [100, 200, 500],
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        size="small"
        loading={loading}
        dataSource={filteredProducts}
      >
        <Table.Column
          title="#"
          dataIndex="rank"
          align="center"
          width={43}
        />
        <Table.Column
          title={t("statistics.ABCAnalysis.Columns.Item")}
          dataIndex="name"
          width="25%"
          render={(name, record) => {
            const menuItemName = name === record.menu_item_group.name
              ? record.menu_item_group.name
              : `${record.menu_item_group.name} ${name}`;
            return (
              <Link to={getMenuItemLink(record)}>{menuItemName}</Link>
            );
          }}
        />
        <Table.ColumnGroup title={t("statistics.ABCAnalysis.Columns.Revenue")} align="left">
          <Table.Column
            title={singletonCurrency.getCurrency()}
            dataIndex="revenue"
            render={(revenue, { revenue_mark }) => ({
              children: formatPrice(revenue),
              props: { style: { backgroundColor: MARK_COLORS[revenue_mark] } },
            })}
          />
          <Table.Column
            title="%"
            align="center"
            dataIndex="revenue_percent"
            render={(percent, { revenue_mark }) => ({
              children: `${(percent * 100).toFixed(2)}%`,
              props: { style: { backgroundColor: MARK_COLORS[revenue_mark] } },
            })}
          />
          <Table.Column
            title="ABC"
            align="center"
            width={90}
            dataIndex="revenue_mark"
            sorter={sortByValue("revenue_percent")}
            render={(mark) => ({
              children: mark,
              props: { style: { backgroundColor: MARK_COLORS[mark], fontWeight: "bold" } },
            })}
          />
        </Table.ColumnGroup>
        <Table.ColumnGroup title={t("statistics.ABCAnalysis.Columns.Profit")} align="left">
          <Table.Column
            title={singletonCurrency.getCurrency()}
            dataIndex="profit"
            render={(profit, { profit_mark }) => ({
              children: formatPrice(profit),
              props: { style: { backgroundColor: MARK_COLORS[profit_mark] } },
            })}
          />
          <Table.Column
            title="%"
            align="center"
            dataIndex="profit_percent"
            render={(percent, { profit_mark }) => ({
              children: `${(percent * 100).toFixed(2)}%`,
              props: { style: { backgroundColor: MARK_COLORS[profit_mark] } },
            })}
          />
          <Table.Column
            title="ABC"
            align="center"
            width={90}
            dataIndex="profit_mark"
            sorter={sortByValue("profit_percent")}
            render={(mark) => ({
              children: mark,
              props: { style: { backgroundColor: MARK_COLORS[mark], fontWeight: "bold" } },
            })}
          />
        </Table.ColumnGroup>
        <Table.ColumnGroup title={t("statistics.ABCAnalysis.Columns.Quantity")} align="left">
          <Table.Column
            title={UNIT_LABELS_BY_VALUE.pieces}
            dataIndex="count"
            width={90}
            render={(count, { count_mark, value }) => ({
              children: `${count} ${UNIT_LABELS_BY_VALUE[value.unit]}`,
              props: { style: { backgroundColor: MARK_COLORS[count_mark] } },
            })}
          />
          <Table.Column
            title="%"
            align="center"
            dataIndex="count_percent"
            render={(percent, { count_mark }) => ({
              children: `${(percent * 100).toFixed(2)}%`,
              props: { style: { backgroundColor: MARK_COLORS[count_mark] } },
            })}
          />
          <Table.Column
            title="ABC"
            align="center"
            width={90}
            dataIndex="count_mark"
            sorter={sortByValue("count_percent")}
            render={(mark) => ({
              children: mark,
              props: { style: { backgroundColor: MARK_COLORS[mark], fontWeight: "bold" } },
            })}
          />
        </Table.ColumnGroup>
      </Table>
    </Page>
  );
};

export default ABCAnalysis;
