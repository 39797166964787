import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as AsianCuisine } from "./asian-cuisine.svg";
import { ReactComponent as BirthdayCake } from "./birthday-cake.svg";
import { ReactComponent as BrewedCoffee } from "./brewed-coffee.svg";
import { ReactComponent as Brochette } from "./brochette.svg";
import { ReactComponent as Cake } from "./cake.svg";
import { ReactComponent as ChickenBreast } from "./chicken-breast.svg";
import { ReactComponent as ChineseTakeout } from "./chinese-takeout.svg";
import { ReactComponent as Coffee } from "./coffee.svg";
import { ReactComponent as Croissant } from "./croissant.svg";
import { ReactComponent as FrenchFries } from "./french-fries.svg";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import { ReactComponent as HotDog } from "./hot-dog.svg";
import { ReactComponent as IceCreamBowl } from "./ice-cream-bowl.svg";
import { ReactComponent as IceCream } from "./ice-cream.svg";
import { ReactComponent as Pie } from "./pie.svg";
import { ReactComponent as Pizza } from "./pizza.svg";
import { ReactComponent as Roast } from "./roast.svg";
import { ReactComponent as Sandwich } from "./sandwich.svg";
import { ReactComponent as Seafood } from "./seafood.svg";
import { ReactComponent as SodaGlass } from "./soda-glass.svg";
import { ReactComponent as Steak } from "./steak.svg";
import { ReactComponent as Sushi } from "./sushi.svg";
import { ReactComponent as Taco } from "./taco.svg";
import { ReactComponent as TakeOutBox } from "./take-out-box.svg";
import { ReactComponent as DomePlateCover } from "./dome-plate-cover.svg";
import { ReactComponent as Condiment } from "./condiment.svg";
import { ReactComponent as Dinner } from "./dinner.svg";
import { ReactComponent as GlazedDonut } from "./glazed-donut.svg";
import { ReactComponent as SunnySideUpEgg } from "./sunny-side-up-egg.svg";

export const MAP_ICON_NAMES = {
  "asian-cuisine": AsianCuisine,
  "birthday-cake": BirthdayCake,
  "brewed-coffee": BrewedCoffee,
  brochette: Brochette,
  cake: Cake,
  "chicken-breast": ChickenBreast,
  "chinese-takeout": ChineseTakeout,
  coffee: Coffee,
  croissant: Croissant,
  "french-fries": FrenchFries,
  hamburger: Hamburger,
  "hot-dog": HotDog,
  "ice-cream-bowl": IceCreamBowl,
  "ice-cream": IceCream,
  pie: Pie,
  pizza: Pizza,
  roast: Roast,
  sandwich: Sandwich,
  seafood: Seafood,
  "soda-glass": SodaGlass,
  steak: Steak,
  sushi: Sushi,
  taco: Taco,
  "take-out-box": TakeOutBox,
  "dome-plate-cover": DomePlateCover,
  condiment: Condiment,
  dinner: Dinner,
  "glazed-donut": GlazedDonut,
  "sunny-side-up-egg": SunnySideUpEgg,
};

const FoodIcon = ({ name, ...props }) => {
  const Icon = MAP_ICON_NAMES[name];
  return Icon ? <Icon {...props} /> : null;
};

FoodIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FoodIcon;
