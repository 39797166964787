export const receipts = [
  {
    id: 1,
    waiter: "Марат",
    open: "4 октября, 08:00",
    close: "08:00",
    paid: "1 897,85",
    discount: "0",
    profit: "1 308,79",
    status: "Закрыт",
  },
  {
    id: 2,
    waiter: "Сауле",
    open: "4 октября, 07:00",
    close: "07:00",
    paid: "1 837,85",
    discount: "0",
    profit: "1 108,79",
    status: "Закрыт",
  },
  {
    id: 3,
    waiter: "Яна",
    open: "4 октября, 06:00",
    close: "06:00",
    paid: "2 397,85",
    discount: "0",
    profit: "2 308,79",
    status: "Закрыт",
  },
  {
    id: 4,
    waiter: "Александр",
    open: "4 октября, 05:00",
    close: "05:00",
    paid: "1 897,85",
    discount: "0",
    profit: "1 308,79",
    status: "Закрыт",
  },
  {
    id: 5,
    waiter: "Яна",
    open: "4 октября, 04:00",
    close: "04:00",
    paid: "5 983,02",
    discount: "0",
    profit: "4 728,79",
    status: "Закрыт",
  },
  {
    id: 6,
    waiter: "Антон",
    open: "4 октября, 03:00",
    close: "03:00",
    paid: "3 897,85",
    discount: "0",
    profit: "2 308,79",
    status: "Закрыт",
  },
  {
    id: 7,
    waiter: "Марат",
    open: "4 октября, 02:00",
    close: "02:00",
    paid: "2 310,79",
    discount: "0",
    profit: "1 308,79",
    status: "Закрыт",
  },
];

export const account = [
  {
    id: 1,
    product: "Яблочный фреш",
    price: "250",
    quantity: "1",
    total: "250",
  },
  {
    id: 2,
    product: "Pepsi",
    price: "400",
    quantity: "2",
    total: "800",
  },
  {
    id: 3,
    product: "Бутерброд с икрой",
    price: "570",
    quantity: "1",
    total: "570",
  },
];

export const history = [
  {
    id: 1,
    time: "4 октября, 08:00",
    terminal: "Демо-терминал",
    action: "Закрыт счет",
  },
  {
    id: 2,
    time: "4 октября, 08:00",
    terminal: "Демо-терминал",
    action: "Добавлен товар: Бутерброд с икрой",
  },
  {
    id: 3,
    time: "4 октября, 08:00",
    terminal: "Демо-терминал",
    action: "Добавлен товар: Pepsi",
  },
  {
    id: 4,
    time: "4 октября, 08:00",
    terminal: "Демо-терминал",
    action: "Добавлен товар: Яблочный фреш",
  },
  {
    id: 5,
    time: "4 октября, 08:00",
    terminal: "Демо-терминал",
    action: "Счет открыт в заведении 'Кафе на Полянке'",
  },
];

export const writeOffs = [
  {
    id: 1,
    product: "Яблочный фреш",
    warehouse: "Cклад Бар",
    quantity: "1",
    costPrice: "215",
  },
  {
    id: 2,
    product: "Бутерброд с икрой",
    warehouse: "Склад Кухня",
    quantity: "1",
    costPrice: "425",
  },
  {
    id: 3,
    product: "Pepsi",
    warehouse: "Cклад Бар",
    quantity: "2",
    costPrice: "360",
  },
];
