import React from "react";
import styled from "styled-components";
import { Table, Row, Button, Col, notification } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";

import { LIMIT_PAGE } from "constants/index";

import { LOCATION_COLUMNS } from "./constants";
import { LOCATIONS_QUERY } from "./locationQueries";

const Container = styled.div`
  padding: 12px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Locations = ({ history }) => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const { data, loading } = useQuery(LOCATIONS_QUERY, {
    onError: notification.error,
    fetchPolicy: "cache-and-network",
  });

  return (
    <Container>
      <StyledRow type="flex" justify="end">
        <FlexCol span={4}>
          <Button
            type="primary"
            onClick={() => history.push("/settings/locations/new")}
          >
            {t("Add")}
          </Button>
        </FlexCol>
      </StyledRow>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize: LIMIT_PAGE,
        }}
        loading={loading}
        columns={LOCATION_COLUMNS}
        dataSource={data && data.locations.filter((l) => !l.deleted_at)}
      />
    </Container>
  );
};

Locations.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Locations;
