import React, { useState } from "react";
import styled from "styled-components";
import { Table, Input, Row, Col, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";

import { LIMIT_PAGE } from "constants/index";

import {
  RECEIPT_COLUMNS,
  EXPANDED_TABLE_HEADER_ACCOUNT,
  EXPANDED_TABLE_HEADER_HISTORY,
  EXPANDED_TABLE_HEADER_WRITEOFFS,
} from "./constants";
import { receipts, account, history, writeOffs } from "./mock";

const Container = styled.div`
  padding: 12px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const { Search } = Input;
const { TabPane } = Tabs;

const renderRowDetails = ({ t }) => (
  <Tabs>
    <TabPane tab={t("statistics.Receipts.Account")} key="1">
      <Table
        rowKey="id"
        columns={EXPANDED_TABLE_HEADER_ACCOUNT}
        dataSource={account}
        pagination={false}
      />
    </TabPane>
    <TabPane tab={t("statistics.Receipts.History")} key="2">
      <Table
        rowKey="id"
        columns={EXPANDED_TABLE_HEADER_HISTORY}
        dataSource={history}
        pagination={false}
      />
    </TabPane>
    <TabPane tab={t("statistics.Receipts.WriteOff")} key="3">
      <Table
        rowKey="id"
        columns={EXPANDED_TABLE_HEADER_WRITEOFFS}
        dataSource={writeOffs}
        pagination={false}
      />
    </TabPane>
  </Tabs>
);

const Receipts = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [search, setSearch] = useState("");

  const receiptsData = receipts.filter(({ waiter, status }) =>
    `${waiter}_${status}`.includes(search.trim().toLowerCase()));

  return (
    <Container>
      <StyledRow>
        <FlexCol>
          <Col span={4}>
            <Search
              placeholder={t("QuickSearch")}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </FlexCol>
      </StyledRow>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize: LIMIT_PAGE,
        }}
        columns={RECEIPT_COLUMNS}
        dataSource={receiptsData}
        expandedRowRender={(props) => renderRowDetails({ t, ...props })}
      />
    </Container>
  );
};

export default Receipts;
