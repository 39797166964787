import gql from "graphql-tag";

export const CASHIER_QUERY = gql`
  query GetCashier @hasura {
    locations {
      code
    }
    terminals {
      password
    }
    users {
      pin_code
    }
  }
`;

export const ORDERS_QUERY = gql`
  query GetOrders @hasura {
    orders {
      id
    }
  }
`;
