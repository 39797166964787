import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Transactions from "./transactions";
import Accounts from "./accounts";
import CashierShifts from "./cashierShifts";
import Salary from "./salary";
import Categories from "./categories";

const Container = () => (
  <Switch>
    <Route path="/finance/transactions" component={Transactions} />
    <Route path="/finance/accounts" component={Accounts} />
    <Route path="/finance/cashier-shifts" component={CashierShifts} />
    <Route path="/finance/salary" component={Salary} />
    <Route path="/finance/categories" component={Categories} />

    <Redirect to="/finance/transactions" />
  </Switch>
);

export default Container;
