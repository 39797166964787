import React from "react";
import { Field } from "redux-form";
import { Table } from "antd";
import PropTypes from "prop-types";
import moment from "moment";

import colors from "theme/colors";
import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { renderInput, renderLabel } from "components/FormItem";
import { formatPrice } from "utils/helpers";
import { required } from "utils/formValidations";

import ProductSpendingPopover from "./ProductSpendingPopover";
import WriteOffPopover from "./WriteOffPopover";
import DeliveryPopover from "./DeliveryPopover";

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

const capPrecision = (value, precision) => parseFloat(value.toFixed(precision));

const parseFloatValue = (precision) => (value) => {
  const parsedValue = parseFloat(value);
  return parsedValue || parsedValue === 0 ? capPrecision(parsedValue, precision) : "";
};

const ProductFields = ({ t, fields, inventory, isCompleted }) => (
  <Table
    rowKey={(field) => fields.getAll().indexOf(field)}
    dataSource={fields.getAll()}
    size="small"
    scroll={{ x: 1060 }}
    pagination={false}
  >
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.Name")}
      dataIndex={["product", "name"]}
      width={160}
      fixed
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.LastChecked")}
      dataIndex="last_inventory_count"
      width={170}
      align="center"
      render={(count) => (
        count ? moment(count.datetime).format("lll") : "-"
      )}
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.InitialQuantity")}
      dataIndex="initial_quantity"
      width={100}
      align="center"
      render={(_, { last_inventory_count, product }) => (
        <span>
          {last_inventory_count?.actual_quantity ?? 0} {UNIT_LABELS_BY_VALUE[product.unit]}
        </span>
      )}
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.Delivery")}
      dataIndex="deliveries"
      width={100}
      align="center"
      render={(_, inventoryCount) => (
        <DeliveryPopover inventory={inventory} inventoryCount={inventoryCount} t={t} />
      )}
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.ProductSpending")}
      dataIndex="product_spendings"
      width={100}
      align="center"
      render={(_, inventoryCount) => (
        <ProductSpendingPopover
          inventory={inventory}
          inventoryCount={inventoryCount}
          t={t}
        />
      )}
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.WriteOff")}
      dataIndex="write_offs"
      width={100}
      align="center"
      render={(_, inventoryCount) =>
        <WriteOffPopover inventory={inventory} inventoryCount={inventoryCount} t={t} />}
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.ExpectedQuantity")}
      dataIndex="expected_quantity"
      width={100}
      align="center"
      render={(expectedQuantity, { product }) => (
        `${expectedQuantity} ${UNIT_LABELS_BY_VALUE[product.unit]}`
      )}
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.ActualQuantity")}
      dataIndex="actual_quantity"
      width={110}
      align="center"
      render={(_, _f, index) => (
        <Field
          name={`${fields.name}[${index}].actual_quantity`}
          component={renderInput}
          validate={required}
          type="number"
          step={0.001}
          parse={parseFloatValue(3)}
          formItemProps={formItemLayout}
          disabled={isCompleted}
        />
      )}
    />
    <Table.Column
      title={t("warehouse.Inventories.Form.Table.Difference")}
      dataIndex="difference"
      width={120}
      render={(_, { expected_quantity, cost }, index) => (
        <Field
          formItemProps={formItemLayout}
          name={`${fields.name}[${index}].actual_quantity`}
          component={renderLabel}
          format={(actualQuantity) => {
            const amount = cost * ((actualQuantity || 0) - expected_quantity);
            return (
              <span style={{ color: amount >= 0 ? colors.green : colors.red }}>
                {formatPrice(amount)}
              </span>
            );
          }}
        />
      )}
    />
  </Table>
);

ProductFields.propTypes = {
  t: PropTypes.func.isRequired,
  inventory: PropTypes.object,
  fields: PropTypes.object.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default ProductFields;
