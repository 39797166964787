import React from "react";
import { Route, Switch } from "react-router-dom";

import Inventories from "./Inventories";
import NewInventory from "./NewInventory";
import EditInventory from "./EditInventory";

const Container = () => (
  <Switch>
    <Route path="/warehouse/inventories/new" component={NewInventory} />
    <Route path="/warehouse/inventories/:id" component={EditInventory} />
    <Route path="/warehouse/inventories" component={Inventories} />
  </Switch>
);

export default Container;
