import gql from "graphql-tag";

export const TECH_CARD_PRODUCTS_QUERY = gql`
  query GetTechCardProducts($id: Int!) @hasura {
    tech_cards_by_pk(id: $id) {
      process_description
      process_duration
      tech_card_products(order_by: {id: asc}) {
        id
        product_id
        product {
          id
          name
          unit
          unit_weight
        }
        tech_card_id
        tech_card {
          id
          name
          unit
          unit_weight
        }
        quantity
        cold_loss
        hot_loss
        cost
      }
    }
    menu_item_modifier_group(
      order_by: {id: asc}
      where: {menu_item: {tech_card_id: {_eq: $id}}}
    ) {
      modifier_group {
        id
        name
        modifier_items(order_by: {id: asc}, where: {archived_at: {_is_null: true}}) {
          id
          name
        }
      }
    }
  }
`;

export const TECH_CARDS_USING_INGREDIENT_QUERY = gql`
  query GetTechCardsUsingIngredient($techCardId: Int, $productId: Int) @hasura {
    tech_card_products(
      order_by: {id: asc}
      where: {tech_card_id: {_eq: $techCardId}, product_id: {_eq: $productId}}
    ) {
      id
      product {
        id
        name
        unit
        unit_weight
      }
      tech_card {
        id
        name
        unit
        unit_weight
      }
      source_tech_card {
        id
        name
        cost
        menu_items {
          id
          menu_item_group_id
          menu_item_group {
            id
            archived_at
          }
        }
      }
      quantity
      cold_loss
      hot_loss
      cost
    }
    modifier_items(
      order_by: {id: asc}
      where: {tech_card_id: {_eq: $techCardId}, product_id: {_eq: $productId}}
    ) {
      id
      name
      price
      cost
      quantity
      product {
        id
        unit
      }
      tech_card {
        id
        unit
      }
      archived_at
      modifier_group {
        id
        name
        description
        archived_at
      }
    }
  }
`;
