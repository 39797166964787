import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./Login";
import Signup from "./Signup";

const AuthRouter = ({ refetch }) => (
  <Switch>
    <Route path="/login" render={() => <Login refetch={refetch} />} />
    <Route path="/signup" render={() => <Signup refetch={refetch} />} />
    <Redirect to="/login" />
  </Switch>
);

AuthRouter.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default AuthRouter;
