import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Popover, Button, Spin } from "antd";
import { Table as ExpandedTable, Thead } from "components/Table";
import { useQuery } from "@apollo/client";
import moment from "moment";

import i18n from "i18n";

import { PRODUCT_SPENDINGS_QUERY } from "../inventoriesQueries";

const EXPANDED_TABLE_HEADER = [
  i18n.t("warehouse.Inventories.ProductSpenindPopover.Table.Date"),
  i18n.t("warehouse.Inventories.ProductSpenindPopover.Table.Quantity"),
  i18n.t("warehouse.Inventories.ProductSpenindPopover.Table.Name"),
  i18n.t("warehouse.Inventories.ProductSpenindPopover.Table.Username"),
];

const Container = styled.div`
  width: 650px;
  max-height: 250px;

  b {
    padding-left: 20px;
    padding-top: 10px;
    display: block;
  }

  table {
    box-shadow: unset;
  }
`;

const StyledButton = styled(Button)`
  border: none;
  background-color: transparent;
  box-shadow: none;
  
  & > span {
    margin-right: 4px;
  }

  i > svg {
    margin-bottom: -1px;
  }
`;

const Placeholder = styled.div`
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const StyledTable = styled(ExpandedTable)`
  width: 100%;

  tr > th {
    padding: 10px 8px 8px 8px;
  }

  tr:nth-child(odd) > td {
    background-color: #effaff;
  }

  td {
    padding: 2px 0;

    :first-child {
      padding-left: 12px;
    }

    :last-child {
      padding-left: 12px;
    }
  }
`;

const Content = ({ t, inventory, inventoryCount: { product, last_inventory_count } }) => {
  const { data, loading } = useQuery(PRODUCT_SPENDINGS_QUERY, {
    variables: {
      filter: {
        product_id: { _eq: product.id },
        order_line: {
          order: {
            time: last_inventory_count
              ? { _gte: last_inventory_count.datetime, _lte: inventory.datetime }
              : { _lte: inventory.datetime },
            location_id: { _eq: inventory.location_id },
          },
        },
      },
    },
  });

  if (loading) {
    return (
      <Container>
        <center>
          <Spin />
        </center>
      </Container>
    );
  }

  return (
    <Container>
      {data.order_line_product_spendings.length > 0 ? (
        <>
          <b>
            {t("warehouse.Inventories.ProductSpenindPopover.Title")}
          </b>
          <StyledTable index>
            <Thead columns={EXPANDED_TABLE_HEADER} />
            <tbody>
              {data.order_line_product_spendings.map(({ id, order_line, quantity }) => (
                <tr key={id}>
                  <td width={200}>
                    {moment(order_line.order.time).format("lll")}
                  </td>
                  <td width={70} align="center">
                    {quantity}&nbsp;{t(`const:unit_type.${product.unit}`)}
                  </td>
                  <td width={280}>
                    {order_line.display_name}
                    {order_line.count > 1 && ` (x${order_line.count})`}
                  </td>
                  <td>{order_line.order.list_user}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      ) : (
        <Placeholder>
          {t("warehouse.Inventories.ProductSpenindPopover.Placeholder")}
        </Placeholder>
      )}
    </Container>
  );
};

Content.propTypes = {
  inventoryCount: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const ProductSpendingPopover = ({ t, inventoryCount, inventory }) => {
  const [isVisible, setVisible] = useState(false);
  const { product_spendings, product } = inventoryCount;

  return (
    <Popover
      content={<Content t={t} inventory={inventory} inventoryCount={inventoryCount} />}
      visible={isVisible}
      onVisibleChange={setVisible}
      trigger="click"
      placement="bottom"
    >
      <StyledButton>
        <span>
          {product_spendings} {t(`const:unit_type.${product.unit}`)}
        </span>
        <i>
          <svg
            viewBox="0 0 1024 1024"
            focusable="false"
            data-icon="caret-down"
            width="10px"
            height="10px"
            fill="#7f7f7f"
            aria-hidden="true"
          >
            <path
              d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
            />
          </svg>
        </i>
      </StyledButton>
    </Popover>
  );
};

ProductSpendingPopover.propTypes = {
  inventoryCount: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default ProductSpendingPopover;
