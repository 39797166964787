import React from "react";
import { notification, Spin } from "antd";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";
import Charts from "components/Charts";

import { SALES_QUERY, SALES_PAGE_QUERY, DELIVERY_ZONE_QUERY } from "./deliveryZoneQueries";

const DeliveryZone = ({ match }) => {
  const deliveryZoneId = parseInt(match.params.id, 10);

  const { t } = useTranslation();

  const [getSales, sales] = useLazyQuery(SALES_QUERY, {
    onError: notification.error,
  });

  const salesPageDataQuery = useQuery(SALES_PAGE_QUERY, {
    onError: notification.error,
  });

  const deliveryZoneQuery = useQuery(DELIVERY_ZONE_QUERY, {
    variables: { id: deliveryZoneId },
  });

  const handleFilter = ({ time, locationId }) => {
    getSales({
      variables: {
        filters: {
          from: time.from.format(),
          to: time.to.format(),
          location_id: locationId,
        },
        delivery_zone_id: deliveryZoneId,
      },
    });
  };

  return (
    <Page
      showBackIcon
      title={`${t("statistics.DeliveryZone.Title")}${deliveryZoneQuery.data
        ? ` - ${deliveryZoneQuery.data.delivery_zones_by_pk.name}` : ""}`}
    >
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        onFilter={handleFilter}
        locations={salesPageDataQuery.data ? salesPageDataQuery.data.locations : []}
        initialData={{ time: { value: "month" } }}
      />
      {sales.data ? (
        <Charts
          data={sales.data}
          t={t}
        />
      ) : (
        <Spin />
      )}
    </Page>
  );
};

DeliveryZone.propTypes = {
  match: PropTypes.object.isRequired,
};

export default DeliveryZone;
