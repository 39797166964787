import React from "react";
import { reduxForm, Field } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Alert, Button, Row, Col } from "antd";

import { required } from "utils/formValidations";
import { renderInput, renderSelect, renderTextarea } from "components/FormItem";

const StyledForm = styled(Form)`
  margin: 20px 40px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-top: 20px;
`;

const LocationForm = ({
  t, error, handleSubmit, pristine, submitting, financeAccounts,
}) => {
  const financeAccountOptions = financeAccounts
    .map((fa) => ({ value: fa.id, label: fa.name }));
  return (
    <StyledForm onSubmit={handleSubmit}>
      {error && <Alert type="error" message={error} />}
      <Row>
        <Col xs={24} md={20} lg={16} xl={12}>
          <Field
            name="name"
            label={t("settings.Location.Form.Location.Label")}
            validate={required}
            component={renderInput}
          />
          <Field
            name="description"
            label={t("Description")}
            component={renderTextarea}
          />
          <Field
            name="address"
            label={t("settings.Location.Form.Address.Label")}
            component={renderInput}
          />
          <Field
            name="phone_number"
            label={t("settings.Location.Form.PhoneNumber.Label")}
            component={renderInput}
          />
          <Field
            name="card_payment_account_id"
            label={t("settings.Location.Form.CardPaymentAccount.Label")}
            component={renderSelect}
            options={financeAccountOptions}
          />
          <StyledFormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              {t("Save")}
            </Button>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
};

LocationForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  financeAccounts: PropTypes.array,
};

export default reduxForm({
  form: "locationForm",
})(LocationForm);
