import React from "react";
import { Route, Switch } from "react-router-dom";

import TechCards from "./TechCards";
import NewTechCard from "./NewTechCard";
import EditTechCard from "./EditTechCard";

const Container = () => (
  <Switch>
    <Route path="/menu/tech-cards/new" component={NewTechCard} />
    <Route path="/menu/tech-cards/:id" component={EditTechCard} />
    <Route path="/menu/tech-cards" component={TechCards} />
  </Switch>
);

export default Container;
