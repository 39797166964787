/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";

import OrderDetails from "./components/OrderDetails";
import OrderSpendingsTable from "./components/OrderSpendingsTable";

const OrderDetailsTabs = ({ match }) => {
  const { t } = useTranslation();
  return (
    <Tabs centered>
      <Tabs.TabPane tab={t("orders.OrderDetailsTabs.Details")} key="details">
        <OrderDetails orderId={match.params.id} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t("orders.OrderDetailsTabs.Spendings")} key="spendings">
        <OrderSpendingsTable orderId={match.params.id} />
      </Tabs.TabPane>
    </Tabs>
  );
};

OrderDetailsTabs.propTypes = {
  match: PropTypes.object.isRequired,
};

export default OrderDetailsTabs;
