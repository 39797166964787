import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { Row, Col, Card, notification, Spin } from "antd";

import Page from "components/Page";

import CompanySettingsForm from "./components/CompanySettingsForm";
import GeneralSettingsForm from "./components/GeneralSettingsForm";
import { GENERAL_SETTINGS_QUERY, COMPANY_SETTINGS_MUTATION, GENERAL_SETTINGS_MUTATION } from "./generalSettingsQueries";

const General = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(GENERAL_SETTINGS_QUERY, {
    onError: notification.error,
  });
  const [updateGeneralSettings] = useMutation(GENERAL_SETTINGS_MUTATION);
  const [updateCompanySettings] = useMutation(COMPANY_SETTINGS_MUTATION);

  if (loading) return <Spin />;

  const onUpdateCompanySettings = ({ id, ...values }) => {
    updateCompanySettings({ variables: { id: data.merchants[0].id, ...values } })
      .then(() => notification.success({ message: t("settings.General.Notification.CompanySettings") }))
      .catch(notification.error);
  };

  const onUpdateGeneralSettings = ({ id, ...values }) => {
    updateGeneralSettings({ variables: { merchant_id: data.merchants[0].id, data: values } })
      .then(() => notification.success({ message: t("settings.General.Notification.GeneralSettings") }))
      .catch(notification.error);
  };

  return (
    <Page>
      <Row gutter={[8, 16]} justify="center">
        <Col xs={24} lg={16}>
          <Card title={t("settings.General.Form.Titles.CompanySettings")}>
            <CompanySettingsForm
              t={t}
              onSubmit={onUpdateCompanySettings}
              initialValues={data.merchants[0]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={16}>
          <Card title={t("settings.General.Form.Titles.GeneralSettings")}>
            <GeneralSettingsForm
              t={t}
              onSubmit={onUpdateGeneralSettings}
              initialValues={data.common_settings[0]}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default General;
