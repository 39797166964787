import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import colors from "theme/colors";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
  color: ${colors.primaryColor};
`;

export const ACCESS_CHECKBOXS = [
  { value: "access_menu", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_menu") },
  { value: "access_warehouse", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_warehouse") },
  { value: "access_finance", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_finance") },
  { value: "access_orders", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_orders") },
  { value: "access_cashier", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_cashier") },
  { value: "access_statistics", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_statistics") },
  { value: "access_users", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_users") },
  { value: "access_location_admin", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_location_admin") },
  { value: "access_marketing", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_marketing") },
  { value: "access_callcenter", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_callcenter") },
  { value: "access_courier", label: i18n.t("access.AccessRoles.AccessCheckboxs.access_courier") },
];

export const ACCESS_ROLE_COLUMNS = [
  {
    title: i18n.t("access.AccessRoles.Columns.Name"),
    dataIndex: "name",
    render: (_, record) => (
      <StyledLink to={`/access/access-roles/${record.id}`}>
        {record.name === "salempos_generated_owner" ? i18n.t("const:owner") : record.name}
      </StyledLink>
    ),
  },
  {
    title: i18n.t("access.AccessRoles.Columns.Access"),
    dataIndex: "access",
    render: (_, value) => {
      const arrayObjects = ACCESS_CHECKBOXS.filter((item) => value[item.value]);
      return arrayObjects.length === ACCESS_CHECKBOXS.length
        ? i18n.t("access.AccessRoles.Columns.FullAccess")
        : arrayObjects.map((item) => item.label).join(", ") || "-";
    },
  },
];
