import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import styled from "styled-components";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";

import { required } from "utils/formValidations";
import { renderSelect, renderInput } from "components/FormItem";

const DeleteButton = styled(Button)`
  margin-right: 10px;
`;

const FlexCol = styled(Col)`
  height: 20px;
  display: flex;
  align-items: center;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  style: { marginTop: 0, marginBottom: 0 },
};

const PromotionConditionFields = ({
  t,
  fields,
  menuItemsOptions,
  conditionType,
}) => {
  if (fields.length === 0) {
    fields.push({ min_count: 0, min_price: 0 });
  }

  return (
    <div>
      <Row gutter={12}>
        <Col xs={0} lg={12}>
          <span>{t("marketing.Promotion.Form.Question.WhatOrder")}</span>
        </Col>
        <Col xs={0} lg={4}>
          <span>{t("marketing.Promotion.Form.Question.HowMuch")}</span>
        </Col>

        <Col xs={0} lg={6}>
          <span>{t("marketing.Promotion.Form.Question.OrHowMuch")}</span>
        </Col>
      </Row>
      {fields.map((promotion_condition, index) => (
        <div key={index}>
          <Row gutter={12}>
            <Col xs={24} lg={12}>
              <Field
                name={`${promotion_condition}.menu_item_id`}
                component={renderSelect}
                formItemProps={formItemLayout}
                options={menuItemsOptions}
                placeholder={t("marketing.Promotion.Form.ChooseProduct")}
                validate={required}
              />
            </Col>
            <Col xs={24} lg={4}>
              <Field
                name={`${promotion_condition}.min_count`}
                component={renderInput}
                type="number"
                placeholder={t("Quantity")}
                validate={required}
                formItemProps={formItemLayout}
              />
            </Col>
            <Col xs={24} lg={4}>
              <Field
                name={`${promotion_condition}.min_price`}
                component={renderInput}
                type="number"
                placeholder={t("marketing.Promotion.Form.ForAmount")}
                addonBefore="$"
                validate={required}
                formItemProps={formItemLayout}
              />
            </Col>
            <Col xs={24} lg={4}>
              {fields.length > 1 && (
                <DeleteButton
                  type="danger"
                  onClick={() => fields.remove(index)}
                >
                  <DeleteOutlined />
                </DeleteButton>
              )}
              {fields.length - 1 === index && (
                <Button onClick={() => fields.push({
                  min_count: 0,
                  min_price: 0,
                })}
                >
                  <PlusOutlined />
                </Button>
              )}
            </Col>
          </Row>
          {fields.length > 1 && fields.length - 1 !== index && (
            <Row>
              <FlexCol>
                <span>{conditionType === "or" ? t("or") : t("and")}</span>
              </FlexCol>
            </Row>
          )}
        </div>
      ))}
    </div>
  );
};

PromotionConditionFields.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  menuItemsOptions: PropTypes.array.isRequired,
  conditionType: PropTypes.string,
};

export default PromotionConditionFields;
