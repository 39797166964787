import gql from "graphql-tag";

export const PAYMENTS_QUERY = gql`
  query PaymentOrders($filters: FiltersInput!) {
    paymentsStatsByDay(filters: $filters) {
      day
      location {
        id
        name
      }
      stats {
        amount
        type
        payment_method {
          id
          name
          type
        }
      }
    }
  }
`;
