import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Spin, Table, Typography, notification, Drawer,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { formatPrice } from "utils/helpers";

// eslint-disable-next-line import/no-cycle
import SetDetailsPanel from "../../sets/components/SetDetailsPanel";

import { TECH_CARDS_USING_INGREDIENT_QUERY } from "../sharedMenuQueries";

const UsageInSetsTable = ({ techCardId, productId }) => {
  const { t } = useTranslation();
  const [selectedSet, setSelectedSet] = useState();

  const { data, loading } = useQuery(TECH_CARDS_USING_INGREDIENT_QUERY, {
    variables: { techCardId, productId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spin />
      </div>
    );
  }

  const techCardUsages = data?.tech_card_products.map(({ tech_card, product, ...tcp }) => {
    const value = tech_card || product;
    return { ...tcp, value };
  });

  if (techCardUsages?.length === 0) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography.Text type="secondary">
          {techCardId
            ? t("menu.UsageInSetsTable.TechCardNotUsed")
            : t("menu.UsageInSetsTable.ProductNotUsed")}
        </Typography.Text>
      </div>
    );
  }

  return (
    <>
      <Table
        size="small"
        rowKey="id"
        bordered
        dataSource={techCardUsages}
        pagination={false}
      >
        <Table.Column
          title={t("menu.UsageInSetsTable.Columns.Name")}
          dataIndex="source_tech_card"
          width="20%"
          render={(set) => (
            <Typography.Link onClick={() => setSelectedSet(set)}>
              {set.name}
              {set.menu_items[0].menu_item_group.archived_at
                && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
            </Typography.Link>
          )}
        />
        <Table.Column
          title={t("menu.UsageInSetsTable.Columns.BruttoUnit")}
          dataIndex="quantity"
          render={(quantity, { value }) => `${quantity} ${UNIT_LABELS_BY_VALUE[value.unit]}`}
        />
        <Table.Column
          title={t("menu.UsageInSetsTable.Columns.Cost")}
          dataIndex="cost"
          render={formatPrice}
        />
        <Table.Column
          title={t("menu.UsageInSetsTable.Columns.CostPercent.Title")}
          dataIndex="source_tech_card"
          width="15%"
          render={(stc, { cost }) => t("menu.UsageInSetsTable.Columns.CostPercent.Content", {
            percent: parseFloat(((cost / stc.cost) * 100).toFixed(2)),
            totalCost: formatPrice(stc.cost),
          })}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedSet?.name}
            &nbsp;
            <Link to={`/menu/sets/${selectedSet?.menu_items[0].menu_item_group_id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedSet}
        onClose={() => setSelectedSet(null)}
        placement="bottom"
        height="60%"
      >
        {selectedSet && (
          <SetDetailsPanel t={t} setId={selectedSet.id} />
        )}
      </Drawer>
    </>
  );
};

UsageInSetsTable.propTypes = {
  techCardId: PropTypes.number,
  productId: PropTypes.number,
};

export default UsageInSetsTable;
