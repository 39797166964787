import React from "react";
import { Switch, Route } from "react-router-dom";

import PaymentMethods from "./PaymentMethods";
import NewPaymentMethods from "./NewPaymentMethod";
import EditPaymentMethods from "./EditPaymentMethod";

const Container = () => (
  <Switch>
    <Route path="/settings/payment-methods/new" component={NewPaymentMethods} />
    <Route path="/settings/payment-methods/:id" component={EditPaymentMethods} />
    <Route path="/settings/payment-methods" component={PaymentMethods} />
  </Switch>
);

export default Container;
