import React from "react";
import styled from "styled-components";
import { PageHeader, Typography } from "antd";
import PropTypes from "prop-types";

const Container = styled.div`
  .ant-page-header {
    padding: 12px 24px;
  }

  .ant-page-header:empty {
    padding: 0;
  }

  .ant-page-header-content {
    padding-top: 6px;
  }

  .ant-page-header-content:only-child {
    padding-top: 0;
  }

  .ant-page-header-heading-title {
    overflow: visible;
  }

  .ant-page-header-heading-sub-title {
    padding-top: 4px;
    white-space: pre-wrap;
  }

  .page-content > .ant-spin {
    margin: 72px auto 0;
    width: 100%;
  }
`;

const Page = ({
  title, subTitle, showBackIcon, tags, footer, extra, children, headerContent, contentPadding = 12,
}) => (
  <Container>
    <PageHeader
      ghost={false}
      title={title}
      subTitle={subTitle}
      onBack={showBackIcon ? () => window.history.back() : null}
      tags={tags}
      footer={footer}
      extra={extra}
    >
      {headerContent && (
        <Typography.Text type="secondary">
          {headerContent}
        </Typography.Text>
      )}
    </PageHeader>
    <div className="page-content" style={{ padding: contentPadding }}>
      {children}
    </div>
  </Container>
);

Page.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showBackIcon: PropTypes.bool,
  tags: PropTypes.node,
  footer: PropTypes.node,
  extra: PropTypes.node,
  children: PropTypes.node.isRequired,
  headerContent: PropTypes.node,
  contentPadding: PropTypes.number,
};

export default Page;
