import React from "react";
import { Switch, Route } from "react-router-dom";

import WriteOffs from "./WriteOffs";
import NewWriteOff from "./NewWriteOff";
import WriteOffDetails from "./WriteOffDetails";

const Container = () => (
  <Switch>
    <Route path="/warehouse/write-offs/new" component={NewWriteOff} />
    <Route path="/warehouse/write-offs/:id" component={WriteOffDetails} />
    <Route path="/warehouse/write-offs" component={WriteOffs} />
  </Switch>
);

export default Container;
