import React from "react";
import { Route, Switch } from "react-router-dom";

import Promotions from "./Promotions";
import NewPromotion from "./NewPromotion";
import EditPromotion from "./EditPromotion";

const Container = () => (
  <Switch>
    <Route path="/marketing/promotions/new" component={NewPromotion} />
    <Route path="/marketing/promotions/:id" component={EditPromotion} />
    <Route path="/marketing/promotions" component={Promotions} />
  </Switch>
);

export default Container;
