import React from "react";
import styled from "styled-components";
import { Table, Row, Button, Col, notification } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { PAYMENT_METHOD_COLUMNS } from "./constants";
import { PAYMENT_METHODS_QUERY } from "./paymentMethodsQueries";

const Container = styled.div`
  padding: 12px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const PaymentMethods = ({ history }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(PAYMENT_METHODS_QUERY, {
    onError: notification.error,
    fetchPolicy: "cache-and-network",
  });

  return (
    <Container>
      <StyledRow type="flex" justify="end">
        <FlexCol span={4}>
          <Button
            type="primary"
            onClick={() => history.push("/settings/payment-methods/new")}
          >
            {t("Add")}
          </Button>
        </FlexCol>
      </StyledRow>
      <Table
        bordered
        rowKey="id"
        loading={loading}
        columns={PAYMENT_METHOD_COLUMNS}
        dataSource={data && data.payment_methods}
      />
    </Container>
  );
};

PaymentMethods.propTypes = {
  history: PropTypes.object.isRequired,
};

export default PaymentMethods;
