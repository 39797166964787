import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import colors from "theme/colors";

const COLORS = [
  "#ffff00",
  "#ff0000",
  "#0000ff",
  "#00b000",
  "#7f6000",
  "#9900ff",
  "#ff00ff",
  "#999999",
  "#e06666",
];

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid ${(props) => props.color};
`;

const ErrorText = styled.p`
  color: ${colors.red};
`;

const Map = ({ dataMap, input, meta, clustererPoints }) => {
  const [objectManagerRef, setObjectManager] = useState();
  const [clustererRef, setClustererRef] = useState();
  const [mapRef, setMap] = useState();

  useEffect(() => {
    if (!window.ymaps) return;
    window.ymaps.ready(["polylabel.create"]).then(() => {
      const map = new window.ymaps.Map(
        "map",
        {
          center: [49.786715, 73.107226],
          zoom: 12,
          controls: ["zoomControl", "typeSelector", "fullscreenControl"],
        },
        { maxZoom: 18, minZoom: 10 },
      );

      if (input) {
        const { onChange, value } = input;
        const newPolygon = new window.ymaps.Polygon(value ? value.coordinates : [], {}, {
          editorDrawingCursor: "crosshair",
          fillColor: "#00FF00",
          fillOpacity: 0.4,
          strokeColor: "#FF0000",
          strokeWidth: 3,
        });

        map.geoObjects.add(newPolygon);
        newPolygon.editor.startDrawing();
        newPolygon.editor.events.add("statechange", () => {
          if (!newPolygon.editor.state.get("drawing")) {
            onChange({
              type: "Polygon",
              coordinates: newPolygon.geometry.getCoordinates(),
            });
          }
        });
      }
      setMap(map);
    });
  }, []);

  useEffect(() => {
    if (!mapRef) return;
    if (objectManagerRef) {
      mapRef.geoObjects.remove(objectManagerRef);
    }
    if (clustererRef) {
      mapRef.geoObjects.remove(clustererRef);
    }
    if (!dataMap) {
      setObjectManager(null);
      return;
    }
    const objectManager = new window.ymaps.ObjectManager();
    dataMap.forEach((zone, index) => {
      objectManager.add({
        type: "Feature",
        id: zone.id,
        geometry: zone.geo,
        options: {
          fillColor: COLORS[index % COLORS.length],
          fillOpacity: 0.4,
          strokeColor: COLORS[index % COLORS.length],
          stokeWidth: 0,
          labelDefaults: "light",
          labelLayout: zone.name,
          cursor: "grab",
        },
      });
    });

    // eslint-disable-next-line no-new, new-cap
    new window.ymaps.polylabel.create(mapRef, objectManager);

    if (clustererPoints && clustererPoints.length > 0) {
      const clusterer = new window.ymaps.Clusterer({ gridSize: 800 });
      clusterer.add(clustererPoints.map((geometry) => new window.ymaps.GeoObject({
        geometry,
      })));
      mapRef.geoObjects.add(clusterer);
      setClustererRef(clusterer);
    }

    mapRef.geoObjects.add(objectManager);
    setObjectManager(objectManager);
  }, [dataMap, clustererPoints, mapRef]);

  return (
    <>
      <MapContainer
        id="map"
        color={(meta && meta.touched && meta.error) ? colors.red : colors.primaryColor}
      />
      {meta && meta.touched && meta.error && (
        <ErrorText>{meta.error}</ErrorText>
      )}
    </>
  );
};

Map.propTypes = {
  dataMap: PropTypes.array.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  clustererPoints: PropTypes.array,
};

export default Map;
