import React from "react";
import { Switch, Route } from "react-router-dom";

import Shops from "./Shops";

const Container = () => (
  <Switch>
    <Route path="/statistics/locations" component={Shops} />
  </Switch>
);

export default Container;
