import React, { useMemo } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table, Input, Select, Row, Col, Space, notification,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQueryParam, NumberParam, StringParam, withDefault } from "use-query-params";
import { useQuery, useLazyQuery } from "@apollo/client";

import ConnectData from "connect.container";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import { formatPrice } from "utils/helpers";
import { sortByAlphabet, sortByValue } from "utils/sorts";
import { UNIT_LABELS_BY_VALUE } from "constants/index";

import { MENU_ITEMS_QUERY, MENU_ITEMS_SALES_STATS_QUERY } from "./productQueries";

const Products = () => {
  const { t } = useTranslation();
  const { locations } = ConnectData.useContainer();

  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 50));
  const [categoryId, setCategoryId] = useQueryParam("category_id", NumberParam);
  const [search, setSearch] = useQueryParam("search", StringParam);

  const { data, loading } = useQuery(MENU_ITEMS_QUERY, {
    onError: notification.error,
  });
  const [getSalesMenuItems, salesDataQuery] = useLazyQuery(MENU_ITEMS_SALES_STATS_QUERY, {
    onError: notification.error,
  });

  const handleFilter = ({ time, locationId }) => {
    getSalesMenuItems({
      variables: {
        filters: {
          from: time.from.format(), to: time.to.format(), location_id: locationId,
        },
      },
    });
  };

  const products = useMemo(() => {
    if (!salesDataQuery.data || !data) {
      return [];
    }
    const startDate = moment(salesDataQuery.variables.filters.from);

    return data.menu_items.reduce((acc, menuItem) => {
      const archivedAt = menuItem.archived_at || menuItem.menu_item_group.archived_at;
      if (archivedAt && startDate.isAfter(archivedAt)) { return acc; }

      const foundSaleMenuItem = salesDataQuery.data.salesStatsByMenuItem
        .find((saleMenuItem) => menuItem.id === saleMenuItem.menu_item_id)
        ?? { count: 0, cost: 0, revenue: 0, profit: 0 };

      return acc.concat({
        ...menuItem, ...foundSaleMenuItem, value: menuItem.product || menuItem.tech_card,
      });
    }, []);
  }, [data, salesDataQuery.data]);

  const filteredProducts = useMemo(() => products?.filter(({ name, menu_item_group: mig }) => {
    if (search && search.length > 0 && !name.toLowerCase().includes(search)) {
      return false;
    }
    return !categoryId || categoryId === mig.category_id;
  }), [products, categoryId, search]);

  return (
    <Page>
      <Row style={{ marginBottom: 12 }} gutter={[8, 8]} justify="space-between">
        <Col>
          <Space wrap>
            <FilterPanelWithQuery
              locations={locations}
              onFilter={handleFilter}
              initialData={{ time: { value: "month" } }}
            />
            <Select
              placeholder={t("Category")}
              style={{ width: 200 }}
              value={categoryId}
              onChange={(value) => setCategoryId(value)}
            >
              <Select.Option value={null}>{t("AllCategories")}</Select.Option>
              {data?.menu_categories.map((category) => (
                <Select.Option value={category.id} key={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Input.Search
            placeholder={t("QuickSearch")}
            style={{ width: 200 }}
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
          />
        </Col>
      </Row>

      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        loading={loading}
        dataSource={filteredProducts}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell>
              {t("SummaryTotal")}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {formatPrice(filteredProducts?.reduce((acc, i) => (acc + i.revenue), 0))}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(filteredProducts?.reduce((acc, i) => (acc + i.cost), 0))}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(filteredProducts?.reduce((acc, i) => (acc + i.profit), 0))}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      >
        <Table.Column
          title={t("statistics.Products.Columns.Item")}
          dataIndex="name"
          sorter={sortByAlphabet("name")}
          render={(name, record) => {
            const menuItemName = name === record.menu_item_group.name
              ? record.menu_item_group.name
              : `${record.menu_item_group.name} ${name}`;

            return (
              <Link to={`/statistics/products/${record.id}${window.location.search}`}>
                {menuItemName}
              </Link>
            );
          }}
        />
        <Table.Column
          title={t("statistics.Products.Columns.Quantity")}
          dataIndex="count"
          sorter={sortByValue("count")}
          render={(count, record) => (`${count || 0} ${UNIT_LABELS_BY_VALUE[record.value.unit]}`)}
        />
        <Table.Column
          title={t("statistics.Products.Columns.Revenue")}
          dataIndex="revenue"
          sorter={sortByValue("revenue")}
          render={formatPrice}
        />
        <Table.Column
          title={t("statistics.Products.Columns.Cost")}
          dataIndex="cost"
          sorter={sortByValue("cost")}
          render={formatPrice}
        />
        <Table.Column
          title={t("statistics.Products.Columns.Profit")}
          dataIndex="profit"
          sorter={sortByValue("profit")}
          defaultSortOrder="descend"
          render={formatPrice}
        />
      </Table>
    </Page>
  );
};

export default Products;
