import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { formatPrice } from "utils/helpers";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
`;

export const DELIVERY_ZONE_COLUMNS = [
  {
    title: i18n.t("settings.DeliveryZones.Columns.Name"),
    dataIndex: "name",
    render: (name, value) => (
      <StyledLink to={`/settings/delivery-zones/${value.id}`}>{name}</StyledLink>
    ),
  },
  {
    title: i18n.t("settings.DeliveryZones.Columns.Description"),
    dataIndex: "description",
    render: (description) => description || "-",
  },
  {
    title: i18n.t("settings.DeliveryZones.Columns.Location"),
    dataIndex: "location",
    render: (location) => location.name,
  },
  {
    title: i18n.t("settings.DeliveryZones.Columns.DeliveryPrice"),
    dataIndex: "price",
    render: (price) => formatPrice(price),
  },
  {
    title: i18n.t("settings.DeliveryZones.Columns.MinOrderSumForDelivery"),
    dataIndex: "min_order_sum_for_delivery",
    render: (min_order_sum_for_delivery) => formatPrice(min_order_sum_for_delivery),
  },
  {
    title: i18n.t("settings.DeliveryZones.Columns.OrderSumForFreeDelivery"),
    dataIndex: "order_sum_for_free_delivery",
    render: (order_sum_for_free_delivery) => (
      order_sum_for_free_delivery ? formatPrice(order_sum_for_free_delivery) : "-"
    ),
  },
];
