import React from "react";
import PropTypes from "prop-types";
import { Spin, notification, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";

import { parseFormErrors } from "utils/formErrors";

import InventoryForm from "./components/InventoryForm";
import {
  EDIT_INVENTORY_QUERY,
  UPDATE_INVENTORY_MUTATION,
  COMPLETE_INVENTORY_MUTATION,
} from "./inventoriesQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

const EditInventory = ({ match, history }) => {
  const inventoryId = parseInt(match.params.id, 10);
  const { t } = useTranslation();

  const { data, loading } = useQuery(EDIT_INVENTORY_QUERY, {
    variables: { id: inventoryId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const productsAndLocationsQuery = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const [updateInventory] = useMutation(UPDATE_INVENTORY_MUTATION, {
    refetchQueries: [{ query: EDIT_INVENTORY_QUERY, variables: { id: inventoryId } }],
    awaitRefetchQueries: true,
  });
  const [completeInventory, { loading: isCompleting }] = useMutation(COMPLETE_INVENTORY_MUTATION);

  if (loading || productsAndLocationsQuery.loading) {
    return <Spin />;
  }

  const handleSubmit = ({ description, inventory_counts }) =>
    updateInventory({
      variables: {
        inventory_id: inventoryId,
        data: {
          description,
          inventory_counts: inventory_counts
            .map(({ id, actual_quantity }) => ({ id, actual_quantity })),
        },
      },
    })
      .catch(parseFormErrors);

  const handleComplete = () =>
    completeInventory({ variables: { inventory_id: inventoryId } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  const initialInventory = data && {
    ...data.inventories_by_pk,
    inventory_categories: data.inventories_by_pk.inventory_categories
      .map(({ type, category_id, menu_category_id }) => {
        switch (type) {
          case "prepmeals": return { type };
          case "ingredients": return { type, category_id };
          default: return { type, menu_category_id };
        }
      }).map(JSON.stringify),
  };

  return (
    <Page showBackIcon title={t("warehouse.Inventories.DetailPage.Title")}>
      <Card>
        <InventoryForm
          t={t}
          initialValues={initialInventory}
          optionsData={productsAndLocationsQuery.data}
          isCompleting={isCompleting}
          onSubmit={handleSubmit}
          onComplete={handleComplete}
        />
      </Card>
    </Page>
  );
};

EditInventory.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditInventory;
