import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Spin, notification, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";
import NewIngredientPane from "components/NewIngredientPane";
import NewWorkshopPane from "components/NewWorkshopPane";

import PrepmealForm from "./components/PrepmealForm";
import { PREPMEAL_QUERY, SELECT_OPTIONS, UPDATE_PREPMEAL_MUTATION } from "./prepmealQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const EditPrepmeal = ({ match, history }) => {
  const prepmealId = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const [isCreatingIngridient, setCreatingIngridient] = useState(false);
  const [isCreatingWorkshop, setCreatingWorkshop] = useState(false);

  const { data: prepmeal, loading: loadingPrepmeal } = useQuery(PREPMEAL_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: { id: prepmealId },
    onError: notification.error,
  });
  const { data, loading } = useQuery(SELECT_OPTIONS, {
    onError: notification.error,
  });
  const [updatePrepmeal] = useMutation(UPDATE_PREPMEAL_MUTATION, {
    refetchQueries: [
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });

  if (loadingPrepmeal || loading) return <Spin />;

  const handleSubmit = ({
    output, total_cost, tech_card_products, product_id, id: techCardId, ...values
  }) => {
    const techCardProducts = tech_card_products.map(({
      content, id, brutto_unit, cold_loss, hot_loss, cost,
    }) => ({
      id,
      product_id: null,
      tech_card_id: null,
      ...content,
      quantity: brutto_unit,
      cost,
      cold_loss,
      hot_loss,
    }));
    const unitChanged = prepmeal?.tech_cards_by_pk.unit !== values.unit;
    return updatePrepmeal({
      variables: {
        id: techCardId,
        data: {
          ...values,
          tech_card_products: { data: techCardProducts },
        },
      },
    })
      .then(() => {
        history.goBack();
        if (unitChanged) {
          Modal.warn({
            title: t("menu.Prepmeals.CheckTechCardsWarning.Title"),
            content: t("menu.Prepmeals.CheckTechCardsWarning.Content"),
            onOk() {},
          });
        }
      })
      .catch(notification.error);
  };

  const initialPrepmeal = data && prepmeal && {
    ...prepmeal.tech_cards_by_pk,
    is_produced: !!prepmeal.tech_cards_by_pk.product_id,
    tech_card_products: prepmeal.tech_cards_by_pk.tech_card_products.map(({
      product_id, tech_card_id, quantity: brutto_unit, ...rest
    }) => {
      const ingredient = product_id
        ? data.products.find((p) => p.id === product_id)
        : data.tech_cards.find((tc) => tc.id === tech_card_id);
      const content = product_id ? { product_id } : { tech_card_id };
      const contentPath = (ingredient.category_id ? ["ingredients"] : [])
        .concat(ingredient.category_id || 0, JSON.stringify(content));
      return { ...rest, brutto_unit, ingredient, content, content_path: contentPath };
    }),
  };

  return (
    <Page showBackIcon title={t("menu.Prepmeals.EditPage.Title")}>
      <Card>
        <PrepmealForm
          t={t}
          onSubmit={handleSubmit}
          initialValues={initialPrepmeal}
          optionsData={data}
          onIngridientCreate={() => setCreatingIngridient(true)}
          onWorkshopCreate={() => setCreatingWorkshop(true)}
        />
      </Card>
      <NewIngredientPane
        isOpen={isCreatingIngridient}
        onClose={() => setCreatingIngridient(false)}
      />
      <NewWorkshopPane
        isOpen={isCreatingWorkshop}
        onClose={() => setCreatingWorkshop(false)}
      />
    </Page>
  );
};

EditPrepmeal.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditPrepmeal;
