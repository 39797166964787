import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Button, Tag, notification, Space } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";

import { CheckCircleOutlined, NodeIndexOutlined } from "@ant-design/icons";

import { formatPrice } from "utils/helpers";
import { UNIT_LABELS_BY_VALUE } from "constants/index";
import colors from "theme/colors";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import { MOVINGS_QUERY } from "./movingQueries";

const MovingProductsTable = ({ t, products }) => (
  <Table
    rowKey="id"
    pagination={false}
    size="small"
    style={{ margin: "10px 100px 10px 50px" }}
    dataSource={products}
  >
    <Table.Column
      title={t("Item")}
      dataIndex={["product", "name"]}
    />
    <Table.Column
      title={t("Quantity")}
      dataIndex="quantity"
      render={(quantity, record) => `${quantity} ${UNIT_LABELS_BY_VALUE[record.product.unit]}`}
    />
    <Table.Column
      title={t("Cost")}
      dataIndex="cost"
      render={formatPrice}
    />
    <Table.Column
      title={t("Amount")}
      dataIndex="cost"
      render={(cost, record) => formatPrice(cost * record.quantity)}
    />
  </Table>
);

MovingProductsTable.propTypes = {
  t: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      product: PropTypes.object.isRequired,
    }),
  ),
};

const Movings = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 30));

  const [getMovings, { data, loading }] = useLazyQuery(MOVINGS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  useEffect(_.throttle(() => {
    if (filter?.time) {
      getMovings({
        variables: {
          limit: pageSize,
          offset: page * pageSize - pageSize,
          filter: {
            datetime: { _gte: filter.time.from, _lt: filter.time.to },
            ...(filter.locationId ? {
              _or: [{
                location_from_id: { _eq: filter.locationId },
              }, {
                location_to_id: { _eq: filter.locationId },
              }],
            } : {}),
          },
        },
      });
    }
  }, 200), [page, pageSize, filter]);

  return (
    <Page
      title={t("warehouse.Movings.Index.Title")}
      subTitle={data?.movings_aggregate.aggregate.count.toString()}
      extra={(
        <Link to="/warehouse/movings/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        initialData={{ time: { value: "this_month" } }}
        locations={data?.locations ?? []}
        onFilter={(values) => { setPage(1); setFilter(values); }}
      />
      <Table
        rowKey="id"
        dataSource={data?.movings}
        loading={loading}
        pagination={{
          total: data?.movings_aggregate.aggregate.count,
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        expandable={{
          expandIconColumnIndex: -1,
          expandedRowKeys,
          expandedRowRender: (record) => (
            <MovingProductsTable t={t} products={record.moving_products} />
          ),
        }}
      >
        <Table.Column
          title="#"
          dataIndex="number"
          width={80}
          align="center"
        />
        <Table.Column
          title={t("warehouse.Movings.Columns.Date")}
          dataIndex="datetime"
          render={(datetime) => moment(datetime).format("lll")}
        />
        <Table.Column
          title={t("warehouse.Movings.Columns.Items")}
          dataIndex="moving_products"
          render={(products) => products.map(({ product: { name } }) => name).join(", ")}
        />
        <Table.Column
          title={t("warehouse.Movings.Columns.Description")}
          dataIndex="description"
          render={(value) => value || "-"}
        />
        <Table.Column
          title={t("warehouse.Movings.Columns.Warehouses")}
          dataIndex="locations"
          render={(_f, moving) => `${moving.location_from.name} → ${moving.location_to.name}`}
        />
        <Table.Column
          title={t("warehouse.Movings.Columns.Amount")}
          dataIndex="moving_products"
          render={(products) => formatPrice(products.reduce(
            (sum, product) => sum + parseFloat(product.cost) * product.quantity, 0,
          ))}
        />
        <Table.Column
          title={t("warehouse.Movings.Columns.Status")}
          dataIndex="completed"
          render={(completed) => (completed ? (
            <Tag icon={<CheckCircleOutlined />} color={colors.green}>{t("Completed")}</Tag>
          ) : (
            <Tag icon={<NodeIndexOutlined />} color={colors.secondaryColor}>{t("Started")}</Tag>
          ))}
        />
        <Table.Column
          dataIndex="id"
          width={135}
          render={(id, { completed }) => (
            <Space size="small">
              <Button
                type="link"
                size="small"
                onClick={() => {
                  if (expandedRowKeys.includes(id)) {
                    return setExpandedRowKeys(expandedRowKeys.filter((v) => v !== id));
                  }
                  return setExpandedRowKeys(expandedRowKeys.concat(id));
                }}
              >
                {t("Details")}
              </Button>
              {!completed && (
                <Link to={`/warehouse/movings/${id}`}>{t("EditShort")}</Link>
              )}
            </Space>
          )}
        />
      </Table>
    </Page>
  );
};

Movings.propTypes = {};

export default Movings;
