import gql from "graphql-tag";

export const TERMINALS_QUERY = gql`
  query @hasura {
    terminals {
      id
      location {
        id
        name
        code
      }
      name
      password
      type
    }
  }
`;

export const TERMINAL_QUERY = gql`
  query GetTerminal($id: Int!) @hasura {
    terminals(where: { id: { _eq: $id } }) {
      id
      location_id
      name
      password
      type
      settings
    }
    locations {
      id
      name
    }
    workshops {
      id
      name
    }
  }
`;

export const CREATE_TERMINAL_MUTATION = gql`
  mutation CreateTerminal($data: terminals_insert_input!) @hasura {
    insert_terminals(objects: [$data]) {
      returning {
        id
        name
        location_id
        type
        settings
      }
    }
  }
`;

export const UPDATE_TERMINAL_MUTATION = gql`
  mutation UpdateTerminal($id: Int!, $data: terminals_set_input!) @hasura {
    update_terminals(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
        name
        location_id
        password
        type
        settings
      }
    }
  }
`;

export const DELETE_TERMINAL_MUTATION = gql`
  mutation DeleteTerminal($id: Int!) @hasura {
    delete_terminals(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const LOCATIONS_AND_WORKSHOPS_QUERY = gql`
  query @hasura {
    locations {
      id
      name
    }
    workshops {
      id
      name
    }
  }
`;
