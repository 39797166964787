import React from "react";
import { Switch, Route } from "react-router-dom";

import Receipts from "./Receipts";

const Container = () => (
  <Switch>
    <Route path="/statistics/receipts" component={Receipts} />
  </Switch>
);

export default Container;
