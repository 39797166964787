/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FoodIcon from "assets/food-icons";
import styled from "styled-components";
import colors from "theme/colors";
import { MenuOutlined } from "@ant-design/icons";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const CategoryList = styled.div`
  background: white;
  padding: 8px;
`;

const CategoryItem = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  padding: 16px;
  margin: 0 0 8px 0;
  border-radius: 5px;
  align-items: center;
  background: ${colors.whisper};
  justify-content: space-between;
`;

const CategoryDnD = ({ categories, onCategoryOrderChange }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    onCategoryOrderChange(reorder(
      categories,
      result.source.index,
      result.destination.index,
    ));
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <CategoryList
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {categories.map((item, index) => (
              <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                {(provided) => (
                  <CategoryItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <FoodIcon
                        name={item.icon || "dinner"}
                        stroke="black"
                        style={{ width: 30, marginRight: 10 }}
                      />
                      <span style={{ fontSize: 16 }}>{item.name}</span>
                    </div>
                    <MenuOutlined style={{ fontSize: 16 }} />
                  </CategoryItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </CategoryList>
        )}
      </Droppable>
    </DragDropContext>
  );
};

CategoryDnD.propTypes = {
  categories: PropTypes.array.isRequired,
  onCategoryOrderChange: PropTypes.func.isRequired,
};

export default CategoryDnD;
