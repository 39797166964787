import React from "react";
import { Switch, Route } from "react-router-dom";

import StatsProduct from "./StatsProduct";
import Products from "./Products";
import ABCAnalysis from "./ABCAnalysis";

const Container = () => (
  <Switch>
    <Route path="/statistics/products/:id" component={StatsProduct} />
    <Route path="/statistics/products" component={Products} />
    <Route path="/statistics/abc-analysis" component={ABCAnalysis} />
  </Switch>
);

export default Container;
