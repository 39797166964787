/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import styled from "styled-components";
import { Layout, Dropdown, Menu, Button } from "antd";
import {
  DownOutlined, SettingOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined,
} from "@ant-design/icons";

import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConnectData from "connect.container";

import colors from "theme/colors";

const LayoutHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  padding: 0 20px;
  border-bottom: 1px solid ${colors.borderColor};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const DropdownContent = styled.div`
  cursor: pointer;
  padding-right: 15px;
  padding-left: 15px;

  :hover {
    background: ${colors.whiteSmoke};
  }
`;

const Header = ({ collapsed, toggle, onLogout }) => {
  const { t } = useTranslation();
  const { currentUser } = ConnectData.useContainer();

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/account">
          <SettingOutlined />
          {t("header.AccountSettings")}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a onClick={onLogout}>
          <LogoutOutlined />
          {t("header.Logout")}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <LayoutHeader>
      <Button
        type="text"
        onClick={() => toggle(!collapsed)}
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      />

      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <DropdownContent>
          {currentUser.name} <DownOutlined />
        </DropdownContent>
      </Dropdown>
    </LayoutHeader>
  );
};

Header.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default withRouter(Header);
