import gql from "graphql-tag";

export const INGREDIENTS_QUERY = gql`
  query GetIngredients @hasura {
    products(order_by: {id: asc}, where: {category_id: {_is_null: false}}) {
      id
      name
      cost
      unit
      unit_weight
      category_id
      product_category {
        id
        name
      }
    }
    product_categories {
      id
      name
    }
  }
`;

export const INGREDIENT_QUERY = gql`
  query GetIngredients($id: Int!) @hasura {
    products_by_pk(id: $id) {
      id
      name
      cost
      unit
      unit_weight
      category_id
      product_category {
        id
        name
      }
      location_products_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

export const CREATE_INGREDIENT_MUTATION = gql`
  mutation CreateIngredient($data: products_insert_input!) @hasura {
    insert_products(objects: [$data]) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_INGREDIENT_MUTATION = gql`
  mutation UpdateIngredient($id: Int!, $data: ProductInput!) {
    updateIngredient(product_id: $id, data: $data) {
      id
    }
  }
`;
