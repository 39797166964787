import React from "react";
import {
  Row, Col, notification, Spin, Table, Card,
} from "antd";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import ConnectData from "connect.container";

import FilterPanelWithQuery from "components/FilterPanelWithQuery";
import Charts from "components/Charts";
import Page from "components/Page";

import { SALES_QUERY } from "./deliveryQueries";

const Delivery = () => {
  const { t } = useTranslation();
  const { locations } = ConnectData.useContainer();

  const [getSales, sales] = useLazyQuery(SALES_QUERY, {
    onError: notification.error,
  });

  const handleFilter = ({ time, locationId }) => {
    const variables = {
      filters: {
        from: time.from.format(),
        to: time.to.format(),
        location_id: locationId,
      },
    };
    getSales({ variables });
  };

  return (
    <Page>
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        onFilter={handleFilter}
        locations={locations}
        initialData={{ time: { value: "this_month" } }}
      />
      {sales.data ? (
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Charts
              data={sales.data}
              t={t}
            />
          </Col>
          <Col span={24}>
            <Card
              title={t("statistics.Delivery.TableDeliveryZone.Title")}
              bodyStyle={{ padding: 0 }}
            >
              <Table
                className="borderless"
                rowKey="id"
                dataSource={sales.data.salesStatsByDeliveryZone}
                size="middle"
                pagination={false}
              >
                <Table.Column
                  title={t("statistics.Delivery.Columns.DeliveryZoneName")}
                  dataIndex="name"
                  sorter={(a, b) => a.name.localeCompare(b.name)}
                  render={(name, value) => (
                    <Link to={`/statistics/delivery/zones/${value.id}${window.location.search}`}>
                      {name}
                    </Link>
                  )}
                />
                <Table.Column
                  title={t("statistics.Delivery.Columns.OrderCount")}
                  dataIndex="orders_count"
                  sorter={(a, b) => a.orders_count - b.orders_count}
                />
                <Table.Column
                  title={t("statistics.Delivery.Columns.Income")}
                  dataIndex="revenue"
                  defaultSortOrder="descend"
                  sorter={(a, b) => a.revenue - b.revenue}
                />
              </Table>
            </Card>
          </Col>
        </Row>
      ) : (
        <Spin />
      )}
    </Page>
  );
};

export default Delivery;
