import React from "react";
import PropTypes from "prop-types";
import { Spin, notification, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";
import { formatPrice, confirm } from "utils/helpers";

import Page from "components/Page";

import DeliveryFrom from "./components/DeliveryForm";
import {
  DELIVERY_OPTIONS_QUERY,
  CREATE_DELIVERY_MUTATION,
} from "./deliveryQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

const NewDelivery = ({ history }) => {
  const { t } = useTranslation();

  const productsAndLocationsQuery = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const deliveryOptionsQuery = useQuery(DELIVERY_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [createDelivery] = useMutation(CREATE_DELIVERY_MUTATION);

  if (productsAndLocationsQuery.loading || deliveryOptionsQuery.loading) {
    return <Spin />;
  }

  const handleCreateDelivery = ({ delivery_products, ...values }) =>
    createDelivery({
      variables: {
        data: {
          delivery_products: delivery_products
            .map(({ product, content_path, total_price, ...dp }) => dp),
          ...values,
        },
      },
    })
      .then((res) => res.data.createDelivery)
      .then((delivery) => history.replace(`/warehouse/deliveries/${delivery.id}`))
      .catch(parseFormErrors);

  const handleSubmit = (delivery) => {
    const productsWithCostMore50 = delivery.delivery_products.reduce((acc, { product, price }) => {
      if (Math.abs(product.cost - price) >= product.cost * 0.5) {
        return acc.concat({ ...product, newCost: price });
      }
      return acc;
    }, []);

    if (productsWithCostMore50.length > 0) {
      return confirm(
        t("warehouse.Deliveries.Form.ConfirmationMessage"),
        (
          <div>
            {productsWithCostMore50.map(({ id, name, cost, newCost }) => (
              <p key={id}>{name}: {formatPrice(cost)} -&gt; {formatPrice(newCost)}</p>
            ))}
          </div>
        ),
      )
        .then((confirmResult) => {
          if (confirmResult) {
            return handleCreateDelivery(delivery);
          }
          return "no_op";
        });
    }
    return handleCreateDelivery(delivery);
  };

  return (
    <Page showBackIcon title={t("warehouse.Deliveries.AddPage.Title")}>
      <Card>
        <DeliveryFrom
          t={t}
          optionsData={{ ...deliveryOptionsQuery.data, ...productsAndLocationsQuery.data }}
          onSubmit={handleSubmit}
        />
      </Card>
    </Page>
  );
};

NewDelivery.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewDelivery;
