import { SubmissionError } from "redux-form";

export const parseFormErrors = (error) => {
  if (error.response && error.response.data.errors) {
    throw new SubmissionError({ _error: error.response.data.errors?.map((e) => e.message) });
  } else {
    const message = (error.response && error.response.data.message) || error.message;
    throw new SubmissionError({ _error: message });
  }
};
