import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Table, Space, Button, Input, notification, Checkbox, Typography, Drawer,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  useQueryParam, NumberParam, StringParam, BooleanParam, withDefault,
} from "use-query-params";

import Page from "components/Page";

import { sortByAlphabet } from "utils/sorts";

import { MODIFIER_GROUPS_QUERY } from "./modifiersQueries";
import ModifierDetailsPanel from "./components/ModifierDetailsPanel";

const Modifiers = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 20));
  const [showAll, setShowAll] = useQueryParam("show_all", withDefault(BooleanParam, false));
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [selectedModifierGroup, setSelectedModifierGroup] = useState(null);

  const { data, loading } = useQuery(MODIFIER_GROUPS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  const modifierGroups = useMemo(() => data?.modifier_groups.filter(
    ({ name, archived_at }) => {
      if (search && search.length > 0 && !name.toLowerCase().includes(search)) {
        return false;
      }
      return showAll || !archived_at;
    },
  ), [data, search, showAll]);

  return (
    <Page
      title={t("menu.Modifiers.Index.Title")}
      headerContent={t("menu.Modifiers.Index.Description")}
      subTitle={data?.modifier_groups.length.toString()}
      extra={(
        <Space>
          <Checkbox checked={showAll} onChange={() => setShowAll(!showAll)}>
            {t("ShowArchived")}
          </Checkbox>
          <Link to="/menu/modifiers/new">
            <Button type="primary">{t("Add")}</Button>
          </Link>
        </Space>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
          style={{ width: 200 }}
        />
      </Space>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        loading={loading}
        dataSource={modifierGroups}
      >
        <Table.Column
          title={t("menu.Modifiers.Columns.Name")}
          dataIndex="name"
          width={240}
          render={(name, record) => (
            <Link to={`/menu/modifiers/${record.id}`}>
              {name}
              {record.archived_at
                && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
            </Link>
          )}
          sorter={sortByAlphabet("name")}
        />
        <Table.Column
          title={t("menu.Modifiers.Columns.Description")}
          dataIndex="description"
        />
        <Table.Column
          title={t("menu.Modifiers.Columns.Type.Title")}
          dataIndex="max_selection"
          render={(max_selection) => (max_selection === 1
            ? t("menu.Modifiers.Columns.Type.OnlyOne")
            : t("menu.Modifiers.Columns.Type.Several"))}
        />
        <Table.Column
          title={t("menu.Modifiers.Columns.Options")}
          dataIndex="modifier_items"
          render={(items) => items.map((i) => i.name).join(", ")}
        />
        <Table.Column
          dataIndex="id"
          width={80}
          render={(_, record) => (
            <Button type="link" size="small" onClick={() => setSelectedModifierGroup(record)}>
              {t("Details")}
            </Button>
          )}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedModifierGroup?.name}
            &nbsp;
            <Link to={`/menu/modifiers/${selectedModifierGroup?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedModifierGroup}
        onClose={() => setSelectedModifierGroup(null)}
        placement="bottom"
        height="60%"
      >
        {selectedModifierGroup && (
          <ModifierDetailsPanel t={t} modifierGroupId={selectedModifierGroup.id} />
        )}
      </Drawer>
    </Page>
  );
};

export default Modifiers;
