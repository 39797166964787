import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageHeader, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import CategoryForm from "./components/CategoryForm";
import {
  FINANCE_CATEGORY_QUERY,
  UPDATE_FINANCE_CATEGORY_MUTATION,
} from "./categoryQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditCategory = ({ match, history }) => {
  const { t } = useTranslation();
  const financeCategoryId = parseInt(match.params.id, 10);
  const { data, loading } = useQuery(FINANCE_CATEGORY_QUERY, {
    variables: { id: financeCategoryId },
  });
  const [updateCategory] = useMutation(UPDATE_FINANCE_CATEGORY_MUTATION);

  if (loading) {
    return <Loader />;
  }

  const handleSubmit = ({ id, ...values }) =>
    updateCategory({
      variables: {
        id,
        data: values,
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("finance.Category.EditPage.Title")}
      />
      <CategoryForm
        t={t}
        initialValues={data && data.financeCategory}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

EditCategory.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditCategory;
