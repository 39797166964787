import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { Col, Upload, Modal } from "antd";
import { useTranslation } from "react-i18next";

import colors from "theme/colors";

const TextUpload = styled.div`
  margin-top: 8px;
  color: ${colors.grey};
`;

const Image = styled.img`
  width: 100%;
`;

const StyledUpload = styled(Upload)`
  .ant-upload-list-item-done {
    height: 100%;
  }
`;

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

const UploadImage = ({ accept, initialValues }) => {
  const { t } = useTranslation();
  const image = initialValues
    ? [
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: initialValues.imgSrc,
      },
    ]
    : [];

  const [previewImage, setPreviewImage] = useState(null);
  const [fileList, setFileList] = useState(image);

  const handlePreview = async (file) => {
    let preview;
    if (!file.url && !file.preview) {
      preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || preview || file.preview);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <TextUpload>{t("uploadImage.Upload")}</TextUpload>
    </div>
  );
  return (
    <Col span={9}>
      <StyledUpload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        accept={accept}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={() => setFileList([...fileList])}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </StyledUpload>
      <Modal
        visible={!!previewImage}
        footer={null}
        onCancel={() => setPreviewImage(null)}
      >
        <Image alt="example" src={previewImage} />
      </Modal>
    </Col>
  );
};

UploadImage.propTypes = {
  accept: PropTypes.string,
  initialValues: PropTypes.object,
};

UploadImage.defaultProps = {
  accept: ".jpeg, .png, .jpg",
};

export default UploadImage;
