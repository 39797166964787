export const taxes = [
  {
    id: 1,
    name: "НДС",
    type: "Добавленая стоимость",
    percent: "18",
    productAmount: "425 361,97",
    taxeAmount: "76 565, 15",
  },
  {
    id: 2,
    name: "Упрощенка",
    type: "Без налога",
    percent: "0",
    productAmount: "1 256 128,268",
    taxeAmount: "61 256, 536",
  },
  {
    id: 3,
    name: "Без налога",
    type: "Salex tax",
    percent: "0",
    productAmount: "76 698,00",
    taxeAmount: "0,00",
  },
  {
    id: 4,
    name: "Налог не указан",
    type: "-",
    percent: "-",
    productAmount: "99 698,23",
    taxeAmount: "0,00",
  },
];
