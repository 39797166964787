import React, { useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import Modal from "react-modal";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import "react-sliding-pane/dist/react-sliding-pane.css";
import { parseFormErrors } from "utils/formErrors";

import { SELECT_OPTIONS_QUERY } from "../pages/menu/techCards/techCardQueries";
import { CREATE_WORKSHOP_MUTATION, WORKSHOPS_QUERY } from "../pages/menu/workshops/workshopQueries";
import { SELECT_OPTIONS } from "../pages/menu/prepmeals/prepmealQueries";
import WorkshopForm from "../pages/menu/workshops/components/WorkshopForm";

const SlidingPaneWithForm = styled(SlidingPane)`
  .slide-pane__content {
    padding: 24px 20px;
  }

  .slide-pane__title {
    font-size: 22px;
    font-weight: 600;
  }
`;

const NewWorkshopPane = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [createWorkshop] = useMutation(CREATE_WORKSHOP_MUTATION, {
    refetchQueries: [
      { query: SELECT_OPTIONS_QUERY },
      { query: SELECT_OPTIONS },
      { query: WORKSHOPS_QUERY },
    ],
  });

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const handleSubmit = (values) =>
    createWorkshop({ variables: { data: values } })
      .then(onClose)
      .catch(parseFormErrors);

  return (
    <SlidingPaneWithForm
      closeIcon={<CloseOutlined />}
      isOpen={isOpen}
      width="40%"
      title={t("menu.NewWorkshopPane.Title")}
      onRequestClose={onClose}
    >
      <WorkshopForm t={t} onSubmit={handleSubmit} />
    </SlidingPaneWithForm>
  );
};

NewWorkshopPane.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewWorkshopPane;
