import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Spin, Table, Typography, notification, Drawer, Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

// eslint-disable-next-line import/no-cycle
import TechCardDetailsPanel from "../../techCards/components/TechCardDetailsPanel";
// eslint-disable-next-line import/no-cycle
import SetDetailsPanel from "../../sets/components/SetDetailsPanel";

import { TECH_CARDS_USING_MODIFIER_GROUP_QUERY } from "../modifiersQueries";

const UsageInTechCardsTable = ({ modifierGroupId }) => {
  const { t } = useTranslation();
  const [selectedTechCard, setSelectedTechCard] = useState();

  const { data, loading } = useQuery(TECH_CARDS_USING_MODIFIER_GROUP_QUERY, {
    variables: { modifierGroupId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spin />
      </div>
    );
  }

  const techCardUsages = data?.modifier_groups_by_pk.menu_item_modifier_groups
    .map((mimg) => mimg.menu_item);

  const inTechCard = techCardUsages?.filter((mi) => !mi.tech_card.is_set);
  const inSet = techCardUsages?.filter((mi) => mi.tech_card.is_set);

  const tables = [
    { key: "tech-cards", usages: inTechCard },
    { key: "sets", usages: inSet },
  ].filter(({ usages }) => usages && usages.length > 0);

  if (tables.length === 0) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography.Text type="secondary">
          {t("menu.Modifiers.UsageInTechCardsTable.NotUsed")}
        </Typography.Text>
      </div>
    );
  }

  return (
    <Space size="middle" wrap>
      {tables.map(({ key, usages }) => (
        <div key={key}>
          <Typography.Text strong>
            {t(`menu.Modifiers.UsageInTechCardsTable.${
              key === "tech-cards" ? "UsageInTechCards" : "UsageInSets"}`)}
          </Typography.Text>
          <Table
            size="small"
            rowKey="id"
            bordered
            dataSource={usages}
            pagination={false}
          >
            <Table.Column
              title={t("menu.Modifiers.UsageInTechCardsTable.Columns.Name")}
              dataIndex="name"
              render={(name, record) => (
                <Typography.Link onClick={() => setSelectedTechCard(record)}>
                  {name}
                </Typography.Link>
              )}
            />
          </Table>
        </div>
      ))}
      <Drawer
        title={(
          <>
            {selectedTechCard?.name}
            &nbsp;
            <Link to={selectedTechCard?.tech_card.is_set
              ? `/menu/sets/${selectedTechCard?.menu_item_group_id}`
              : `/menu/tech-cards/${selectedTechCard?.menu_item_group_id}`}
            >
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedTechCard}
        onClose={() => setSelectedTechCard(null)}
        placement="bottom"
        height="60%"
      >
        {selectedTechCard && (selectedTechCard.tech_card.is_set ? (
          <SetDetailsPanel t={t} setId={selectedTechCard.tech_card.id} />
        ) : (
          <TechCardDetailsPanel t={t} techCardId={selectedTechCard.tech_card.id} />
        ))}
      </Drawer>
    </Space>
  );
};

UsageInTechCardsTable.propTypes = {
  modifierGroupId: PropTypes.number.isRequired,
};

export default UsageInTechCardsTable;
