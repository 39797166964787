import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import colors from "theme/colors";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
  color: ${colors.primaryColor};
`;

export const TERMINAL_TYPES = [
  { value: "pos", label: i18n.t("access.Terminal.TerminalOption.pos") },
  { value: "kitchen", label: i18n.t("access.Terminal.TerminalOption.kitchen") },
  { value: "distribution", label: i18n.t("access.Terminal.TerminalOption.distribution") },
  { value: "tv", label: i18n.t("access.Terminal.TerminalOption.tv") },
];

const TERMINAL_TYPE_LABELS = (type) =>
  TERMINAL_TYPES.find((t) => t.value === type).label;

export const TERMINAL_COLUMNS = [
  {
    title: i18n.t("access.Terminal.Columns.Name"),
    dataIndex: "name",
    render: (_, record) => (
      <StyledLink to={`/access/terminals/${record.id}`}>
        {record.name}
      </StyledLink>
    ),
  },
  {
    title: i18n.t("access.Terminal.Columns.Location"),
    dataIndex: ["location", "name"],
  },
  {
    title: i18n.t("access.Terminal.Columns.Type"),
    dataIndex: "type",
    render: (type) => TERMINAL_TYPE_LABELS(type),
  },
  {
    title: i18n.t("access.Terminal.Columns.LocationCode"),
    dataIndex: ["location", "code"],
  },
  {
    title: i18n.t("access.Terminal.Columns.Password"),
    dataIndex: "password",
  },
];
