import gql from "graphql-tag";

export const SALES_QUERY = gql`
  query getStats(
    $filters: FiltersInput!,
    $limit: Int!,
    $user_id: Int
  ) {
    salesStatsByDay(filters: $filters, user_id: $user_id) {
      day
      revenue
      orders_count
    }
    salesStatsByWeekday(filters: $filters, user_id: $user_id) {
      weekday
      revenue
      orders_count
    }
    salesStatsByHour(filters: $filters, user_id: $user_id) {
      hour
      revenue
      orders_count
    }
    salesStatsByMenuItem(filters: $filters, limit: $limit, user_id: $user_id)  {
      menu_item_id
      count
    }
    posByDay: salesStatsByDay(filters: $filters, source: pos, user_id: $user_id) {
      day
      revenue
      orders_count
    }
    callcenterByDay: salesStatsByDay(filters: $filters, source: callcenter, user_id: $user_id) {
      day
      revenue
      orders_count
    }
    paymentMethodStats(filters: $filters, user_id: $user_id) {
      revenue
      payment_method_id
      orders_count
    }
    orderTypeStats(filters: $filters, user_id: $user_id) {
      order_type
      revenue
      count
    }
  }
`;

export const SALES_PAGE_QUERY = gql`
  query getMenuItems @hasura {
    menu_items {
      id
      name
      menu_item_group {
        id
        name
      }
    }
    locations {
      id
      name
    }
    payment_methods {
      id
      name
      type
    }
  }
`;

export const USER_QUERY = gql`
  query GetUser($user_id: Int) @hasura {
    users(where: { id: { _eq: $user_id }}) {
      id
      name
    }
  }
`;

export const TODAYS_SALES_QUERY = gql`
  query getTodaySales ($filters: FiltersInput!) {
    salesStatsByDay(filters: $filters) {
      day
      revenue
      orders_count
    }
  }
`;
