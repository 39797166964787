import React, { useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Spin, notification } from "antd";
import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import Modal from "react-modal";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import "react-sliding-pane/dist/react-sliding-pane.css";
import { parseFormErrors } from "utils/formErrors";

import { INGREDIENT_CATEGORIES_QUERY } from "../pages/menu/ingredientCategories/ingredientCategoryQueries";
import { CREATE_INGREDIENT_MUTATION } from "../pages/menu/ingredients/ingredientQueries";
import { SELECT_OPTIONS } from "../pages/menu/prepmeals/prepmealQueries";
import { SELECT_OPTIONS_QUERY as MODIFIER_OPTIONS_QUERY } from "../pages/menu/modifiers/modifiersQueries";
import IngredientForm from "../pages/menu/ingredients/components/IngredientForm";

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const SlidingPaneWithForm = styled(SlidingPane)`
  .slide-pane__content {
    padding: 24px 20px;
  }

  .slide-pane__title {
    font-size: 22px;
    font-weight: 600;
  }
`;

const NewIngredientPane = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(INGREDIENT_CATEGORIES_QUERY, {
    onError: notification.error,
  });
  const [createIngredient] = useMutation(CREATE_INGREDIENT_MUTATION, {
    refetchQueries: [
      { query: SELECT_OPTIONS },
      { query: MODIFIER_OPTIONS_QUERY },
    ],
  });

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  if (loading) return <Loader />;

  const handleSubmit = ({ unit_weight, unit, ...values }) => {
    const uw = unit === "pieces" ? unit_weight : 1;
    return createIngredient({ variables: { data: { unit_weight: uw, unit, ...values } } })
      .then(onClose)
      .catch(parseFormErrors);
  };

  return (
    <SlidingPaneWithForm
      closeIcon={<CloseOutlined />}
      isOpen={isOpen}
      width="40%"
      title={t("menu.NewIngredientPane.Title")}
      onRequestClose={onClose}
    >
      <IngredientForm
        t={t}
        onSubmit={handleSubmit}
        ingredientCategories={data ? data.product_categories : []}
      />
    </SlidingPaneWithForm>
  );
};

NewIngredientPane.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewIngredientPane;
