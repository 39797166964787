import React from "react";
import { Route, Switch } from "react-router-dom";

import Sets from "./Sets";
import NewSet from "./NewSet";
import EditSet from "./EditSet";

const Container = () => (
  <Switch>
    <Route path="/menu/sets/new" component={NewSet} />
    <Route path="/menu/sets/:id" component={EditSet} />
    <Route path="/menu/sets" component={Sets} />
  </Switch>
);

export default Container;
