import colors from "theme/colors";

import i18n from "../i18n";

export const UNIT_OPTIONS = [
  { value: "pieces", label: i18n.t("const:unit_type.pieces") },
  { value: "kg", label: i18n.t("const:unit_type.kg") },
  { value: "liter", label: i18n.t("const:unit_type.liter") },
];

export const CURRENCY_UNIT_OPTIONS = [
  { value: "USD", label: "Доллар США, $" },
  { value: "EUR", label: "Евро, €" },
  { value: "KZT", label: "Казахстанский тенге, ₸" },
  { value: "RUB", label: "Российский рубль" },
  { value: "TRY", label: "Турецкая лира, ₺" },
  { value: "UZS", label: "Узбекский сум, СУМ" },
];

export const LOCAL_CURRENCY = {
  USD: "en-US",
  EUR: "en-US",
  RUB: "ru-RU",
  TRY: "tr-TR",
};

export const UNIT_LABELS_BY_VALUE = UNIT_OPTIONS
  .concat([
    { value: "ml", label: i18n.t("const:unit_type.ml") },
    { value: "g", label: i18n.t("const:unit_type.g") },
  ])
  .reduce((acc, options) => ({ ...acc, [options.value]: options.label }), {});

export const ORDER_TYPE = {
  IN_STORE: "in_store",
  DELIVERY: "delivery",
  PICKUP: "pickup",
};

export const LIMIT_PAGE = 20;

export const LINE_CHART = (dataLine) => ({
  labels: dataLine.labels,
  datasets: [
    {
      fill: true,
      lineTension: 0,
      borderWidth: 2,
      borderColor: colors.primaryColor,
      borderCapStyle: "round",
      borderJoinStyle: "miter",
      pointBorderColor: colors.white,
      pointBackgroundColor: colors.primaryColor,
      pointBorderWidth: 1,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
      data: dataLine.data,
    },
  ],
});

export const CHART_OPTIONS = (options = { legend: { display: false } }) => ({
  tooltips: {
    displayColors: false,
    callbacks: {
      label: (tooltipItem) => `${tooltipItem.xLabel} - ${tooltipItem.yLabel}` || "",
      title: () => "",
    },
    intersect: true,
    bodyFontSize: 14,
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: { autoSkip: true, maxTicksLimit: 5, maxRotation: 0 },
      },
    ],
    yAxes: [{ ticks: { min: 0 } }],
  },
  ...options,
});

export const BAR_CHART = (values) => ({
  labels: values.labels,
  datasets: [
    {
      backgroundColor: colors.primaryColor,
      borderColor: colors.primaryColor,
      borderWidth: 1,
      hoverBackgroundColor: colors.cornflowerBlue,
      hoverBorderColor: colors.cornflowerBlue,
      data: values.data,
    },
  ],
});

export const SOURCE_OPTIONS = Object.entries(
  i18n.t("const:source_options", { returnObjects: true }),
).reduce((acc, [value, label]) => acc.concat({ value, label }), []);

export const PAYMENT_METHOD_NAME = {
  salempos_generated_card: i18n.t("const:payment_method_name.card"),
  salempos_generated_cash: i18n.t("const:payment_method_name.cash"),
};

export const PAYMENT_METHOD_TYPE = {
  CASH: "cash",
  CARD: "card",
  CUSTOM: "custom",
};

export const PAYMENT_TYPE = {
  PAYMENT: "payment",
  REFUND: "refund",
};
