import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";
import { formatPrice, confirm } from "utils/helpers";

import Page from "components/Page";

import DeliveryFrom from "./components/DeliveryForm";
import {
  EDIT_DELIVERY_QUERY,
  DELIVERY_OPTIONS_QUERY,
  UPDATE_DELIVERY_MUTATION,
  COMPLETE_DELIVERY_MUTATION,
} from "./deliveryQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

import { contentPathForProduct } from "../shared/utils";

const EditDelivery = ({ match, history }) => {
  const deliveryId = parseInt(match.params.id, 10);
  const { t } = useTranslation();

  const { data, loading } = useQuery(EDIT_DELIVERY_QUERY, {
    variables: { id: deliveryId },
    onError: notification.error,
  });
  const productsAndLocationsQuery = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const deliveryOptionsQuery = useQuery(DELIVERY_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [updateDelivery] = useMutation(UPDATE_DELIVERY_MUTATION, {
    refetchQueries: [{ query: EDIT_DELIVERY_QUERY, variables: { id: deliveryId } }],
    awaitRefetchQueries: true,
  });
  const [completeDelivery, { loading: isCompleting }] = useMutation(COMPLETE_DELIVERY_MUTATION, {
    refetchQueries: [{ query: PRODUCTS_AND_LOCATIONS_QUERY }],
  });

  const initialDelivery = useMemo(() => data && productsAndLocationsQuery.data && {
    ...data.deliveries_by_pk,
    delivery_products: data.deliveries_by_pk.delivery_products.map((dp) => {
      const product = productsAndLocationsQuery.data.products.find((p) => p.id === dp.product_id);
      const totalPrice = dp.quantity * dp.price;
      return {
        ...dp, product, content_path: contentPathForProduct(product), total_price: totalPrice,
      };
    }),
  }, [data, productsAndLocationsQuery.data]);

  if (loading || productsAndLocationsQuery.loading || deliveryOptionsQuery.loading) {
    return <Spin />;
  }

  const handleCompleted = () =>
    completeDelivery({ variables: { delivery_id: deliveryId } })
      .then(() => history.push("/warehouse/deliveries"))
      .catch(parseFormErrors);

  const handleUpdateDelivery = ({ delivery_products, completed, id, ...values }) =>
    updateDelivery({
      variables: {
        delivery_id: deliveryId,
        data: {
          ...values,
          delivery_products: delivery_products
            .map(({ product, content_path, total_price, ...dp }) => dp),
        },
      },
    })
      .catch(parseFormErrors);

  const handleSubmit = (delivery) => {
    const productsWithCostMore50 = delivery.delivery_products.reduce((acc, { product, price }) => {
      if (Math.abs(product.cost - price) >= product.cost * 0.5) {
        return acc.concat({ ...product, newCost: price });
      }
      return acc;
    }, []);

    if (productsWithCostMore50.length > 0) {
      return confirm(
        t("warehouse.Deliveries.Form.ConfirmationMessage"),
        (
          <div>
            {productsWithCostMore50.map(({ id, name, cost, newCost }) => (
              <p key={id}>{name}: {formatPrice(cost)} -&gt; {formatPrice(newCost)}</p>
            ))}
          </div>
        ),
      )
        .then((confirmResult) => {
          if (confirmResult) {
            return handleUpdateDelivery(delivery);
          }
          return "no_op";
        });
    }
    return handleUpdateDelivery(delivery);
  };

  return (
    <Page showBackIcon title={t("warehouse.Deliveries.DetailPage.Title")}>
      <Card>
        <DeliveryFrom
          t={t}
          initialValues={initialDelivery}
          optionsData={{ ...deliveryOptionsQuery.data, ...productsAndLocationsQuery.data }}
          isCompleting={isCompleting}
          onSubmit={handleSubmit}
          handleComplete={handleCompleted}
        />
      </Card>
    </Page>
  );
};

EditDelivery.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditDelivery;
