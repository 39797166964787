import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import colors from "theme/colors";
import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  color: ${colors.primaryColor};
`;

export const LOCATION_COLUMNS = [
  {
    title: i18n.t("settings.Location.Columns.Location"),
    dataIndex: "name",
    render: (name, location) => (
      <StyledLink to={`/settings/locations/${location.id}`}>{name}</StyledLink>
    ),
  },
  {
    title: i18n.t("settings.Location.Columns.Description"),
    dataIndex: "description",
    render: (description) => description || "-",
  },
  {
    title: i18n.t("settings.Location.Columns.Address"),
    dataIndex: "address",
    render: (address) => address || "-",
  },
  {
    title: i18n.t("settings.Location.Columns.PhoneNumber"),
    dataIndex: "phone_number",
    render: (phone_number) => phone_number || "-",
  },
];
