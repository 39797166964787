import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "theme/colors";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  color: ${colors.primaryColor};
`;

export const SHOPS_COLUMNS = [
  {
    title: i18n.t("statistics.Shops.Columns.Location"),
    dataIndex: "name",
    render: (name) => <StyledLink to="/statistics/sales">{name}</StyledLink>,
  },
  {
    title: i18n.t("statistics.Shops.Columns.Address"),
    dataIndex: "address",
  },
  {
    title: i18n.t("statistics.Shops.Columns.Revenue"),
    dataIndex: "revenue",
  },
  {
    title: i18n.t("statistics.Shops.Columns.Profit"),
    dataIndex: "profit",
  },
  {
    title: i18n.t("statistics.Shops.Columns.Checks"),
    dataIndex: "checks",
  },
  {
    title: i18n.t("statistics.Shops.Columns.AverageCheck"),
    dataIndex: "averagуСheck",
  },
];
