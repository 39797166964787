import React from "react";
import { Switch, Route } from "react-router-dom";

import Terminals from "./Terminals";
import NewTerminal from "./NewTerminal";
import EditTerminal from "./EditTerminal";

const Container = () => (
  <Switch>
    <Route path="/access/terminals/new" component={NewTerminal} />
    <Route path="/access/terminals/:id" component={EditTerminal} />
    <Route path="/access/terminals" component={Terminals} />
  </Switch>
);

export default Container;
