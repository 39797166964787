import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Row, Col, Menu, Card, Radio } from "antd";
import PropTypes from "prop-types";
import { round } from "lodash";

import { formatPrice } from "utils/helpers";
import LineChart from "components/LineChart";
import BarCharts from "components/BarCharts";

const FlexMenu = styled(Menu)`
  display: flex;
  justify-content: space-between;
  background: none;
`;

const Text = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const Charts = ({ data, t }) => {
  const [dataType, setDataType] = useState("revenue");
  const [periodType, setPeriodType] = useState("day");

  const total = useMemo(() => {
    const income = data.salesStatsByDay.reduce((sum, current) => sum + current.revenue, 0);
    const orderCount = data.salesStatsByDay.reduce((sum, current) => sum + current.orders_count, 0);
    const avgCheck = income / orderCount || 0;
    return { income, orderCount, avgCheck };
  }, [data]);

  const chartsData = useMemo(
    () =>
      data.salesStatsByDay.map((el) => ({
        day: el.day,
        value: dataType === "average_check"
          ? parseFloat(el.revenue / el.orders_count).toFixed(2)
          : el[dataType],
      })),
    [data, dataType],
  );

  const weekdayBarDataAll = useMemo(
    () =>
      data.salesStatsByWeekday.map(({ revenue, orders_count, ...salesData }) => ({
        ...salesData,
        revenue,
        orders_count,
        average_check: round(revenue / orders_count),
      })),
    [data],
  );

  const hourBarDataAll = useMemo(
    () =>
      data.salesStatsByHour.map(({ revenue, orders_count, ...salesData }) => ({
        ...salesData,
        revenue,
        orders_count,
        average_check: round(revenue / orders_count),
      })),
    [data],
  );

  return (
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <Card
          title={t("statistics.Sales.LineChart.Revenue")}
          extra={(
            <Radio.Group defaultValue="day" size="small">
              <Radio.Button value="day" onClick={() => setPeriodType("day")}>
                {t("statistics.Sales.LineChart.Day")}
              </Radio.Button>
              <Radio.Button value="week" onClick={() => setPeriodType("week")}>
                {t("statistics.Sales.LineChart.Week")}
              </Radio.Button>
              <Radio.Button value="month" onClick={() => setPeriodType("month")}>
                {t("statistics.Sales.LineChart.Month")}
              </Radio.Button>
            </Radio.Group>
          )}
        >
          <LineChart periodType={periodType} data={chartsData} />

          <FlexMenu defaultSelectedKeys="income" mode="horizontal">
            <Menu.Item key="income" onClick={() => setDataType("revenue")}>
              <Text>{formatPrice(total.income)}</Text> {t("statistics.Sales.Charts.revenue")}
            </Menu.Item>
            <Menu.Item key="count" onClick={() => setDataType("orders_count")}>
              <Text>{total.orderCount}</Text> {t("statistics.Sales.Charts.orders")}
            </Menu.Item>
            <Menu.Item
              key="average_check"
              onClick={() => setDataType("average_check")}
            >
              <Text>{formatPrice(total.avgCheck)}</Text> {t("statistics.Sales.Charts.averageCheck")}
            </Menu.Item>
          </FlexMenu>
        </Card>
      </Col>

      <Col span={24}>
        <BarCharts
          type={dataType}
          t={t}
          statsByWeekday={weekdayBarDataAll}
          statsByHour={hourBarDataAll}
        />
      </Col>
    </Row>
  );
};

Charts.propTypes = {
  data: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default Charts;
