import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import {
  Row, Col, Space, Button, Alert, Typography,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { required } from "utils/formValidations";
import { renderSelect, renderTextarea, renderDatePicker } from "components/FormItem";

import ProductFields from "./ProductFields";

const buttonsLayout = {
  wrapperCol: {
    md: { span: 15, offset: 6 },
    xs: { span: 24 },
  },
};

const tableLayout = {
  wrapperCol: {
    xs: 24,
    md: { span: 22, offset: 1 },
    lg: { span: 15, offset: 3 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const MovingForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  error,
  initialValues = {},
  optionsData,
  change,
}) => {
  const locationsOptions = (optionsData?.locations ?? [])
    .map((item) => ({ value: item.id, label: item.name }));

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="datetime"
        label={t("warehouse.Movings.Form.DateAndTime.Label")}
        component={renderDatePicker}
        formItemProps={formItemLayout}
        showTime
        placeholder={t("warehouse.Movings.Form.DateAndTime.Placeholder")}
        disabled={!!initialValues.id}
        validate={required}
      />
      <Field
        name="location_from_id"
        label={t("warehouse.Movings.Form.FromWarehouse.Label")}
        component={renderSelect}
        style={{ width: 250 }}
        formItemProps={formItemLayout}
        placeholder={t("warehouse.Movings.Form.FromWarehouse.Placeholder")}
        options={locationsOptions}
        disabled={!!initialValues.id}
        validate={required}
      />
      <Field
        name="location_to_id"
        label={t("warehouse.Movings.Form.ToWarehouse.Label")}
        component={renderSelect}
        style={{ width: 250 }}
        formItemProps={formItemLayout}
        placeholder={t("warehouse.Movings.Form.ToWarehouse.Placeholder")}
        options={locationsOptions}
        disabled={!!initialValues.id}
        validate={required}
      />
      <Field
        name="description"
        label={t("warehouse.Movings.Form.Description")}
        component={renderTextarea}
        formItemProps={formItemLayout}
        disabled={!!initialValues.id}
      />

      <Form.Item {...tableLayout}>
        <FieldArray
          name="moving_products"
          t={t}
          component={ProductFields}
          changeField={change}
          optionsData={optionsData}
          disabled={!!initialValues.id}
        />
      </Form.Item>

      <Row style={{ marginBottom: 12 }}>
        <Col {...tableLayout.wrapperCol}>
          <Typography.Text type="secondary">
            {t("warehouse.Movings.Form.ActionsText")}
          </Typography.Text>
        </Col>
      </Row>

      {error && (
        <Form.Item {...tableLayout}>
          <Alert message={error} type="error" />
        </Form.Item>
      )}

      <Form.Item {...buttonsLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!!initialValues.id || pristine || submitting}
          >
            {t("Start")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            icon={<CheckOutlined />}
            disabled={!initialValues.id || initialValues.completed}
          >
            {t("Complete")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

MovingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  optionsData: PropTypes.object,
  error: PropTypes.string,
};

export default reduxForm({
  form: "movingForm",
  initialValues: {
    moving_products: [],
  },
})(MovingForm);
