import gql from "graphql-tag";

export const TECH_CARDS_QUERY = gql`
  query GetTechCards @hasura {
    menu_item_groups(
      order_by: {id: asc}
      where: {menu_items: {tech_card: {is_set: {_eq: false}}}}
    ) {
      id
      name
      description
      category_id
      category {
        id
        name
      }
      color
      archived_at
      menu_items {
        id
        price
        tech_card {
          id
          cost
          unit
          product_id
        }
        archived_at
      }
    }
    menu_categories(
      order_by: {id: asc}
      where: {menu_item_groups: {menu_items: {tech_card: {is_set: {_eq: false}}}}}
    ) {
      id
      name
    }
  }
`;

export const TECH_CARD_QUERY = gql`
  query GetTechCard($id: Int!) @hasura {
    menu_item_groups_by_pk(id: $id) {
      id
      name
      description
      category_id
      archived_at
      color
      menu_items {
        id
        name
        limited_location_availability
        price
        tech_card_id
        archived_at
        tech_card {
          id
          is_set
          cost
          unit
          unit_weight
          output_quantity
          workshop_id
          process_description
          process_duration
          product_id
          tech_card_products(order_by: {id: asc}) {
            id
            product_id
            tech_card_id
            quantity
            cold_loss
            hot_loss
            cost
          }
        }
        menu_item_modifier_groups {
          modifier_group_id
        }
      }
    }
  }
`;

export const SELECT_OPTIONS_QUERY = gql`
  query @hasura {
    menu_categories(order_by: {id: asc}) {
      id
      name
    }
    locations(order_by: {id: asc}) {
      id
      name
    }
    products(order_by: {id: asc}, where: {category_id: {_is_null: false}}) {
      id
      name
      cost
      category_id
      unit
      unit_weight
    }
    tech_cards(order_by: {id: asc}, where: {_not: {menu_items: {}}}) {
      id
      name
      cost
      unit
      unit_weight
      output_quantity
      product {
        id
        cost
      }
    }
    product_categories(order_by: {id: asc}) {
      id
      name
    }
    workshops(order_by: {id: asc}) {
      id
      name
    }
    modifier_groups(order_by: {id: asc}, where: { archived_at: { _is_null: true } }) {
      id
      name
      description
      modifier_items(order_by: {id: asc}, where: { archived_at: { _is_null: true } }) {
        id
        name
      }
    }
  }
`;

export const CREATE_TECH_CARD_MUTATION = gql`
  mutation CreateTechCard($data: [menu_item_groups_insert_input!]!) @hasura {
    insert_menu_item_groups(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_TECH_CARD_MUTATION = gql`
  mutation UpdateMenuItemGroup($id: Int!, $data: MenuItemGroupInput!) {
    updateMenuItemGroup(menu_item_group_id: $id, data: $data) {
      id
    }
  }
`;
