import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Tag } from "antd";

import colors from "theme/colors";
import { PAYMENT_METHOD_TYPE, PAYMENT_METHOD_NAME } from "constants/index";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  color: ${colors.primaryColor};
`;

const formatPercent = (number) => (number ? `${number}%` : "0%");

const BooleanTag = ({ value }) =>
  <Tag color={value ? colors.green : colors.red}>{value ? i18n.t("Yes") : i18n.t("No")}</Tag>;

BooleanTag.propTypes = {
  value: PropTypes.bool,
};

export const PAYMENT_METHOD_COLUMNS = [
  {
    title: i18n.t("settings.PaymentMethod.Columns.Name"),
    dataIndex: "name",
    render: (name, paymentMethod) => (
      <StyledLink to={`/settings/payment-methods/${paymentMethod.id}`}>
        {paymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM
          ? name : PAYMENT_METHOD_NAME[name] }
      </StyledLink>
    ),
  },
  {
    title: i18n.t("settings.PaymentMethod.Columns.FinanceAccount"),
    dataIndex: "finance_account",
    render: (finance_account, paymentMethod) => {
      if (paymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM) {
        return finance_account ? finance_account.name : "-";
      }
      return (
        <StyledLink to="/settings/locations">
          {i18n.t("settings.PaymentMethod.Columns.LocationSetting")}
        </StyledLink>
      );
    },
  },
  {
    title: i18n.t("settings.PaymentMethod.Columns.ProcessingFeePercent"),
    dataIndex: "processing_fee_percent",
    render: (processing_fee_percent) => formatPercent(processing_fee_percent),
  },
  {
    title: i18n.t("settings.PaymentMethod.Columns.ProcessingFeeAmount"),
    dataIndex: "processing_fee_amount",
    render: (processing_fee_amount) => formatPercent(processing_fee_amount),
  },
  {
    title: i18n.t("settings.PaymentMethod.Columns.ShowInCallcenter"),
    dataIndex: "show_in_callcenter",
    render: (show_in_callcenter) => <BooleanTag value={show_in_callcenter} />,
  },
  {
    title: i18n.t("settings.PaymentMethod.Columns.ShowInPOS"),
    dataIndex: "show_in_pos",
    render: (show_in_pos) => <BooleanTag value={show_in_pos} />,
  },
];
