import gql from "graphql-tag";

export const CASHIER_SHIFTS_QUERY = gql`
  query GetCashierShifts($location_id: Int, $limit: Int, $offset: Int) {
    cashierShifts(location_id: $location_id, limit: $limit, offset: $offset) {
      id
      number
      open_user_id
      open_user {
        id
        name
      }
      close_user_id
      close_user {
        id
        name
      }
      location_id
      location {
        id
        name
      }
      open_cash_amounts
      close_cash_amounts
      actual_cash_amounts
      expected_cash_amounts
      opened_at
      closed_at
      updated_at
      created_at
      open_correction_transaction {
        id
        type
        transfer_to_account_id
        transfer_to_account {
          name
          location {
            id
            name
          }
        }
        transfer_to_amount
        description
        amount
        time
        category_id
        category {
          name
        }
        account_id
        account {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
    cashierShiftsCount(location_id: $location_id) {
      count
    }
  }
`;

export const CASHIER_SHIFT_DETAILS_QUERY = gql`
  query GetCashierShiftDetails($shift_id: ID!) {
    cashierShiftDetails(shift_id: $shift_id) {
      shift_id
      cash_revenue
      card_revenue
      other_revenue {
        total
        detailed {
          payment_method_id
          payment_method_name
          revenue
        }
      }
      incomes
      expenses
      incassation
      transactions {
        id
        type
        transfer_to_account_id
        transfer_to_account {
          name
          location {
            id
            name
          }
        }
        transfer_to_amount
        description
        amount
        time
        category_id
        category {
          name
        }
        account_id
        account {
          name
          location {
            id
            name
          }
        }
        user {
          id
          name
        }
      }
    }
  }
`;
