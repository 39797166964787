import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Alert } from "antd";
import { Form } from "@ant-design/compatible";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { renderInput, renderSelect } from "components/FormItem";
import { required, email, phoneNumber } from "utils/formValidations";

const ExtendedForm = styled(Form)`
  max-width: 360px;
  min-width: 320px;

  .signup-form-button {
    width: 100%;
  }
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const SignupForm = ({ handleSubmit, t, submitting, error }) => (
  <ExtendedForm onSubmit={handleSubmit}>
    {error && <Alert type="error" message={error} />}
    <Field
      name="name"
      label={t("signup.Form.Name.label")}
      placeholder={t("signup.Form.Name.placeholder")}
      component={renderInput}
      validate={required}
      formItemProps={formItemLayout}
    />
    <Field
      name="company_name"
      label={t("signup.Form.CompanyName.label")}
      placeholder={t("signup.Form.CompanyName.placeholder")}
      component={renderInput}
      validate={required}
      formItemProps={formItemLayout}
    />
    <Field
      name="phone_number"
      label={t("signup.Form.PhoneNumber.label")}
      placeholder={t("signup.Form.PhoneNumber.placeholder")}
      component={renderInput}
      validate={[required, phoneNumber]}
      formItemProps={formItemLayout}
    />
    <Field
      prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
      label={t("signup.Form.Email.label")}
      placeholder={t("signup.Form.Email.placeholder")}
      name="email"
      normalize={(str) => str && str.trim()}
      component={renderInput}
      validate={[required, email]}
      formItemProps={formItemLayout}
    />
    <Field
      prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
      label={t("signup.Form.Password.label")}
      placeholder={t("signup.Form.Password.placeholder")}
      type="password"
      name="password"
      component={renderInput}
      validate={required}
      formItemProps={formItemLayout}
    />
    <Field
      name="plan"
      label={t("signup.Form.Plan.label")}
      component={renderSelect}
      validate={required}
      formItemProps={formItemLayout}
      options={[
        { value: "starter", label: t("signup.Form.Plan.options.Starter") },
        { value: "standard", label: t("signup.Form.Plan.options.Standard") },
      ]}
    />

    <Form.Item>
      <Button
        disabled={submitting}
        type="primary"
        htmlType="submit"
        className="signup-form-button"
      >
        {t("signup.Form.Signup")}
      </Button>
    </Form.Item>
  </ExtendedForm>
);

SignupForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: "signupForm",
  initialValues: {
    plan: "standard",
  },
})(SignupForm);
