import gql from "graphql-tag";

export const PREPMEALS_QUERY = gql`
  query @hasura {
    tech_cards(order_by: {id: asc}, where: {_not: {menu_items:{}}}) {
      id
      name
      workshop_id
      cost
      unit
      unit_weight
      output_quantity
      is_set
      product_id
    }
  }
`;

export const PREPMEAL_QUERY = gql`
  query GetPrepmeal($id: Int!) @hasura {
    tech_cards_by_pk(id: $id) {
      id
      name
      workshop_id
      cost
      unit
      unit_weight
      output_quantity
      is_set
      process_description
      process_duration
      product_id
      tech_card_products(order_by: {id: asc}) {
        id
        product_id
        tech_card_id
        quantity
        cold_loss
        hot_loss
        cost
      }
    }
  }
`;

export const SELECT_OPTIONS = gql`
  query @hasura {
    workshops(order_by: {id: asc}) {
      id
      name
    }
    products(order_by: {id: asc}, where: {category_id: {_is_null: false}}) {
      id
      name
      unit
      cost
      category_id
      unit_weight
    }
    tech_cards(order_by: {id: asc}, where: {_not: {menu_items:{}}}) {
      id
      name
      cost
      unit
      unit_weight
      output_quantity
      product {
        id
        cost
      }
    }
    product_categories(order_by: {id: asc}) {
      id
      name
    }
  }
`;

export const CREATE_PREPMEAL_MUTATION = gql`
  mutation CreatePrepmeal($data: tech_cards_insert_input!) @hasura {
    insert_tech_cards_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_PREPMEAL_MUTATION = gql`
  mutation UpdatePrepmeal($id: Int!, $data: TechCardInput!) {
    updatePrepmeal(tech_card_id: $id, data: $data) {
      id
    }
  }
`;
