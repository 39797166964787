import React from "react";
import { Route, Switch } from "react-router-dom";

import IngredientCategories from "./IngredientCategories";
import NewIngredientCategory from "./NewIngredientCategory";
import EditIngredientCategory from "./EditIngredientCategory";

const Container = () => (
  <Switch>
    <Route path="/menu/ingredient-categories/new" component={NewIngredientCategory} />
    <Route path="/menu/ingredient-categories/:id" component={EditIngredientCategory} />
    <Route path="/menu/ingredient-categories" component={IngredientCategories} />
  </Switch>
);

export default Container;
