import React, { useMemo } from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";

import { required } from "utils/formValidations";
import { renderCascader, renderInput } from "components/FormItem";
import { UNIT_LABELS_BY_VALUE } from "constants/index";

import { cascadeCategoriesForProductsData } from "../../shared/utils";

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

const capPrecision = (value, precision) => parseFloat(value.toFixed(precision));

const parseFloatValue = (precision) => (value) => {
  const parsedValue = parseFloat(value);
  return parsedValue || parsedValue === 0 ? capPrecision(parsedValue, precision) : "";
};

const ProductFields = ({
  t, fields, changeField, disabled, optionsData,
}) => {
  const contents = useMemo(() => {
    if (!optionsData) return [];
    return cascadeCategoriesForProductsData(optionsData);
  }, [optionsData]);

  const onSelectContent = (idx, value) => {
    const product = value.reduce(
      (val, pathItem) => val.children.find((c) => c.value === pathItem),
      { children: contents },
    );

    changeField(`${fields.name}[${idx}].product`, product);
    changeField(`${fields.name}[${idx}].product_id`, product.value);
  };

  return (
    <Table
      rowKey={(field) => fields.getAll().indexOf(field)}
      dataSource={fields.getAll()}
      size="small"
      pagination={false}
      locale={{ emptyText: t("warehouse.Movings.Form.ProductFields.EmptyProducts") }}
      summary={disabled ? undefined : (() => (
        <Table.Summary.Row style={{ backgroundColor: "#fff" }}>
          <Table.Summary.Cell colSpan={3}>
            <Space>
              <Button type="dashed" icon={<PlusOutlined />} onClick={() => fields.push({})}>
                {t("warehouse.Movings.Form.ProductFields.AddProduct")}
              </Button>
              {fields.length > 1 && (
              <Button type="link" danger onClick={() => fields.removeAll()}>
                {t("DeleteAll")}
              </Button>
              )}
            </Space>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ))}
    >
      <Table.Column
        title={t("warehouse.Movings.Form.ProductFields.Name")}
        dataIndex="content_path"
        render={(_c, _f, index) => (
          <Field
            name={`${fields.name}[${index}].content_path`}
            disabled={disabled}
            style={{ width: 240 }}
            component={renderCascader}
            onChange={(v) => onSelectContent(index, v)}
            validate={required}
            placeholder={t("warehouse.Movings.Form.ProductFields.ChooseProduct")}
            formItemProps={formItemLayout}
            options={contents}
            showSearch
          />
        )}
      />
      <Table.Column
        title={t("warehouse.Movings.Form.ProductFields.Quantity")}
        dataIndex="product"
        render={(product, _p, index) => (
          <Field
            name={`${fields.name}[${index}].quantity`}
            disabled={disabled}
            component={renderInput}
            validate={required}
            type="number"
            min={0}
            step={0.001}
            parse={parseFloatValue(3)}
            formItemProps={formItemLayout}
            addonAfter={product && UNIT_LABELS_BY_VALUE[product.unit]}
          />
        )}
      />
      {disabled || (
        <Table.Column
          dataIndex="content_path"
          render={(_c, _p, index) => (
            <Button danger onClick={() => fields.remove(index)}>
              <DeleteOutlined />
            </Button>
          )}
        />
      )}
    </Table>
  );
};

ProductFields.propTypes = {
  t: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  optionsData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ProductFields;
