import React from "react";
import { reduxForm, FieldArray } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button, Alert } from "antd";

import TablesFields from "./TablesFields";

const StyledForm = styled(Form)`
  margin: 20px 40px;
`;

const TablesForm = ({
  t, handleSubmit, pristine, submitting, error,
}) => (
  <StyledForm onSubmit={handleSubmit}>
    {error && <Alert type="error" message={error} />}
    <Row>
      <Col xs={24} md={20} lg={16} xl={12}>
        <FieldArray t={t} name="tables" component={TablesFields} />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            {t("Save")}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  </StyledForm>
);

TablesForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default reduxForm({
  form: "locationTablesForm",
})(TablesForm);
