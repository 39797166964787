import gql from "graphql-tag";

export const ACCOUNT_QUERY = gql`
  query GetAccount @hasura {
    current_user_view {
      id
      name
      email
      phone_number
    }
  }
`;

export const UPDATE_ACCOUNT_QUERY = gql`
  mutation UpdateAccount($id: Int!, $data: current_user_view_set_input!) @hasura {
    update_current_user_view (_set: $data, where: { id: {  _eq: $id } }) {
      returning {
        id
        name
        email
        phone_number
      }
    }
  }    
`;
