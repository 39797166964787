import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Table } from "antd";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { formatPrice } from "utils/helpers";

const ProductRemains = ({ product, locations }) => {
  const { t } = useTranslation();

  const locationsWithRemains = locations.map((location) => {
    const locationProduct = product.locationProducts.find((lp) => lp.location_id === location.id);
    return { ...location, quantity: locationProduct?.quantity ?? 0 };
  });

  return (
    <Table
      rowKey="id"
      pagination={false}
      size="small"
      style={{ margin: "10px 100px 10px 50px" }}
      dataSource={locationsWithRemains}
    >
      <Table.Column
        title={t("Warehouse")}
        dataIndex="name"
      />
      <Table.Column
        title={t("Remains")}
        dataIndex="quantity"
        render={(quantity) => `${quantity} ${UNIT_LABELS_BY_VALUE[product.unit]}`}
      />
      <Table.Column
        title={t("Cost")}
        dataIndex="cost"
        render={() => formatPrice(product.cost)}
      />
      <Table.Column
        title={t("Amount")}
        dataIndex="quantity"
        render={(quantity) => formatPrice(quantity * product.cost)}
      />
    </Table>
  );
};

ProductRemains.propTypes = {
  product: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
};

export default ProductRemains;
