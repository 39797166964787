import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Alert } from "antd";
import { Form } from "@ant-design/compatible";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { required, email } from "utils/formValidations";
import { renderInput } from "components/FormItem";

const ExtendedForm = styled(Form)`
  max-width: 360px;
  min-width: 320px;

  .login-form-signup {
    float: right;
  }
`;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const LoginForm = ({ handleSubmit, t, submitting, error }) => (
  <ExtendedForm onSubmit={handleSubmit}>
    {error && <Alert type="error" message={error} style={{ marginBottom: 10 }} />}
    <Field
      prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
      placeholder="Email"
      name="email"
      normalize={(str) => str && str.trim()}
      component={renderInput}
      validate={[required, email]}
      formItemProps={formItemLayout}
    />
    <Field
      prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
      placeholder="Password"
      type="password"
      name="password"
      component={renderInput}
      validate={required}
      formItemProps={formItemLayout}
    />

    <Form.Item {...formItemLayout}>
      <Link className="login-form-signup" to="/signup">
        {t("login.Form.SignupLink")}
      </Link>
    </Form.Item>

    <Form.Item {...formItemLayout}>
      <Button
        disabled={submitting}
        type="primary"
        htmlType="submit"
        block
      >
        {t("login.Form.Login")}
      </Button>
    </Form.Item>
  </ExtendedForm>
);

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({ form: "loginForm" })(LoginForm);
