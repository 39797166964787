import React, { useEffect, useMemo } from "react";
import { Field, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DeleteOutlined, PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Table, Button, Divider, Tooltip } from "antd";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { renderCascader, renderInput, renderLabel } from "components/FormItem";
import { required } from "utils/formValidations";
import { formatPrice } from "utils/helpers";

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const ProductsInputTable = styled(Table)`
  // Remove arrows because no space
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

const capPrecision = (value, precision) => parseFloat(value.toFixed(precision));

const parseFloatValue = (precision) => (value) => {
  const parsedValue = parseFloat(value);
  return parsedValue || parsedValue === 0 ? capPrecision(parsedValue, precision) : "";
};

const TechCardProductsFields = ({
  t, fields, changeField, optionsData, onIngridientCreate, emptyText, meta, allowEmpty = false,
}) => {
  const contents = useMemo(() => {
    if (!optionsData) return [];
    const categories = optionsData.products.concat(optionsData.tech_cards)
      .reduce((acc, { id, name, category_id, ...rest }) => {
        const item = {
          ...rest,
          category_id: category_id || 0,
          label: name,
          value: JSON.stringify(category_id ? { product_id: id } : { tech_card_id: id }),
        };
        const categoryIdx = acc.findIndex((cat) => cat.value === (category_id || 0));
        if (categoryIdx > -1) {
          return acc.map((cat, idx) => (idx !== categoryIdx ? cat : {
            ...cat, children: cat.children.concat(item),
          }));
        }
        const category = category_id
          ? optionsData.product_categories.find((c) => c.id === category_id)
          : { id: 0, name: t("Prepmeals") };
        return acc.concat({
          label: category.name, value: category.id, children: [item],
        });
      }, []);
    return [{
      label: t("Ingredients"),
      value: "ingredients",
      children: categories.filter((cat) => cat.value !== 0),
    }].concat(categories.filter((cat) => cat.value === 0));
  }, [optionsData]);

  const onOutputChange = (idx, value, newNettoWeight) => {
    const { netto_weight } = fields.get(idx);
    const nettoWeight = newNettoWeight === undefined ? netto_weight : newNettoWeight;
    if (!nettoWeight && nettoWeight !== 0) return;
    const numberValue = value || 0;

    const newHotLoss = capPrecision(1 - numberValue / nettoWeight, 2);
    changeField(`${fields.name}[${idx}].hot_loss`, newHotLoss);
  };

  const onHotLossChange = (idx, value, newNettoWeight) => {
    const { netto_weight } = fields.get(idx);
    const nettoWeight = newNettoWeight === undefined ? netto_weight : newNettoWeight;
    if (!nettoWeight && nettoWeight !== 0) return;
    const numberValue = value || 0;

    const newOutputWeight = capPrecision(nettoWeight * (1 - numberValue), 0);
    changeField(`${fields.name}[${idx}].output_weight`, newOutputWeight);
  };

  const onNettoValueUpdated = (idx, value) => {
    const { hot_loss } = fields.get(idx);

    if (!hot_loss && hot_loss !== 0) {
      changeField(`${fields.name}[${idx}].hot_loss`, 0);
    }
    onHotLossChange(idx, hot_loss || 0, value);
  };

  const onNettoChange = (idx, value, newBruttoWeight) => {
    const { brutto_weight } = fields.get(idx);
    const bruttoWeight = newBruttoWeight === undefined ? brutto_weight : newBruttoWeight;
    if (!bruttoWeight && bruttoWeight !== 0) return;
    const numberValue = value || 0;

    const newColdLoss = capPrecision(1 - numberValue / bruttoWeight, 2);
    changeField(`${fields.name}[${idx}].cold_loss`, newColdLoss);
    onNettoValueUpdated(idx, numberValue);
  };

  const onColdLossChange = (idx, value, newBruttoWeight) => {
    const { brutto_weight } = fields.get(idx);
    const bruttoWeight = newBruttoWeight === undefined ? brutto_weight : newBruttoWeight;
    if (!bruttoWeight && bruttoWeight !== 0) return;
    const numberValue = value || 0;

    const newNettoWeight = capPrecision(bruttoWeight * (1 - numberValue), 0);
    changeField(`${fields.name}[${idx}].netto_weight`, newNettoWeight);
    onNettoValueUpdated(idx, newNettoWeight);
  };

  const onBruttoValueUpdated = (idx, value) => {
    const { cold_loss } = fields.get(idx);

    if (!cold_loss && cold_loss !== 0) {
      changeField(`${fields.name}[${idx}].cold_loss`, 0);
    }
    onColdLossChange(idx, cold_loss || 0, value);
  };

  const onBruttoUnitValueUpdated = (idx, value, newIngredient) => {
    const ingredient = newIngredient === undefined
      ? fields.get(idx).ingredient : newIngredient;
    if (!ingredient) return;
    const numberValue = value || 0;
    const finalCost = ingredient.product ? ingredient.product.cost : ingredient.cost;

    changeField(`${fields.name}[${idx}].cost`, finalCost * numberValue);
  };

  const onBruttoChange = (idx, value) => {
    const { ingredient } = fields.get(idx);
    if (!ingredient) return;
    const numberValue = value || 0;

    const newBruttoUnit = capPrecision(numberValue / ingredient.unit_weight / 1000, 3);
    changeField(`${fields.name}[${idx}].brutto_unit`, newBruttoUnit);
    onBruttoUnitValueUpdated(idx, newBruttoUnit);
    onBruttoValueUpdated(idx, numberValue);
  };

  const onBruttoUnitChange = (idx, value, newIngredient) => {
    const ingredient = newIngredient === undefined
      ? fields.get(idx).ingredient : newIngredient;
    if (!ingredient) return;
    const numberValue = value || 0;

    const newBruttoWeight = capPrecision(numberValue * ingredient.unit_weight * 1000, 0);
    changeField(`${fields.name}[${idx}].brutto_weight`, newBruttoWeight);
    onBruttoUnitValueUpdated(idx, numberValue, ingredient);
    onBruttoValueUpdated(idx, newBruttoWeight);
  };

  const onSelectContent = (idx, value) => {
    const { brutto_unit } = fields.get(idx);
    const valueItem = value.reduce(
      (val, pathItem) => val.children.find((c) => c.value === pathItem),
      { children: contents },
    );

    changeField(`${fields.name}[${idx}].ingredient`, valueItem);
    changeField(`${fields.name}[${idx}].content`, JSON.parse(valueItem.value));
    if (brutto_unit || brutto_unit === 0) {
      changeField(`${fields.name}[${idx}].brutto_unit`, brutto_unit);
      onBruttoUnitChange(idx, brutto_unit, valueItem);
    }
    if (valueItem.unit_weight === 0) {
      ["brutto_weight", "cold_loss", "netto_weight", "hot_loss", "output_weight"]
        .forEach((field) => changeField(`${fields.name}[${idx}].${field}`, 0));
    }
  };

  const selector = formValueSelector(meta.form);
  const techCardProducts = useSelector((state) => selector(state, fields.name));

  const totalBrutto = (techCardProducts || [])
    .reduce((acc, { brutto_weight }) => acc + (brutto_weight || 0), 0);
  const totalNetto = (techCardProducts || [])
    .reduce((acc, { netto_weight }) => acc + (netto_weight || 0), 0);
  const totalOutput = (techCardProducts || [])
    .reduce((acc, { output_weight }) => acc + (output_weight || 0), 0);
  const totalCost = (techCardProducts || []).reduce((acc, { cost }) => acc + (cost || 0), 0);

  useEffect(() => changeField("total_cost", totalCost), [totalCost]);
  useEffect(() => changeField("output", totalOutput), [totalOutput]);
  useEffect(() => {
    (techCardProducts || []).forEach(({ brutto_unit }, idx) => {
      if (brutto_unit || brutto_unit === 0) {
        onBruttoUnitChange(idx, brutto_unit);
      }
    });
  }, []);

  return (
    <ProductsInputTable
      rowKey={(field) => fields.getAll().indexOf(field)}
      dataSource={fields.getAll()}
      size="small"
      pagination={false}
      locale={{ emptyText }}
      summary={() => (
        <>
          <Table.Summary.Row style={{ backgroundColor: "#fff" }}>
            <Table.Summary.Cell colSpan={9}>
              <Button type="dashed" onClick={() => fields.push({})}>
                <PlusOutlined /> {t("menu.TechCardProductsFields.AddIngredient")}
              </Button>
            </Table.Summary.Cell>
          </Table.Summary.Row>
          <Table.Summary.Row>
            <Table.Summary.Cell>
              {t("menu.TechCardProductsFields.Summary")}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {totalBrutto} {UNIT_LABELS_BY_VALUE.g}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {totalNetto} {UNIT_LABELS_BY_VALUE.g}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {totalOutput} {UNIT_LABELS_BY_VALUE.g}
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2}>
              {formatPrice(totalCost)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )}
    >
      <Table.Column
        title={t("menu.TechCardProductsFields.Ingredient.Name")}
        dataIndex="content_path"
        render={(_c, _f, index) => (
          <Field
            style={{ width: 220 }}
            name={`${fields.name}[${index}].content_path`}
            component={renderCascader}
            onChange={(v) => onSelectContent(index, v)}
            validate={required}
            placeholder={t("menu.TechCardProductsFields.Ingredient.Placeholder")}
            formItemProps={formItemLayout}
            options={contents}
            showSearch
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "2px 0 4px" }} />
                <LinkContainer onMouseDown={(e) => e.preventDefault()}>
                  <Button type="link" onClick={onIngridientCreate}>
                    {t("menu.TechCardProductsFields.Ingredient.CreateNew")}
                  </Button>
                </LinkContainer>
              </div>
            )}
          />
        )}
      />
      <Table.Column
        title={t("menu.TechCardProductsFields.BruttoUnit.Name")}
        dataIndex="ingredient"
        render={(ingredient, _p, index) => (
          <Field
            name={`${fields.name}[${index}].brutto_unit`}
            component={renderInput}
            onChange={(_e, v) => onBruttoUnitChange(index, v)}
            validate={required}
            type="number"
            min={0}
            step={0.001}
            parse={parseFloatValue(3)}
            formItemProps={formItemLayout}
            addonAfter={ingredient && UNIT_LABELS_BY_VALUE[ingredient.unit]}
          />
        )}
      />
      <Table.Column
        title={t("menu.TechCardProductsFields.BruttoWeight.Name")}
        dataIndex="ingredient"
        render={(ingredient, _p, index) => (
          <Field
            name={`${fields.name}[${index}].brutto_weight`}
            component={renderInput}
            disabled={ingredient?.unit_weight === 0}
            onChange={(_e, v) => onBruttoChange(index, v)}
            validate={required}
            type="number"
            min={0}
            parse={parseFloatValue(0)}
            formItemProps={formItemLayout}
            addonAfter={UNIT_LABELS_BY_VALUE.g}
          />
        )}
      />
      <Table.Column
        title={t("menu.TechCardProductsFields.ColdProcessingLoss.Name")}
        dataIndex="ingredient"
        render={(ingredient, _p, index) => (
          <Field
            name={`${fields.name}[${index}].cold_loss`}
            component={renderInput}
            disabled={ingredient?.unit_weight === 0}
            onChange={(_e, v) => onColdLossChange(index, v)}
            validate={required}
            type="number"
            min={0}
            step={0.01}
            max={100}
            parse={parseFloatValue(2)}
            normalize={(val) => (val || val === 0 ? val / 100 : "")}
            format={(val) => (val || val === 0 ? val * 100 : "")}
            formItemProps={formItemLayout}
            addonAfter="%"
          />
        )}
      />
      <Table.Column
        title={t("menu.TechCardProductsFields.NettoWeight.Name")}
        dataIndex="ingredient"
        render={(ingredient, _p, index) => (
          <Field
            name={`${fields.name}[${index}].netto_weight`}
            component={renderInput}
            disabled={ingredient?.unit_weight === 0}
            onChange={(_e, v) => onNettoChange(index, v)}
            validate={required}
            type="number"
            min={0}
            parse={parseFloatValue(0)}
            formItemProps={formItemLayout}
            addonAfter={UNIT_LABELS_BY_VALUE.g}
          />
        )}
      />
      <Table.Column
        title={t("menu.TechCardProductsFields.HotProcessingLoss.Name")}
        dataIndex="ingredient"
        render={(ingredient, _p, index) => (
          <Field
            name={`${fields.name}[${index}].hot_loss`}
            component={renderInput}
            disabled={ingredient?.unit_weight === 0}
            onChange={(_e, v) => onHotLossChange(index, v)}
            validate={required}
            type="number"
            min={0}
            step={0.01}
            max={100}
            parse={parseFloatValue(2)}
            normalize={(val) => (val || val === 0 ? val / 100 : "")}
            format={(val) => (val || val === 0 ? val * 100 : "")}
            formItemProps={formItemLayout}
            addonAfter="%"
          />
        )}
      />
      <Table.Column
        title={t("menu.TechCardProductsFields.OutputWeight.Name")}
        dataIndex="ingredient"
        render={(ingredient, _p, index) => (
          <Field
            name={`${fields.name}[${index}].output_weight`}
            component={renderInput}
            disabled={ingredient?.unit_weight === 0}
            onChange={(_e, v) => onOutputChange(index, v)}
            validate={required}
            type="number"
            min={0}
            parse={parseFloatValue(0)}
            formItemProps={formItemLayout}
            addonAfter={UNIT_LABELS_BY_VALUE.g}
          />
        )}
      />
      <Table.Column
        title={t("menu.TechCardProductsFields.Cost.Name")}
        dataIndex={["ingredient", "product"]}
        render={(product, _p, index) => (
          <Field
            name={`${fields.name}[${index}].cost`}
            component={renderLabel}
            format={(cost) => (product ? (
              <Tooltip title={t("menu.TechCardProductsFields.Cost.UsingProductCost")}>
                {formatPrice(cost)}
                &nbsp;
                <InfoCircleOutlined />
              </Tooltip>
            ) : formatPrice(cost))}
            formItemProps={formItemLayout}
          />
        )}
      />
      <Table.Column
        dataIndex="content_path"
        render={(_c, _p, index) => (
          <Button
            danger
            disabled={fields.length === (allowEmpty ? 0 : 1)}
            onClick={() => fields.remove(index)}
          >
            <DeleteOutlined />
          </Button>
        )}
      />
    </ProductsInputTable>
  );
};

TechCardProductsFields.propTypes = {
  t: PropTypes.func.isRequired,
  allowEmpty: PropTypes.bool,
  emptyText: PropTypes.string,
  changeField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  optionsData: PropTypes.object,
  onIngridientCreate: PropTypes.func.isRequired,
};

export default TechCardProductsFields;
