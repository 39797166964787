import { formatPrice } from "utils/helpers";
import i18n from "../../../i18n";

export const INCOMING_ORDERS_COLUMNS = [
  {
    title: i18n.t("statistics.IncomingOrders.Columns.Application"),
    dataIndex: "app",
  },
  {
    title: i18n.t("statistics.IncomingOrders.Columns.Location"),
    dataIndex: "location_id",
    filters: [
      {
        text: i18n.t("statistics.IncomingOrders.Columns.CoffeHouse"),
        value: i18n.t("statistics.IncomingOrders.Columns.CoffeHouse"),
      },
      {
        text: i18n.t("statistics.IncomingOrders.Columns.CafePolyanka"),
        value: i18n.t("statistics.IncomingOrders.Columns.CafePolyanka"),
      },
    ],
    onFilter: (value, record) => record.location_id.indexOf(value) === 0,
  },
  {
    title: i18n.t("statistics.IncomingOrders.Columns.Waiter"),
    dataIndex: "waiter",
  },
  {
    title: i18n.t("statistics.IncomingOrders.Columns.Reserved"),
    dataIndex: "reserved",
  },
  {
    title: i18n.t("statistics.IncomingOrders.Columns.Changed"),
    dataIndex: "change",
  },
  {
    title: i18n.t("statistics.IncomingOrders.Columns.Paid"),
    dataIndex: "paid",
    filters: [
      {
        text: i18n.t("statistics.IncomingOrders.Columns.Paid"),
        value: i18n.t("statistics.IncomingOrders.Columns.Paid"),
      },
      {
        text: i18n.t("statistics.IncomingOrders.Columns.Unpaid"),
        value: i18n.t("statistics.IncomingOrders.Columns.Unpaid"),
      },
    ],
    onFilter: (value, record) => record.paid.indexOf(value) === 0,
  },
  {
    title: i18n.t("statistics.IncomingOrders.Columns.AmountCheck"),
    dataIndex: "amount",
    render: formatPrice,
  },
  {
    title: i18n.t("statistics.IncomingOrders.Columns.Status"),
    dataIndex: "status",
    filters: [
      {
        text: i18n.t("statistics.IncomingOrders.Columns.Performed"),
        value: i18n.t("statistics.IncomingOrders.Columns.Performed"),
      },
      {
        text: i18n.t("statistics.IncomingOrders.Columns.NotPerformed"),
        value: i18n.t("statistics.IncomingOrders.Columns.NotPerformed"),
      },
    ],
    onFilter: (value, record) => record.status.indexOf(value) === 0,
  },
];
