import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";
import NewIngredientPane from "components/NewIngredientPane";
import NewWorkshopPane from "components/NewWorkshopPane";

import PrepmealForm from "./components/PrepmealForm";
import { SELECT_OPTIONS, CREATE_PREPMEAL_MUTATION } from "./prepmealQueries";
import { SELECT_OPTIONS_QUERY as MODIFIER_OPTIONS_QUERY } from "../modifiers/modifiersQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const NewPrepmeal = ({ history }) => {
  const { t } = useTranslation();
  const [isCreatingIngridient, setCreatingIngridient] = useState(false);
  const [isCreatingWorkshop, setCreatingWorkshop] = useState(false);

  const { data, loading } = useQuery(SELECT_OPTIONS, {
    onError: notification.error,
  });
  const [createPrepmeal] = useMutation(CREATE_PREPMEAL_MUTATION, {
    refetchQueries: [
      { query: SELECT_OPTIONS },
      { query: MODIFIER_OPTIONS_QUERY },
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });

  const handleSubmit = ({ is_produced, output, total_cost, tech_card_products, ...values }) => {
    const techCardProducts = tech_card_products
      .map(({ content, brutto_unit, cold_loss, hot_loss, cost }) => ({
        ...content, quantity: brutto_unit, cost, cold_loss, hot_loss,
      }));
    return createPrepmeal({
      variables: {
        data: {
          tech_card_products: { data: techCardProducts },
          is_set: false,
          ...values,
          product: is_produced ? {
            data: {
              name: values.name,
              cost: values.cost,
              unit: values.unit,
              unit_weight: values.unit_weight,
            },
          } : undefined,
        },
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  if (loading) return <Spin />;

  return (
    <Page showBackIcon title={t("menu.Prepmeals.AddPage.Title")}>
      <Card>
        <PrepmealForm
          t={t}
          onSubmit={handleSubmit}
          optionsData={data}
          onIngridientCreate={() => setCreatingIngridient(true)}
          onWorkshopCreate={() => setCreatingWorkshop(true)}
        />
      </Card>
      <NewIngredientPane
        isOpen={isCreatingIngridient}
        onClose={() => setCreatingIngridient(false)}
      />
      <NewWorkshopPane
        isOpen={isCreatingWorkshop}
        onClose={() => setCreatingWorkshop(false)}
      />
    </Page>
  );
};

NewPrepmeal.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewPrepmeal;
