import gql from "graphql-tag";

export const EMPLOYEES_QUERY = gql`
  query($filters: FiltersInput!, $source: OrderSourceEnum) {
    employeesStats(filters: $filters, source: $source) {
      id
      name
      orders_count
      revenue
    }
  }
`;
