import gql from "graphql-tag";

export const WRITE_OFFS_QUERY = gql`
  query GetWriteOffs($limit: Int, $offset: Int, $filter: write_offs_bool_exp) @hasura {
    write_offs(limit: $limit, offset: $offset, where: $filter, order_by: {id: desc})  {
      id
      number
      datetime
      description
      reason
      location {
        id
        name
      }
      write_off_products(order_by: {id: asc}) {
        id
        cost
        quantity
        product {
          id
          name
          unit
        }
      }
    }
    locations(order_by: {id: asc}) {
      id
      name
    }
    write_offs_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
`;

export const EDIT_WRITE_OFF_QUERY = gql`
  query EditWriteOffQuery($id: Int!) @hasura {
    write_offs_by_pk(id: $id)  {
      id
      reason
      location_id
      datetime
      description
      write_off_products(order_by: {id: asc}) {
        id
        quantity
        product_id
      }
    }
  }
`;

export const CREATE_WRITE_OFF_MUTATION = gql`
  mutation CreateWriteOff($data: WriteOffInput!) {
    createWriteOff(data: $data) {
      id
    }
  }
`;
