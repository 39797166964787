import gql from "graphql-tag";

export const LOCATION_PRODUCTS_QUERY = gql`
  query @hasura {
    location_products {
      id
      location_id
      product_id
      quantity
    }
  }
`;
