import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";
import Page from "components/Page";

import IngredientForm from "./components/IngredientForm";
import { CREATE_INGREDIENT_MUTATION } from "./ingredientQueries";
import { INGREDIENT_CATEGORIES_QUERY } from "../ingredientCategories/ingredientCategoryQueries";
import { SELECT_OPTIONS } from "../prepmeals/prepmealQueries";
import { SELECT_OPTIONS_QUERY } from "../techCards/techCardQueries";
import { SELECT_OPTIONS_QUERY as MODIFIER_OPTIONS_QUERY } from "../modifiers/modifiersQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const NewIngredient = ({ history }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(INGREDIENT_CATEGORIES_QUERY, {
    onError: notification.error,
  });
  const [createIngredient] = useMutation(CREATE_INGREDIENT_MUTATION, {
    refetchQueries: [
      { query: SELECT_OPTIONS },
      { query: SELECT_OPTIONS_QUERY },
      { query: MODIFIER_OPTIONS_QUERY },
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });

  if (loading) return <Spin />;

  const handleSubmit = (values) =>
    createIngredient({ variables: { data: values } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("menu.Ingredients.AddPage.Title")}>
      <Row>
        <Col xs={24} md={20} lg={16}>
          <Card>
            <IngredientForm
              t={t}
              onSubmit={handleSubmit}
              ingredientCategories={data ? data.product_categories : []}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

NewIngredient.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewIngredient;
