import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button } from "antd";

import { renderInput } from "components/FormItem";
import { required } from "utils/formValidations";

const StyledCol = styled(Col)`
  margin-bottom: 8px;
`;

const PlusButton = styled(Button)`
  margin-right: 10px;
`;

const formItem = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
  labelAlign: "left",
  colon: false,
};

const fieldLayout = {
  wrapperCol: {
    sm: { span: 23 },
  },
  style: {
    marginTop: 0,
    marginBottom: 8,
  },
};

const TablesFields = ({ t, fields }) => (
  <Form.Item label={t("settings.Location.Form.Tables.Label")} {...formItem}>
    {fields.map((tables, index) => (
      <Row key={index} type="flex" justify="start" align="middle">
        <Col span={18}>
          <Field
            name={`${tables}.name`}
            placeholder={t("settings.Location.Form.TableName.Label")}
            component={renderInput}
            validate={required}
            formItemProps={fieldLayout}
          />
        </Col>
        {fields.length > 0 && (
        <StyledCol span={4}>
          <Button type="danger" onClick={() => fields.remove(index)}>
            <DeleteOutlined />
          </Button>
        </StyledCol>
        )}
      </Row>
    ))}
    <PlusButton type="dashed" onClick={() => fields.push({})}>
      <PlusOutlined /> {t("settings.Location.Form.AddTable")}
    </PlusButton>
    {fields.length > 1 && (
    <Button onClick={() => fields.removeAll()}>{t("DeleteAll")}</Button>
    )}
  </Form.Item>
);

TablesFields.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object,
};

export default TablesFields;
