import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Card, notification, Spin, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";

import Page from "components/Page";
import NewWorkshopPane from "components/NewWorkshopPane";
import NewCategoryPane from "components/NewCategoryPane";

import SetForm from "./components/SetForm";
import {
  SET_QUERY,
  UPDATE_SET_MUTATION,
  SELECT_OPTIONS_QUERY,
} from "./setQueries";

const EditSet = ({ history, match }) => {
  const setId = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const [isCreatingWorkshop, setCreatingWorkshop] = useState(false);
  const [isCreatingCategory, setCreatingCategory] = useState(false);
  const [toggleArchived, setToggleArchived] = useState(false);

  const { data, loading } = useQuery(SET_QUERY, {
    variables: { id: setId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const selectQuery = useQuery(SELECT_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [updateSet] = useMutation(UPDATE_SET_MUTATION);

  const contentItems = useMemo(() => selectQuery.data?.menu_categories
    .flatMap(({ id: categoryId, menu_item_groups }) => menu_item_groups
      .flatMap(({ id: migId, menu_items }) => menu_items
        .map(({ product, tech_card }) => {
          const { id, workshop_id, ...item } = product || tech_card;
          return {
            categoryId,
            migId,
            menuItemsCount: menu_items.length,
            ...item,
            value: JSON.stringify(workshop_id ? { tech_card_id: id } : { product_id: id }),
          };
        }))), [selectQuery.data]);

  const mig = data?.menu_item_groups[0];
  const initialSet = useMemo(() => mig && contentItems && {
    ...mig,
    menu_item: {
      ...mig.menu_items[0],
      cost: mig.menu_items[0].tech_card.cost,
    },
    tech_card: {
      ...mig.menu_items[0].tech_card,
      tech_card_products: mig.menu_items[0].tech_card.tech_card_products.map(({
        product_id, tech_card_id, quantity: brutto_unit, ...rest
      }) => {
        const content = product_id ? { product_id } : { tech_card_id };
        const { categoryId, migId, menuItemsCount, value, ...ingredient } = contentItems
          .find((ci) => ci.value === JSON.stringify(content));
        const contentPath = [categoryId].concat(menuItemsCount > 1 ? [migId, value] : [value]);
        return { ...rest, brutto_unit, ingredient, content, content_path: contentPath };
      }),
    },
  }, [mig, contentItems]);

  if (loading || selectQuery.loading) return <Spin />;

  const handleSubmit = ({
    id: igId, total_cost, output, name, archived_at,
    tech_card: { id: tId, product_id, ...tech_card },
    menu_item: {
      id: mId, limited_location_availability = [], cost, price, menu_item_modifier_groups,
    },
    ...itemGroup
  }) => {
    const menuItem = {
      id: mId,
      name,
      price,
      menu_item_modifier_groups: { data: menu_item_modifier_groups || [] },
      limited_location_availability: limited_location_availability?.length === 0
        ? null : limited_location_availability,
      tech_card: {
        data: {
          ...tech_card,
          name,
          cost,
          tech_card_products: {
            data: tech_card.tech_card_products
              .map(({ id, content, brutto_unit, cost: pCost }) => ({
                id,
                product_id: null,
                tech_card_id: null,
                ...content,
                quantity: brutto_unit,
                cost: pCost,
                cold_loss: 0,
                hot_loss: 0,
              })),
          },
        },
      },
    };

    const archiveTechCard = archived_at ? null : moment().format();
    return updateSet({
      variables: {
        id: setId,
        data: {
          ...itemGroup,
          name,
          menu_items: { data: [menuItem] },
          archived_at: toggleArchived ? archiveTechCard : archived_at,
        },
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  const archivingOrUnarchivingButton = (
    <Button
      type="danger"
      htmlType="submit"
      onClick={() => setToggleArchived(true)}
    >
      {initialSet?.archived_at ? t("Unarchiving") : t("Archiving")}
    </Button>
  );

  return (
    <Page showBackIcon title={t("menu.Sets.EditPage.Title")}>
      <Card>
        <SetForm
          t={t}
          onSubmit={handleSubmit}
          initialValues={initialSet}
          optionsData={selectQuery.data}
          onWorkshopCreate={() => setCreatingWorkshop(true)}
          onCategoryCreate={() => setCreatingCategory(true)}
          archivingOrUnarchivingButton={archivingOrUnarchivingButton}
        />
      </Card>
      <NewWorkshopPane
        isOpen={isCreatingWorkshop}
        onClose={() => setCreatingWorkshop(false)}
      />
      <NewCategoryPane
        isOpen={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
      />
    </Page>
  );
};

EditSet.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditSet;
