import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import styled from "styled-components";
import { Form } from "@ant-design/compatible";
import { Row, Col } from "antd";

import colors from "theme/colors";
import { renderInputNumber } from "components/FormItem";
import { required } from "utils/formValidations";
import { singletonCurrency } from "utils/helpers";

const BorderRow = styled(Row)`
  border-bottom: 1px solid ${colors.lightGrey};
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const formItem = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  labelAlign: "left",
  colon: false,
};

const formItemStyle = {
  style: {
    marginTop: 0,
    marginBottom: 0,
  },
};

const Payroll = ({ t }) => (
  <div>
    <BorderRow>
      <h2>{t("access.AccessRoles.Payroll.Payroll")}</h2>
    </BorderRow>
    <StyledRow>
      <span>
        {t("access.AccessRoles.Payroll.ChoosePayrollDescription")}
      </span>
    </StyledRow>
    <StyledRow>
      <Col span={16}>
        <Form.Item label={t("access.AccessRoles.Payroll.FixedRate")} {...formItem}>
          <Field
            name="payroll_hour"
            label={t("access.AccessRoles.Payroll.InAnHour", { currency: singletonCurrency.getCurrency() })}
            type="number"
            min={0}
            component={renderInputNumber}
            formItemProps={formItemStyle}
            validate={required}
          />
          <Field
            name="payroll_shift"
            label={t("access.AccessRoles.Payroll.PerShift", { currency: singletonCurrency.getCurrency() })}
            type="number"
            min={0}
            component={renderInputNumber}
            formItemProps={formItemStyle}
            validate={required}
          />
          <Field
            name="payroll_month"
            label={t("access.AccessRoles.Payroll.PerMonth", { currency: singletonCurrency.getCurrency() })}
            type="number"
            min={0}
            component={renderInputNumber}
            formItemProps={formItemStyle}
            validate={required}
          />
        </Form.Item>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col span={16}>
        <Form.Item label={t("access.AccessRoles.Payroll.PercentageOfSales")} {...formItem}>
          <Field
            name="percent_personal_sales"
            label={`${t("access.AccessRoles.Payroll.Personal")} (%)`}
            type="number"
            min={0}
            component={renderInputNumber}
            formItemProps={formItemStyle}
            validate={required}
          />
          <Field
            name="percent_shift_sales"
            label={`${t("access.AccessRoles.Payroll.PerShift")} (%)`}
            type="number"
            min={0}
            component={renderInputNumber}
            formItemProps={formItemStyle}
            validate={required}
          />
        </Form.Item>
      </Col>
    </StyledRow>
  </div>
);

Payroll.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Payroll;
