import { formatPrice } from "utils/helpers";
import i18n from "../../../i18n";

export const SALARY_COLUMNS = [
  {
    title: i18n.t("finance.Salary.Columns.Employee"),
    dataIndex: "employee",
  },
  {
    title: i18n.t("finance.Salary.Columns.Position"),
    dataIndex: "job_position",
  },
  {
    title: i18n.t("finance.Salary.Columns.RateMonthly"),
    dataIndex: "rate_monthly",
    render: formatPrice,
  },
  {
    title: i18n.t("finance.Salary.Columns.Hours"),
    dataIndex: "hours",
  },
  {
    title: i18n.t("finance.Salary.Columns.Shift"),
    dataIndex: "shift",
  },
  {
    title: i18n.t("finance.Salary.Columns.ShiftSales"),
    dataIndex: "shift_sales",
    render: formatPrice,
  },
  {
    title: i18n.t("finance.Salary.Columns.Total"),
    dataIndex: "total",
    render: formatPrice,
  },
];
