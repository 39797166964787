import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Row, Col, Button, Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  NumberParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import { LIMIT_PAGE } from "constants/index";

import { ACCESS_ROLE_COLUMNS } from "./constants";
import { ACCESS_ROLES_QUERY } from "./accessRolesQueries";

const Container = styled.div`
  margin: 12px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const AccessRoles = ({ history }) => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const { data, loading } = useQuery(ACCESS_ROLES_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  return (
    <Container>
      <StyledRow>
        <FlexCol span={4} offset={20}>
          <Button
            type="primary"
            onClick={() => history.push("/access/access-roles/new")}
          >
            {t("Add")}
          </Button>
        </FlexCol>
      </StyledRow>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize: LIMIT_PAGE,
        }}
        loading={loading}
        columns={ACCESS_ROLE_COLUMNS}
        dataSource={data && data.access_roles}
      />
    </Container>
  );
};

AccessRoles.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AccessRoles;
