import React from "react";
import { Switch, Route } from "react-router-dom";

import Workshops from "./Workshops";
import NewWorkshop from "./NewWorkshop";
import EditWorkshop from "./EditWorkshop";

const Container = () => (
  <Switch>
    <Route path="/menu/workshops/new" component={NewWorkshop} />
    <Route path="/menu/workshops/:id" component={EditWorkshop} />
    <Route path="/menu/workshops" component={Workshops} />
  </Switch>
);

export default Container;
