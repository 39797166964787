import { formatPrice } from "utils/helpers";
import i18n from "../../../i18n";

export const TAXE_COLUMNS = [
  {
    title: i18n.t("statistics.Taxe.Columns.Name"),
    dataIndex: "name",
  },
  {
    title: i18n.t("statistics.Taxe.Columns.Type"),
    dataIndex: "type",
  },
  {
    title: i18n.t("statistics.Taxe.Columns.Percent"),
    dataIndex: "percent",
    render: (percent) => `${percent} %`,
  },
  {
    title: i18n.t("statistics.Taxe.Columns.ItemAmount"),
    dataIndex: "productAmount",
    render: formatPrice,
  },
  {
    title: i18n.t("statistics.Taxe.Columns.TaxeAmount"),
    dataIndex: "taxeAmount",
    render: formatPrice,
  },
];
