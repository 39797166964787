import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
`;

export const RESULT_TYPE_OPTIONS = [
  { value: "product", label: i18n.t("marketing.Promotion.ResultType.BonusItem") },
  { value: "discount", label: i18n.t("marketing.Promotion.ResultType.DiscountPercentage") },
];

export const PROMOTION_COLUMNS = [
  {
    title: i18n.t("marketing.Promotion.Columns.Name"),
    dataIndex: "name",
    render: (_, record) => (
      <StyledLink to={`/marketing/promotions/${record.id}`}>
        {record.name}
      </StyledLink>
    ),
  },
  {
    title: i18n.t("marketing.Promotion.Columns.DateStart"),
    dataIndex: "start_date",
    render: (date) => moment(date).format("LL"),
  },
  {
    title: i18n.t("marketing.Promotion.Columns.DateEnd"),
    dataIndex: "end_date",
    render: (date) => date && moment(date).format("LL"),
  },
  {
    title: i18n.t("marketing.Promotion.Columns.ResultType"),
    dataIndex: "result_type",
    render: (type) => RESULT_TYPE_OPTIONS.find((o) => o.value === type).label,
  },
];

export const CONDITION_TYPE_OPTIONS = [
  {
    value: "or",
    label: i18n.t("marketing.Promotion.ConditionType.or"),
  },
  {
    value: "and",
    label: i18n.t("marketing.Promotion.ConditionType.and"),
  },
];

export const DATE_WEEK = [1, 2, 3, 4, 5, 6, 7].map((dow) => ({
  value: dow,
  label: moment().isoWeekday(dow).format("ddd"),
}));
