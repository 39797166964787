import React, { useState, useEffect, useMemo } from "react";
import { Table, notification } from "antd";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import _ from "lodash";

import ConnectData from "connect.container";

import { LIMIT_PAGE, PAYMENT_METHOD_TYPE } from "constants/index";
import { formatPrice } from "utils/helpers";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import { PAYMENT_COLUMNS } from "./constants";
import { PAYMENTS_QUERY } from "./paymentsQueries";
import PaymentsDetails from "./components/PaymentsDetails";

const Payments = () => {
  const { t } = useTranslation();
  const { locations } = ConnectData.useContainer();

  const [getPaymentsInfo, { loading, data }] = useLazyQuery(PAYMENTS_QUERY, {
    onError: notification.error,
  });

  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [filter, setFilter] = useState(null);

  useEffect(_.throttle(() => {
    if (filter && filter.time) {
      getPaymentsInfo({
        variables: {
          filters: {
            to: filter.time.to.format(),
            from: filter.time.from.format(),
            location_id: filter.locationId,
          },
        },
      });
    }
  }, 200), [filter]);

  const summary = useMemo(() => data?.paymentsStatsByDay.reduce((acc, { stats }) =>
    stats.reduce((paymentsAcc, { amount, type, payment_method }) => {
      const idx = paymentsAcc.findIndex(({ id }) => payment_method.id === id);
      const changes = { payment: 0, refund: 0, [type]: amount };
      if (idx > -1) {
        return paymentsAcc.map((value, index) => {
          if (index === idx) {
            return {
              ...value,
              payment: value.payment + changes.payment,
              refund: value.refund + changes.refund,
            };
          }
          return value;
        });
      }
      return paymentsAcc.concat({ ...payment_method, ...changes });
    }, acc),
  []) ?? [], [data]);

  const getTotalForType = (totalType) => summary.reduce((acc, { payment, refund, type }) =>
    (type === totalType ? acc + payment - refund : acc), 0);
  const total = summary.reduce((acc, { payment, refund }) => acc + payment - refund, 0);

  return (
    <Page>
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        locations={locations}
        onFilter={(values) => { if (filter) { setPage(1); } setFilter(values); }}
      />
      <Table
        rowKey={({ day, location: { id } }) => `${day}-${id}`}
        loading={loading}
        pagination={{
          current: page,
          onChange: setPage,
          pageSize: LIMIT_PAGE,
          showSizeChanger: false,
        }}
        columns={PAYMENT_COLUMNS}
        dataSource={data?.paymentsStatsByDay}
        expandRowByClick
        expandedRowRender={({ stats }) => <PaymentsDetails stats={stats} t={t} />}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell />
            <Table.Summary.Cell>{t("SummaryTotal")}</Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(getTotalForType(PAYMENT_METHOD_TYPE.CASH))}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(getTotalForType(PAYMENT_METHOD_TYPE.CARD))}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(getTotalForType(PAYMENT_METHOD_TYPE.CUSTOM))}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(total)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    </Page>
  );
};

export default Payments;
