import React from "react";
import { Route, Switch } from "react-router-dom";

import Delivery from "./Delivery";
import DeliveryZone from "./DeliveryZone";

const Container = () => (
  <Switch>
    <Route path="/statistics/delivery/zones/:id" component={DeliveryZone} />
    <Route path="/statistics/delivery" component={Delivery} />
  </Switch>
);

export default Container;
