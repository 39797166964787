import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Table, Row, Col, DatePicker, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";

import { LIMIT_PAGE } from "constants/index";

import { shops } from "../mock";
import { SHOPS_COLUMNS } from "./constants";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Container = styled.div`
  padding: 12px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const dateFormat = "ll";

const Shops = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [search, setSearch] = useState("");

  const shopsData = shops.filter(({ name, address }) =>
    `${name}_${address}`.includes(search.trim().toLowerCase()));

  return (
    <Container>
      <StyledRow>
        <Col span={4}>
          <Search
            placeholder={t("QuickSearch")}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <FlexCol span={6} offset={14}>
          <RangePicker
            disabledDate={(current) => current && current > moment().endOf("day")}
            defaultValue={[
              moment().subtract(1, "month", dateFormat),
              moment(new Date(), dateFormat),
            ]}
            format={dateFormat}
          />
        </FlexCol>
      </StyledRow>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize: LIMIT_PAGE,
        }}
        columns={SHOPS_COLUMNS}
        dataSource={shopsData}
      />
    </Container>
  );
};

export default Shops;
