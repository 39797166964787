import React from "react";
import { Switch, Route } from "react-router-dom";

import Promotions from "./promotions";

const Container = () => (
  <Switch>
    <Route path="/marketing/promotions" component={Promotions} />
  </Switch>
);

export default Container;
