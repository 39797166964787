import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Alert } from "antd";

import { renderSelect } from "components/FormItem";
import { required } from "utils/formValidations";

const StyledForm = styled(Form)`
  padding: 10px 40px;
`;

const ConvertToTechCardForm = ({
  t,
  handleSubmit,
  workshops,
  error,
}) => {
  const workshopOptions = workshops.map(
    ({ id, name }) => ({ value: id, label: name }),
  );
  return (
    <StyledForm onSubmit={handleSubmit}>
      {error && <Alert message={error} type="error" />}
      <Field
        name="workshop_id"
        label={t("menu.Products.ConvertToTechCardModal.Form.Workshop.Label")}
        placeholder={`${t("menu.Products.ConvertToTechCardModal.Form.Workshop.Placeholder")}`}
        component={renderSelect}
        options={workshopOptions}
        validate={required}
      />
    </StyledForm>
  );
};

ConvertToTechCardForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  workshops: PropTypes.array.isRequired,
  error: PropTypes.string,
};

export default reduxForm({
  form: "convertToTechCardForm",
})(ConvertToTechCardForm);
