import React from "react";
import { Switch, Route } from "react-router-dom";

import Ingredients from "./Ingredients";
import NewIngredient from "./NewIngredient";
import EditIngredient from "./EditIngredient";

const Container = () => (
  <Switch>
    <Route path="/menu/ingredients/new" component={NewIngredient} />
    <Route path="/menu/ingredients/:id" component={EditIngredient} />
    <Route path="/menu/ingredients" component={Ingredients} />
  </Switch>
);

export default Container;
