import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  List,
  Tag,
  notification,
  Spin,
  Tooltip,
  Row,
  Col,
  Descriptions,
  Table,
  Typography,
} from "antd";
import moment from "moment";

import { formatPrice, stringifyModifiers } from "utils/helpers";
import { PAYMENT_METHOD_TYPE, PAYMENT_METHOD_NAME, PAYMENT_TYPE } from "constants/index";
import i18n from "i18n";

import { ORDER_DETAILS_QUERY } from "../ordersQueries";
import {
  getDisplayNumber,
  ORDER_STATUS,
  ORDER_SOURCE_ICONS, PAYMENT_TYPE_ICONS, ORDER_LINE_STATUS_ICONS,
  ORDER_STATUS_COLORS, ORDER_LINE_STATUS_COLORS,
} from "../constants";

const { Text } = Typography;

const Loader = styled(Spin)`
  position: absolute;
  left: 50%;
  margin-top: 72px;
`;

const OrderLinesColumns = [
  {
    title: i18n.t("orders.OrderDetails.OrderLines.Table.Name"),
    dataIndex: "display_name",
    render: (name, { notes, modifiers }) => (
      <Text>
        {name}{notes && (<Text type="secondary">&nbsp;<i>{notes}</i></Text>)}
        {modifiers?.filter((m) => m.value.length > 0)
          .map((modifier) => (
            <div key={modifier.list_group_name} style={{ marginLeft: 10, fontSize: 12 }}>
              {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
            </div>
          ))}
      </Text>
    ),
  },
  {
    title: i18n.t("orders.OrderDetails.OrderLines.Table.Price"),
    dataIndex: "price",
    render: formatPrice,
  },
  {
    title: i18n.t("orders.OrderDetails.OrderLines.Table.Count"),
    dataIndex: "count",
  },
  {
    title: i18n.t("orders.OrderDetails.OrderLines.Table.TotalPrice"),
    dataIndex: "total_price",
    render: formatPrice,
  },
  {
    title: i18n.t("orders.OrderDetails.OrderLines.Table.Status.Title"),
    dataIndex: "status",
    render: (status, { order_line_status_updates, order_line_cancel, status_updated_at }) => {
      const time = moment(status_updated_at)
        .diff(order_line_status_updates[0].created_at, "minutes");
      const updates = order_line_status_updates
        .map((su) => `${i18n.t(`const:status.${su.status}`)} - ${moment(su.created_at).format("LTS")}`)
        .join("\n");
      return (
        <>
          <Tooltip title={<span style={{ whiteSpace: "pre-wrap" }}>{updates}</span>}>
            <Tag color={ORDER_LINE_STATUS_COLORS[status]}>
              <LegacyIcon type={ORDER_LINE_STATUS_ICONS[status]} />
            &nbsp;
              {i18n.t(`const:status.${status}`)}
            &nbsp;
              {i18n.t("orders.OrderDetails.OrderLines.Table.Status.Duration", { time })}
            </Tag>
          </Tooltip>
          {order_line_cancel && (
          <Text type="secondary">
            {order_line_cancel.reason}
            {order_line_cancel.user && <i> - {order_line_cancel.user.name}</i>}
          </Text>
          )}
        </>
      );
    },
  },
];

const OrderDetails = ({ orderId }) => {
  const { t } = useTranslation();

  const { data } = useQuery(ORDER_DETAILS_QUERY, {
    variables: { orderId },
    onError: notification.error,
  });

  const order = data?.orders_by_pk;

  if (!order) return <Loader />;

  const orderTime = moment(order.status_updated_at).diff(order.time, "minutes");
  const {
    order_lines_total, discount, service_percent, delivery_price,
  } = _.last(order.order_price_updates);
  const customerOrdersCount = order.customer?.orders_aggregate.aggregate.count;

  const servedStatus = order.order_status_updates
    .find((osu) => osu.status === ORDER_STATUS.SERVED);
  const servedTime = servedStatus && moment(servedStatus.created_at).diff(order.time, "minutes");

  return (
    <Row justify="center" gutter={[8, 8]}>
      <Col span={23}>
        <Descriptions
          size="small"
          title={t("orders.OrderDetails.Details.Title", { number: getDisplayNumber(order) })}
        >
          <Descriptions.Item label={t("orders.OrderDetails.Details.Time")}>
            {moment(order.time).format("lll")}
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Details.Status")}>
            <Tooltip title={t("orders.OrderCard.Duration.Description")}>
              <Tag color={ORDER_STATUS_COLORS[order.status]}>
                {t(`const:status.${order.status}`)}
                &nbsp;
                {t("orders.OrderCard.Duration.Label", { orderTime })}
              </Tag>
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Details.ListPrice")}>
            {formatPrice(order.list_price)}
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Details.Type")}>
            {t(`const:order_type.${order.type}`)}
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Details.Location")}>
            {order.location.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Details.User")}>
            <Tooltip title={t(`orders.OrderDetails.Details.Source.${order.source}`)}>
              <LegacyIcon type={ORDER_SOURCE_ICONS[order.source]} />&nbsp;{order.list_user}
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Details.OrderNotes")}>
            <i>{order.notes || t("orders.OrderDetails.Details.NoNotes")}</i>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      {order.customer && (
        <Col span={23}>
          <Descriptions
            size="small"
            layout="vertical"
            title={t("orders.OrderDetails.Customer.Title")}
          >
            <Descriptions.Item label={t("orders.OrderDetails.Customer.Name")}>
              {order.customer.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("orders.OrderDetails.Customer.PhoneNumber")}>
              {order.customer.phone_number}
            </Descriptions.Item>
            <Descriptions.Item label={t("orders.OrderDetails.Customer.Status.Title")}>
              {customerOrdersCount > 1 ? (
                <Tag color="geekblue">
                  {t("orders.OrderDetails.Customer.Status.ExistingCustomer", {
                    ordersCount: customerOrdersCount,
                  })}
                </Tag>
              ) : (
                <Tag color="purple">
                  {t("orders.OrderDetails.Customer.Status.NewCustomer")}
                </Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      )}
      {order.delivery_info && (
        <Col span={23}>
          <Descriptions
            size="small"
            layout="vertical"
            column={4}
            title={t("orders.OrderDetails.Delivery.Title")}
          >
            <Descriptions.Item label={t("orders.OrderDetails.Delivery.Courier")}>
              {order.courier?.name ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label={t("orders.OrderDetails.Delivery.Address")}>
              {[
                order.delivery_info.address.street,
                order.delivery_info.address.house,
                order.delivery_info.address.flat,
                order.delivery_info.address.notes,
              ].filter((v) => v).join(", ")}
            </Descriptions.Item>
            <Descriptions.Item label={t("orders.OrderDetails.Delivery.Price")}>
              {formatPrice(delivery_price)}
            </Descriptions.Item>
            <Descriptions.Item label={t("orders.OrderDetails.Delivery.Time.Title")}>
              {servedTime
                ? t("orders.OrderDetails.Delivery.Time.Duration", { time: servedTime }) : "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      )}
      <Col span={23}>
        <Descriptions size="small" title={t("orders.OrderDetails.OrderLines.Title")} />
      </Col>
      <Col span={23}>
        <Table
          columns={OrderLinesColumns}
          dataSource={order.order_lines}
          size="small"
          rowKey="id"
          pagination={false}
        />
      </Col>
      <Col span={23}>
        <Descriptions size="small" layout="vertical" column={4}>
          <Descriptions.Item label={t("orders.OrderDetails.Price.OrderLinesTotal")}>
            {formatPrice(order_lines_total)}
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Price.ServicePercent")}>
            {service_percent * 100}%
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Price.Discount")}>
            {discount * 100}%
          </Descriptions.Item>
          <Descriptions.Item label={t("orders.OrderDetails.Price.ListPrice")}>
            <Text style={{ fontSize: "1.2em" }} strong>{formatPrice(order.list_price)}</Text>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      {order.order_cancel && (
        <Col span={23}>
          <Descriptions
            size="small"
            layout="vertical"
            title={t("orders.OrderDetails.Cancel.Title")}
          >
            <Descriptions.Item label={t("orders.OrderDetails.Cancel.User")}>
              <Tooltip title={t(`orders.OrderDetails.Cancel.Source.${order.order_cancel.source}`)}>
                <LegacyIcon type={ORDER_SOURCE_ICONS[order.order_cancel.source]} />
                &nbsp;
                {order.order_cancel.user.name}
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label={t("orders.OrderDetails.Cancel.Reason")}>
              {order.order_cancel.reason}
            </Descriptions.Item>
            <Descriptions.Item label={t("orders.OrderDetails.Cancel.Notes")}>
              <i>{order.order_cancel.notes ?? "-"}</i>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      )}
      <Col span={14}>
        <Descriptions size="small" title={t("orders.OrderDetails.Payments.Title")} />
        <List
          bordered
          size="small"
          rowKey="id"
          dataSource={order.order_payments.length > 0 ? order.order_payments : ["none"]}
          renderItem={(payment) => {
            if (payment === "none") {
              return (
                <List.Item>
                  <Text type="secondary"><i>{t("orders.OrderDetails.Payments.NoPayments")}</i></Text>
                </List.Item>
              );
            }

            return (
              <List.Item actions={[
                payment.user?.name,
                <Text style={{ display: "inline-block", width: "100px", textAlign: "right" }}>
                  {formatPrice(payment.amount)}
                </Text>,
              ]}
              >
                {moment(payment.created_at).format("LT")}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                <LegacyIcon type={PAYMENT_TYPE_ICONS[payment.payment_method.type]} />
                  &nbsp;
                {payment.payment_method.type === PAYMENT_METHOD_TYPE.CUSTOM
                  ? payment.payment_method.name
                  : PAYMENT_METHOD_NAME[payment.payment_method.name]}
                {payment.type === PAYMENT_TYPE.REFUND && (
                  <>
                  &nbsp;&nbsp;
                    <Tag color="red">{t("orders.OrderDetails.Payments.Refund")}</Tag>
                  </>
                )}
              </List.Item>
            );
          }}
        />
      </Col>
      <Col span={9}>
        <Descriptions size="small" title={t("orders.OrderDetails.Statuses.Title")} />
        <List
          bordered
          size="small"
          rowKey="id"
          dataSource={order.order_status_updates}
          renderItem={(statusUpdate) => (
            <List.Item actions={statusUpdate.user && [statusUpdate.user.name]}>
              {moment(statusUpdate.created_at).format("LTS")}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {t(`const:status.${statusUpdate.status}`)}
            </List.Item>
          )}
        />
      </Col>
      <Col className="scroll-space" style={{ height: "30px" }} span={24} />
    </Row>
  );
};

OrderDetails.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default OrderDetails;
