import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import Page from "components/Page";

import SupplierForm from "./components/SupplierForm";
import { SUPPLIERS_QUERY, CREATE_SUPPLIER_MUTATION } from "./supplierQueries";

const NewSupplier = ({ history }) => {
  const { t } = useTranslation();

  const [createSupplier, { error, loading }] = useMutation(CREATE_SUPPLIER_MUTATION, {
    refetchQueries: [{ query: SUPPLIERS_QUERY }],
  });

  const handleSubmit = (data) => createSupplier({ variables: { data } })
    .then(() => history.goBack()).catch();

  return (
    <Page showBackIcon title={t("warehouse.Suppliers.AddPage.Title")}>
      <Row>
        <Col xs={24} md={18} lg={14}>
          <Card>
            <SupplierForm
              t={t}
              onSubmit={handleSubmit}
              submitting={loading}
              error={error?.message}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

NewSupplier.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewSupplier;
