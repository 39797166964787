import React from "react";
import { Switch, Route } from "react-router-dom";

import Productions from "./Productions";
import NewProduction from "./NewProduction";

const Container = () => (
  <Switch>
    <Route path="/warehouse/productions/new" component={NewProduction} />
    <Route path="/warehouse/productions" component={Productions} />
  </Switch>
);

export default Container;
