import React from "react";
import { Switch, Route } from "react-router-dom";

import CashierShifts from "./CashierShifts";

const Container = () => (
  <Switch>
    <Route path="/finance/cashier-shifts" component={CashierShifts} />
  </Switch>
);

export default Container;
