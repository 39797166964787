import React from "react";
import { Switch, Route } from "react-router-dom";

import DeliveryZones from "./DeliveryZones";
import NewDeliveryZone from "./NewDeliveryZone";
import EditDeliveryZone from "./EditDeliveryZone";

const Container = () => (
  <Switch>
    <Route path="/settings/delivery-zones/new" component={NewDeliveryZone} />
    <Route path="/settings/delivery-zones/:id" component={EditDeliveryZone} />
    <Route path="/settings/delivery-zones" component={DeliveryZones} />
  </Switch>
);

export default Container;
