import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import Page from "components/Page";
import NewIngredientPane from "components/NewIngredientPane";

import ModifierGroupForm from "./components/ModifierGroupForm";
import { SELECT_OPTIONS_QUERY, CREATE_MODIFIER_MUTATION } from "./modifiersQueries";
import { SELECT_OPTIONS_QUERY as TECH_CARD_OPTIONS_QUERY } from "../techCards/techCardQueries";
import { SELECT_OPTIONS_QUERY as SET_OPTIONS_QUERY } from "../sets/setQueries";

const NewModifiers = ({ history }) => {
  const { t } = useTranslation();
  const [isCreatingIngridient, setCreatingIngridient] = useState(false);

  const { loading, data } = useQuery(SELECT_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [createModifier] = useMutation(CREATE_MODIFIER_MUTATION, {
    refetchQueries: [{ query: TECH_CARD_OPTIONS_QUERY }, { query: SET_OPTIONS_QUERY }],
  });

  if (loading) return <Spin />;

  const handleSubmit = ({
    name, description, modifier_type, is_required, min_selection, max_selection, modifier_items,
  }) => createModifier({
    variables: {
      data: {
        name,
        description,
        ...(modifier_type === "only_one" ? {
          min_selection: is_required ? 1 : 0,
          max_selection: 1,
        } : {
          min_selection,
          max_selection,
        }),
        modifier_items: {
          // eslint-disable-next-line no-shadow
          data: modifier_items.map(({ name, price, cost, quantity, content }) => ({
            ...content, name, cost, price, quantity, default_value: null,
          })),
        },
      },
    },
  })
    .then(() => history.goBack())
    .catch(notification.error);

  return (
    <Page showBackIcon title={t("menu.Modifiers.AddPage.Title")}>
      <Card>
        <ModifierGroupForm
          t={t}
          onSubmit={handleSubmit}
          optionsData={data}
          onIngridientCreate={() => setCreatingIngridient(true)}
        />
      </Card>
      <NewIngredientPane
        isOpen={isCreatingIngridient}
        onClose={() => setCreatingIngridient(false)}
      />
    </Page>
  );
};

NewModifiers.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewModifiers;
