import gql from "graphql-tag";

export const PAYMENT_METHODS_QUERY = gql`
  query GetPaymentMethods @hasura {
    payment_methods  {
      id
      name
      type
      finance_account {
        name
      }
      processing_fee_percent
      processing_fee_amount
      show_in_pos
      show_in_callcenter
    }
  }
`;
export const NEW_PAYMENT_METHOD_QUERY = gql`
  query GetNewPaymentMethod @hasura {
    finance_accounts {
      id
      name
    }
  }
`;

export const EDIT_PAYMENT_METHOD_QUERY = gql`
  query GetPaymentMethod($id: Int!) @hasura {
    payment_methods(where: { id: { _eq: $id } })  {
      id
      name
      type
      account_id
      processing_fee_percent
      processing_fee_amount
      show_in_pos
      show_in_callcenter
    }
    finance_accounts {
      id
      name
    }
  }
`;

export const CREATE_PAYMENT_METHOD_MUTATION = gql`
  mutation CreateIngredient($data: payment_methods_insert_input!) @hasura {
    insert_payment_methods(objects: [$data]) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_PAYMENT_METHOD_MUTATION = gql`
  mutation UpdateIngredient($id: Int!, $data: payment_methods_set_input!) @hasura {
    update_payment_methods(where: {id: {_eq: $id}}, _set: $data) {
      returning {
        id
      }
    }
  }
`;
