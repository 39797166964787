import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button, Alert } from "antd";

import { renderInput } from "components/FormItem";
import { required, email, phoneNumber } from "utils/formValidations";

const StyledForm = styled(Form)`
  padding: 8px 40px 20px 40px;
`;

const AccountForm = ({ handleSubmit, t, submitting, error }) => (
  <StyledForm onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}

    <Row>
      <Col xs={24} md={20} lg={16} xl={12}>
        <Field
          name="name"
          label={t("account.AccountForm.FirstName")}
          placeholder={t("FirstName")}
          component={renderInput}
        />
        <Field
          name="email"
          label={t("account.AccountForm.Email")}
          placeholder="Email"
          component={renderInput}
          validate={[required, email]}
        />
        <Field
          name="phone_number"
          label={t("account.AccountForm.PhoneNumber")}
          placeholder={t("account.AccountForm.PlaceholderNumber")}
          component={renderInput}
          validate={[required, phoneNumber]}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={{ offset: 5 }} xs={24}>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          {t("Save")}
        </Button>
      </Col>
    </Row>
  </StyledForm>
);

AccountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default reduxForm({
  form: "accountForm",
})(AccountForm);
