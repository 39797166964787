import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Table, Input, Select, notification, Button, Space,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  useQueryParam, withDefault,
  NumberParam, StringParam, NumericArrayParam, ArrayParam,
} from "use-query-params";

import Page from "components/Page";

import { formatPrice } from "utils/helpers";
import { sortByFnValue, sortByValue, sortByAlphabet } from "utils/sorts";
import { UNIT_LABELS_BY_VALUE } from "constants/index";

import { LOCATION_PRODUCTS_QUERY } from "./productQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

import ProductRemains from "./components/ProductRemains";

import { categoryForProduct, PRODUCT_CATEGORY_TYPE_LABELS } from "../shared/utils";

const Products = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 30));
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [expandedProductIds, setExpandedProductIds] = useState([]);
  const [selectedLocations, setSelectedLocations] = useQueryParam("locations", NumericArrayParam);
  const [selectedCategories, setSelectedCategories] = useQueryParam("categories", ArrayParam);

  const { data, loading } = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const locationProductsQuery = useQuery(LOCATION_PRODUCTS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  const { categoryGroups, products } = useMemo(() => {
    if (!data || !locationProductsQuery.data) return { categoryGroups: [], products: [] };
    // eslint-disable-next-line no-shadow
    const { categories, products } = data.products.reduce((acc, product) => {
      const category = categoryForProduct(product, data);
      const categoryValue = `${category.type}:${category.id}`;

      const locationProducts = locationProductsQuery.data.location_products
        .filter((lp) => lp.product_id === product.id);
      const item = { ...product, category, categoryValue, locationProducts };

      const categoryAdded = acc.categories.some((c) => c.value === categoryValue);

      return {
        products: acc.products.concat(item),
        categories: categoryAdded ? acc.categories : acc.categories.concat({
          ...category, value: categoryValue,
        }),
      };
    }, { categories: [], products: [] });

    // eslint-disable-next-line no-shadow
    const categoryGroups = Object.keys(PRODUCT_CATEGORY_TYPE_LABELS).map((type) => ({
      label: PRODUCT_CATEGORY_TYPE_LABELS[type],
      value: type,
      children: categories.filter((c) => c.type === type),
    })).filter((c) => c.children.length > 0);

    return { categoryGroups, products };
  }, [data, locationProductsQuery.data]);

  const filteredProducts = useMemo(() => products
    .filter(({ categoryValue, name }) => {
      if (selectedCategories && !selectedCategories.includes(categoryValue)) {
        return false;
      }
      if (search && search.length > 0 && !name.toLowerCase().includes(search)) {
        return false;
      }
      return true;
    })
    .map((product) => ({
      ...product,
      remains: product.locationProducts
        .filter(({ location_id: id }) => selectedLocations?.includes(id) ?? true)
        .map((lp) => lp.quantity).reduce((acc, curr) => acc + curr, 0),
    })), [products, search, selectedLocations, selectedCategories]);

  const filteredLocations = data?.locations
    .filter(({ id }) => !selectedLocations || selectedLocations.includes(id));

  return (
    <Page>
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
        />
        <Select
          placeholder={t("warehouse.Products.ChooseWarehouse")}
          mode="multiple"
          style={{ width: 200 }}
          allowClear
          optionFilterProp="children"
          onChange={setSelectedLocations}
        >
          {data?.locations.map((location) => (
            <Select.Option key={location.id} value={location.id}>
              {location.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          placeholder={t("warehouse.Products.ChooseCategory")}
          mode="multiple"
          style={{ width: 250 }}
          allowClear
          optionFilterProp="children"
          onChange={setSelectedCategories}
        >
          {categoryGroups.map((categoryGroup) => (
            <Select.OptGroup label={categoryGroup.label} key={categoryGroup.value}>
              {categoryGroup.children.map((category) => (
                <Select.Option key={category.value} value={category.value}>
                  {category.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Space>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        loading={loading}
        dataSource={filteredProducts}
        expandable={{
          expandedRowRender: (record) => (
            <ProductRemains product={record} locations={filteredLocations} />
          ),
          expandedRowKeys: expandedProductIds,
          expandIconColumnIndex: -1,
        }}
      >
        <Table.Column
          title={t("warehouse.Products.Columns.Name")}
          dataIndex="name"
          width="20%"
          sorter={sortByAlphabet("name")}
        />
        <Table.Column
          title={t("warehouse.Products.Columns.Type")}
          dataIndex={["category", "type"]}
          render={(type) => PRODUCT_CATEGORY_TYPE_LABELS[type]}
        />
        <Table.Column
          title={t("warehouse.Products.Columns.Category")}
          dataIndex={["category", "name"]}
        />
        <Table.Column
          title={t("warehouse.Products.Columns.Remains")}
          dataIndex="remains"
          sorter={sortByValue("remains")}
          render={(remains, { unit }) => `${remains.toFixed(3)} ${UNIT_LABELS_BY_VALUE[unit]}`}
        />
        <Table.Column
          title={t("warehouse.Products.Columns.Cost")}
          dataIndex="cost"
          sorter={sortByValue("cost")}
          render={formatPrice}
        />
        <Table.Column
          title={t("warehouse.Products.Columns.Amount")}
          dataIndex="value"
          sorter={sortByFnValue((p) => p.cost * p.remains)}
          render={(_, product) => formatPrice(product.cost * product.remains)}
        />
        <Table.Column
          dataIndex="id"
          render={(id) => (
            <Button
              type="link"
              size="small"
              onClick={() => {
                if (expandedProductIds.includes(id)) {
                  return setExpandedProductIds(expandedProductIds.filter((v) => v !== id));
                }
                return setExpandedProductIds(expandedProductIds.concat(id));
              }}
            >
              {t("warehouse.Products.Actions.Remains")}
            </Button>
          )}
        />
      </Table>
    </Page>
  );
};

export default Products;
