import gql from "graphql-tag";

export const MENU_CATEGORIES_QUERY = gql`
  query GetMenuCategories @hasura {
    menu_categories(order_by: {position: asc_nulls_last}) {
      id
      name
      icon
      position
    }
  }
`;

export const EDIT_CATEGORY_QUERY = gql`
  query EditMenuCategories($id: Int!) @hasura {
    menu_categories_by_pk(id: $id) {
      id
      name
      icon
      position
    }
  }
`;

export const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateMenuCategory($object: menu_categories_insert_input!) @hasura {
    insert_menu_categories_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateMenuCategory($id: Int!, $object: menu_categories_set_input!) @hasura {
    update_menu_categories(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        id
        name
        icon
        position
      }
    }
  }
`;

export const UPDATE_CATEGORY_POSITION = gql`
  mutation UpdateCategoryPosition($data: [MenuCategoryPositionInput!]) {
    updateMenuCategoryPositions(data: $data) {
      id
      name
      position
    }
  }
`;
