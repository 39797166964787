import gql from "graphql-tag";

export const SALES_QUERY = gql`
  query getStats($filters: FiltersInput!) {
    salesStatsByDay(filters: $filters, type: delivery) {
      day
      revenue
      orders_count
    }
    salesStatsByWeekday(filters: $filters, type: delivery) {
      weekday
      revenue
      orders_count
    }
    salesStatsByHour(filters: $filters, type: delivery) {
      hour
      revenue
      orders_count
    }
    salesStatsByDeliveryZone(filters: $filters)  {
      id
      name
      revenue
      orders_count
    }
  }
`;
