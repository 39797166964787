import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import {
  Table, Button, Input, Select, notification, Checkbox, Space, Typography, Drawer, Row,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  useQueryParam, NumberParam, StringParam, withDefault, BooleanParam,
} from "use-query-params";

import colors from "theme/colors";
import Page from "components/Page";
import { ColorDot } from "components/ColorInput";

import { formatPrice } from "utils/helpers";
import { sortByValue, sortByAlphabet } from "utils/sorts";

import ProductDetailsPanel from "./components/ProductDetailsPanel";

import { PRODUCTS_QUERY } from "./productQueries";

const ProductsTable = styled(Table)`
  .ant-table-row-level-1 {
    background: ${colors.secondaryBackground};
  }
`;

const ChildTitle = styled.div`
  margin-left: 25px;
`;

const Products = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 20));
  const [showAll, setShowAll] = useQueryParam("show_all", withDefault(BooleanParam, false));
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [categoryId, setCategoryId] = useQueryParam("category_id", NumberParam);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { data, loading } = useQuery(PRODUCTS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  const menuItemGroups = useMemo(() => {
    if (!data) return [];
    return data.menu_item_groups
      .map(({ menu_items, id: migId, ...rest }) => {
        const menuItems = menu_items.filter(({ archived_at }) => showAll || !archived_at);
        const children = menuItems.length > 1 && menuItems
          .map(({ product, id, price, ...restMI }) => ({
            id: `item-${id}`,
            migId,
            price,
            product,
            cost: product.cost,
            unit: product.unit,
            markup: Math.round((price / product.cost - 1) * 100),
            ...restMI,
          }));
        const menuItem = _.minBy(menuItems, "price");
        return ({
          children,
          id: migId,
          migId,
          product: menuItem?.product,
          hasMenuItems: menuItems.length > 0,
          cost: menuItem?.product.cost,
          unit: menuItem?.product.unit,
          price: menuItem?.price,
          markup: Math.round((menuItem?.price / menuItem?.product.cost - 1) * 100),
          ...rest,
        });
      })
      .filter(({ archived_at, hasMenuItems }) => hasMenuItems && (showAll || !archived_at));
  }, [data, showAll]);

  const products = useMemo(() => menuItemGroups.filter(({ name, category_id }) => {
    if (search && search.length > 0 && !name.toLowerCase().includes(search)) {
      return false;
    }
    return !categoryId || categoryId === category_id;
  }), [menuItemGroups, categoryId, search]);

  return (
    <Page
      title={t("menu.Products.Index.Title")}
      subTitle={data?.menu_item_groups.length.toString()}
      headerContent={t("menu.Products.Index.Description")}
      extra={(
        <Space>
          <Checkbox checked={showAll} onChange={() => setShowAll(!showAll)}>
            {t("ShowArchived")}
          </Checkbox>
          <Link to="/menu/products/new">
            <Button type="primary">{t("Add")}</Button>
          </Link>
        </Space>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          style={{ width: 200 }}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
        />
        <Select
          placeholder={t("Category")}
          style={{ width: 200 }}
          value={categoryId}
          onChange={(value) => setCategoryId(value)}
        >
          <Select.Option value={null}>{t("AllCategories")}</Select.Option>
          {data && data.menu_categories.map((category) => (
            <Select.Option value={category.id} key={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <ProductsTable
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        loading={loading}
        dataSource={products}
        expandedRowKeys={menuItemGroups.map((mig) => mig.id)}
        expandIcon={() => null}
      >
        <Table.Column
          title={t("menu.Products.Columns.Name")}
          dataIndex="name"
          width={240}
          render={(name, record) => (
            record.category
              ? (
                <Link to={`/menu/products/${record.migId}`}>
                  <Row align="middle">
                    {name}
                    &nbsp;
                    <ColorDot color={record.color} size="small" style={{ marginTop: 1 }} />
                    {record.archived_at
                      && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
                  </Row>
                </Link>
              )
              : (
                <ChildTitle>
                  {name}
                  {record.archived_at
                    && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
                </ChildTitle>
              )
          )}
          sorter={sortByAlphabet("name")}
        />
        <Table.Column
          title={t("menu.Products.Columns.Category")}
          dataIndex={["category", "name"]}
          width={180}
        />
        <Table.Column
          title={t("menu.Products.Columns.CostPrice")}
          dataIndex="cost"
          width={120}
          render={(cost, record) => (!record.children ? formatPrice(cost) : "-")}
          sorter={sortByValue("cost")}
        />
        <Table.Column
          title={t("menu.Products.Columns.Price")}
          dataIndex="price"
          width={120}
          render={(price, record) => (!record.children ? formatPrice(price) : "-")}
          sorter={sortByValue("price")}
        />
        <Table.Column
          title={t("menu.Products.Columns.Markup")}
          dataIndex="markup"
          width={120}
          render={(markup, record) => (_.isInteger(markup) && !record.children ? `${markup}%` : "-")}
          sorter={sortByValue("markup")}
        />
        <Table.Column
          title={t("menu.Products.Columns.IsWeighted")}
          dataIndex="unit"
          width={100}
          render={(unit, record) => (!record.children ? t(unit === "kg" ? "Yes" : "No") : "-")}
        />
        <Table.Column
          title={t("menu.Products.Columns.Description")}
          dataIndex="description"
          width={190}
          render={(description, record) => (record.category ? (description || "-") : "")}
        />
        <Table.Column
          dataIndex="id"
          width={80}
          render={(_id, record) => {
            if (record.children) return null;
            return (
              <Button type="link" size="small" onClick={() => setSelectedProduct(record)}>
                {t("Details")}
              </Button>
            );
          }}
        />
      </ProductsTable>
      <Drawer
        title={(
          <>
            {selectedProduct?.product.name}
            &nbsp;
            <Link to={`/menu/products/${selectedProduct?.migId}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedProduct}
        onClose={() => setSelectedProduct(null)}
        placement="bottom"
        height="60%"
      >
        {selectedProduct && (
          <ProductDetailsPanel t={t} productId={selectedProduct.product.id} />
        )}
      </Drawer>
    </Page>
  );
};

Products.propTypes = {};

export default Products;
