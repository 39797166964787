import React from "react";
import {
  ProfileOutlined, BarChartOutlined, DollarOutlined, FileOutlined,
  BankOutlined, PieChartOutlined, KeyOutlined, SettingOutlined,
} from "@ant-design/icons";

const isDev = process.env.NODE_ENV === "development";

export const MENU = [
  {
    key: "orders",
    icon: <ProfileOutlined />,
    access_key: "access_orders",
    access_value: true,
    subMenu: [
      { key: "orders", to: "/orders" },
    ],
  },
  {
    key: "statistics",
    icon: <BarChartOutlined />,
    access_key: "access_statistics",
    access_value: true,
    subMenu: isDev
      ? [
        { key: "sales", to: "/statistics/sales" },
        { key: "locations", to: "/statistics/locations" },
        { key: "products", to: "/statistics/products" },
        { key: "abc-analysis", to: "/statistics/abc-analysis" },
        { key: "receipts", to: "/statistics/receipts" },
        { key: "incoming-orders", to: "/statistics/incoming-orders" },
        { key: "payments", to: "/statistics/payments" },
        { key: "taxes", to: "/statistics/taxes" },
        { key: "delivery", to: "/statistics/delivery" },
        { key: "employees", to: "/statistics/employees" },
      ]
      : [
        { key: "sales", to: "/statistics/sales" },
        { key: "products", to: "/statistics/products" },
        { key: "abc-analysis", to: "/statistics/abc-analysis" },
        { key: "payments", to: "/statistics/payments" },
        { key: "delivery", to: "/statistics/delivery" },
        { key: "employees", to: "/statistics/employees" },
      ],
  },
  {
    key: "finance",
    icon: <DollarOutlined />,
    access_key: "access_finance",
    access_value: true,
    subMenu: isDev ? [
      { key: "transactions", to: "/finance/transactions" },
      { key: "accounts", to: "/finance/accounts" },
      { key: "cashier-shifts", to: "/finance/cashier-shifts" },
      { key: "salary", to: "/finance/salary" },
      { key: "categories", to: "/finance/categories" },
    ] : [
      { key: "cashier-shifts", to: "/finance/cashier-shifts" },
      { key: "transactions", to: "/finance/transactions" },
      { key: "accounts", to: "/finance/accounts" },
      { key: "categories", to: "/finance/categories" },
    ],
  },
  {
    key: "menu",
    icon: <FileOutlined />,
    access_key: "access_menu",
    access_value: true,
    subMenu: [
      { key: "products", to: "/menu/products" },
      { key: "tech-cards", to: "/menu/tech-cards" },
      { key: "sets", to: "/menu/sets" },
      { key: "categories", to: "/menu/categories" },
      { key: "modifiers", to: "/menu/modifiers" },
      { key: "ingredients", to: "/menu/ingredients" },
      { key: "ingredient-categories", to: "/menu/ingredient-categories" },
      { key: "prepmeals", to: "/menu/prepmeals" },
      { key: "workshops", to: "/menu/workshops" },
    ],
  },
  {
    key: "warehouse",
    icon: <BankOutlined />,
    access_key: "access_warehouse",
    access_value: true,
    subMenu: [
      { key: "products", to: "/warehouse/products" },
      { key: "suppliers", to: "/warehouse/suppliers" },
      { key: "deliveries", to: "/warehouse/deliveries" },
      { key: "productions", to: "/warehouse/productions" },
      { key: "movings", to: "/warehouse/movings" },
      { key: "write-offs", to: "/warehouse/write-offs" },
      { key: "inventories", to: "/warehouse/inventories" },
    ],
  },
  {
    key: "marketing",
    icon: <PieChartOutlined />,
    access_key: "name",
    access_value: isDev ? "salempos_generated_owner" : "not_ready",
    subMenu: [
      { key: "promotions", to: "/marketing/promotions" },
    ],
  },
  {
    key: "access",
    icon: <KeyOutlined />,
    access_key: "access_users",
    access_value: true,
    subMenu: [
      { key: "employees", to: "/access/employees" },
      { key: "access-roles", to: "/access/access-roles" },
      { key: "terminals", to: "/access/terminals" },
      { key: "api-keys", to: "/access/api-keys" },
    ],
  },
  {
    key: "setting",
    icon: <SettingOutlined />,
    access_key: "name",
    access_value: "salempos_generated_owner",
    subMenu: [
      { key: "general", to: "/settings/general" },
      { key: "locations", to: "/settings/locations" },
      { key: "check", to: "/settings/check" },
      { key: "payment-methods", to: "/settings/payment-methods" },
      { key: "delivery-zones", to: "/settings/delivery-zones" },
    ],
  },
];
