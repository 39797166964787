import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Checkbox } from "antd";

const formItem = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  labelAlign: "left",
  colon: false,
  style: {
    marginTop: 8,
    marginBottom: 8,
  },
};

const formItemCheckboxs = {
  wrapperCol: {
    sm: { span: 24, offset: 0 },
  },
  style: {
    marginTop: 0,
    marginBottom: 0,
  },
};

const CheckboxGroupField = ({
  input: { onChange, value },
  meta: { touched, error },
  label,
  checkboxs,
  formItemProps = formItem,
}) => {
  const arrValue = value === "" ? [] : value;
  return (
    <Row>
      <Col>
        <Form.Item
          label={label}
          {...formItemProps}
          validateStatus={touched && error ? "error" : "success"}
          help={(touched && error) || ""}
        >
          {checkboxs.map((checkbox, index) => (
            <Form.Item {...formItemCheckboxs}>
              <Checkbox
                key={index}
                value={checkbox.value}
                checked={arrValue.includes(checkbox.value)}
                onChange={() => {
                  if (arrValue.includes(checkbox.value)) {
                    onChange(arrValue.filter((id) => id !== checkbox.value));
                  } else {
                    onChange(arrValue.concat(checkbox.value));
                  }
                }}
              >
                {checkbox.label}
              </Checkbox>
            </Form.Item>
          ))}
        </Form.Item>
      </Col>
    </Row>
  );
};

CheckboxGroupField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  checkboxs: PropTypes.array.isRequired,
  formItemProps: PropTypes.object,
};

export default CheckboxGroupField;
