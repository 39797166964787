import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form as OldForm } from "@ant-design/compatible";
import {
  Row, Col, Button, Divider, Alert, Form,
} from "antd";

import { renderInput, renderCheckbox } from "components/FormItem";
import colors from "theme/colors";
import { required } from "utils/formValidations";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const StyledForm = styled(OldForm)`
  padding: 20px 40px;
`;

const StyledDivider = styled(Divider)`
  background-color: ${colors.lightGrey};
`;

const CategoriesForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  error,
}) => (
  <StyledForm onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}
    <Row>
      <Col span={16}>
        <Field
          name="name"
          label={t("finance.Category.Form.Name.Label")}
          component={renderInput}
          formItemProps={formItemLayout}
          validate={required}
        />
        <Form.Item label={t("finance.Category.Form.Allow.Label")} {...formItemLayout}>
          <Field
            checkboxLabel={t("finance.Category.Form.Allow.AllowIncome")}
            name="allow_income"
            component={renderCheckbox}
          />
          <Field
            checkboxLabel={t("finance.Category.Form.Allow.AllowExpenses")}
            name="allow_expenses"
            component={renderCheckbox}
          />
        </Form.Item>
      </Col>
    </Row>
    <StyledDivider />
    <Row>
      <Col offset={3} span={10}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={pristine || submitting}
        >
          {t("Save")}
        </Button>
      </Col>
    </Row>
  </StyledForm>
);

CategoriesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default reduxForm({
  form: "financeCategoryForm",
})(CategoriesForm);
