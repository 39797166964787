import React, { useState, useMemo } from "react";
import { Card, Radio } from "antd";
import { HorizontalBar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { round } from "lodash";

import { BAR_CHART, CHART_OPTIONS, ORDER_TYPE } from "constants/index";

const barOptions = {
  ...CHART_OPTIONS(),
  scales: {
    yAxes: [
      {
        stacked: true,
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          min: 0,
        },
      },
    ],
  },
};

const orderTypes = Object.values(ORDER_TYPE);

const valueByType = (values, type) =>
  round(type === "averageCheck" ? values.revenue / values.count : values[type], 2);

const OrderTypeBar = ({ t, data }) => {
  const [type, setType] = useState("revenue");

  const orderTypeStats = useMemo(
    () => ({
      labels: orderTypes.map((orderType) => t(`const:order_type.${orderType}`)),
      data: orderTypes.map((orderType) => {
        const foundOrderType = data.orderTypeStats.find(({ order_type }) => (
          order_type === orderType
        ));
        return foundOrderType ? valueByType(foundOrderType, type) : 0;
      }),
    }),
    [data, type],
  );

  return (
    <Card
      title={t("statistics.Sales.HorizontalBarCharts.OrderTypeBar.Title")}
      extra={(
        <Radio.Group
          size="small"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <Radio.Button value="revenue">
            {t("statistics.Sales.HorizontalBarCharts.revenue")}
          </Radio.Button>
          <Radio.Button value="count">
            {t("statistics.Sales.HorizontalBarCharts.orders")}
          </Radio.Button>
          <Radio.Button value="averageCheck">
            {t("statistics.Sales.HorizontalBarCharts.averageCheck")}
          </Radio.Button>
        </Radio.Group>
      )}
    >
      <HorizontalBar
        data={BAR_CHART(orderTypeStats)}
        options={barOptions}
        height={22 * orderTypes.length}
      />
    </Card>
  );
};

OrderTypeBar.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default OrderTypeBar;
