import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Checkbox,
  Radio,
  TimePicker,
  Cascader,
  Typography,
} from "antd";
import PropTypes from "prop-types";
import moment from "moment";

import UploadImage from "components/UploadImage";

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 13 },
  },
};

export const buttonLayout = {
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 14, offset: 10 },
  },
};

export const errorLayout = {
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 14, offset: 2 },
  },
  style: {
    marginTop: 24,
  },
};

const getValidationState = ({ error, warning, touched }) => {
  if (touched && error) return "error";
  if (touched && warning) return "warning";
  return undefined;
};

export const render = (
  {
    input, label, meta, formItemProps, extra, ...props
  },
  renderInput,
) => {
  const id = `${meta.form}_${input.name}`;
  const inputProps = { ...input, id, ...props };
  const validateStatus = getValidationState(meta);
  return (
    <Form.Item
      {...formItemLayout}
      {...formItemProps}
      extra={extra}
      label={label || ""}
      htmlFor={id}
      validateStatus={validateStatus}
      help={meta.touched && meta.error}
    >
      {renderInput(inputProps)}
    </Form.Item>
  );
};

render.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  formItemProps: PropTypes.object.isRequired,
  extra: PropTypes.string,
  meta: PropTypes.objectOf(
    PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.string,
      warning: PropTypes.string,
    }),
  ),
};

export const renderInput = (props) =>
  render(props, (inputProps) => <Input {...inputProps} />);

export const renderInputNumber = (props) =>
  render(props, ({ onBlur, ...inputProps }) => <InputNumber {...inputProps} />);

export const renderTextarea = (props) =>
  render(props, (textareaProps) => <Input.TextArea {...textareaProps} allowClear />);

export const renderSelect = (props) =>
  render(props, (selectProps) => {
    const { options = [], value, onBlur, ...rest } = selectProps;
    const fixedValue = value === "" ? undefined : value;
    return (
      <Select
        {...rest}
        value={fixedValue}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {options.map(({ label, ...opt }) => (
          <Select.Option key={opt.value} {...opt}>
            {label}
          </Select.Option>
        ))}
      </Select>
    );
  });

export const renderSelectGroup = (props) =>
  render(props, (selectProps) => {
    const { options, value, onBlur, ...rest } = selectProps;
    const fixedValue = value === "" ? undefined : value;
    return (
      <Select {...rest} value={fixedValue}>
        {options.map((category) => (
          <Select.OptGroup label={category.label} key={category.value}>
            {category.children.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
    );
  });

export const renderCheckbox = (props) =>
  render(props, (checkboxProps) => {
    const { checkboxLabel, value, ...rest } = checkboxProps;
    return (
      <Checkbox {...rest} checked={value || false}>
        {checkboxLabel}
      </Checkbox>
    );
  });

export const renderDatePicker = (props) =>
  render(props, ({ onBlur, value, ...datePickerProps }) => (
    <DatePicker
      {...datePickerProps}
      value={typeof value === "string" && value.length > 0 ? moment(value) : value}
    />
  ));

export const renderTimePicker = (props) =>
  render(props, ({ onBlur, ...pickerProps }) => (
    <TimePicker {...pickerProps} />
  ));

export const renderUpload = (props) =>
  render(props, (uploadProps) => <UploadImage {...uploadProps} />);

export const renderLabel = (props) => render(props, (labelProps) =>
  <Typography.Text>{labelProps.text || labelProps.value}</Typography.Text>);

export const renderRadioGroup = (props) =>
  render(props, ({ items, ...rest }) => (
    <Radio.Group {...rest}>
      {items.map((item) => (
        <Radio key={item.value} value={item.value}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  ));

export const renderCascader = (props) =>
  render(props, ({
    options, onBlur, showSearch, ...selectProps
  }) => (
    <Cascader
      options={options}
      expandTrigger="hover"
      allowClear={false}
      displayRender={(labels) => labels[labels.length - 1]}
      showSearch={showSearch && {
        filter: (inputValue, path) => path
          .some((option) => option.label.toLowerCase().includes(inputValue.toLowerCase())),
        matchInputWidth: false,
      }}
      onPopupVisibleChange={(visible) => { if (!visible) onBlur(); }}
      {...selectProps}
    />
  ));

renderRadioGroup.propTypes = {
  input: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};
