import React, { useMemo } from "react";
import { Spin, notification, Row, Col, Card } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Check from "@salempos/check";

import { PAYMENT_METHOD_NAME } from "constants/index";
import { singletonCurrency } from "utils/helpers";
import Page from "components/Page";

import { templateOrder, paymentMethods } from "./constants";
import { CHECK_SETTINGS_QUERY, UPDATE_CHECK_SETTINGS } from "./checkQueries";
import CheckForm from "./components/CheckForm";

const fields = [
  "name",
  "field_top",
  "field_bottom",
  "font_size",
  "number_display_type",
  "print_order_notes",
  "print_delivery_info",
];

const CheckPage = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(CHECK_SETTINGS_QUERY, {
    onError: notification.error,
  });
  const [updateCheckSetting] = useMutation(UPDATE_CHECK_SETTINGS);
  const checkSettings = useSelector((state) => formValueSelector("checkForm")(state, ...fields));

  const checkLang = useMemo(() => ({
    ...t("const:check", { returnObjects: true }),
    currency: singletonCurrency.getCurrency(),
    paymentMethods: PAYMENT_METHOD_NAME,
    orderTypes: t("const:order_type", { returnObjects: true }),
  }), [singletonCurrency.getCurrency()]);

  if (loading) {
    return <Spin />;
  }

  const handleSubmit = ({ __typename, id, ...values }) =>
    updateCheckSetting({ variables: { id, data: values } })
      .then(() => notification.success({ message: "Updated!" }))
      .catch(notification.error);

  return (
    <Page>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={15} xl={14}>
          <Card>
            <CheckForm t={t} onSubmit={handleSubmit} initialValues={data.check_settings[0]} />
          </Card>
        </Col>
        <Col>
          <Card>
            <Check
              order={templateOrder}
              lang={checkLang}
              checkSettings={checkSettings}
              paymentMethods={paymentMethods}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default CheckPage;
