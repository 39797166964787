import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Layout, Spin } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { QueryParamProvider } from "use-query-params";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

import ConnectData from "connect.container";

import Header from "components/Header";
import { Sidebar } from "components/Sidebar";
import { singletonCurrency } from "utils/helpers";

import Statistics from "./pages/statistics";
import Orders from "./pages/orders";
import Finance from "./pages/finance";
import Menu from "./pages/menu";
import Warehouse from "./pages/warehouse";
import Marketing from "./pages/marketing";
import Access from "./pages/access";
import Settings from "./pages/settings";
import { Account } from "./pages/account";
import Onboarding from "./pages/onboarding";

import AuthRouter from "./pages/auth";

import { PROFILE_QUERY } from "./commonQueries";

const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const ContentLayout = styled(Layout)`
  overflow-y: auto;
  height: 100vh;

  .page-layout > .ant-spin {
    margin: 72px auto 0;
    width: 100%;
  }
`;

const getHomePath = (accessRole) => {
  if (accessRole.access_orders) return "/statistics/sales";
  if (accessRole.access_warehouse) return "/warehouse";
  return "/account";
};

const setupFavicon = (merchant) => {
  if (merchant.favicon_url) {
    const link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = merchant.favicon_url;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
};

const AppRouter = ({ refetch, onLogout }) => {
  const { settings, merchant, currentUser, locations } = ConnectData.useContainer();
  const [collapsed, toggle] = useState(false);

  moment.tz.setDefault(settings.timezone);
  setupFavicon(merchant);
  singletonCurrency.setCurrency(settings.currency_code);

  const showOnboarding = locations.length === 0;
  return (
    <Layout>
      {showOnboarding || <Sidebar collapsed={collapsed} />}
      <ContentLayout>
        <Header collapsed={collapsed} toggle={toggle} onLogout={onLogout} />
        <div className="page-layout">
          {showOnboarding ? (
            <Switch>
              <Route
                path="/onboarding"
                render={(props) => (
                  <Onboarding {...props} refetch={refetch} />
                )}
              />
              <Redirect to="/onboarding" />
            </Switch>
          ) : (
            <Switch>
              {currentUser.access_role.access_statistics && (
                <Route path="/statistics" component={Statistics} />
              )}

              {currentUser.access_role.access_orders && (
                <Route path="/orders" component={Orders} />
              )}

              {currentUser.access_role.access_finance && (
                <Route path="/finance" component={Finance} />
              )}

              {currentUser.access_role.access_menu && (
                <Route path="/menu" component={Menu} />
              )}

              {currentUser.access_role.access_warehouse && (
                <Route path="/warehouse" component={Warehouse} />
              )}

              {currentUser.access_role.name === "salempos_generated_owner" && (
                <Route path="/marketing" component={Marketing} />
              )}

              {currentUser.access_role.access_users && (
                <Route path="/access" component={Access} />
              )}

              {currentUser.access_role.name === "salempos_generated_owner" && (
                <Route path="/settings" component={Settings} />
              )}

              <Route exact path="/account" component={Account} />

              <Redirect to={getHomePath(currentUser.access_role)} />
            </Switch>
          )}
        </div>
      </ContentLayout>
    </Layout>
  );
};

AppRouter.propTypes = {
  onLogout: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

const App = () => {
  const { client, data, loading, error, refetch } = useQuery(PROFILE_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const handleLogout = () => axios.delete("/auth/admin/logout")
    .then(() => client.resetStore());

  if (loading) {
    return <Loader />;
  }

  Sentry.setUser(data ? {
    id: data.current_user_view[0].id,
    username: data.current_user_view[0].email,
    name: data.current_user_view[0].name,
    merchantId: data.merchants[0].id,
    merchant: data.merchants[0].company_name,
    plan: data.merchants[0].plan,
    accessRole: data.current_user_view[0].access_role.name,
    accessRoleId: data.current_user_view[0].access_role.id,
  } : null);

  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        {data && !error ? (
          <ConnectData.Provider initialState={data}>
            <AppRouter refetch={refetch} onLogout={handleLogout} />
          </ConnectData.Provider>
        ) : (
          <AuthRouter refetch={refetch} />
        )}
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default App;
