import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Row, Col, Button, Card, Space, Typography,
} from "antd";
import { Field, formValueSelector } from "redux-form";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";

import { renderInput, renderCheckbox } from "components/FormItem";
import { required } from "utils/formValidations";

import PriceFields from "../../shared/components/PriceFields";

const formItemArchived = {
  wrapperCol: {
    sm: { span: 24 },
  },
  style: {
    marginTop: 0,
    marginBottom: 0,
  },
};

const nameLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 19 },
  },
};

const MenuItemFields = ({ t, changeField, fields }) => {
  const selector = formValueSelector("productForm");
  const modifierGroup = useSelector((state) => selector(state, "menu_items"));

  return (
    <Space direction="vertical">
      {fields.map((modifier, index) => (
        <Card key={modifier} bodyStyle={{ padding: "10px 20px" }}>
          {fields.length > 1 && (
            <>
              <Row
                align="middle"
                justify={modifierGroup[index].archived_at ? "space-between" : "end"}
              >
                {modifierGroup[index].id ? (
                  <>
                    {modifierGroup[index].archived_at && (
                      <Col>
                        <Typography.Text>
                          {t("menu.Products.Form.Name")}: {modifierGroup[index].name}
                        </Typography.Text>
                      </Col>
                    )}
                    <Col>
                      <Field
                        name={`${modifier}.archived_at`}
                        checkboxLabel={t("Archiving")}
                        component={renderCheckbox}
                        formItemProps={formItemArchived}
                        normalize={(value) => (value ? moment().format() : null)}
                        format={(value) => !!value}
                      />
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button danger type="link" onClick={() => fields.remove(index)}>
                      {t("menu.Products.Form.Modifications.Delete")}
                    </Button>
                  </Col>
                )}
              </Row>
              {!modifierGroup[index].archived_at && (
                <Field
                  label={t("menu.Products.Form.Name")}
                  name={`${modifier}.name`}
                  component={renderInput}
                  validate={required}
                  formItemProps={nameLayout}
                />
              )}
            </>
          )}
          {!modifierGroup[index].archived_at && (
            <>
              <PriceFields
                t={t}
                prefix={modifier}
                changeField={changeField}
                modifier={modifierGroup[index]}
              />
              <Typography.Text type="secondary">
                {t("menu.Products.Form.Cost.Help")}
              </Typography.Text>
            </>
          )}
        </Card>
      ))}
      <Button type="dashed" onClick={() => fields.push({ cost: 0 })}>
        <PlusOutlined /> {t("menu.Products.Form.Modifications.Add")}
      </Button>
    </Space>
  );
};

MenuItemFields.propTypes = {
  t: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
};

export default MenuItemFields;
