import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageHeader, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { PAYMENT_METHOD_TYPE, PAYMENT_METHOD_NAME } from "constants/index";

import PaymentMethodForm from "./components/PaymentMethodForm";
import { EDIT_PAYMENT_METHOD_QUERY, UPDATE_PAYMENT_METHOD_MUTATION } from "./paymentMethodsQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditPaymentMethod = ({ match, history }) => {
  const paymentMethodId = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const { data, loading } = useQuery(EDIT_PAYMENT_METHOD_QUERY, {
    onError: notification.error,
    variables: { id: paymentMethodId },
  });
  const [updatePaymentMethods] = useMutation(UPDATE_PAYMENT_METHOD_MUTATION);

  if (loading) return <Loader />;

  const handleSubmit = ({ id, type, name, ...rest }) =>
    updatePaymentMethods({
      variables: {
        id,
        data: {
          ...rest,
          name: type === PAYMENT_METHOD_TYPE.CUSTOM ? name : type,
        },
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("settings.PaymentMethod.EditPage.Title")}
      />
      <PaymentMethodForm
        t={t}
        onSubmit={handleSubmit}
        financeAccounts={data ? data.finance_accounts : []}
        initialValues={data && {
          ...data.payment_methods[0],
          name: data.payment_methods[0].type === PAYMENT_METHOD_TYPE.CUSTOM
            ? data.payment_methods[0].name : PAYMENT_METHOD_NAME[data.payment_methods[0].name],
        }}
      />
    </div>
  );
};

EditPaymentMethod.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditPaymentMethod;
