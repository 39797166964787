import React from "react";
import { Switch, Route } from "react-router-dom";

import Payments from "./Payments";

const Container = () => (
  <Switch>
    <Route path="/statistics/payments" component={Payments} />
  </Switch>
);

export default Container;
