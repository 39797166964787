import React, { useEffect, useMemo } from "react";
import { Field, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Button } from "antd";

import { renderCascader, renderInput, renderLabel } from "components/FormItem";
import { required } from "utils/formValidations";
import { formatPrice } from "utils/helpers";

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

const ItemsInputTable = styled(Table)`
  // Remove arrows because no space
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

const capPrecision = (value, precision) => parseFloat(value.toFixed(precision));

const parseFloatValue = (precision) => (value) => {
  const parsedValue = parseFloat(value);
  return parsedValue || parsedValue === 0 ? capPrecision(parsedValue, precision) : "";
};

const SetItemsFields = ({
  t, fields, changeField, optionsData, meta,
}) => {
  const contents = useMemo(() => {
    if (!optionsData) return [];
    return optionsData.menu_categories.map(({ id, name, menu_item_groups }) => ({
      value: id,
      label: name,
      // eslint-disable-next-line no-shadow
      children: menu_item_groups.map(({ id, name, menu_items }) => {
        if (menu_items.length === 0) return null;
        if (menu_items.length === 1) {
          // eslint-disable-next-line no-shadow
          const { id, workshop_id, ...item } = menu_items[0].product || menu_items[0].tech_card;
          return {
            ...item,
            label: item.name,
            value: JSON.stringify(workshop_id ? { tech_card_id: id } : { product_id: id }),
          };
        }
        return {
          value: id,
          label: name,
          // eslint-disable-next-line no-shadow
          children: menu_items.map(({ product, tech_card }) => {
            // eslint-disable-next-line no-shadow
            const { id, workshop_id, ...item } = product || tech_card;
            return {
              ...item,
              label: item.name,
              value: JSON.stringify(workshop_id ? { tech_card_id: id } : { product_id: id }),
            };
          }),
        };
      }).filter((mig) => mig),
    })).filter((c) => c.children.length > 0);
  }, [optionsData]);

  const onBruttoUnitValueUpdated = (idx, value, newIngredient) => {
    const ingredient = newIngredient === undefined
      ? fields.get(idx).ingredient : newIngredient;
    if (!ingredient) return;
    const numberValue = value || 0;

    changeField(`${fields.name}[${idx}].cost`, ingredient.cost * numberValue);
    const newBruttoWeight = capPrecision(numberValue * ingredient.unit_weight * 1000, 0);
    changeField(`${fields.name}[${idx}].brutto_weight`, newBruttoWeight);
  };

  const onBruttoUnitChange = (idx, value, newIngredient) => {
    const ingredient = newIngredient === undefined
      ? fields.get(idx).ingredient : newIngredient;
    if (!ingredient) return;
    const numberValue = value || 0;

    onBruttoUnitValueUpdated(idx, numberValue, ingredient);
  };

  const onSelectContent = (idx, value) => {
    const { brutto_unit } = fields.get(idx);
    const valueItem = value.reduce(
      (val, pathItem) => val.children.find((c) => c.value === pathItem),
      { children: contents },
    );

    changeField(`${fields.name}[${idx}].ingredient`, valueItem);
    changeField(`${fields.name}[${idx}].content`, JSON.parse(valueItem.value));
    if (brutto_unit || brutto_unit === 0) {
      changeField(`${fields.name}[${idx}].brutto_unit`, brutto_unit);
      onBruttoUnitChange(idx, brutto_unit, valueItem);
    }
  };

  const selector = formValueSelector(meta.form);
  const techCardProducts = useSelector((state) => selector(state, fields.name));

  const totalOutput = (techCardProducts || [])
    .reduce((acc, { brutto_weight }) => acc + (brutto_weight || 0), 0);
  const totalCost = (techCardProducts || [])
    .reduce((acc, { cost }) => acc + (cost || 0), 0);

  useEffect(() => changeField("total_cost", totalCost), [totalCost]);
  useEffect(() => changeField("output", totalOutput), [totalOutput]);
  useEffect(() => {
    (techCardProducts || []).forEach(({ brutto_unit }, idx) => {
      if (brutto_unit || brutto_unit === 0) {
        onBruttoUnitChange(idx, brutto_unit);
      }
    });
  }, []);

  return (
    <ItemsInputTable
      rowKey={(field) => fields.getAll().indexOf(field)}
      dataSource={fields.getAll()}
      size="small"
      pagination={false}
      summary={() => (
        <>
          <Table.Summary.Row style={{ backgroundColor: "#fff" }}>
            <Table.Summary.Cell colSpan={9}>
              <Button type="dashed" onClick={() => fields.push({})}>
                <PlusOutlined /> {t("menu.Sets.Form.SetItems.AddIngredient")}
              </Button>
            </Table.Summary.Cell>
          </Table.Summary.Row>
          <Table.Summary.Row>
            <Table.Summary.Cell>
              {t("menu.Sets.Form.SetItems.Summary")}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell colSpan={2}>
              {formatPrice(totalCost)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )}
    >
      <Table.Column
        title={t("menu.Sets.Form.SetItems.Ingredient.Name")}
        dataIndex="content_path"
        render={(_c, _f, index) => (
          <Field
            name={`${fields.name}[${index}].content_path`}
            component={renderCascader}
            style={{ width: 250 }}
            onChange={(v) => onSelectContent(index, v)}
            validate={required}
            placeholder={t("menu.Sets.Form.SetItems.Ingredient.Placeholder")}
            formItemProps={formItemLayout}
            options={contents}
            showSearch
          />
        )}
      />
      <Table.Column
        title={t("menu.Sets.Form.SetItems.BruttoUnit.Name")}
        dataIndex="ingredient"
        render={(ingredient, _p, index) => (
          <Field
            name={`${fields.name}[${index}].brutto_unit`}
            component={renderInput}
            style={{ width: 140 }}
            onChange={(_e, v) => onBruttoUnitChange(index, v)}
            validate={required}
            type="number"
            min={0}
            step={0.001}
            parse={parseFloatValue(3)}
            formItemProps={formItemLayout}
            addonAfter={ingredient && t(`const:unit_type.${ingredient.unit}`)}
          />
        )}
      />
      <Table.Column
        title={t("menu.Sets.Form.SetItems.Cost.Name")}
        dataIndex="cost"
        render={(_c, _p, index) => (
          <Field
            name={`${fields.name}[${index}].cost`}
            component={renderLabel}
            style={{ width: 140 }}
            format={formatPrice}
            formItemProps={formItemLayout}
          />
        )}
      />
      <Table.Column
        dataIndex="content_path"
        render={(_c, _p, index) => (
          <Button
            danger
            disabled={fields.length < 3}
            onClick={() => fields.remove(index)}
          >
            <DeleteOutlined />
          </Button>
        )}
      />
    </ItemsInputTable>
  );
};

SetItemsFields.propTypes = {
  t: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  optionsData: PropTypes.object,
};

export default SetItemsFields;
