import React from "react";
import styled from "styled-components";
import { DateTime } from "luxon";
import { Table } from "antd";
import PropTypes from "prop-types";

import colors from "theme/colors";
import i18n from "i18n";
import { formatPrice } from "utils/helpers";

import { getAccountName } from "../../accounts/constants";
import { getCategoryName } from "../../categories/constants";
import { TRANSACTION_TYPES } from "../constants";

const Text = styled.span`
  color: ${(props) => props.color};
`;

const TransactionsTable = ({ t, pagination, loading, dataSource, ...props }) => (
  <Table
    rowKey="id"
    pagination={pagination}
    loading={loading}
    dataSource={dataSource}
    {...props}
  >
    <Table.Column
      title={t("finance.Transaction.Columns.Date")}
      dataIndex="date"
      render={(value) => value && (
        <b>{value.toLocaleString(DateTime.DATE_MED)}</b>
      )}
    />
    <Table.Column
      title={t("finance.Transaction.Columns.Category")}
      dataIndex="category"
      render={(category, record) => {
        if (record.type === TRANSACTION_TYPES.TRANSFER) {
          return i18n.t("finance.Transaction.Transfer");
        }
        return getCategoryName(category?.name || "");
      }}
    />
    <Table.Column
      title={t("finance.Transaction.Columns.Description")}
      dataIndex="description"
    />
    <Table.Column
      title={t("finance.Transaction.Columns.Amount")}
      dataIndex="amount"
      render={(amount, record) => {
        if (record.type === TRANSACTION_TYPES.INCOME) {
          return <Text color={colors.green}>+{formatPrice(amount)}</Text>;
        }
        if (record.type === TRANSACTION_TYPES.EXPENSE) {
          return <Text color={colors.red}>-{formatPrice(amount)}</Text>;
        }

        const sumTo = record.transfer_to_amount;

        return <Text color={colors.grey}>-{formatPrice(amount)} → {formatPrice(sumTo)}</Text>;
      }}
    />
    <Table.Column
      title={t("finance.Transaction.Columns.Employee")}
      dataIndex={["user", "name"]}
    />
    <Table.Column
      title={t("finance.Transaction.Columns.Account")}
      dataIndex="account"
      render={(account, record) => (record.type === TRANSACTION_TYPES.TRANSFER
        ? `${getAccountName(account)} → ${getAccountName(record.transfer_to_account)}`
        : getAccountName(account))}
    />
    <Table.Column
      title={t("finance.Transaction.Columns.Time")}
      dataIndex="time"
      render={(time) => DateTime.fromMillis(time).toLocaleString(DateTime.TIME_24_SIMPLE)}
    />
  </Table>
);

TransactionsTable.propTypes = {
  t: PropTypes.func,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  dataSource: PropTypes.object,
};

export default TransactionsTable;
