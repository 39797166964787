import styled, { createGlobalStyle } from "styled-components";

import colors from "theme/colors";

export const Align = styled.div`
  display: flex;
  justify-content: ${(props) => props.align};
`;

export const Span = styled.span`
  font-weight: ${(props) => props.weight};
`;

const GlobalStyle = createGlobalStyle`
  .cap-line {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .ant-descriptions-header {
    margin-bottom: 5px;
  }

  .ant-descriptions-row + .ant-descriptions-row > td {
    vertical-align: top;
  }

  .ant-form-item, .ant-legacy-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-label {
    white-space: pre-wrap;
  }

  .ant-table-container {
    border: 1px solid ${colors.borderColor};
  }

  .ant-table-wrapper.borderless .ant-table-container {
    border: none;
  }

  .ant-table-summary {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.75);
    background-color: #fafafa;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    word-break: break-word;
  }

  .ant-card-bordered {
    border: 1px solid ${colors.borderColor};
  }

  .ant-card-head-title {
    font-weight: bold;
    font-size: 17px;
  }

  .ant-input-affix-wrapper .ant-input-suffix {
    z-index: 0;
  }

  .ant-popover-inner-content {
    overflow-y: scroll;
    padding: 0;
    padding: 7px 0;
  }
}
`;

export default GlobalStyle;
