import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "theme/colors";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
`;

export const getCategoryName = (name) =>
  (name.startsWith("salempos_generated") ? i18n.t(`generated:finance_category.${name}`) : name);

export const CATEGORY_COLUMNS = [
  {
    title: i18n.t("finance.Category.Columns.Name"),
    dataIndex: "name",
    render: (_, record) => (
      record.name.startsWith("salempos_generated")
        ? (
          <>
            {getCategoryName(record.name)}{" "}
            <i style={{ fontSize: 14, color: colors.grey }}>
              {i18n.t("SalemPOSGenerated")}
            </i>
          </>
        ) : (
          <StyledLink to={`/finance/categories/${record.id}`}>
            {record.name}
          </StyledLink>
        )
    ),
  },
  {
    title: i18n.t("finance.Category.Columns.ValidTransactions"),
    dataIndex: "valid_transactions",
    render: (_, record) => {
      if (record.allow_expenses && record.allow_income) {
        return i18n.t("finance.Category.Columns.AllTransaction");
      }
      if (record.allow_expenses) {
        return i18n.t("finance.Category.Columns.AllowExpenses");
      }
      if (record.allow_income) {
        return i18n.t("finance.Category.Columns.AllowIncome");
      }
      return "-";
    },
  },
];
