import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Card, Menu, Radio } from "antd";
import PropTypes from "prop-types";

import LinesChart from "./LinesChart";

const FlexMenu = styled(Menu)`
  display: flex;
  justify-content: space-between;
  background: none;
`;

const AreaChartBySource = ({ t, data }) => {
  const [dataType, setDataType] = useState("revenue");
  const [periodType, setPeriodType] = useState("day");

  const chartData = useMemo(
    () => ({
      posByDay: data.posByDay.map((el) => ({
        day: el.day,
        value: dataType === "average_check"
          ? parseFloat(el.revenue / el.orders_count).toFixed(2)
          : el[dataType],
      })),
      callcenterByDay: data.callcenterByDay.map((el) => ({
        day: el.day,
        value: dataType === "average_check"
          ? parseFloat(el.revenue / el.orders_count).toFixed(2)
          : el[dataType],
      })),
    }),
    [data, dataType],
  );

  return (
    <Card
      title={t("statistics.Sales.AreaChartBySource.Revenue")}
      extra={(
        <Radio.Group defaultValue="day" size="small">
          <Radio.Button value="day" onClick={() => setPeriodType("day")}>
            {t("statistics.Sales.AreaChartBySource.Day")}
          </Radio.Button>
          <Radio.Button value="week" onClick={() => setPeriodType("week")}>
            {t("statistics.Sales.AreaChartBySource.Week")}
          </Radio.Button>
          <Radio.Button value="month" onClick={() => setPeriodType("month")}>
            {t("statistics.Sales.AreaChartBySource.Month")}
          </Radio.Button>
        </Radio.Group>
      )}
    >
      <LinesChart t={t} periodType={periodType} data={chartData} />
      <FlexMenu defaultSelectedKeys="income" mode="horizontal">
        <Menu.Item key="income" onClick={() => setDataType("revenue")}>
          {t("statistics.Sales.AreaChartBySource.revenue")}
        </Menu.Item>
        <Menu.Item key="count" onClick={() => setDataType("orders_count")}>
          {t("statistics.Sales.AreaChartBySource.orders")}
        </Menu.Item>
        <Menu.Item
          key="average_check"
          onClick={() => setDataType("average_check")}
        >
          {t("statistics.Sales.AreaChartBySource.averageCheck")}
        </Menu.Item>
      </FlexMenu>
    </Card>
  );
};

AreaChartBySource.propTypes = {
  data: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default AreaChartBySource;
