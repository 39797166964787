import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Card, notification, Spin, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import _ from "lodash";

import Page from "components/Page";
import NewIngredientPane from "components/NewIngredientPane";

import ModifierGroupForm from "./components/ModifierGroupForm";
import {
  MODIFIER_GROUP_QUERY,
  UPDATE_MODIFIER_GROUP_MUTATION,
  MODIFIER_GROUPS_QUERY,
  SELECT_OPTIONS_QUERY,
} from "./modifiersQueries";

import { SELECT_OPTIONS_QUERY as TECH_CARD_OPTIONS_QUERY } from "../techCards/techCardQueries";
import { SELECT_OPTIONS_QUERY as SET_OPTIONS_QUERY } from "../sets/setQueries";

const EditModifier = ({ history, match }) => {
  const modifierGroupId = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const [isCreatingIngridient, setCreatingIngridient] = useState(false);
  const [toggleArchived, setToggleArchived] = useState(false);

  const { data, loading } = useQuery(MODIFIER_GROUP_QUERY, {
    variables: { id: modifierGroupId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const optionsQuery = useQuery(SELECT_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [updateModifierGroup] = useMutation(UPDATE_MODIFIER_GROUP_MUTATION, {
    refetchQueries: [
      { query: MODIFIER_GROUPS_QUERY },
      { query: TECH_CARD_OPTIONS_QUERY },
      { query: SET_OPTIONS_QUERY },
    ],
  });

  const modifierGroup = data?.modifier_groups_by_pk;

  const initialModifierGroup = useMemo(() => modifierGroup && optionsQuery.data && {
    ...modifierGroup,
    modifier_type: modifierGroup.max_selection === 1 ? "only_one" : "several",
    is_required: modifierGroup.min_selection === 1,
    modifier_items: modifierGroup.modifier_items
      .map(({ product_id, tech_card_id, ...rest }) => {
        if (product_id || tech_card_id) {
          const ingredient = product_id
            ? optionsQuery.data.products.find((p) => p.id === product_id)
            : optionsQuery.data.tech_cards.find((tc) => tc.id === tech_card_id);
          const content = product_id ? { product_id } : { tech_card_id };
          const contentPath = (ingredient.category_id ? ["ingredients"] : [])
            .concat(ingredient.category_id || 0, JSON.stringify(content));
          return { ...rest, ingredient, content, content_path: contentPath };
        }
        return { ...rest, content_path: [null] };
      }),
  }, [modifierGroup, optionsQuery.data]);

  if (loading || optionsQuery.loading) return <Spin />;

  const handleSubmit = ({
    name, description, modifier_type, is_required,
    min_selection, max_selection, modifier_items, archived_at,
  }) =>
    updateModifierGroup({
      variables: {
        id: modifierGroupId,
        data: {
          name,
          description,
          // eslint-disable-next-line no-nested-ternary
          archived_at: toggleArchived ? (archived_at ? null : moment().format()) : archived_at,
          ...(modifier_type === "only_one" ? {
            min_selection: is_required ? 1 : 0,
            max_selection: 1,
          } : {
            min_selection,
            max_selection,
          }),
          modifier_items: {
            data: modifier_items.map(({
              // eslint-disable-next-line no-shadow
              id, name, price, cost, quantity, content, default_value,
            }) => ({
              id,
              name,
              cost,
              price,
              quantity,
              default_value,
              tech_card_id: null,
              product_id: null,
              ...content,
            })).concat(_.differenceBy(modifierGroup.modifier_items, modifier_items, "id")
              .map(({ tech_card, product, ...item }) => ({
                ...item, archived_at: moment().format(),
              }))),
          },
        },
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);

  const archivingOrUnarchivingButton = (
    <Button
      type="danger"
      htmlType="submit"
      onClick={() => setToggleArchived(true)}
    >
      {initialModifierGroup?.archived_at
        ? t("menu.Modifiers.Unarchiving") : t("menu.Modifiers.Archiving")}
    </Button>
  );

  return (
    <Page showBackIcon title={t("menu.Modifiers.EditPage.Title")}>
      <Card>
        <ModifierGroupForm
          t={t}
          onSubmit={handleSubmit}
          initialValues={initialModifierGroup}
          optionsData={optionsQuery.data}
          onIngridientCreate={() => setCreatingIngridient(true)}
          archivingOrUnarchivingButton={archivingOrUnarchivingButton}
        />
      </Card>
      <NewIngredientPane
        isOpen={isCreatingIngridient}
        onClose={() => setCreatingIngridient(false)}
      />
    </Page>
  );
};

EditModifier.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditModifier;
