import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Button, Typography } from "antd";

import { renderLabel, renderSelect } from "components/FormItem";
import { required } from "utils/formValidations";

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

const TechCardModifierGroupsFields = ({ t, fields, optionsData, disabled }) => (
  <Table
    rowKey={(field) => fields.getAll().indexOf(field)}
    dataSource={fields.getAll()}
    size="small"
    pagination={false}
    locale={{
      emptyText: disabled
        ? t("menu.TechCardModifierGroupsFields.DisabledLabel")
        : t("menu.TechCardModifierGroupsFields.EmptyLabel"),
    }}
    summary={() => (disabled ? null : (
      <Table.Summary.Row style={{ backgroundColor: "#fff" }}>
        <Table.Summary.Cell colSpan={9}>
          <Button type="dashed" onClick={() => fields.push({})}>
            <PlusOutlined /> {t("menu.TechCardModifierGroupsFields.AddModifier")}
          </Button>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ))}
  >
    <Table.Column
      title={t("menu.TechCardModifierGroupsFields.ModifierGroup")}
      dataIndex="modifier_group_id"
      width={240}
      render={(_c, _f, index) => (
        <Field
          style={{ width: 220 }}
          name={`${fields.name}[${index}].modifier_group_id`}
          component={renderSelect}
          validate={required}
          formItemProps={formItemLayout}
          optionLabelProp="name"
          options={optionsData.modifier_groups.map((mg) => ({
            value: mg.id,
            name: mg.name,
            label: (
              <div>
                <Typography.Text>{mg.name}</Typography.Text>
                {mg.description && (
                  <div>
                    <Typography.Text type="secondary">{mg.description}</Typography.Text>
                  </div>
                )}
              </div>
            ),
          }))}
        />
      )}
    />
    <Table.Column
      title={t("menu.TechCardModifierGroupsFields.ModifierOptions")}
      dataIndex="modifier_group_id"
      render={(_c, _f, index) => (
        <Field
          name={`${fields.name}[${index}].modifier_group_id`}
          component={renderLabel}
          formItemProps={formItemLayout}
          format={(groupId) => groupId && optionsData.modifier_groups
            .find((mg) => mg.id === groupId).modifier_items.map((mi) => mi.name).join(", ")}
        />
      )}
    />
    <Table.Column
      dataIndex="content_path"
      width={70}
      render={(_c, _p, index) => (
        <Button danger onClick={() => fields.remove(index)}>
          <DeleteOutlined />
        </Button>
      )}
    />
  </Table>
);

TechCardModifierGroupsFields.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  optionsData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default TechCardModifierGroupsFields;
