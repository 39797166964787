import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import colors from "theme/colors";
import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  color: ${colors.primaryColor};
`;

export const EMPLOYEE_COLUMNS = [
  {
    title: i18n.t("access.Employee.Columns.FirstName"),
    dataIndex: "name",
    render: (name, employee) => (
      <StyledLink to={`/access/employees/${employee.id}`}>{name}</StyledLink>
    ),
  },
  {
    title: i18n.t("access.Employee.Columns.Login"),
    dataIndex: "email",
  },
  {
    title: i18n.t("access.Employee.Columns.PhoneNumber"),
    dataIndex: "phone_number",
  },
  {
    title: i18n.t("access.Employee.Columns.Location"),
    dataIndex: "users_locations",
    render: (users_locations) =>
      users_locations.map((userLocation) => userLocation.location.name).join(", "),
  },
  {
    title: i18n.t("access.Employee.Columns.AccessRole"),
    dataIndex: "access_role",
    render: (access_role) => (access_role.name === "salempos_generated_owner"
      ? i18n.t("const:owner") : access_role.name),
  },
  {
    title: "PIN code",
    dataIndex: "pin_code",
  },
];
