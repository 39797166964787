import gql from "graphql-tag";

export const PROFILE_QUERY = gql`
  query getMe @hasura {
    common_settings {
      id
      currency_code
      timezone
      shift_offset
    }
    merchants {
      id
      favicon_url
      logo_url
      company_name
      plan
    }
    locations {
      id
      name
    }
    current_user_view {
      id
      name
      email
      access_role {
        id
        name
        access_menu
        access_users
        access_orders
        access_cashier
        access_finance
        access_marketing
        access_warehouse
        access_statistics
        access_callcenter
      }
    }
  }
`;
