import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import { CREATE_LOCATION_MUTATION } from "../settings/locations/locationQueries";
import {
  CREATE_PRODUCT_MUTATION,
} from "../menu/products/productQueries";
import WizardForm from "./components/WizardForm";

const Container = styled.div`
  display: flex;
  justify-content: center;

  & .inner-container {
    max-width: 1200px;
    width: 100%;
    height: 100%;
  }
`;

const Onboarding = ({ refetch }) => {
  const { t } = useTranslation();
  const [createLocation] = useMutation(CREATE_LOCATION_MUTATION);
  const [createProduct] = useMutation(CREATE_PRODUCT_MUTATION);

  const handleCreateLocation = (values) =>
    createLocation({ variables: { data: values } })
      .catch(parseFormErrors);

  const handleCreateItem = ({ category_name, name, price, unit }) => {
    const values = {
      data: {
        name,
        category: {
          data: {
            name: category_name,
          },
        },
        menu_items: {
          data: [{
            limited_location_availability: null,
            name,
            price,
            product: {
              data: {
                name, cost: 0, unit,
              },
            },
          }],
        },
      },
    };
    return createProduct({ variables: values });
  };

  return (
    <Container>
      <div className="inner-container">
        <WizardForm
          t={t}
          onCreateLocation={handleCreateLocation}
          onCreateItem={handleCreateItem}
          refetch={refetch}
        />
      </div>
    </Container>
  );
};

Onboarding.propTypes = {
  refetch: PropTypes.func,
};

export default Onboarding;
