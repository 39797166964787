import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageHeader, notification, Spin } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import PromotionForm from "./components/PromotionForm";
import {
  MENU_ITEMS_QUERY,
  CREATE_PROMOTION_MUTATION,
} from "./promotionQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const NewPromotion = ({ history }) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(MENU_ITEMS_QUERY, {
    onError: notification.error,
  });
  const [createPromotion] = useMutation(CREATE_PROMOTION_MUTATION);

  if (loading) return <Loader />;

  const onCreatePromotion = (value) => createPromotion({
    variables: { data: value },
  })
    .then(() => history.goBack())
    .catch((error) => notification.error(error));

  const handleSubmit = (values) => {
    const {
      discount,
      bonus_items_max_count,
      promotion_conditions,
      promotion_products,
      ...rest
    } = values;

    if (values.result_type === "product") {
      return onCreatePromotion({
        ...rest,
        bonus_items_max_count: parseInt(bonus_items_max_count, 10),
        promotion_conditions: { data: promotion_conditions },
        promotion_products: { data: promotion_products },
      });
    }
    return onCreatePromotion({
      ...rest,
      discount: parseFloat(discount),
      promotion_conditions: { data: promotion_conditions },
    });
  };

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("marketing.Promotion.AddPage.Title")}
      />
      <PromotionForm
        t={t}
        menuItems={data ? data.menu_items : []}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

NewPromotion.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewPromotion;
