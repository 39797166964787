import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import colors from "../theme/colors";

export const Th = styled.th`
  font-size: 12px;
  color: #999999;
  padding: 20px 8px 8px 8px;

  :first-child {
    padding-left: 28px;
  }

  :last-child {
    padding-right: 28px;
  }
`;

export const Td = styled.td`
  text-align: ${(props) => props.align};
  padding: 8px;
  line-height: 1.43;
  vertical-align: top;

  :first-child {
    padding-left: 28px;
  }

  :last-child {
    padding-right: 28px;
  }
`;

export const Thead = styled(({ columns, className }) => (
  <thead className={className}>
    <tr>
      {columns.map((column) => (
        <Th key={column}>{column}</Th>
      ))}
    </tr>
  </thead>
))`
  border-bottom: 1px solid ${colors.whisper};
`;

export const Table = styled.table`
  background-color: ${colors.white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

Thead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  className: PropTypes.string,
};
