import React from "react";
import { Switch, Route } from "react-router-dom";

import Employees from "./Employees";
import Sales from "../sales/Sales";

const Container = () => (
  <Switch>
    <Route path="/statistics/employees/:userId" component={Sales} />
    <Route path="/statistics/employees" component={Employees} />
  </Switch>
);

export default Container;
