import gql from "graphql-tag";

export const MENU_ITEMS_QUERY = gql`
  query @hasura {
    menu_items(order_by: {id: asc}) {
      id
      name
      archived_at
      tech_card {
        id
        unit
        is_set
      }
      product {
        id
        unit
      }
      menu_item_group {
        id
        name
        category_id
        archived_at
      }
    }
    menu_categories(order_by: {id: asc}) {
      id
      name
    }
  }
`;

export const MENU_ITEMS_SALES_STATS_QUERY = gql`
  query salesProducts($filters: FiltersInput!) {
    salesStatsByMenuItem(filters: $filters) {
      menu_item_id
      count
      profit
      cost
      revenue
    }
  }
`;

export const MENU_ITEMS_AND_LOCATIONS_QUERY = gql`
  query($id: Int!) @hasura {
    locations {
      id
      name
    }
    menu_items(where: {id: {_eq: $id}}) {
      id
      name
      menu_item_group {
        id
        name
      }
    }
  }
`;

export const MENU_ITEMS_STATS_QUERY = gql`
  query MenuItemsStats($filters: FiltersInput!, $menu_item_id: Int!) {
    menuItemStatsByWeekday(filters: $filters, menu_item_id: $menu_item_id) {
      weekday
      count
    }
    menuItemStatsByHour(filters: $filters, menu_item_id: $menu_item_id) {
      hour
      count
    }
    menuItemStatsByDay(filters: $filters, menu_item_id: $menu_item_id) {
      day
      count
    }
  }
`;
