import moment from "moment";
import { formatPrice } from "utils/helpers";

import { PAYMENT_METHOD_TYPE } from "constants/index";
import i18n from "../../../i18n";

export const PAYMENT_COLUMNS = [
  {
    title: i18n.t("statistics.Payments.Columns.Date"),
    dataIndex: "day",
    width: 200,
    render: (date) => moment(date).format("ll"),
  },
  {
    title: i18n.t("statistics.Payments.Columns.CashTotal"),
    dataIndex: "cash_total",
    width: 200,
    render: (_, { stats }) => {
      const cashTotal = stats.reduce((acc, { amount, type, payment_method }) => {
        if (payment_method.type === PAYMENT_METHOD_TYPE.CASH) {
          return acc + (type === "payment" ? amount : -amount);
        }
        return acc;
      }, 0);

      return formatPrice(cashTotal);
    },
  },
  {
    title: i18n.t("statistics.Payments.Columns.CardTotal"),
    dataIndex: "card_total",
    width: 200,
    render: (_, { stats }) => {
      const cardTotal = stats.reduce((acc, { amount, type, payment_method }) => {
        if (payment_method.type === PAYMENT_METHOD_TYPE.CARD) {
          return acc + (type === "payment" ? amount : -amount);
        }
        return acc;
      }, 0);

      return formatPrice(cardTotal);
    },
  },
  {
    title: i18n.t("statistics.Payments.Columns.CustomTotal"),
    dataIndex: "custom_total",
    width: 200,
    render: (_, { stats }) => {
      const customTotal = stats.reduce((acc, { amount, type, payment_method }) => {
        if (payment_method.type === PAYMENT_METHOD_TYPE.CUSTOM) {
          return acc + (type === "payment" ? amount : -amount);
        }
        return acc;
      }, 0);

      return formatPrice(customTotal);
    },
  },
  {
    title: i18n.t("statistics.Payments.Columns.All"),
    dataIndex: "all",
    width: 200,
    render: (_, { stats }) => {
      const total = stats.reduce((acc, { type, amount }) =>
        acc + (type === "payment" ? amount : -amount), 0);

      return formatPrice(total);
    },
  },
  {
    title: i18n.t("statistics.Payments.Columns.Location"),
    dataIndex: ["location", "name"],
    width: 200,
  },
];
