import React, { useEffect } from "react";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form } from "@ant-design/compatible";
import { Button, Divider, Alert, Space } from "antd";
import { useSelector } from "react-redux";

import { renderInput, renderTextarea, renderSelect } from "components/FormItem";
import { required } from "utils/formValidations";
import ColorInput from "components/ColorInput";

import SetItemsFields from "./SetItemsFields";
import PriceFields from "../../shared/components/PriceFields";
import TechCardModifierGroupsFields from "../../shared/components/TechCardModifierGroupsFields";

const buttonsLayout = {
  wrapperCol: {
    md: { span: 15, offset: 6 },
    xs: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const SetForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  error,
  optionsData,
  onCategoryCreate,
  onWorkshopCreate,
  change: changeField,
  archivingOrUnarchivingButton,
}) => {
  const workshopOptions = (optionsData?.workshops ?? []).map(
    ({ id, name }) => ({ value: id, label: name }),
  );
  const locationOptions = (optionsData?.locations ?? []).map(
    ({ id, name }) => ({ value: id, label: name }),
  );
  const menuCategoryOptions = (optionsData?.menu_categories ?? []).map(
    ({ id, name }) => ({ value: id, label: name }),
  );

  const selector = formValueSelector("setForm");
  const output = useSelector((state) => selector(state, "output"));
  const totalCost = useSelector((state) => selector(state, "total_cost"));
  const menuItem = useSelector((state) => selector(state, "menu_item"));

  useEffect(() => {
    changeField("tech_card.unit", "pieces");
    changeField("tech_card.unit_weight", output / 1000);
    changeField("tech_card.output_quantity", 1);
    changeField("menu_item.cost", totalCost);
  }, [output, totalCost]);

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert type="error" message={error} />}
      <Field
        formItemProps={formItemLayout}
        name="name"
        label={t("menu.Sets.Form.Name")}
        component={renderInput}
        validate={required}
      />
      <Field
        formItemProps={formItemLayout}
        name="category_id"
        label={t("menu.Sets.Form.Category.Label")}
        placeholder={t("menu.Sets.Form.Category.Placeholder")}
        component={renderSelect}
        style={{ width: 250 }}
        validate={required}
        options={menuCategoryOptions}
        showSearch
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <LinkContainer onMouseDown={(e) => e.preventDefault()}>
              <Button
                type="link"
                onClick={onCategoryCreate}
              >
                {t("menu.Sets.Form.Category.DropdownButton")}
              </Button>
            </LinkContainer>
          </div>
        )}
      />
      <Field
        formItemProps={formItemLayout}
        name="tech_card.workshop_id"
        label={t("menu.Sets.Form.Workshop.Label")}
        placeholder={t("menu.Sets.Form.Workshop.Placeholder")}
        component={renderSelect}
        style={{ width: 250 }}
        options={workshopOptions}
        validate={required}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <LinkContainer onMouseDown={(e) => e.preventDefault()}>
              <Button type="link" onClick={onWorkshopCreate}>
                {t("menu.Sets.Form.Workshop.DropdownButton")}
              </Button>
            </LinkContainer>
          </div>
        )}
      />
      <Field
        formItemProps={formItemLayout}
        name="description"
        label={t("Description")}
        component={renderTextarea}
      />
      <Field
        formItemProps={formItemLayout}
        name="color"
        label={t("Color")}
        component={ColorInput}
      />

      <br />

      <Form.Item
        label={t("menu.Sets.Form.SetItems.Title")}
        labelCol={{ xs: 24, sm: 10, md: 6 }}
        wrapperCol={{ xs: 24, xl: 16 }}
      >
        <FieldArray
          t={t}
          name="tech_card.tech_card_products"
          changeField={changeField}
          component={SetItemsFields}
          optionsData={optionsData}
        />
      </Form.Item>

      <Form.Item
        label={t("menu.Sets.Form.Modifiers.Title")}
        labelCol={{ xs: 24, sm: 10, md: 6 }}
        wrapperCol={{ xs: 24, xl: 16 }}
      >
        <FieldArray
          t={t}
          name="menu_item.menu_item_modifier_groups"
          component={TechCardModifierGroupsFields}
          optionsData={optionsData}
        />
      </Form.Item>

      <Form.Item {...buttonsLayout}>
        <PriceFields
          t={t}
          disableCost
          prefix="menu_item"
          changeField={changeField}
          modifier={menuItem}
        />
      </Form.Item>

      <Field
        formItemProps={formItemLayout}
        name="menu_item.limited_location_availability"
        label={t("menu.Sets.Form.LocationAvailability.Label")}
        component={renderSelect}
        placeholder={t("menu.Sets.Form.LocationAvailability.Placeholder")}
        mode="multiple"
        options={locationOptions}
        extra={t("menu.Sets.Form.LocationAvailability.Extra")}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <Button
              type="link"
              onClick={() => changeField("menu_item.limited_location_availability", null)}
            >
              {t("menu.Sets.Form.LocationAvailability.DropdownButton")}
            </Button>
          </div>
        )}
      />

      <Form.Item {...buttonsLayout}>
        <Space>
          <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
            {t("Save")}
          </Button>
          {archivingOrUnarchivingButton}
        </Space>
      </Form.Item>
    </Form>
  );
};

SetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  optionsData: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  archivingOrUnarchivingButton: PropTypes.element,
  onWorkshopCreate: PropTypes.func,
};

export default reduxForm({
  form: "setForm",
  initialValues: {
    menu_item: {},
    tech_card: {
      tech_card_products: [{}, {}],
    },
  },
})(SetForm);
