import i18n from "i18n";

export const PRODUCT_CATEGORY_TYPE_LABELS = {
  products: i18n.t("Products"),
  tech_cards: i18n.t("TechCards"),
  ingredients: i18n.t("Ingredients"),
  prepmeals: i18n.t("Prepmeals"),
};

export const PRODUCT_CATEGORY_TYPE_SINGULAR_LABELS = {
  products: i18n.t("Product"),
  tech_cards: i18n.t("TechCard"),
  ingredients: i18n.t("Ingredient"),
  prepmeals: i18n.t("Prepmeal"),
};

export const categoryForProduct = ({ tech_card, category_id, menu_items }, productsData) => {
  if (tech_card) {
    if (tech_card.menu_items[0]) {
      const id = tech_card.menu_items[0].menu_item_group.category_id;
      const category = productsData.menu_categories.find((c) => c.id === id);
      return {
        ...category,
        type: "tech_cards",
        name: `${category.name} (${i18n.t("ShortTechCard")})`,
      };
    }
    return { type: "prepmeals", id: 0, name: i18n.t("Prepmeals") };
  }
  if (category_id) {
    return {
      type: "ingredients", ...productsData.product_categories.find((c) => c.id === category_id),
    };
  }
  const id = menu_items[0].menu_item_group.category_id;
  return { type: "products", ...productsData.menu_categories.find((c) => c.id === id) };
};

export const cascadeCategoriesForProductsData = (productsData) => {
  const categories = productsData.products.reduce((acc, product) => {
    if (product.menu_items[0]?.archived_at || product.menu_items[0]?.menu_item_group.archived_at
        || product.tech_card?.menu_items[0]?.archived_at
        || product.tech_card?.menu_items[0]?.menu_item_group.archived_at) {
      return acc;
    }

    const category = categoryForProduct(product, productsData);
    const item = { ...product, label: product.name, value: product.id };

    const categoryIdx = acc.findIndex((c) => c.value === category.id && c.type === category.type);

    if (categoryIdx >= 0) {
      return acc.map((cat, idx) => (idx !== categoryIdx ? cat : {
        ...cat, children: cat.children.concat(item),
      }));
    }

    return acc.concat({
      label: category.name, value: category.id, type: category.type, children: [item],
    });
  }, []);

  return [{
    label: PRODUCT_CATEGORY_TYPE_LABELS.products,
    value: "products",
    children: categories.filter((c) => c.type === "products"),
  }, {
    label: PRODUCT_CATEGORY_TYPE_LABELS.tech_cards,
    value: "tech_cards",
    children: categories.filter((c) => c.type === "tech_cards"),
  }, {
    label: PRODUCT_CATEGORY_TYPE_LABELS.ingredients,
    value: "ingredients",
    children: categories.filter((c) => c.type === "ingredients"),
  }, {
    label: PRODUCT_CATEGORY_TYPE_LABELS.prepmeals,
    value: "prepmeals",
    children: categories.find((c) => c.type === "prepmeals")?.children ?? [],
  }].filter((c) => c.children.length > 0);
};

export const contentPathForProduct = ({ id, tech_card, category_id, menu_items }) => {
  if (tech_card) {
    if (tech_card.menu_items[0]) {
      const categoryId = tech_card.menu_items[0].menu_item_group.category_id;
      return ["tech_cards", categoryId, id];
    }
    return ["prepmeals", id];
  }
  if (category_id) {
    return ["ingredients", category_id, id];
  }
  const categoryId = menu_items[0].menu_item_group.category_id;
  return ["products", categoryId, id];
};
