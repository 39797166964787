import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Button, Alert } from "antd";

import { renderInput, buttonLayout } from "components/FormItem";
import { requiredText } from "utils/formValidations";

const IngredientCategoryForm = ({
  handleSubmit, t, pristine, submitting, error,
}) => (
  <Form onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}
    <Field
      name="name"
      label={t("menu.IngredientCategories.Form.Name.Label")}
      placeholder={`${t("menu.IngredientCategories.Form.Name.Placeholder")}`}
      component={renderInput}
      validate={requiredText}
    />
    <Form.Item {...buttonLayout}>
      <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
        {t("Save")}
      </Button>
    </Form.Item>
  </Form>
);

IngredientCategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default reduxForm({
  form: "ingredientCategoryForm",
})(IngredientCategoryForm);
