import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Table, Space, Button, Input, notification, Checkbox, Select, Typography, Drawer, Row,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  useQueryParam, NumberParam, StringParam, BooleanParam, withDefault,
} from "use-query-params";

import Page from "components/Page";
import { ColorDot } from "components/ColorInput";

import { formatPrice } from "utils/helpers";
import { sortByFnValue, sortByValue, sortByAlphabet } from "utils/sorts";

import TechCardDetailsPanel from "./components/TechCardDetailsPanel";

import { TECH_CARDS_QUERY } from "./techCardQueries";

const TechCards = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 20));
  const [showAll, setShowAll] = useQueryParam("show_all", withDefault(BooleanParam, false));
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [categoryId, setCategoryId] = useQueryParam("category_id", NumberParam);
  const [selectedTechCard, setSelectedTechCard] = useState(null);

  const { data, loading } = useQuery(TECH_CARDS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  const techCardsWithMarkup = useMemo(() => data?.menu_item_groups.map((mig) => ({
    ...mig,
    markup: parseFloat(
      ((mig.menu_items[0].price / mig.menu_items[0].tech_card.cost - 1) * 100).toFixed(2),
    ),
  })), [data]);

  const techCards = useMemo(() => techCardsWithMarkup?.filter(
    ({ name, category_id, archived_at }) => {
      if (search && search.length > 0 && !name.toLowerCase().includes(search)) {
        return false;
      }
      if (categoryId && category_id !== categoryId) {
        return false;
      }
      return showAll || !archived_at;
    },
  ), [techCardsWithMarkup, categoryId, search, showAll]);

  return (
    <Page
      title={t("menu.TechCards.Index.Title")}
      subTitle={data?.menu_item_groups.length.toString()}
      headerContent={t("menu.TechCards.Index.Description")}
      extra={(
        <Space>
          <Checkbox checked={showAll} onChange={() => setShowAll(!showAll)}>
            {t("ShowArchived")}
          </Checkbox>
          <Link to="/menu/tech-cards/new">
            <Button type="primary">{t("Add")}</Button>
          </Link>
        </Space>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
          style={{ width: 200 }}
        />
        <Select
          placeholder={t("Category")}
          style={{ width: 200 }}
          value={categoryId}
          onChange={(value) => setCategoryId(value)}
        >
          <Select.Option value={null}>{t("AllCategories")}</Select.Option>
          {data && data.menu_categories.map((category) => (
            <Select.Option value={category.id} key={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        loading={loading}
        dataSource={techCards}
      >
        <Table.Column
          title={t("menu.TechCards.Columns.Name")}
          dataIndex="name"
          width={240}
          render={(name, record) => (
            <Link to={`/menu/tech-cards/${record.id}`}>
              <Row align="middle">
                {name}
                &nbsp;
                <ColorDot color={record.color} size="small" style={{ marginTop: 1 }} />
                {record.archived_at
                  && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
              </Row>
            </Link>
          )}
          sorter={sortByAlphabet("name")}
        />
        <Table.Column
          title={t("menu.TechCards.Columns.Category")}
          dataIndex={["category", "name"]}
          width={140}
        />
        <Table.Column
          title={t("menu.TechCards.Columns.Cost")}
          dataIndex={["menu_items", 0, "tech_card", "cost"]}
          width={120}
          render={formatPrice}
          sorter={sortByFnValue((mig) => mig.menu_items[0].tech_card.cost)}
        />
        <Table.Column
          title={t("menu.TechCards.Columns.Price")}
          dataIndex={["menu_items", 0, "price"]}
          width={120}
          render={formatPrice}
          sorter={sortByFnValue((mig) => mig.menu_items[0].price)}
        />
        <Table.Column
          title={t("menu.TechCards.Columns.Markup")}
          dataIndex="markup"
          width={120}
          render={(markup) => (Number.isFinite(markup) ? `${markup}%` : "-")}
          sorter={sortByValue("markup")}
        />
        <Table.Column
          title={t("menu.TechCards.Columns.IsWeighted")}
          dataIndex={["menu_items", 0, "tech_card", "unit"]}
          width={100}
          render={(unit) => t(unit === "kg" ? "Yes" : "No")}
        />
        <Table.Column
          title={t("menu.Prepmeals.Columns.IsProduced")}
          dataIndex={["menu_items", 0, "tech_card", "product_id"]}
          width={100}
          render={(product_id) => t(product_id ? "Yes" : "No")}
          sorter={sortByFnValue((mig) => mig.menu_items[0].tech_card.product_id)}
        />
        <Table.Column
          title={t("menu.TechCards.Columns.Description")}
          dataIndex="description"
          width={190}
        />
        <Table.Column
          dataIndex="id"
          width={80}
          render={(_, record) => (
            <Button type="link" size="small" onClick={() => setSelectedTechCard(record)}>
              {t("Details")}
            </Button>
          )}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedTechCard?.name}
            &nbsp;
            <Link to={`/menu/tech-cards/${selectedTechCard?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedTechCard}
        onClose={() => setSelectedTechCard(null)}
        placement="bottom"
        height="60%"
      >
        {selectedTechCard && (
          <TechCardDetailsPanel t={t} techCardId={selectedTechCard.menu_items[0].tech_card.id} />
        )}
      </Drawer>
    </Page>
  );
};

TechCards.propTypes = {};

export default TechCards;
