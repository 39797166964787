import gql from "graphql-tag";

export const DELIVERY_ZONES_AND_LOCATIONS_QUERY = gql`
  query @hasura {
    delivery_zones(where: {deleted_at: {_is_null: true}}) {
      id
      name
      description
      price
      geo
      min_order_sum_for_delivery
      order_sum_for_free_delivery
      location_id
      deleted_at
      location {
        id
        name
      }
    }
    locations {
      id
      name
    }
  }
`;

export const CREATE_DELIVERY_ZONE_MUTATION = gql`
  mutation CreateDeliveryZone($data: [delivery_zones_insert_input!]!) @hasura {
    insert_delivery_zones(objects: $data) {
      returning {
        id
        name
        description
        price
        geo
        location_id
        deleted_at
        location {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_DELIVERY_ZONE_MUTATION = gql`
  mutation EditDeliveryZone($id: Int!, $data: delivery_zones_set_input!) @hasura {
    update_delivery_zones(_set: $data, where: {id: {_eq: $id}}) {
      returning {
        id
        name
        description
        price
        geo
        location_id
        deleted_at
        location {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_DELIVERY_ZONE_MUTATION = gql`
  mutation DeleteDeliveryZone($id: Int!, $delete_date: timestamptz!) @hasura {
    update_delivery_zones(where: {id: {_eq: $id}}, _set: {deleted_at: $delete_date}) {
      returning {
        id
        name
        description
        price
        geo
        location_id
        deleted_at
        location {
          id
          name
        }
      }
    }
  }
`;
