import React from "react";
import styled from "styled-components";
import axios from "axios";
import PropTypes from "prop-types";
import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";

import { parseFormErrors } from "utils/formErrors";

import AccessRoleForm from "./components/AccessRoleForm";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const NewAccessRole = ({ history }) => {
  const { t } = useTranslation();

  const handleSubmit = (values) =>
    axios.post("/admin_api/access_roles", values)
      .then(() => history.goBack())
      .catch(parseFormErrors);

  const initialValues = {
    payroll_hour: 0,
    payroll_shift: 0,
    payroll_month: 0,
    percent_personal_sales: 0,
    percent_shift_sales: 0,
  };

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("access.AccessRoles.EditPage.Title")}
      />
      <AccessRoleForm t={t} initialValues={initialValues} onSubmit={handleSubmit} />
    </div>
  );
};

NewAccessRole.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewAccessRole;
