import React, { useEffect, useState } from "react";
import moment from "moment";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form } from "@ant-design/compatible";
import { Button, Divider, Alert, Typography, Space } from "antd";
import { useSelector } from "react-redux";

import {
  renderInput, renderTextarea, renderSelect, renderCheckbox, renderTimePicker, errorLayout,
} from "components/FormItem";
import { required } from "utils/formValidations";
import ColorInput from "components/ColorInput";

import TechCardProductsFields from "../../shared/components/TechCardProductsFields";
import TechCardModifierGroupsFields from "../../shared/components/TechCardModifierGroupsFields";
import PriceFields from "../../shared/components/PriceFields";

const buttonsLayout = {
  wrapperCol: {
    md: { span: 15, offset: 6 },
    xs: { span: 24 },
  },
};

const checkboxLayout = {
  wrapperCol: {
    md: { span: 10, offset: 6 },
    xs: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const TechCardForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  error,
  optionsData,
  onIngridientCreate,
  onCategoryCreate,
  initialValues,
  change: changeField,
  archivingOrUnarchivingButton,
  onWorkshopCreate,
}) => {
  const workshopOptions = (optionsData?.workshops ?? []).map(
    ({ id, name }) => ({ value: id, label: name }),
  );
  const locationOptions = (optionsData?.locations ?? []).map(
    ({ id, name }) => ({ value: id, label: name }),
  );
  const menuCategoryOptions = (optionsData?.menu_categories ?? []).map(
    ({ id, name }) => ({ value: id, label: name }),
  );

  const selector = formValueSelector("techCardForm");
  const output = useSelector((state) => selector(state, "output"));
  const totalCost = useSelector((state) => selector(state, "total_cost"));
  const menuItem = useSelector((state) => selector(state, "menu_item"));
  const isWeighted = useSelector((state) => selector(state, "is_weighted"));
  const modifiers = useSelector((state) => selector(state, "menu_item.menu_item_modifier_groups"));

  const [modifiersCache, setModifiersCache] = useState();

  useEffect(() => {
    if (isWeighted) {
      changeField("tech_card.unit", "kg");
      changeField("tech_card.unit_weight", 1);
      changeField("tech_card.output_quantity", output / 1000);
      changeField("menu_item.cost", totalCost * (output ? 1000 / output : 0));
    } else {
      changeField("tech_card.unit", "pieces");
      changeField("tech_card.unit_weight", output / 1000);
      changeField("tech_card.output_quantity", 1);
      changeField("menu_item.cost", totalCost);
    }
  }, [isWeighted, output, totalCost]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        formItemProps={formItemLayout}
        name="name"
        label={t("menu.TechCards.Form.Name")}
        component={renderInput}
        validate={required}
      />
      <Field
        formItemProps={formItemLayout}
        name="category_id"
        label={t("menu.TechCards.Form.Category.Label")}
        placeholder={t("menu.TechCards.Form.Category.Placeholder")}
        component={renderSelect}
        style={{ width: 250 }}
        validate={required}
        options={menuCategoryOptions}
        showSearch
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <LinkContainer onMouseDown={(e) => e.preventDefault()}>
              <Button
                type="link"
                onClick={onCategoryCreate}
              >
                {t("menu.TechCards.Form.Category.DropdownButton")}
              </Button>
            </LinkContainer>
          </div>
        )}
      />
      <Field
        formItemProps={formItemLayout}
        name="tech_card.workshop_id"
        label={t("menu.TechCards.Form.Workshop.Label")}
        placeholder={t("menu.TechCards.Form.Workshop.Placeholder")}
        component={renderSelect}
        style={{ width: 250 }}
        options={workshopOptions}
        validate={required}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <LinkContainer onMouseDown={(e) => e.preventDefault()}>
              <Button type="link" onClick={onWorkshopCreate}>
                {t("menu.TechCards.Form.Workshop.DropdownButton")}
              </Button>
            </LinkContainer>
          </div>
        )}
      />
      <Field
        formItemProps={formItemLayout}
        name="description"
        label={t("Description")}
        component={renderTextarea}
      />
      <Field
        formItemProps={formItemLayout}
        name="color"
        label={t("Color")}
        component={ColorInput}
      />

      <br />

      <Field
        formItemProps={formItemLayout}
        name="tech_card.process_duration"
        label={t("menu.TechCards.Form.ProcessDuration.Label")}
        placeholder={t("menu.TechCards.Form.ProcessDuration.Placeholder")}
        renderExtraFooter={() => t("menu.TechCards.Form.ProcessDuration.Placeholder")}
        parse={(v) => v.diff(moment().startOf("day"), "seconds")}
        format={(v) => (v || v === 0 ? moment().startOf("day").add(v, "seconds") : "")}
        showNow={false}
        component={renderTimePicker}
      />

      <Field
        formItemProps={formItemLayout}
        name="tech_card.process_description"
        label={t("menu.TechCards.Form.ProcessDescription.Label")}
        component={renderTextarea}
      />

      <Typography.Title level={5}>
        {t("menu.TechCards.Form.TechCardProducts.Title")}
      </Typography.Title>
      <FieldArray
        t={t}
        name="tech_card.tech_card_products"
        changeField={changeField}
        component={TechCardProductsFields}
        optionsData={optionsData}
        allowEmpty
        emptyText={t("menu.TechCards.Form.TechCardProducts.EmptyLabel")}
        onIngridientCreate={onIngridientCreate}
      />

      <br />

      <Form.Item {...buttonsLayout}>
        <Typography.Title level={5}>{t("menu.TechCards.Form.Modifiers.Title")}</Typography.Title>
        <FieldArray
          t={t}
          name="menu_item.menu_item_modifier_groups"
          component={TechCardModifierGroupsFields}
          optionsData={optionsData}
          disabled={isWeighted}
        />
      </Form.Item>

      <Form.Item {...buttonsLayout}>
        <PriceFields
          t={t}
          disableCost
          prefix="menu_item"
          changeField={changeField}
          modifier={menuItem}
        />
      </Form.Item>

      <Field
        formItemProps={checkboxLayout}
        name="is_weighted"
        disabled={initialValues.is_produced}
        checkboxLabel={t("menu.TechCards.Form.IsWeighted.Label")}
        component={renderCheckbox}
        extra={t("menu.TechCards.Form.IsWeighted.Extra")}
        onChange={(e) => {
          if (e.target.checked) {
            setModifiersCache(modifiers);
            changeField("menu_item.menu_item_modifier_groups", null);
          } else {
            changeField("menu_item.menu_item_modifier_groups", modifiersCache);
          }
        }}
      />

      <Field
        formItemProps={checkboxLayout}
        name="is_produced"
        disabled={initialValues.is_produced}
        checkboxLabel={t("menu.TechCards.Form.IsProduced.Label")}
        extra={t("menu.TechCards.Form.IsProduced.Help")}
        component={renderCheckbox}
      />

      <Field
        formItemProps={formItemLayout}
        name="menu_item.limited_location_availability"
        label={t("menu.TechCards.Form.LocationAvailability.Label")}
        component={renderSelect}
        placeholder={t("menu.TechCards.Form.LocationAvailability.Placeholder")}
        mode="multiple"
        options={locationOptions}
        extra={t("menu.TechCards.Form.LocationAvailability.Extra")}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <Button
              type="link"
              onClick={() => changeField("menu_item.limited_location_availability", null)}
            >
              {t("menu.TechCards.Form.LocationAvailability.DropdownButton")}
            </Button>
          </div>
        )}
      />

      {error && (
        <Form.Item {...errorLayout}>
          <Alert type="error" message={error} />
        </Form.Item>
      )}

      <Form.Item {...buttonsLayout}>
        <Space>
          <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
            {t("Save")}
          </Button>
          {archivingOrUnarchivingButton}
        </Space>
      </Form.Item>
    </Form>
  );
};

TechCardForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  optionsData: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  onIngridientCreate: PropTypes.func.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  archivingOrUnarchivingButton: PropTypes.element,
  onWorkshopCreate: PropTypes.func,
};

export default reduxForm({
  form: "techCardForm",
  initialValues: {
    menu_item: {},
    tech_card: {
      tech_card_products: [],
    },
  },
})(TechCardForm);
