import gql from "graphql-tag";

export const MOVINGS_QUERY = gql`
  query GetMovings($limit: Int, $offset: Int, $filter: movings_bool_exp) @hasura {
    movings(limit: $limit, offset: $offset, where: $filter, order_by: {id: desc}) {
      id
      number
      datetime
      description
      completed
      location_to {
        id
        name
      }
      location_from {
        id
        name
      }
      moving_products(order_by: {id: asc}) {
        id
        cost
        quantity
        product {
          id
          name
          unit
        }
      }
    }
    locations(order_by: {id: asc}) {
      id
      name
    }
    movings_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
`;

export const EDIT_MOVING_QUERY = gql`
  query EditMovingQuery($id: Int!) @hasura {
    movings_by_pk(id: $id)  {
      id
      completed
      location_to_id
      location_from_id
      description
      datetime
      moving_products {
        id
        product_id
        quantity
      }
    }
  }
`;

export const CREATE_MOVING_MUTATION = gql`
  mutation CreateMoving($data: MovingInput!) {
    createMoving(data: $data) {
      id
    }
  }
`;

export const COMPLETE_MOVING_MUTATION = gql`
  mutation CompleteMoving($moving_id: Int!) {
    completeMoving(moving_id: $moving_id) {
      id
    }
  }
`;
