import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import Page from "components/Page";

import SupplierForm from "./components/SupplierForm";
import { EDIT_SUPPLIER_QUERY, UPDATE_SUPPLIER_MUTATION } from "./supplierQueries";

const EditSupplier = ({ match, history }) => {
  const supplierId = parseInt(match.params.id, 10);
  const { t } = useTranslation();

  const { data, loading } = useQuery(EDIT_SUPPLIER_QUERY, {
    variables: { id: supplierId },
    onError: notification.error,
  });

  const [updateSupplier, { error, loading: submitting }] = useMutation(UPDATE_SUPPLIER_MUTATION);

  if (loading) {
    return <Spin />;
  }

  const handleSubmit = (values) =>
    updateSupplier({ variables: { id: supplierId, data: values } })
      .then(() => history.goBack()).catch();

  return (
    <Page showBackIcon title={t("warehouse.Suppliers.EditPage.Title")}>
      <Row>
        <Col xs={24} md={18} lg={14}>
          <Card>
            <SupplierForm
              t={t}
              initialValues={data?.suppliers_by_pk}
              onSubmit={handleSubmit}
              submitting={submitting}
              error={error?.message}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

EditSupplier.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditSupplier;
