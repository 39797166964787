import { PAYMENT_METHOD_TYPE } from "constants/index";

export const ORDER_STATUS_COLORS = {
  created: "#f1f1f1",
  received: "#e7707d",
  processing: "#28cacc",
  on_delivery: "#4682b4",
  ready: "#83d160",
  served: "#ffce45",
  completed: "#008000",
  cancelled: "#fb3e44",
};

export const ORDER_LINE_STATUS_COLORS = {
  received: "#e7707d",
  processing: "#28cacc",
  ready: "#83d160",
  served: "#ffce45",
  created: "#bb1f1f",
  cancelled: "#fb3e44",
};

export const ORDER_STATUS = {
  CREATED: "created",
  RECEIVED: "received",
  PROCESSING: "processing",
  READY: "ready",
  ON_DELIVERY: "on_delivery",
  SERVED: "served",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};

export const ORDER_LINE_STATUS_ICONS = {
  created: "bell",
  received: "clock-circle",
  processing: "thunderbolt",
  ready: "check",
  served: "like",
  cancelled: "close",
};

export const PAYMENT_TYPE_ICONS = {
  [PAYMENT_METHOD_TYPE.CASH]: "wallet",
  [PAYMENT_METHOD_TYPE.CARD]: "credit-card",
  [PAYMENT_METHOD_TYPE.CUSTOM]: "api",
};

export const ORDER_SOURCE_ICONS = {
  callcenter: "phone",
  pos: "user",
  api: "api",
};

export const getDisplayNumber = ({ number, source }) => {
  switch (source) {
    case "callcenter": return `${number}C`;
    case "api": return `${number}A`;
    default: return number.toString();
  }
};
