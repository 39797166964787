import React from "react";
import moment from "moment";
import { Spin, Card, Statistic, Space } from "antd";
import PropTypes from "prop-types";

import { singletonCurrency } from "utils/helpers";

const TodayStatistics = ({
  data: { income, orderCount, avgPrice },
  loading,
  t,
}) => (
  <div>
    <Space style={{ marginBottom: 16 }}>
      <Card>
        <Statistic
          style={{ marginRight: 20 }}
          title={t("statistics.Sales.TodayStatistics.Today")}
          value={moment().format("LL")}
        />
      </Card>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Card>
            <Statistic
              style={{ marginRight: 20 }}
              title={t("statistics.Sales.TodayStatistics.revenue")}
              value={income}
              precision={2}
              suffix={singletonCurrency.getCurrency()}
            />
          </Card>
          <Card>
            <Statistic
              style={{ marginRight: 20 }}
              title={t("statistics.Sales.TodayStatistics.orders")}
              value={orderCount || 0}
            />
          </Card>
          <Card>
            <Statistic
              style={{ marginRight: 20 }}
              title={t("statistics.Sales.TodayStatistics.averageCheck")}
              value={avgPrice}
              precision={2}
              suffix={singletonCurrency.getCurrency()}
            />
          </Card>
        </>
      )}
    </Space>
  </div>
);

TodayStatistics.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default TodayStatistics;
