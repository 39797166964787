import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PageHeader, notification, Spin } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  TERMINALS_QUERY,
  CREATE_TERMINAL_MUTATION,
  LOCATIONS_AND_WORKSHOPS_QUERY,
} from "./terminalQueries";
import TerminalForm from "./components/TerminalForm";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const NewTerminal = ({ history }) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(LOCATIONS_AND_WORKSHOPS_QUERY, {
    onError: notification.error,
  });
  const [createTerminal] = useMutation(CREATE_TERMINAL_MUTATION, {
    refetchQueries: [{ query: TERMINALS_QUERY }],
  });

  if (loading) return <Loader />;

  const handleSubmit = (values) => {
    createTerminal({ variables: { data: values } })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("access.Terminal.AddPage.Title")}
      />
      <TerminalForm
        locations={data ? data.locations : []}
        workshops={data ? data.workshops : []}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

NewTerminal.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewTerminal;
