/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";

import ModifierItemsTable from "./ModifierItemsTable";
import UsageInTechCardsTable from "./UsageInTechCardsTable";

const ModifierDetailsPanel = ({ t, modifierGroupId }) => (
  <Tabs size="small" centered>
    <Tabs.TabPane tab={t("menu.Modifiers.DetailsPanel.ItemsTab.Title")} key="ingredients">
      <ModifierItemsTable modifierGroupId={modifierGroupId} />
    </Tabs.TabPane>
    <Tabs.TabPane tab={t("menu.Modifiers.DetailsPanel.UsageTab.Title")} key="usage">
      <UsageInTechCardsTable modifierGroupId={modifierGroupId} />
    </Tabs.TabPane>
  </Tabs>
);

ModifierDetailsPanel.propTypes = {
  modifierGroupId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default ModifierDetailsPanel;
