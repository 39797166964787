import gql from "graphql-tag";

export const DELIVERIES_QUERY = gql`
  query GetDeliveries($limit: Int!, $offset: Int!, $filter: deliveries_bool_exp) @hasura {
    deliveries(limit: $limit, offset: $offset, where: $filter, order_by: {number: desc}) {
      id
      number
      datetime
      description
      completed
      location {
        id
        name
      }
      supplier {
        id
        name
      }
      finance_account {
        id
        name
      }
      delivery_products {
        id
        quantity
        price
      }
    }
    suppliers(order_by: {id: asc}) {
      id
      name
    }
    locations(order_by: {id: asc}) {
      id
      name
    }
    deliveries_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
`;

export const DELIVERY_PRODUCTS_QUERY = gql`
  query GetDeliveryProducts($id: Int!) @hasura {
    delivery_products(order_by: {id: asc}, where: {delivery_id: {_eq: $id}}) {
      id
      product {
        id
        name
        unit
      }
      quantity
      price
    }
  }
`;

export const DELIVERY_OPTIONS_QUERY = gql`
  query DeliveryOptions @hasura {
    suppliers(order_by: {id: asc}) {
      id
      name
    }
    finance_accounts(order_by: {id: asc}) {
      id
      name
    }
  }
`;

export const SUPPLIER_PRODUCTS_QUERY = gql`
  query SupplierProducts($supplierId: Int!) {
    supplierProducts(supplierId: $supplierId) {
      product_id
      price
    }
  }
`;

export const EDIT_DELIVERY_QUERY = gql`
  query EditDelivery($id: Int!) @hasura {
    deliveries_by_pk(id: $id) {
      id
      datetime
      description
      completed
      location_id
      supplier_id
      finance_account_id
      delivery_products {
        id
        product_id
        quantity
        price
      }
    }
  }
`;

export const CREATE_DELIVERY_MUTATION = gql`
  mutation CreateDelivery($data: DeliveryInput!) {
    createDelivery(data: $data) {
      id
    }
  }
`;

export const UPDATE_DELIVERY_MUTATION = gql`
  mutation UpdateDelivery($delivery_id: Int!, $data: DeliveryInput!) {
    updateDelivery(delivery_id: $delivery_id, data: $data) {
      id
    }
  }
`;

export const COMPLETE_DELIVERY_MUTATION = gql`
  mutation CompleteDelivery($delivery_id: Int!) {
    completeDelivery(delivery_id: $delivery_id) {
      id
    }
  }
`;
