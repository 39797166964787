import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button, Divider } from "antd";

import { renderInput, renderDatePicker, renderSelect } from "components/FormItem";
import { required } from "utils/formValidations";
import colors from "theme/colors";
import CheckboxGroupField from "components/CheckboxGroupField";

import TimeWorkPromotionFields from "./TimeWorkPromotionFields";
import PromotionConditionFields from "./PromotionConditionFields";
import BonusItemFields from "./BonusItemFields";
import {
  CONDITION_TYPE_OPTIONS,
  DATE_WEEK,
  RESULT_TYPE_OPTIONS,
} from "../constants";

const checkboxSelected = (value) =>
  (value && value.length < 1 ? "Нужно выбрать день недели" : undefined);

const StyledForm = styled(Form)`
  padding: 20px 40px;
  .buttons-layout {
    margin-right: 10px;
  }
  .divider {
    background-color: ${colors.lightGrey};
  }
  .row {
    margin-bottom: 20px;
  }
`;

const BorderRow = styled(Row)`
  border-bottom: 1px solid ${colors.lightGrey};
  margin-bottom: 10px;
`;

const formItem = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  labelAlign: "left",
  colon: false,
  style: {
    marginTop: 8,
    marginBottom: 8,
  },
};

const formItemArrayLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
  labelAlign: "left",
  colon: false,
  style: {
    marginTop: 8,
    marginBottom: 8,
  },
};

const buttonsLayout = {
  wrapperCol: {
    sm: { span: 16, offset: 5 },
  },
};

const PromotionForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  menuItems,
  deleteButton,
}) => {
  const formSelector = formValueSelector("promotionForm");
  const resultType = useSelector((state) => formSelector(state, "result_type"));
  const conditionType = useSelector((state) => formSelector(state, "condition_type"));
  const conditions = useSelector((state) => formSelector(state, "promotion_conditions"));

  const menuItemsOptions = menuItems.map((menuItem) => ({
    value: menuItem.id,
    label: menuItem.name,
  }));

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Row className="row">
        <Col>
          <Field
            name="name"
            label={t("marketing.Promotion.Form.Name")}
            component={renderInput}
            validate={required}
            formItemProps={formItem}
          />
          <Field
            name="start_date"
            label={t("marketing.Promotion.Form.PromotionStartDate")}
            component={renderDatePicker}
            validate={required}
            formItemProps={formItem}
          />
          <Field
            name="end_date"
            label={t("marketing.Promotion.Form.PromotionEndDate")}
            component={renderDatePicker}
            validate={required}
            formItemProps={formItem}
          />
        </Col>
      </Row>

      <BorderRow>
        <h2>{t("marketing.Promotion.PromotionCondition.TermsOfAction")}</h2>
      </BorderRow>
      <Row className="row">
        <Col>
          {conditions && conditions.length > 1 && (
            <Field
              name="condition_type"
              label={t("marketing.Promotion.ChooseBonusItem")}
              component={renderSelect}
              formItemProps={formItem}
              options={CONDITION_TYPE_OPTIONS}
              validate={required}
            />
          )}
          <Form.Item label={t("marketing.Promotion.PromotionCondition.CategoriesAndProducts")} {...formItemArrayLayout}>
            <FieldArray
              name="promotion_conditions"
              component={PromotionConditionFields}
              menuItemsOptions={menuItemsOptions}
              conditionType={conditionType}
              validate={required}
              t={t}
            />
          </Form.Item>
          <Field
            name="active_weekdays"
            label={t("marketing.Promotion.PromotionCondition.PromotionDays")}
            component={CheckboxGroupField}
            formItemProps={formItemArrayLayout}
            checkboxs={DATE_WEEK}
            validate={[required, checkboxSelected]}
          />
          <Form.Item label={t("marketing.Promotion.PromotionCondition.PromotionHours")} {...formItemArrayLayout}>
            <FieldArray
              name="active_hours"
              component={TimeWorkPromotionFields}
            />
          </Form.Item>
        </Col>
      </Row>

      <BorderRow>
        <h2>{t("marketing.Promotion.PromotionResult.PromotionResult")}</h2>
      </BorderRow>
      <Row className="row">
        <Col>
          <Field
            name="result_type"
            label={t("marketing.Promotion.PromotionResult.WhatGetCustomer")}
            placeholder={t("marketing.Promotion.PromotionResult.ChoosePromotion")}
            component={renderSelect}
            validate={required}
            options={RESULT_TYPE_OPTIONS}
            formItemProps={formItem}
          />
          {resultType === "product" && (
            <>
              <Form.Item
                label={t("marketing.Promotion.PromotionResult.BonusItemOptions")}
                {...formItemArrayLayout}
              >
                <FieldArray
                  name="promotion_products"
                  component={BonusItemFields}
                  menuItemsOptions={menuItemsOptions}
                  t={t}
                />
              </Form.Item>
              <Field
                name="bonus_items_max_count"
                label={t("marketing.Promotion.PromotionResult.NumberBonusItems")}
                component={renderInput}
                formItemProps={formItem}
                type="number"
                addonAfter="шт."
              />
            </>
          )}
          {resultType === "discount" && (
            <Field
              name="discount"
              label={t("marketing.Promotion.PromotionResult.FixedDiscountAmount")}
              component={renderInput}
              validate={required}
              formItemProps={formItem}
              addonAfter="%"
              type="number"
              extra={t("marketing.Promotion.PromotionResult.Extra")}
            />
          )}
        </Col>
      </Row>

      <Divider className="divider" />
      <Row>
        <Col>
          <Form.Item {...buttonsLayout}>
            <Button
              type="primary"
              htmlType="submit"
              className="buttons-layout"
              disabled={pristine || submitting}
            >
              {t("Save")}
            </Button>
            {deleteButton}
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

PromotionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
  deleteButton: PropTypes.element,
};

export default reduxForm({
  form: "promotionForm",
  initialValues: {
    condition_type: "or",
    result_type: "product",
  },
})(PromotionForm);
