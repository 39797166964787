import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Products from "./products";
import Suppliers from "./suppliers";
import Deliveries from "./deliveries";
import Productions from "./productions";
import Movings from "./movings";
import WriteOffs from "./writeOffs";
import Inventories from "./inventories";

const Container = () => (
  <Switch>
    <Route path="/warehouse/products" component={Products} />,
    <Route path="/warehouse/suppliers" component={Suppliers} />,
    <Route path="/warehouse/deliveries" component={Deliveries} />,
    <Route path="/warehouse/productions" component={Productions} />,
    <Route path="/warehouse/movings" component={Movings} />,
    <Route path="/warehouse/write-offs" component={WriteOffs} />,
    <Route path="/warehouse/inventories" component={Inventories} />,
    <Redirect to="/warehouse/products" />
  </Switch>
);

export default Container;
