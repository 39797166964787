import React from "react";
import axios from "axios";
import styled from "styled-components";
import { notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import AccounForm from "./components/AccountForm";
import PasswordForm from "./components/PasswordForm";
import { ACCOUNT_QUERY, UPDATE_ACCOUNT_QUERY } from "./accountQueries";

const Title = styled.h2`
  padding: 20px 40px 0 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const Account = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(ACCOUNT_QUERY, {
    onError: notification.error,
  });

  const [updateAccount] = useMutation(UPDATE_ACCOUNT_QUERY, {
    onError: notification.error,
  });

  if (loading) {
    return <Loader />;
  }

  const handleSubmit = ({ id, ...values }) =>
    updateAccount({ variables: { id, data: values } })
      .then(() => notification.success({ message: t("account.AccountSuccessfullyUpdated") }))
      .catch(parseFormErrors);

  const handlePasswordSubmit = (values) =>
    axios
      .put("/admin_api/me/password", values)
      .then(() => notification.success({ message: t("account.PasswordSuccessfullyChanged") }))
      .catch(parseFormErrors);

  return (
    <div>
      <Title>{t("account.SettingsAccount")}</Title>
      <AccounForm t={t} initialValues={data && data.current_user_view[0]} onSubmit={handleSubmit} />
      <PasswordForm t={t} onSubmit={handlePasswordSubmit} />
    </div>
  );
};

export default Account;
