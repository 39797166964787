import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import styled from "styled-components";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";

import { required } from "utils/formValidations";
import { renderSelect } from "components/FormItem";

const DeleteButton = styled(Button)`
  margin-right: 10px;
`;

const FlexCol = styled(Col)`
  height: 20px;
  display: flex;
  align-items: center;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  style: { marginTop: 0, marginBottom: 0 },
};

const BonusItemFields = ({ t, fields, menuItemsOptions }) => {
  if (fields.length === 0) fields.push({});

  return (
    <div>
      {fields.map((promotion_product, index) => (
        <div key={index}>
          <Row gutter={12}>
            <Col span={14}>
              <Field
                name={`${promotion_product}.menu_item_id`}
                placeholder={t("marketing.Promotion.ChooseBonusItem")}
                component={renderSelect}
                validate={required}
                formItemProps={formItemLayout}
                options={menuItemsOptions}
              />
            </Col>

            <Col span={6}>
              {fields.length > 1 && (
                <DeleteButton
                  type="danger"
                  onClick={() => fields.remove(index)}
                >
                  <DeleteOutlined />
                </DeleteButton>
              )}
              {fields.length - 1 === index && (
                <Button onClick={() => fields.push({})}>
                  <PlusOutlined />
                </Button>
              )}
            </Col>
          </Row>
          {fields.length > 1 && fields.length - 1 !== index && (
            <Row>
              <FlexCol>
                <span>{t("or")}</span>
              </FlexCol>
            </Row>
          )}
        </div>
      ))}
    </div>
  );
};

BonusItemFields.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  menuItemsOptions: PropTypes.array.isRequired,
};

export default BonusItemFields;
