import React from "react";
import { Route, Switch } from "react-router-dom";

import Transactions from "./Transactions";

const Container = () => (
  <Switch>
    <Route path="/finance/transactions" component={Transactions} />
  </Switch>
);

export default Container;
