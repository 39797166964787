import React from "react";
import styled from "styled-components";
import { Table, Row, Col, Button, notification } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { ACCOUNT_COLUMNS } from "./constants";
import { FINANCE_ACCOUNTS_QUERY } from "./accountsQueries";

const Container = styled.div`
  padding: 12px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Accounts = ({ history }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(FINANCE_ACCOUNTS_QUERY, {
    error: notification.error,
    fetchPolicy: "cache-and-network",
  });

  return (
    <Container>
      <StyledRow>
        <FlexCol span={4} offset={20}>
          <Button
            type="primary"
            onClick={() => history.push("/finance/accounts/new")}
          >
            {t("Add")}
          </Button>
        </FlexCol>
      </StyledRow>
      <Table
        rowKey="id"
        loading={loading}
        columns={ACCOUNT_COLUMNS}
        dataSource={data ? data.financeAccounts : []}
      />
    </Container>
  );
};

Accounts.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Accounts;
