import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Button, Alert } from "antd";

import { renderInput } from "components/FormItem";
import { required } from "utils/formValidations";

const buttonLayout = {
  wrapperCol: {
    sm: { span: 14, offset: 10 },
  },
};

const CompanySettingsForm = ({ handleSubmit, pristine, submitting, error, t }) => (
  <Form onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}
    <Field
      name="company_name"
      label={t("settings.General.Form.CompanyName")}
      component={renderInput}
      validate={required}
    />
    <Form.Item {...buttonLayout}>
      <Button
        type="primary"
        htmlType="submit"
        disabled={pristine || submitting}
      >
        {t("Save")}
      </Button>
    </Form.Item>
  </Form>
);

CompanySettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "companySettingsForm",
})(CompanySettingsForm);
