import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Button, Alert } from "antd";

import { renderInput, buttonLayout } from "components/FormItem";
import { requiredText } from "utils/formValidations";

const ApiKeyForm = ({
  t, handleSubmit, pristine, submitting, error,
}) => (
  <Form onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}
    <Field
      name="name"
      label={t("access.ApiKeys.Form.Name")}
      component={renderInput}
      validate={requiredText}
    />

    <Form.Item {...buttonLayout}>
      <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
        {t("Create")}
      </Button>
    </Form.Item>
  </Form>
);

ApiKeyForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default reduxForm({
  form: "apiKeyForm",
})(ApiKeyForm);
