import React from "react";
import { Table, Input, Row, Col, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from "use-query-params";
import { useLazyQuery } from "@apollo/client";

import ConnectData from "connect.container";

import FilterPanelWithQuery from "components/FilterPanelWithQuery";
import Page from "components/Page";
import { SOURCE_OPTIONS } from "constants/index";

import { EMPLOYEES_QUERY } from "./employeeQueries";
import { EMPLOYEES_COLUMNS } from "./constants";

const findEmployees = (employees, search) =>
  (search && search.length > 0
    ? employees.filter(({ name }) => name.toLowerCase().includes(search))
    : employees);

const Employees = () => {
  const { t } = useTranslation();
  const { locations } = ConnectData.useContainer();

  const [search, setSearch] = useQueryParam("search", StringParam);

  const [getEmployees, employeesQuery] = useLazyQuery(EMPLOYEES_QUERY, {
    onError: notification.error,
  });

  const employees = employeesQuery.data ? employeesQuery.data.employeesStats : [];
  const handleFilter = ({ time, locationId, source }) =>
    getEmployees({
      variables: {
        filters: {
          from: time.from.format(),
          to: time.to.format(),
          location_id: locationId,
        },
        source,
      },
    });

  return (
    <Page
      headerContent={(
        <Row justify="space-between">
          <Col>
            <FilterPanelWithQuery
              locations={locations}
              souce
              onFilter={handleFilter}
              initialData={{ time: { value: "month" } }}
              sourceOptions={SOURCE_OPTIONS}
            />
          </Col>
          <Col>
            <Input.Search
              placeholder={t("QuickSearch")}
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
            />
          </Col>
        </Row>
      )}
    >
      <Table
        rowKey="id"
        columns={EMPLOYEES_COLUMNS}
        loading={employeesQuery.loading}
        dataSource={findEmployees(employees, search)}
        pagination={false}
      />
    </Page>
  );
};

export default Employees;
