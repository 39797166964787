import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "theme/colors";
import { formatPrice } from "utils/helpers";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
`;

export const getAccountName = (account) =>
  (account.name.startsWith("salempos_generated")
    ? i18n.t(`generated:finance_account.${account.name}`, { location: account.location?.name })
    : account.name);

export const ACCOUNT_COLUMNS = [
  {
    title: i18n.t("finance.Account.Columns.Name"),
    dataIndex: "name",
    render: (_, record) => (
      record.name.startsWith("salempos_generated")
        ? (
          <>
            {getAccountName(record)}{" "}
            <i style={{ fontSize: 14, color: colors.grey }}>
              {i18n.t("SalemPOSGenerated")}
            </i>
          </>
        ) : (
          <StyledLink to={`/finance/accounts/${record.id}`}>
            {record.name}
          </StyledLink>
        )
    ),
  },
  {
    title: i18n.t("finance.Account.Columns.CurrentBalance"),
    dataIndex: "current_balance",
    render: formatPrice,
  },
];

export const TYPE_OPTIONS = [
  { value: "cashless_payments", label: "finance.Account.CashlessPayments" },
  { value: "bank_card", label: i18n.t("finance.Account.BankCard") },
  { value: "cash", label: i18n.t("finance.Account.Cash") },
];
