import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import Page from "components/Page";

import WriteOffForm from "./components/WriteOffForm";
import { EDIT_WRITE_OFF_QUERY } from "./writeOffQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

import { contentPathForProduct } from "../shared/utils";

const EditWriteOff = ({ match }) => {
  const { t } = useTranslation();
  const writeOffId = parseInt(match.params.id, 10);

  const { data, loading } = useQuery(EDIT_WRITE_OFF_QUERY, {
    variables: { id: writeOffId },
    onError: notification.error,
  });
  const optionsQuery = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });

  const initialWriteOff = useMemo(() => data && optionsQuery.data && {
    ...data.write_offs_by_pk,
    write_off_products: data.write_offs_by_pk.write_off_products.map((mp) => {
      const product = optionsQuery.data.products.find((p) => p.id === mp.product_id);
      return { ...mp, product, content_path: contentPathForProduct(product) };
    }),
  }, [data, optionsQuery.data]);

  if (loading || optionsQuery.loading) {
    return <Spin />;
  }

  return (
    <Page showBackIcon title={t("warehouse.WriteOffs.DetailPage.Title")}>
      <Card>
        <WriteOffForm
          t={t}
          initialValues={initialWriteOff}
          optionsData={optionsQuery.data}
        />
      </Card>
    </Page>
  );
};

EditWriteOff.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditWriteOff;
