import React from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Alert, Button } from "antd";
import { reduxForm, Field } from "redux-form";

import { required } from "utils/formValidations";
import { renderInput, renderSelect } from "components/FormItem";
import { UNIT_OPTIONS } from "constants/index";

const ItemForm = ({ t, handleSubmit, error, pristine, submitting }) => (
  <Form onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}
    <Field
      name="name"
      label={t("onboarding.ItemForm.Name.Label")}
      validate={required}
      component={renderInput}
    />
    <Field
      name="category_name"
      label={t("onboarding.ItemForm.Category.Label")}
      validate={required}
      component={renderInput}
    />
    <Field
      name="price"
      label={t("onboarding.ItemForm.Price.Label")}
      validate={required}
      component={renderInput}
    />
    <Field
      name="unit"
      label={t("onboarding.ItemForm.Unit.Label")}
      validate={required}
      component={renderSelect}
      options={UNIT_OPTIONS}
    />
    <Form.Item wrapperCol={{ offset: 10 }}>
      <Button
        disabled={pristine || submitting}
        htmlType="submit"
        type="primary"
      >
        {t("onboarding.Buttons.Next")}
      </Button>
    </Form.Item>
  </Form>
);

ItemForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  error: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({ form: "itemForm" })(ItemForm);
