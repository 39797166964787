import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import Page from "components/Page";

import MovingForm from "./components/MovingForm";
import {
  EDIT_MOVING_QUERY,
  COMPLETE_MOVING_MUTATION,
} from "./movingQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

import { contentPathForProduct } from "../shared/utils";

const EditMoving = ({ match, history }) => {
  const { t } = useTranslation();
  const movingId = parseInt(match.params.id, 10);

  const { data, loading } = useQuery(EDIT_MOVING_QUERY, {
    variables: { id: movingId },
    onError: notification.error,
  });
  const optionsQuery = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const [completeMoving] = useMutation(COMPLETE_MOVING_MUTATION);

  const initialMoving = useMemo(() => data && optionsQuery.data && {
    ...data.movings_by_pk,
    moving_products: data.movings_by_pk.moving_products.map((mp) => {
      const product = optionsQuery.data.products.find((p) => p.id === mp.product_id);
      return { ...mp, product, content_path: contentPathForProduct(product) };
    }),
  }, [data, optionsQuery.data]);

  if (loading || optionsQuery.loading) {
    return <Spin />;
  }

  const handleSubmit = () =>
    completeMoving({ variables: { moving_id: movingId } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("warehouse.Movings.DetailPage.Title")}>
      <Card>
        <MovingForm
          t={t}
          initialValues={initialMoving}
          optionsData={optionsQuery.data}
          onSubmit={handleSubmit}
        />
      </Card>
    </Page>
  );
};

EditMoving.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditMoving;
