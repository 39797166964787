import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PageHeader, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import { UPDATE_FINANCE_ACCOUNT_MUTATION, FINANCE_ACCOUNT_QUERY } from "./accountsQueries";
import AccountForm from "./components/AccountForm";
import { FINANCE_TRANSACTIONS_QUERY } from "../transactions/transactionsQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditAccount = ({ match, history }) => {
  const financeAccountId = parseInt(match.params.id, 10);
  const { t } = useTranslation();

  const { data, loading } = useQuery(FINANCE_ACCOUNT_QUERY, {
    variables: { id: financeAccountId },
    error: notification.error,
  });

  const [updateAccount] = useMutation(UPDATE_FINANCE_ACCOUNT_MUTATION, {
    refetchQueries: [{
      query: FINANCE_TRANSACTIONS_QUERY,
    }],
  });

  const handleSubmit = ({ id, name }) =>
    updateAccount({ variables: { id, data: { name } } })
      .then(() => history.goBack())
      .catch(notification.error);

  if (loading) return <Loader />;

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack("/finance/accounts")}
        title={t("finance.Account.EditPage.Title")}
      />
      <AccountForm
        initialValues={data && data.financeAccount}
        onSubmit={handleSubmit}
        t={t}
      />
    </div>
  );
};

EditAccount.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditAccount;
