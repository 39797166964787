import React, { useState, useEffect } from "react";
import { Row, Col, Button, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { reset } from "redux-form";
import { useDispatch } from "react-redux";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DateTime } from "luxon";

import FilterPanelWithQuery from "components/FilterPanelWithQuery";
import { LIMIT_PAGE } from "constants/index";

import { TRANSACTION_TYPES } from "./constants";
import NewTransactionModal from "./components/NewTransactionModal";
import {
  FINANCE_TRANSACTIONS_QUERY,
  CREATE_FINANCE_TRANSACTION_QUERY,
} from "./transactionsQueries";
import TransactionsTable from "./components/TransactionsTable";

export const Container = styled.div`
  padding: 12px;
`;

export const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

export const transactionsGroupDate = (transactions) =>
  transactions.map((item, i, transactionsArr) => {
    const time = DateTime.fromMillis(item.time);
    if (i === 0 || !DateTime.fromMillis(transactionsArr[i - 1].time).hasSame(time, "day")) {
      return { ...item, date: time };
    }
    return item;
  });

const Transactions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [showNewTransactionPanel, setShowNewTransactionPanel] = useState(false);
  const [timeFilter, setTimeFilter] = useState(null);
  const [getFinanceTransactions, { loading, data, refetch }] = useLazyQuery(
    FINANCE_TRANSACTIONS_QUERY, { onError: notification.error },
  );
  const [createFinanceTransaction] = useMutation(CREATE_FINANCE_TRANSACTION_QUERY);

  useEffect(_.throttle(() => {
    if (timeFilter) {
      getFinanceTransactions({
        variables: {
          limit: LIMIT_PAGE,
          offset: page * LIMIT_PAGE - LIMIT_PAGE,
          time: { gte: timeFilter.from, lt: timeFilter.to },
        },
      });
    }
  }, 200), [timeFilter, page]);

  const handleHide = () => {
    setShowNewTransactionPanel(false);
    dispatch(reset("transactionForm"));
  };

  const handleSubmit = ({ account_id, amount, type, transfer_to_amount, ...values }) => {
    const fieldTransferToAmount = type === TRANSACTION_TYPES.TRANSFER ? {
      transfer_to_amount: parseFloat(transfer_to_amount),
    } : {};

    createFinanceTransaction({
      variables: {
        data: {
          ...values,
          amount: parseFloat(amount),
          type,
          ...fieldTransferToAmount,
        },
        account_id,
      },
    })
      .then(() => {
        setShowNewTransactionPanel(false);
        dispatch(reset("transactionForm"));
        refetch();
      })
      .catch(notification.error);
  };

  return (
    <Container>
      <StyledRow type="flex" justify="space-between">
        <Col xs={20}>
          <FilterPanelWithQuery
            hideLocationPicker
            onFilter={(values) => { if (timeFilter) { setPage(1); } setTimeFilter(values.time); }}
          />
        </Col>
        <Col>
          <Button
            onClick={() => setShowNewTransactionPanel(true)}
            type="primary"
            icon={<PlusOutlined />}
          />
        </Col>
      </StyledRow>
      <TransactionsTable
        pagination={{
          total: data && data.financeTransactionsCount.count,
          pageSize: LIMIT_PAGE,
          current: page,
          onChange: setPage,
          showSizeChanger: false,
        }}
        loading={loading}
        dataSource={transactionsGroupDate(data ? data.financeTransactions : [])}
        t={t}
      />
      <NewTransactionModal
        t={t}
        onSubmit={handleSubmit}
        onHide={handleHide}
        show={showNewTransactionPanel}
      />
    </Container>
  );
};

export default Transactions;
