import gql from "graphql-tag";

export const API_KEYS_QUERY = gql`
  query {
    getAPIKeys {
      id
      name
    }
  }
`;

export const CREATE_API_KEY_MUTATION = gql`
  mutation CreateApiKey($name: String!) {
    createAPIKey(name: $name) {
      apiKey {
        id
        name
      }
      token
    }
  }
`;

export const REFRESH_API_KEY_MUTATION = gql`
  mutation RefreshAPIKey($id: ID!) {
    refreshAPIKey(key_id: $id) {
      apiKey {
        id
        name
      }
      token
    }
  }
`;

export const DELETE_API_KEY_MUTATION = gql`
  mutation DeleteAPIKey($id: ID!) {
    deleteAPIKey(key_id: $id) {
      id
    }
  }
`;
