import i18n from "i18n";

export const templateOrder = {
  id: "3883478929900010",
  type: "in_store",
  display_number: "55",
  number: 55,
  notes: i18n.t("settings.Check.OrderTemplate.notes"),
  order_price_updates: [{
    order_lines_total: 2700,
    discount: 0,
    service_percent: 0.2,
    list_price: 3240,
  }],
  order_payments: [{
    amount: 100,
    type: "payment",
    payment_method_id: 1,
  }, {
    amount: 200,
    type: "payment",
    payment_method_id: 2,
  }, {
    amount: 100,
    type: "refund",
    payment_method_id: 2,
  }, {
    amount: 500,
    type: "payment",
    payment_method_id: 3,
  }],
  delivery_info: {
    payment_method_id: 2,
    cash_return: 1000,
    delivery_price: 200,
    address: {
      street: i18n.t("settings.Check.OrderTemplate.street"),
      house: "221b",
      notes: i18n.t("settings.Check.OrderTemplate.addressNotes"),
    },
  },
  customer: {
    name: i18n.t("settings.Check.OrderTemplate.customerName"),
    phone_number: "+7 777 777 77 77",
  },
  order_lines: [
    {
      id: 1,
      display_name: "Pizza",
      total_price: 1500,
      count: 1,
    },
    {
      id: 2,
      display_name: "Burger",
      total_price: 900,
      count: 1,
    },
    {
      id: 3,
      display_name: "Coca Cola",
      total_price: 600,
      count: 2,
    },
  ],
};

export const paymentMethods = [{
  id: 1,
  type: "cash",
  name: "salempos_generated_cash",
}, {
  id: 2,
  type: "card",
  name: "salempos_generated_card",
}, {
  id: 3,
  type: "custom",
  name: "Glovo",
}];
