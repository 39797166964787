import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { reducer as reduxFormReducer } from "redux-form";

const reducers = combineReducers({
  form: reduxFormReducer,
});

const store = createStore(reducers, {}, applyMiddleware(thunk));

export { store };
