import React from "react";
import PropTypes from "prop-types";
import { notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useLazyQuery } from "@apollo/client";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import Charts from "./components/Charts";
import { MENU_ITEMS_AND_LOCATIONS_QUERY, MENU_ITEMS_STATS_QUERY } from "./productQueries";

const StatsProduct = ({ match }) => {
  const productId = parseInt(match.params.id, 10);
  const { t } = useTranslation();

  const [getMenuItemsStats, menuItemsStats] = useLazyQuery(MENU_ITEMS_STATS_QUERY, {
    onError: notification.error,
  });

  const { data, loading } = useQuery(MENU_ITEMS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
    variables: { id: productId },
  });

  if (loading) return <Spin />;

  const handleFilter = ({ time, locationId }) => {
    getMenuItemsStats({
      variables: {
        filters: {
          location_id: locationId,
          from: time.from.format(),
          to: time.to.format(),
        },
        menu_item_id: productId,
      },
    });
  };

  const title = data && (data.menu_items[0].menu_item_group.name === data.menu_items[0].name
    ? data.menu_items[0].name : `${data.menu_items[0].menu_item_group.name} ${data.menu_items[0].name}`);

  return (
    <Page
      showBackIcon
      title={`${t("statistics.Products.StatsProduct.Title")} - ${title}`}
    >
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        onFilter={handleFilter}
        locations={data ? data.locations : []}
        initialData={{ time: { value: "month" } }}
      />
      {menuItemsStats.data ? (
        <Charts data={menuItemsStats.data} t={t} />
      ) : (
        <Spin />
      )}
    </Page>
  );
};

StatsProduct.propTypes = {
  match: PropTypes.object.isRequired,
};

export default StatsProduct;
