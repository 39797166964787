/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, notification, Typography, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { formatPrice } from "utils/helpers";
import { UNIT_LABELS_BY_VALUE } from "constants/index";

import PrepmealDetailsPanel from "../../prepmeals/components/PrepmealDetailsPanel";
import IngredientDetailsPanel from "../../ingredients/components/IngredientDetailsPanel";

import { MODIFIER_GROUP_QUERY } from "../modifiersQueries";

const ModifierItemsTable = ({ modifierGroupId }) => {
  const { t } = useTranslation();
  const [selectedPrepmeal, setSelectedPrepmeal] = useState();
  const [selectedIngredient, setSelectedIngredient] = useState();

  const { data, loading } = useQuery(MODIFIER_GROUP_QUERY, {
    variables: { id: modifierGroupId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const modifierGroup = data?.modifier_groups_by_pk;

  return (
    <>
      <Table
        size="small"
        rowKey="id"
        bordered
        loading={loading}
        dataSource={modifierGroup?.modifier_items
          .map((mi) => ({ ...mi, value: mi.product || mi.tech_card }))}
        pagination={false}
        footer={(() => (
          <>
            <div>
              <Typography.Text strong>
                {t("menu.Modifiers.ModifierItemsTable.Footer.MinSelection")}:&nbsp;
              </Typography.Text>
              {modifierGroup?.min_selection}
            </div>
            <div>
              <Typography.Text strong>
                {t("menu.Modifiers.ModifierItemsTable.Footer.MaxSelection")}:&nbsp;
              </Typography.Text>
              {modifierGroup?.max_selection}
            </div>
          </>
        ))}
      >
        <Table.Column
          title={t("menu.Modifiers.ModifierItemsTable.Columns.Name")}
          dataIndex="name"
          width="20%"
        />
        <Table.Column
          title={t("menu.Modifiers.ModifierItemsTable.Columns.Ingredient")}
          dataIndex="value"
          render={(value, record) => (value ? (
            <Typography.Link
              onClick={() => (record.product_id
                ? setSelectedIngredient(record.product)
                : setSelectedPrepmeal(record.tech_card))}
            >
              {value.name}{!record.product_id && `, ${t("ShortPrepmeal")}`}
            </Typography.Link>
          ) : <i>{t("NoSpending")}</i>)}
        />
        <Table.Column
          title={t("menu.Modifiers.ModifierItemsTable.Columns.Cost")}
          dataIndex="cost"
          render={formatPrice}
        />
        <Table.Column
          title={t("menu.Modifiers.ModifierItemsTable.Columns.Quantity")}
          dataIndex="quantity"
          render={(quantity, record) => (record.value
            ? `${quantity} ${UNIT_LABELS_BY_VALUE[record.value.unit]}` : "-")}
        />
        <Table.Column
          title={t("menu.Modifiers.ModifierItemsTable.Columns.Price")}
          dataIndex="price"
          render={formatPrice}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedPrepmeal?.name}
            &nbsp;
            <Link to={`/menu/prepmeals/${selectedPrepmeal?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedPrepmeal}
        onClose={() => setSelectedPrepmeal(null)}
        placement="bottom"
        height="60%"
      >
        {selectedPrepmeal && (
          <PrepmealDetailsPanel t={t} prepmealId={selectedPrepmeal.id} />
        )}
      </Drawer>
      <Drawer
        title={(
          <>
            {selectedIngredient?.name}
            &nbsp;
            <Link to={`/menu/ingredients/${selectedIngredient?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedIngredient}
        onClose={() => setSelectedIngredient(null)}
        placement="bottom"
        height="60%"
      >
        {selectedIngredient && (
          <IngredientDetailsPanel t={t} ingredientId={selectedIngredient.id} />
        )}
      </Drawer>
    </>
  );
};

ModifierItemsTable.propTypes = {
  modifierGroupId: PropTypes.number.isRequired,
};

export default ModifierItemsTable;
