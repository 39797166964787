import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Col, Drawer, notification, Row, Table, Typography,
} from "antd";
import { useQuery } from "@apollo/client";

import PrepmealDetailsPanel from "pages/menu/prepmeals/components/PrepmealDetailsPanel";
import IngredientDetailsPanel from "pages/menu/ingredients/components/IngredientDetailsPanel";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { stringifyModifiers } from "utils/helpers";

import { ORDER_SPENDINGS_QUERY } from "../ordersQueries";

const OrderSpendingsTable = ({ orderId }) => {
  const { t } = useTranslation();
  const [selectedPrepmeal, setSelectedPrepmeal] = useState();
  const [selectedIngredient, setSelectedIngredient] = useState();

  const { data, loading } = useQuery(ORDER_SPENDINGS_QUERY, {
    variables: { orderId }, onError: notification.error,
  });

  const handleProductSelect = (product) => {
    if (product.tech_card) {
      setSelectedPrepmeal(product.tech_card);
    } else {
      setSelectedIngredient(product);
    }
  };

  return (
    <Row justify="center">
      <Col span={23}>
        <Table
          rowKey="id"
          pagination={false}
          size="small"
          loading={loading}
          dataSource={data?.order_lines}
          expandable={{
            expandIconColumnIndex: -1,
            expandedRowKeys: data?.order_lines.map((ol) => ol.id),
            expandedRowRender: ((orderLine) => (
              <>
                <table>
                  <tbody>
                    {orderLine.order_line_product_spendings
                      .map(({ id, product, quantity }) => (
                        <tr key={id}>
                          <td style={{ paddingLeft: 20 }}>
                            <Typography.Link onClick={() => handleProductSelect(product)}>
                              {product.name}
                            </Typography.Link>
                          </td>
                          <td width="30%">
                            {parseFloat(quantity.toFixed(3))} {UNIT_LABELS_BY_VALUE[product.unit]}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )),
          }}
        >
          <Table.Column
            title={t("Item")}
            dataIndex="display_name"
            render={(name, { modifiers }) => (
              <Typography.Text>
                {name}
                {modifiers?.filter((m) => m.value.length > 0)
                  .map((modifier) => (
                    <div key={modifier.list_group_name} style={{ marginLeft: 10, fontSize: 12 }}>
                      {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
                    </div>
                  ))}
              </Typography.Text>
            )}
          />
          <Table.Column
            title={t("Quantity")}
            dataIndex="count"
            render={(count, { unit }) => `${count} ${UNIT_LABELS_BY_VALUE[unit]}`}
          />
        </Table>
        <Drawer
          title={(
            <>
              {selectedPrepmeal?.name}
            &nbsp;
              <Link to={`/menu/prepmeals/${selectedPrepmeal?.id}`}>
                <small>{t("Edit")}</small>
              </Link>
            </>
        )}
          visible={!!selectedPrepmeal}
          onClose={() => setSelectedPrepmeal(null)}
          placement="bottom"
          height="60%"
        >
          {selectedPrepmeal && (
          <PrepmealDetailsPanel t={t} prepmealId={selectedPrepmeal.id} />
          )}
        </Drawer>
        <Drawer
          title={(
            <>
              {selectedIngredient?.name}
            &nbsp;
              <Link to={`/menu/ingredients/${selectedIngredient?.id}`}>
                <small>{t("Edit")}</small>
              </Link>
            </>
        )}
          visible={!!selectedIngredient}
          onClose={() => setSelectedIngredient(null)}
          placement="bottom"
          height="60%"
        >
          {selectedIngredient && (
          <IngredientDetailsPanel t={t} ingredientId={selectedIngredient.id} />
          )}
        </Drawer>
      </Col>
      <Col className="scroll-space" style={{ height: "30px" }} span={24} />
    </Row>
  );
};

OrderSpendingsTable.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default OrderSpendingsTable;
