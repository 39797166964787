import React from "react";
import { Route, Switch } from "react-router-dom";

import AccessRoles from "./AccessRoles";
import NewAccessRole from "./NewAccessRole";
import EditAccessRole from "./EditAccessRole";

const Container = () => (
  <Switch>
    <Route path="/access/access-roles/new" component={NewAccessRole} />
    <Route path="/access/access-roles/:id" component={EditAccessRole} />
    <Route path="/access/access-roles" component={AccessRoles} />
  </Switch>
);

export default Container;
