import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageHeader, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import CategoryForm from "./components/CategoryForm";
import {
  CREATE_FINANCE_CATEGORY_MUTATION,
  FINANCE_CATEGORIES_QUERY,
} from "./categoryQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const NewCategory = ({ history }) => {
  const { t } = useTranslation();
  const [createCategory] = useMutation(CREATE_FINANCE_CATEGORY_MUTATION, {
    refetchQueries: [{
      query: FINANCE_CATEGORIES_QUERY,
    }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (values) =>
    createCategory({
      variables: {
        data: {
          allow_income: false,
          allow_expenses: false,
          ...values,
        },
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("finance.Category.AddPage.Title")}
      />
      <CategoryForm t={t} onSubmit={handleSubmit} />
    </div>
  );
};

NewCategory.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewCategory;
