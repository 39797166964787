import React from "react";
import { Switch, Route } from "react-router-dom";

import Salary from "./Salary";

const Container = () => (
  <Switch>
    <Route path="/finance/salary" component={Salary} />
  </Switch>
);

export default Container;
