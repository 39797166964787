import React from "react";
import PropTypes from "prop-types";
import parsePhoneNumber from "libphonenumber-js";
import { useQuery } from "@apollo/client";
import {
  Button, Form, Input, Select, Space,
} from "antd";

import { PAYMENT_METHOD_NAME, PAYMENT_METHOD_TYPE } from "constants/index";
import { PHONE_NUMBER_REGEX } from "utils/formValidations";

import { FILTERS_FORM_QUERY } from "../ordersQueries";
import { ORDER_STATUS } from "../constants";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 15 },
  },
};

const buttonLayout = {
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 14, offset: 8 },
  },
};

const { CREATED, CANCELLED, COMPLETED, ...OPEN_STATUSES } = ORDER_STATUS;

const STATUSES_BY_VALUE = {
  open: Object.values(OPEN_STATUSES),
  completed: [COMPLETED],
  cancelled: [CANCELLED],
};

const FiltersForm = ({ t, onFilter }) => {
  const { data } = useQuery(FILTERS_FORM_QUERY);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    const filters = Object.entries(values).reduce((acc, [key, value]) => {
      if (!value) { return acc; }
      if (key === "payment_method") {
        return { ...acc, order_payments: { payment_method_id: { _eq: value } } };
      }
      if (key === "status" && value.length > 0) {
        return { ...acc, status: { _in: STATUSES_BY_VALUE[value] } };
      }
      if (key === "phone_number") {
        const phoneNumber = parsePhoneNumber(value, "KZ");
        if (phoneNumber) {
          return { ...acc, customer: { phone_number: { _eq: phoneNumber.format("E.164") } } };
        }
      }
      if (key === "risks" && value.length > 0) {
        const expressions = value.map((risk) => {
          switch (risk) {
            case "discount": return { order_price_updates: { discount: { _gt: 0 } } };
            case "refund": return { order_payments: { type: { _eq: "refund" } } };
            case "cancelled": return { order_lines: { status: { _eq: "cancelled" } } };
            default: return { id: { _is_null: false } };
          }
        });
        return { ...acc, _or: expressions };
      }
      return acc;
    }, {});
    onFilter(filters);
  };

  const resetFilters = () => {
    form.resetFields();
    form.submit();
  };

  return (
    <Form
      name="ordersFiltersForm"
      form={form}
      {...formItemLayout}
      style={{ width: 450, paddingTop: 15 }}
      onFinish={handleFinish}
    >
      <Form.Item
        name="payment_method"
        label={t("orders.FiltersForm.PaymentMethod.Label")}
      >
        <Select allowClear placeholder={t("orders.FiltersForm.PaymentMethod.Placeholder")}>
          <Select.Option value={null}>{t("All")}</Select.Option>
          {data?.payment_methods.map(({ id, type, name }) => (
            <Select.Option value={id} key={id}>
              {type === PAYMENT_METHOD_TYPE.CUSTOM ? name : PAYMENT_METHOD_NAME[name]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="status"
        label={t("orders.FiltersForm.Status.Label")}
      >
        <Select allowClear placeholder={t("orders.FiltersForm.Status.Placeholder")}>
          <Select.Option value={null}>{t("All")}</Select.Option>
          {Object.entries(t("orders.FiltersForm.Status.Options", { returnObjects: true }))
            .map(([value, label]) => (
              <Select.Option key={value} value={value}>{label}</Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="phone_number"
        label={t("orders.FiltersForm.Customer.Label")}
        rules={[{ pattern: PHONE_NUMBER_REGEX, message: t("orders.FiltersForm.Customer.Invalid") }]}
      >
        <Input placeholder={t("orders.FiltersForm.Customer.Placeholder")} />
      </Form.Item>
      <Form.Item
        name="risks"
        label={t("orders.FiltersForm.Risks.Label")}
      >
        <Select mode="multiple" placeholder={t("orders.FiltersForm.Risks.Placeholder")} allowClear>
          {Object.entries(t("orders.FiltersForm.Risks.Options", { returnObjects: true }))
            .map(([value, label]) => (
              <Select.Option key={value} value={value}>{label}</Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item {...buttonLayout}>
        <Space>
          <Button type="primary" htmlType="submit">
            {t("Apply")}
          </Button>
          <Button onClick={resetFilters}>
            {t("Reset")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

FiltersForm.propTypes = {
  t: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default FiltersForm;
