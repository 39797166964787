import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageHeader, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import PaymentMethodForm from "./components/PaymentMethodForm";
import { CREATE_PAYMENT_METHOD_MUTATION, NEW_PAYMENT_METHOD_QUERY } from "./paymentMethodsQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const NewPaymentMethod = ({ history }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(NEW_PAYMENT_METHOD_QUERY, {
    onError: notification.error,
  });
  const [createPaymentMethod] = useMutation(CREATE_PAYMENT_METHOD_MUTATION);

  if (loading) return <Loader />;

  const handleSubmit = (values) =>
    createPaymentMethod({ variables: { data: values } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("settings.PaymentMethod.AddPage.Title")}
      />
      <PaymentMethodForm
        t={t}
        onSubmit={handleSubmit}
        financeAccounts={data ? data.finance_accounts : []}
        initialValues={{
          show_in_pos: true,
          show_in_callcenter: true,
          processing_fee_percent: 0,
          processing_fee_amount: 0,
        }}
      />
    </div>
  );
};

NewPaymentMethod.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewPaymentMethod;
