import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button, Divider, Alert } from "antd";
import { useTranslation } from "react-i18next";

import { renderInput, renderSelect, renderCheckbox } from "components/FormItem";
import { required, passwordTerminal, number } from "utils/formValidations";
import CheckboxGroupField from "components/CheckboxGroupField";
import colors from "theme/colors";

import i18n from "../../../../i18n";
import { TERMINAL_TYPES } from "../constants";

const workshopSelected = (value) =>
  (value && value.length < 1 ? i18n.t("access.Terminal.NeedChooseWorkshop") : undefined);

const StyledForm = styled(Form)`
  padding: 20px 40px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledDivider = styled(Divider)`
  background-color: ${colors.lightGrey};
`;

const TerminalForm = ({
  handleSubmit,
  pristine,
  submitting,
  error,
  locations,
  workshops,
  deleteButton,
  disableTypeChange,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState(initialValues && initialValues.type);

  const locationsOptions = locations.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  const workshopsOptions = workshops.map((workshop) => ({
    value: workshop.id,
    label: workshop.name,
  }));

  return (
    <StyledForm onSubmit={handleSubmit}>
      {error && <Alert message={error} type="error" />}
      <StyledRow>
        <Col xs={24} md={20} lg={16} xl={12}>
          <Field
            name="name"
            label={t("access.Terminal.Form.TerminalName")}
            component={renderInput}
            validate={required}
          />
          <Field
            name="location_id"
            label={t("access.Terminal.Form.Location")}
            component={renderSelect}
            validate={required}
            options={locationsOptions}
          />
          <Field
            name="type"
            label={t("access.Terminal.Form.Type")}
            component={renderSelect}
            validate={required}
            options={TERMINAL_TYPES}
            onChange={(value) => setType(value)}
            disabled={disableTypeChange}
          />
          <Field
            name="password"
            label={t("access.Terminal.Form.Password")}
            component={renderInput}
            validate={[required, passwordTerminal, number]}
          />
          {type === "pos" && (
            <Field
              name="settings.charge_service_percent_by_default"
              label={t("access.Terminal.Form.AddServicePercent.Label")}
              component={renderCheckbox}
            />
          )}
        </Col>
      </StyledRow>

      {type === "kitchen" && (
        <StyledRow>
          <StyledDivider />
          <Col span={24}>
            <Field
              name="settings.workshopIds"
              label={t("access.Terminal.Form.ChooseWorkshop")}
              component={CheckboxGroupField}
              checkboxs={workshopsOptions}
              validate={[required, workshopSelected]}
            />
          </Col>
        </StyledRow>
      )}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={pristine || submitting}
        >
          {t("Save")}
        </Button>
        {deleteButton}
      </Form.Item>
    </StyledForm>
  );
};

TerminalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.object,
  locations: PropTypes.array.isRequired,
  workshops: PropTypes.array.isRequired,
  deleteButton: PropTypes.element,
  initialValues: PropTypes.shape({
    type: PropTypes.string,
  }),
  disableTypeChange: PropTypes.bool,
};

export default reduxForm({
  form: "terminalForm",
})(TerminalForm);
