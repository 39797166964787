import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PageHeader, Button, Spin, notification } from "antd";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import EmployeeForm from "./components/EmployeeForm";
import { EDIT_USER_QUERY } from "./employeesQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditEmployee = ({ match, history }) => {
  const { t } = useTranslation();
  const employeeId = parseInt(match.params.id, 10);
  const { data, loading } = useQuery(EDIT_USER_QUERY, {
    variables: { id: employeeId },
    onError: notification.error,
  });

  const employee = data && data.users[0];

  const handleSubmit = (values) =>
    axios.put(`/admin_api/users/${employeeId}`, values)
      .then(() => history.goBack())
      .catch((err) => notification.error(err.message));

  const handleDelete = () =>
    axios.delete(`/admin_api/users/${employeeId}`)
      .then(() => history.goBack())
      .catch((err) => notification.error(err.message));

  const deleteButton = (
    <Button type="danger" onClick={handleDelete}>
      {t("Delete")}
    </Button>
  );

  if (loading) return <Loader />;

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("access.Employee.DetailPage.Title")}
      />
      <EmployeeForm
        initialValues={{
          ...employee,
          user_locations: employee.users_locations,
        }}
        locations={data.locations}
        accessRoles={data.access_roles}
        onSubmit={handleSubmit}
        deleteButton={deleteButton}
      />
    </div>
  );
};

EditEmployee.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditEmployee;
