import { ApolloClient } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { ApolloLink, HttpLink, split } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { getDirectiveNames, getOperationName, getMainDefinition } from "@apollo/client/utilities";

const graphqlApiClient = new HttpLink({
  uri: "/graphql_api",
});

const hasuraClient = new HttpLink({
  uri: "/v1/graphql",
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const omitTypename = (key, value) => (key === "__typename" ? undefined : value);

  const def = getMainDefinition(operation.query);
  if (def && def.operation === "mutation") {
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

export default new ApolloClient({
  link: ApolloLink.from([
    cleanTypenameLink,
    onError(({ graphQLErrors, networkError, operation }) => {
      if (operation.operationName === "getMe") return;

      if (graphQLErrors && graphQLErrors[0].message.includes("unauthorized")) window.location.assign("/login");
      if (networkError && networkError.statusCode === 401) window.location.assign("/login");
    }),
    split(
      ({ query }) => getOperationName(query) === "IntrospectionQuery"
        || getDirectiveNames(query).includes("hasura"),
      hasuraClient,
      graphqlApiClient,
    ),
  ]),
  cache: new InMemoryCache(),
});
