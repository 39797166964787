/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";

import UsageInSetsTable from "../../shared/components/UsageInSetsTable";
import TechCardProductsTable from "../../shared/components/TechCardProductsTable";

const TechCardDetailsPanel = ({ t, techCardId }) => (
  <Tabs size="small" centered>
    <Tabs.TabPane tab={t("menu.TechCards.DetailsPanel.IngredientsTab.Title")} key="ingredients">
      <TechCardProductsTable techCardId={techCardId} />
    </Tabs.TabPane>
    <Tabs.TabPane tab={t("menu.TechCards.DetailsPanel.UsageTab.Title")} key="usage">
      <UsageInSetsTable techCardId={techCardId} />
    </Tabs.TabPane>
  </Tabs>
);

TechCardDetailsPanel.propTypes = {
  techCardId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default TechCardDetailsPanel;
