import React from "react";
import PropTypes from "prop-types";
import { Card, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import Page from "components/Page";

import MovingForm from "./components/MovingForm";
import { CREATE_MOVING_MUTATION } from "./movingQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

const NewMoving = ({ history }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const [createMoving] = useMutation(CREATE_MOVING_MUTATION);

  if (loading) {
    return <Spin />;
  }

  const handleSubmit = ({ moving_products, ...values }) =>
    createMoving({
      variables: {
        data: {
          ...values,
          moving_products: moving_products.map(({ product, content_path, ...mp }) => mp),
        },
      },
    })
      .then((res) => res.data.createMoving)
      .then((moving) => history.replace(`/warehouse/movings/${moving.id}`))
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("warehouse.Movings.AddPage.Title")}>
      <Card>
        <MovingForm t={t} optionsData={data} onSubmit={handleSubmit} />
      </Card>
    </Page>
  );
};

NewMoving.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewMoving;
