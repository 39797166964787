import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { formatPrice } from "utils/helpers";
import colors from "theme/colors";

import i18n from "../../../i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
  color: ${colors.primaryColor};
`;

export const EMPLOYEES_COLUMNS = [
  {
    title: i18n.t("statistics.Employees.Columns.Name"),
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (name, record) => (
      <StyledLink to={`/statistics/employees/${record.id}${window.location.search}`}>
        {name}
      </StyledLink>
    ),
  },
  {
    title: i18n.t("statistics.Employees.Columns.Revenue"),
    dataIndex: "revenue",
    sorter: (a, b) => a.revenue - b.revenue,
    render: formatPrice,
  },
  {
    title: i18n.t("statistics.Employees.Columns.OrderCount"),
    dataIndex: "orders_count",
    sorter: (a, b) => a.orders_count - b.orders_count,
    render: (count) => (count ? `${count} шт.` : "0 шт."),
  },
  {
    title: i18n.t("statistics.Employees.Columns.AvgPrice"),
    dataIndex: "avgPrice",
    sorter: (a, b) => (a.revenue / a.orders_count) - (b.revenue / b.orders_count),
    render: (_, employee) => formatPrice(employee.revenue / employee.orders_count),
  },
];
