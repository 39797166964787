import React from "react";
import { Switch, Route } from "react-router-dom";

import Employees from "./Employees";
import NewEmployee from "./NewEmployee";
import EditEmployee from "./EditEmployee";

const Container = () => (
  <Switch>
    <Route path="/access/employees/new" component={NewEmployee} />
    <Route path="/access/employees/:id" component={EditEmployee} />
    <Route path="/access/employees" component={Employees} />
  </Switch>
);

export default Container;
