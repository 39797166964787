import React, { useEffect, useMemo } from "react";
import { Field, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DeleteOutlined, PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Table, Button, Divider, Tooltip } from "antd";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { renderCascader, renderCheckbox, renderInput, renderLabel } from "components/FormItem";
import { required } from "utils/formValidations";
import { formatPrice, singletonCurrency } from "utils/helpers";

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const capPrecision = (value, precision) => parseFloat(value.toFixed(precision));

const parseFloatValue = (precision) => (value) => {
  const parsedValue = parseFloat(value);
  return parsedValue || parsedValue === 0 ? capPrecision(parsedValue, precision) : "";
};

const ModifierItemsFields = ({
  t, fields, changeField, optionsData, onIngridientCreate, meta,
}) => {
  const contents = useMemo(() => {
    if (!optionsData) return [];
    const categories = optionsData.products.concat(optionsData.tech_cards)
      .reduce((acc, { id, name, category_id, ...rest }) => {
        const item = {
          ...rest,
          category_id: category_id || 0,
          label: name,
          value: JSON.stringify(category_id ? { product_id: id } : { tech_card_id: id }),
        };
        const categoryIdx = acc.findIndex((cat) => cat.value === (category_id || 0));
        if (categoryIdx > -1) {
          return acc.map((cat, idx) => (idx !== categoryIdx ? cat : {
            ...cat, children: cat.children.concat(item),
          }));
        }
        const category = category_id
          ? optionsData.product_categories.find((c) => c.id === category_id)
          : { id: 0, name: t("Prepmeals") };
        return acc.concat({
          label: category.name, value: category.id, children: [item],
        });
      }, []);
    return [{
      label: t("NoSpending"),
      value: null,
    }, {
      label: t("Ingredients"),
      value: "ingredients",
      children: categories.filter((cat) => cat.value !== 0),
    }].concat(categories.filter((cat) => cat.value === 0));
  }, [optionsData]);

  const onQuantityValueUpdated = (idx, value, newIngredient) => {
    const ingredient = newIngredient === undefined
      ? fields.get(idx).ingredient : newIngredient;
    if (!ingredient) return;
    const numberValue = value || 0;
    const finalCost = ingredient.product ? ingredient.product.cost : ingredient.cost;

    changeField(`${fields.name}[${idx}].cost`, (finalCost || 0) * numberValue);
  };

  const onQuantityChange = (idx, value, newIngredient) => {
    const ingredient = newIngredient === undefined
      ? fields.get(idx).ingredient : newIngredient;
    if (!ingredient) return;
    const numberValue = value || 0;

    onQuantityValueUpdated(idx, numberValue, ingredient);
  };

  const onSelectContent = (idx, value) => {
    const { quantity } = fields.get(idx);

    const valueItem = value.reduce(
      (val, pathItem) => val.children.find((c) => c.value === pathItem),
      { children: contents },
    );

    changeField(`${fields.name}[${idx}].content`, JSON.parse(valueItem.value));

    if (!value[1]) { // No spending
      changeField(`${fields.name}[${idx}].cost`, 0);
      changeField(`${fields.name}[${idx}].quantity`, 0);
      changeField(`${fields.name}[${idx}].ingredient`, null);
      changeField(`${fields.name}[${idx}].name`, "");
    } else {
      changeField(`${fields.name}[${idx}].ingredient`, valueItem);
      changeField(`${fields.name}[${idx}].name`, valueItem.label);
      if (quantity || quantity === 0) {
        changeField(`${fields.name}[${idx}].quantity`, quantity);
        onQuantityChange(idx, quantity, valueItem);
      }
    }
  };

  const selector = formValueSelector(meta.form);
  const modifierItems = useSelector((state) => selector(state, fields.name));

  useEffect(() => {
    (modifierItems || []).forEach(({ quantity }, idx) => {
      if (quantity || quantity === 0) {
        onQuantityChange(idx, quantity);
      }
    });
  }, []);

  return (
    <Table
      rowKey={(field) => fields.getAll().indexOf(field)}
      dataSource={fields.getAll()}
      size="small"
      pagination={false}
      summary={() => (
        <Table.Summary.Row style={{ backgroundColor: "#fff" }}>
          <Table.Summary.Cell colSpan={6}>
            <Button type="dashed" onClick={() => fields.push({})}>
              <PlusOutlined /> {t("menu.Modifiers.Form.ModifierItems.AddModifier")}
            </Button>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
    >
      <Table.Column
        title={t("menu.Modifiers.Form.ModifierItems.Columns.Modifier.Name")}
        dataIndex="content_path"
        render={(_c, _f, index) => (
          <Field
            style={{ width: 220 }}
            name={`${fields.name}[${index}].content_path`}
            component={renderCascader}
            onChange={(v) => onSelectContent(index, v)}
            validate={required}
            placeholder={t("menu.Modifiers.Form.ModifierItems.Columns.Modifier.Placeholder")}
            formItemProps={formItemLayout}
            options={contents}
            showSearch
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "2px 0 4px" }} />
                <LinkContainer onMouseDown={(e) => e.preventDefault()}>
                  <Button type="link" onClick={onIngridientCreate}>
                    {t("menu.Modifiers.Form.ModifierItems.AddIngredient")}
                  </Button>
                </LinkContainer>
              </div>
            )}
          />
        )}
      />
      <Table.Column
        title={t("menu.Modifiers.Form.ModifierItems.Columns.Name")}
        dataIndex="name"
        style={{ width: 220 }}
        render={(_n, _p, index) => (
          <Field
            name={`${fields.name}[${index}].name`}
            component={renderInput}
            validate={required}
            formItemProps={formItemLayout}
          />
        )}
      />
      <Table.Column
        title={t("menu.Modifiers.Form.ModifierItems.Columns.Quantity")}
        dataIndex="ingredient"
        render={(ingredient, field, index) => (
          <Field
            name={`${fields.name}[${index}].quantity`}
            component={renderInput}
            onChange={(_e, v) => onQuantityChange(index, v)}
            validate={required}
            disabled={field.content_path?.[0] === null}
            type="number"
            min={0}
            step={0.001}
            parse={parseFloatValue(3)}
            formItemProps={formItemLayout}
            addonAfter={ingredient && UNIT_LABELS_BY_VALUE[ingredient.unit]}
          />
        )}
      />
      <Table.Column
        title={t("menu.Modifiers.Form.ModifierItems.Columns.Cost.Name")}
        dataIndex={["ingredient", "product"]}
        render={(product, field, index) => (
          <Field
            name={`${fields.name}[${index}].cost`}
            component={renderLabel}
            format={(cost) => (product ? (
              <Tooltip title={t("menu.Modifiers.Form.ModifierItems.Columns.Cost.UsingProductCost")}>
                {formatPrice(cost)}
                &nbsp;
                <InfoCircleOutlined />
              </Tooltip>
            ) : formatPrice(cost))}
            formItemProps={formItemLayout}
          />
        )}
      />
      <Table.Column
        title={t("menu.Modifiers.Form.ModifierItems.Columns.Price")}
        dataIndex="price"
        render={(_n, field, index) => (
          <Field
            name={`${fields.name}[${index}].price`}
            component={renderInput}
            type="number"
            step={0.01}
            validate={required}
            parse={parseFloatValue(2)}
            formItemProps={formItemLayout}
            addonAfter={singletonCurrency.getCurrency()}
          />
        )}
      />
      <Table.Column
        title={t("menu.Modifiers.Form.ModifierItems.Columns.DefaultValue")}
        dataIndex="price"
        render={(_n, field, index) => (
          <>
            <Field
              checkboxLabel={t("const:order_type.in_store")}
              name={`${fields.name}[${index}].default_value.in_store`}
              component={renderCheckbox}
              formItemProps={formItemLayout}
            />
            <Field
              checkboxLabel={t("const:order_type.delivery")}
              name={`${fields.name}[${index}].default_value.delivery`}
              component={renderCheckbox}
              formItemProps={formItemLayout}
            />
            <Field
              checkboxLabel={t("const:order_type.pickup")}
              name={`${fields.name}[${index}].default_value.pickup`}
              component={renderCheckbox}
              formItemProps={formItemLayout}
            />
          </>
        )}
      />
      <Table.Column
        dataIndex="content_path"
        render={(_c, _p, index) => (
          <Button
            danger
            disabled={fields.length === 1}
            onClick={() => fields.remove(index)}
          >
            <DeleteOutlined />
          </Button>
        )}
      />
    </Table>
  );
};

ModifierItemsFields.propTypes = {
  t: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  optionsData: PropTypes.object,
  onIngridientCreate: PropTypes.func.isRequired,
};

export default ModifierItemsFields;
