import React from "react";
import PropTypes from "prop-types";
import {
  Row, Col, Card, notification, Spin, Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";

import IngredientForm from "./components/IngredientForm";
import {
  INGREDIENT_QUERY, UPDATE_INGREDIENT_MUTATION,
} from "./ingredientQueries";
import { INGREDIENT_CATEGORIES_QUERY } from "../ingredientCategories/ingredientCategoryQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const EditIngredient = ({ match, history }) => {
  const ingredientId = match.params.id;
  const { t } = useTranslation();
  const { data, loading } = useQuery(INGREDIENT_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: { id: ingredientId },
    onError: notification.error,
  });
  const ingredientCategoryQuery = useQuery(INGREDIENT_CATEGORIES_QUERY, {
    onError: notification.error,
  });

  const [updateIngredient] = useMutation(UPDATE_INGREDIENT_MUTATION, {
    refetchQueries: [
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });

  if (loading || ingredientCategoryQuery.loading) return <Spin />;

  const handleSubmit = ({ product_category, id, location_products_aggregate, ...values }) => {
    const unitChanged = data?.products_by_pk.unit !== values.unit
      || data?.products_by_pk.unit_weight !== values.unit_weight;
    return updateIngredient({ variables: { id, data: values } })
      .then(() => {
        history.goBack();
        if (unitChanged) {
          Modal.warn({
            title: t("menu.Ingredients.CheckTechCardsWarning.Title"),
            content: t("menu.Ingredients.CheckTechCardsWarning.Content"),
            onOk() {},
          });
        }
      })
      .catch(notification.error);
  };

  const hasDeliveries = data?.products_by_pk.location_products_aggregate.aggregate.count > 0;

  return (
    <Page showBackIcon title={t("menu.Ingredients.EditPage.Title")}>
      <Row>
        <Col xs={24} md={20} lg={16}>
          <Card>
            <IngredientForm
              t={t}
              onSubmit={handleSubmit}
              ingredientCategories={ingredientCategoryQuery.data?.product_categories ?? []}
              initialValues={data?.products_by_pk}
              hasDeliveries={hasDeliveries}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

EditIngredient.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditIngredient;
