import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, notification, Popover, Row, Table } from "antd";
import { useLazyQuery } from "@apollo/client";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

import ConnectData from "connect.container";

import FilterPanelWithQuery from "components/FilterPanelWithQuery";
import { LIMIT_PAGE } from "constants/index";
import colors from "theme/colors";

import { formatPrice } from "utils/helpers";
import { CASHIER_SHIFTS_QUERY } from "./cashierShiftQueries";
import CashierShiftDetails from "./components/CashierShiftDetails";

const Container = styled.div`
  padding: 12px;
  .open-shift {
    background: ${colors.backgroundYellow};
  }
  .invalid-shift {
    background: ${colors.backgroundRed};
  }
`;

const CashierShifts = () => {
  const { t } = useTranslation();
  const { locations } = ConnectData.useContainer();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [filters, setFilters] = useState(null);

  const [getCashierShifts, { loading, data }] = useLazyQuery(CASHIER_SHIFTS_QUERY, {
    error: notification.error,
    fetchPolicy: "cache-and-network",
  });

  useEffect(_.throttle(() => {
    if (filters) {
      getCashierShifts({
        variables: {
          location_id: filters.locationId,
          limit: LIMIT_PAGE,
          offset: page * LIMIT_PAGE - LIMIT_PAGE,
        },
      });
    }
  }, 200), [filters, page]);

  return (
    <Container>
      <Row style={{ paddingBottom: 12 }} type="flex" justify="space-between">
        <Col xs={20}>
          <FilterPanelWithQuery
            hideTimePicker
            onFilter={(values) => { if (filters) { setPage(1); } setFilters(values); }}
            locations={locations}
          />
        </Col>
      </Row>
      <Table
        rowKey="id"
        pagination={{
          total: data && data.cashierShiftsCount.count,
          pageSize: LIMIT_PAGE,
          current: page,
          onChange: setPage,
          showSizeChanger: false,
        }}
        dataSource={data ? data.cashierShifts : []}
        loading={loading}
        rowClassName={(record) => {
          if (!record.closed_at) return "open-shift";
          if (record.open_correction_transaction) return "invalid-shift";
          const closeDifference = Object.entries(record.expected_cash_amounts)
            .map(([terminalId, amount]) =>
              parseFloat(amount) - parseFloat(record.actual_cash_amounts[terminalId]))
            .reduce((total, amount) => total + amount, 0);
          if (closeDifference > 0) return "invalid-shift";
          return null;
        }}
        expandable={{
          expandedRowRender: (record) => <CashierShiftDetails shift={record} t={t} />,
        }}
      >
        <Table.Column
          dataIndex="number"
          title="#"
          align="center"
          width={60}
        />
        <Table.Column
          dataIndex={["location", "name"]}
          title={t("finance.CashierShifts.Columns.Location")}
          width={150}
        />
        <Table.Column
          title={t("finance.CashierShifts.Columns.OpenShift")}
          dataIndex="opened_at"
          render={(openedAt) => DateTime.fromMillis(openedAt).toLocaleString(DateTime.DATETIME_MED)}
          width={200}
        />
        <Table.Column
          title={t("finance.CashierShifts.Columns.CloseShift")}
          dataIndex="closed_at"
          render={(closedAt) => (closedAt
            ? DateTime.fromMillis(closedAt).toLocaleString(DateTime.DATETIME_MED)
            : t("finance.CashierShifts.NotClosed"))}
          width={200}
        />
        <Table.Column
          title={t("finance.CashierShifts.Columns.OpenCashAmounts")}
          dataIndex="open_cash_amounts"
          render={(openCashAmounts, record) => (
            <>
              {formatPrice(Object.values(openCashAmounts)
                .reduce((total, amount) => total + parseFloat(amount), 0))}
              {record.open_correction_transaction && (
                <Popover
                  content={(
                    <div style={{ width: 300, marginRight: 10, marginLeft: 10 }}>
                      {t("finance.CashierShifts.OpenCashNotMatching", {
                        amount: formatPrice(record.open_correction_transaction.amount),
                      })}
                    </div>
                  )}
                  trigger="click"
                >
                  <InfoCircleOutlined
                    style={{
                      color: colors.primaryColor,
                      fontSize: 18,
                      marginLeft: 5,
                    }}
                  />
                </Popover>
              )}
            </>
          )}
        />
        <Table.Column
          title={t("finance.CashierShifts.Columns.Incassation")}
          dataIndex="actual_cash_amounts"
          render={(actual, record) => actual && formatPrice(Object.entries(actual)
            .map(([terminalId, amount]) =>
              parseFloat(amount) - parseFloat(record.close_cash_amounts[terminalId]))
            .reduce((total, amount) => total + amount, 0))}
        />
        <Table.Column
          title={t("finance.CashierShifts.Columns.CloseCashAmounts")}
          dataIndex="close_cash_amounts"
          render={(closeCashAmounts) =>
            closeCashAmounts && formatPrice(Object.values(closeCashAmounts)
              .reduce((total, amount) => total + parseFloat(amount), 0))}
        />
        <Table.Column
          title={t("finance.CashierShifts.Columns.Difference")}
          dataIndex="expected_cash_amounts"
          render={(expected, record) => expected && formatPrice(Object.entries(expected)
            .map(([terminalId, amount]) =>
              parseFloat(amount) - parseFloat(record.actual_cash_amounts[terminalId]))
            .reduce((total, amount) => total + amount, 0))}
        />
      </Table>
    </Container>
  );
};

export default CashierShifts;
