import React from "react";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Button, Alert, Space, Typography } from "antd";
import { useSelector } from "react-redux";

import i18n from "i18n";

import {
  renderCheckbox, renderInput, renderInputNumber, renderRadioGroup, renderTextarea, errorLayout,
} from "components/FormItem";
import { required } from "utils/formValidations";
import { formatPrice } from "utils/helpers";

import ModifierItemsFields from "./ModifierItemsFields";

const buttonsLayout = {
  wrapperCol: {
    md: { span: 15, offset: 6 },
    xs: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const ModifierGroupForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  error,
  optionsData,
  change: changeField,
  archivingOrUnarchivingButton,
  onIngridientCreate,
}) => {
  const selector = formValueSelector("modifierGroupForm");
  const modifierType = useSelector((state) => selector(state, "modifier_type"));

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        formItemProps={formItemLayout}
        name="name"
        label={t("menu.Modifiers.Form.Name")}
        component={renderInput}
        validate={required}
      />
      <Field
        formItemProps={formItemLayout}
        name="description"
        label={t("menu.Modifiers.Form.Description")}
        component={renderTextarea}
      />
      <Field
        formItemProps={formItemLayout}
        name="modifier_type"
        label={t("menu.Modifiers.Form.SelectionRange.Title")}
        extra={t("menu.Modifiers.Form.SelectionRange.Help")}
        component={renderRadioGroup}
        validate={required}
        items={[{
          value: "only_one", label: t("menu.Modifiers.Form.SelectionRange.Options.OnlyOne.Title"),
        }, {
          value: "several", label: t("menu.Modifiers.Form.SelectionRange.Options.Several.Title"),
        }]}
      />
      {modifierType === "only_one" && (
        <Field
          formItemProps={buttonsLayout}
          name="is_required"
          checkboxLabel={t("menu.Modifiers.Form.SelectionRange.Options.OnlyOne.Required")}
          component={renderCheckbox}
        />
      )}
      {modifierType === "several" && (
        <>
          <Field
            formItemProps={formItemLayout}
            name="min_selection"
            label={t("menu.Modifiers.Form.SelectionRange.Options.Several.MinSelection")}
            component={renderInputNumber}
            validate={required}
          />
          <Field
            formItemProps={formItemLayout}
            name="max_selection"
            label={t("menu.Modifiers.Form.SelectionRange.Options.Several.MaxSelection")}
            component={renderInputNumber}
            extra={t("menu.Modifiers.Form.SelectionRange.Options.Several.Help")}
            validate={required}
          />
        </>
      )}

      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {t("menu.Modifiers.Form.ModifierItems.Title")}
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        {t("menu.Modifiers.Form.ModifierItems.Description")}
      </Typography.Paragraph>
      <FieldArray
        t={t}
        name="modifier_items"
        changeField={changeField}
        component={ModifierItemsFields}
        optionsData={optionsData}
        onIngridientCreate={onIngridientCreate}
      />
      <Typography.Paragraph type="secondary">
        {t("menu.Modifiers.Form.ModifierItems.PriceDescription", { zeroPrice: formatPrice(0) })}
      </Typography.Paragraph>

      {error && (
        <Form.Item {...errorLayout}>
          <Alert banner type="error" message={error} />
        </Form.Item>
      )}

      <Form.Item {...buttonsLayout}>
        <Space>
          <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
            {t("Save")}
          </Button>
          {archivingOrUnarchivingButton}
        </Space>
      </Form.Item>
    </Form>
  );
};

ModifierGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  optionsData: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  onIngridientCreate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  archivingOrUnarchivingButton: PropTypes.element,
};

const validateDefaultValues = (values) => {
  const maxSelection = values.modifier_type === "only_one" ? 1 : values.max_selection;
  const defaultValues = (values.modifier_items || []).reduce((acc, { default_value: dv }) => ({
    delivery: acc.delivery + (dv?.delivery ? 1 : 0),
    in_store: acc.in_store + (dv?.in_store ? 1 : 0),
    pickup: acc.pickup + (dv?.pickup ? 1 : 0),
  }), { delivery: 0, in_store: 0, pickup: 0 });
  const errors = Object.keys(defaultValues)
    .filter((key) => defaultValues[key] > maxSelection)
    .map((key) => i18n.t("menu.Modifiers.Form.DefaultValueMaxError", {
      orderType: key, count: defaultValues[key], maxCount: maxSelection,
    }));
  if (errors.length > 0) {
    return { _error: errors.join("\n") };
  }
  return {};
};

export default reduxForm({
  form: "modifierGroupForm",
  validate: validateDefaultValues,
  initialValues: {
    modifier_items: [{}],
    min_selection: 0,
    max_selection: 2,
    modifier_type: "only_one",
  },
})(ModifierGroupForm);
