import React from "react";
import { Switch, Route } from "react-router-dom";

import Products from "./Products";

const Container = () => (
  <Switch>
    <Route path="/warehouse/products" component={Products} />
  </Switch>
);

export default Container;
