import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row, Col, Table, Card } from "antd";
import PropTypes from "prop-types";

import colors from "theme/colors";
import i18n from "i18n";

const StyledLink = styled(Link)`
  margin-left: 4px;
  color: ${colors.primaryColor};
`;

export const TABLE_COLUMNS = [
  {
    title: i18n.t("statistics.Sales.Columns.Name"),
    dataIndex: "name",
    render: (name, value) => (
      <StyledLink to={`/statistics/products/${value.id}${window.location.search}`}>
        {name}
      </StyledLink>
    ),
  },
  { title: i18n.t("statistics.Sales.Columns.Orders"), dataIndex: "count" },
];

const PopularProductsTable = ({ data, t, loading }) => {
  const [left, right] = _.chunk(data, Math.round(data.length / 2));
  return (
    <Card
      title={t("statistics.Sales.PopularProductsTable.PopularItems")}
      extra={(
        <Link to="/statistics/products">{t("statistics.Sales.PopularProductsTable.ShowAll")}</Link>
      )}
    >
      <Row gutter={8}>
        {(right ? [left, right] : [left]).map((table, idx) => (
          <Col span={12} key={idx}>
            <Table
              className="borderless"
              rowKey="name"
              columns={TABLE_COLUMNS}
              loading={loading}
              dataSource={table}
              size="small"
              pagination={false}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

PopularProductsTable.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PopularProductsTable;
