import React from "react";
import { Switch, Route } from "react-router-dom";

import Employees from "./employees";
import AccessRoles from "./accessRoles";
import Terminals from "./terminals";
import ApiKeys from "./apiKeys";

const Container = () => (
  <Switch>
    <Route path="/access/employees" component={Employees} />
    <Route path="/access/access-roles" component={AccessRoles} />
    <Route path="/access/terminals" component={Terminals} />
    <Route path="/access/api-keys" component={ApiKeys} />
  </Switch>
);

export default Container;
