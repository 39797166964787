import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Button, notification, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";

import { formatPrice } from "utils/helpers";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import ProductionDetailsPanel from "./components/ProductionDetailsPanel";

import { PRODUCTIONS_QUERY } from "./productionQueries";

const Productions = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 30));
  const [selectedProduction, setSelectedProduction] = useState();

  const [getProductions, { data, loading }] = useLazyQuery(PRODUCTIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  useEffect(_.throttle(() => {
    if (filter?.time) {
      getProductions({
        variables: {
          limit: pageSize,
          offset: page * pageSize - pageSize,
          filter: {
            datetime: { _gte: filter.time.from, _lt: filter.time.to },
            ...(filter.locationId ? { location_id: { _eq: filter.locationId } } : {}),
          },
        },
      });
    }
  }, 200), [page, pageSize, filter]);

  return (
    <Page
      title={t("warehouse.Productions.Index.Title")}
      subTitle={data?.productions_aggregate.aggregate.count.toString()}
      extra={(
        <Link to="/warehouse/productions/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        initialData={{ time: { value: "this_month" } }}
        locations={data?.locations ?? []}
        onFilter={(values) => { setPage(1); setFilter(values); }}
      />
      <Table
        rowKey="id"
        loading={loading}
        dataSource={data?.productions}
        pagination={{
          total: data?.productions_aggregate.aggregate.count,
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
      >
        <Table.Column
          title="#"
          dataIndex="number"
          width={80}
          align="center"
        />
        <Table.Column
          title={t("warehouse.Productions.Columns.Date")}
          dataIndex="datetime"
          render={(datetime) => moment(datetime).format("lll")}
        />
        <Table.Column
          title={t("warehouse.Productions.Columns.Products")}
          dataIndex="production_products"
          render={(products) => products.map(({ product }) => product.name).join(", ")}
        />
        <Table.Column
          title={t("warehouse.Productions.Columns.Description")}
          dataIndex="description"
          render={(value) => value || "-"}
        />
        <Table.Column
          title={t("warehouse.Productions.Columns.Warehouse")}
          dataIndex={["location", "name"]}
        />
        <Table.Column
          title={t("warehouse.Productions.Columns.Amount")}
          dataIndex="production_products"
          render={(products) => formatPrice(products.reduce(
            (sum, product) => sum + parseFloat(product.cost) * product.quantity, 0,
          ))}
        />
        <Table.Column
          dataIndex="id"
          width={100}
          render={(_id, record) => (
            <Button type="link" size="small" onClick={() => setSelectedProduction(record)}>
              {t("Details")}
            </Button>
          )}
        />
      </Table>
      <Drawer
        title={[`#${selectedProduction?.number}`,
          moment(selectedProduction?.datetime).format("lll"),
          selectedProduction?.location.name].join(" / ")}
        visible={!!selectedProduction}
        onClose={() => setSelectedProduction(null)}
        placement="bottom"
        height="60%"
      >
        {selectedProduction && (
          <ProductionDetailsPanel t={t} productionId={selectedProduction.id} />
        )}
      </Drawer>
    </Page>
  );
};

Productions.propTypes = {};

export default Productions;
