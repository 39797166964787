import React from "react";
import { Switch, Route } from "react-router-dom";

import Accounts from "./Accounts";
import EditAccount from "./EditAccount";
import NewAccount from "./NewAccount";

const Container = () => (
  <Switch>
    <Route path="/finance/accounts/new" component={NewAccount} />
    <Route path="/finance/accounts/:id" component={EditAccount} />
    <Route path="/finance/accounts" component={Accounts} />
  </Switch>
);

export default Container;
