import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Button, Alert } from "antd";
import _ from "lodash";

import { renderSelect } from "components/FormItem";
import { required } from "utils/formValidations";
import { CURRENCY_UNIT_OPTIONS } from "constants/index";

import { TIMEZONE_OPTIONS } from "../constants";

const buttonLayout = {
  wrapperCol: {
    sm: { span: 14, offset: 10 },
  },
};

const h12Format = new Intl.DateTimeFormat(window.navigator.languages[0])
  .resolvedOptions()
  .hour12;

const hoursOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
]
  .map((hour) => ({
    label: h12Format
      ? `${String(hour <= 12 ? hour : (hour % 12)).padStart(2, "0")}:00${hour < 12 ? "AM" : "PM"}`
      : `${String(hour).padStart(2, "0")}:00`,
    value: hour * 60 * 60,
  }));

const GeneralSettingsForm = ({ handleSubmit, pristine, submitting, error, t }) => {
  const servicePercentOptions = _.range(101).map((value) => (
    value === 0 ? {
      value: 0,
      label: t("settings.General.Form.NotServicePercent"),
    } : {
      value: value / 100,
      label: `${value}%`,
    }));

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert message={error} type="error" />}
      <Field
        name="timezone"
        label={t("settings.General.Form.Timezone")}
        component={renderSelect}
        options={TIMEZONE_OPTIONS}
        validate={required}
      />
      <Field
        name="shift_offset"
        label={t("settings.General.Form.ShiftEndTime.Label")}
        component={renderSelect}
        options={hoursOptions}
        extra={t("settings.General.Form.ShiftEndTime.Extra")}
        validate={required}
      />
      <Field
        name="currency_code"
        label={t("settings.General.Form.CurrencyUnite")}
        component={renderSelect}
        options={CURRENCY_UNIT_OPTIONS}
        validate={required}
      />
      <Field
        name="service_percent"
        label={t("settings.General.Form.ServicePercent.Label")}
        component={renderSelect}
        options={servicePercentOptions}
        validate={required}
      />

      <Form.Item {...buttonLayout}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={pristine || submitting}
        >
          {t("Save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

GeneralSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "generalSettingsForm",
})(GeneralSettingsForm);
