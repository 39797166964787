import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";

// eslint-disable-next-line import/no-cycle
import UsageInTechCardsTable from "../../shared/components/UsageInTechCardsTable";

const IngredientDetailsPanel = ({ t, ingredientId }) => (
  <Tabs size="small" centered>
    <Tabs.TabPane tab={t("menu.Ingredients.DetailsPanel.UsageTab.Title")} key="usage">
      <UsageInTechCardsTable productId={ingredientId} />
    </Tabs.TabPane>
  </Tabs>
);

IngredientDetailsPanel.propTypes = {
  ingredientId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default IngredientDetailsPanel;
