import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useQueryParam } from "use-query-params";

import { parseFormErrors } from "utils/formErrors";
import salempos from "../../assets/salempos.png";

import SignupForm from "./components/SignupForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Image = styled.img`
  height: 120px;
  background-size: cover;
  margin-bottom: 32px;
`;

const Signup = ({ refetch }) => {
  const { t } = useTranslation();
  const [ref] = useQueryParam("ref");

  const handleSubmit = (values) => axios
    .post("/auth/admin/signup", { ...values, ref })
    .then(() => refetch())
    .catch(parseFormErrors);

  return (
    <Container>
      <Link to="/login">
        <Image src={salempos} alt="logo" />
      </Link>
      <SignupForm onSubmit={handleSubmit} t={t} />
    </Container>
  );
};

Signup.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default Signup;
