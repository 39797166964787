/* eslint-disable jsx-a11y/label-has-associated-control */

import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Col, Row, Button } from "antd";
import { useTranslation } from "react-i18next";

import { required } from "utils/formValidations";
import { renderSelect } from "components/FormItem";

const formItemLayout = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  style: { marginTop: 4, marginBottom: 4 },
};

const PlusButton = styled(Button)`
  margin-right: 10px;
`;

const StyledCol = styled(Col)`
  padding-top: 12px;
`;

const locationOptions = (locations) =>
  locations.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

const ProductFields = ({ fields, locations }) => {
  const { t } = useTranslation();
  if (fields.length === 0) fields.push({});

  return (
    <Row>
      <StyledCol
        xs={24}
        lg={10}
        className="ant-form-item-label ant-form-item-label-left"
      >
        <label className="ant-form-item-no-colon">
          {t("access.Employee.Form.Location.Label")}
        </label>
      </StyledCol>
      <Col xs={24} lg={14}>
        {fields.map((user_locations, index) => (
          <Row gutter={24} type="flex" justify="start" align="middle" key={index}>
            <Col span={20}>
              <Field
                name={`${user_locations}.location_id`}
                placeholder={t("access.Employee.Form.Location.Placeholder")}
                validate={required}
                component={renderSelect}
                options={locationOptions(locations)}
                formItemProps={formItemLayout}
              />
            </Col>
            {fields.length > 1 && (
            <Col span={4}>
              <Button type="danger" onClick={() => fields.remove(index)}>
                <DeleteOutlined />
              </Button>
            </Col>
            )}
          </Row>
        ))}
        <Form.Item>
          <PlusButton onClick={() => fields.push({})}>
            <PlusOutlined />
          </PlusButton>
          {fields.length > 1 && (
          <Button onClick={() => fields.removeAll()}>{t("DeleteAll")}</Button>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

ProductFields.propTypes = {
  fields: PropTypes.object.isRequired,
  locations: PropTypes.array,
};

export default ProductFields;
