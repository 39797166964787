import { createContainer } from "unstated-next";

const useConnect = (data) => ({
  settings: data.common_settings[0],
  merchant: data.merchants[0],
  locations: data.locations,
  currentUser: data.current_user_view[0],
});

export default createContainer(useConnect);
