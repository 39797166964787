import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Table, Button, Input, Space, notification,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQueryParam, NumberParam, StringParam, withDefault } from "use-query-params";

import Page from "components/Page";

import { sortByAlphabet } from "utils/sorts";

import { SUPPLIERS_QUERY } from "./supplierQueries";

const Suppliers = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 20));

  const { data, loading } = useQuery(SUPPLIERS_QUERY, {
    onError: notification.error,
  });

  const suppliers = useMemo(() => data?.suppliers.filter(({ name, phone_number, address }) => {
    const searchString = [name, phone_number, address].filter((v) => v).join("_");
    return search && search.length > 0 ? searchString.toLowerCase().includes(search) : true;
  }), [data, search]);

  return (
    <Page
      title={t("warehouse.Suppliers.Index.Title")}
      subTitle={data?.suppliers.length.toString()}
      extra={(
        <Link to="/warehouse/suppliers/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          style={{ width: 200 }}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
        />
      </Space>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        loading={loading}
        dataSource={suppliers}
      >
        <Table.Column
          title={t("warehouse.Suppliers.Columns.Name")}
          dataIndex="name"
          sorter={sortByAlphabet("name")}
        />
        <Table.Column
          title={t("warehouse.Suppliers.Columns.PhoneNumber")}
          dataIndex="phone_number"
        />
        <Table.Column
          title={t("warehouse.Suppliers.Columns.Address")}
          dataIndex="address"
          render={(value) => value || "-"}
        />
        <Table.Column
          title={t("warehouse.Suppliers.Columns.Description")}
          dataIndex="comment"
          render={(value) => value || "-"}
        />
        <Table.Column
          dataIndex="id"
          width={100}
          render={(id) => (
            <Space size="small">
              <Link to={`/warehouse/suppliers/${id}`}>{t("EditShort")}</Link>
            </Space>
          )}
        />
      </Table>
    </Page>
  );
};

Suppliers.propTypes = {};

export default Suppliers;
