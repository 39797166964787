import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button, Alert } from "antd";

import { renderInput, renderInputNumber } from "components/FormItem";
import { required } from "utils/formValidations";

import { TYPE_OPTIONS } from "../constants";

const StyledForm = styled(Form)`
  padding: 20px 40px;
`;

const AccountForm = ({
  handleSubmit, t, pristine, submitting, error, initialValues = {},
}) => (
  <StyledForm onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}
    <Row>
      <Col xs={24} md={20} lg={16} xl={12}>
        <Field
          name="name"
          label={t("finance.Account.Form.Name")}
          component={renderInput}
          validate={required}
        />
        <Field
          name="start_balance"
          label={t("finance.Account.Form.StartBalance")}
          component={renderInputNumber}
          options={TYPE_OPTIONS}
          validate={required}
          type="number"
          disabled={!!initialValues.id}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          type="primary"
          htmlType="submit"
          disabled={pristine || submitting}
        >
          {t("Save")}
        </Button>
      </Col>
    </Row>
  </StyledForm>
);

AccountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.object,
};

export default reduxForm({
  form: "accountForm",
})(AccountForm);
