import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Spin, Table, Typography, notification, Space, Drawer,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { formatPrice } from "utils/helpers";

// eslint-disable-next-line import/no-cycle
import PrepmealDetailsPanel from "../../prepmeals/components/PrepmealDetailsPanel";
// eslint-disable-next-line import/no-cycle
import TechCardDetailsPanel from "../../techCards/components/TechCardDetailsPanel";
// eslint-disable-next-line import/no-cycle
import ModifierDetailsPanel from "../../modifiers/components/ModifierDetailsPanel";

import { TECH_CARDS_USING_INGREDIENT_QUERY } from "../sharedMenuQueries";

const UsageInTechCardsTable = ({ techCardId, productId }) => {
  const { t } = useTranslation();
  const [selectedTechCard, setSelectedTechCard] = useState();
  const [selectedModifierGroup, setSelectedModifierGroup] = useState();

  const { data, loading } = useQuery(TECH_CARDS_USING_INGREDIENT_QUERY, {
    variables: { techCardId, productId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spin />
      </div>
    );
  }

  const techCardUsages = data?.tech_card_products.map(({ tech_card, product, ...tcp }) => {
    const value = tech_card || product;
    const bruttoWeight = tcp.quantity * value.unit_weight * 1000;
    return {
      ...tcp,
      value,
      bruttoWeight,
      nettoWeight: bruttoWeight * (1 - tcp.cold_loss),
      outputWeight: bruttoWeight * (1 - tcp.cold_loss) * (1 - tcp.hot_loss),
    };
  });

  const inPrepmeal = techCardUsages?.filter((tcp) => tcp.source_tech_card.menu_items.length === 0);
  const inTechCard = techCardUsages?.filter((tcp) => tcp.source_tech_card.menu_items.length > 0);

  const tables = [
    { key: "tech-cards", usages: inTechCard },
    { key: "prepmeals", usages: inPrepmeal },
  ].filter(({ usages }) => usages && usages.length > 0);

  if (tables.length === 0 && data?.modifier_items.length === 0) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography.Text type="secondary">
          {techCardId
            ? t("menu.UsageInTechCardsTable.TechCardNotUsed")
            : t("menu.UsageInTechCardsTable.IngredientNotUsed")}
        </Typography.Text>
      </div>
    );
  }

  return (
    <Space direction="vertical" size="middle">
      {tables.map(({ key, usages }) => (
        <div key={key}>
          <Typography.Text strong>
            {t(`menu.UsageInTechCardsTable.${
              key === "tech-cards" ? "UsageInTechCards" : "UsageInPrepmeals"}`)}
          </Typography.Text>
          <Table
            size="small"
            rowKey="id"
            bordered
            dataSource={usages}
            pagination={false}
          >
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.Name")}
              dataIndex="source_tech_card"
              width="20%"
              render={(techCard) => (
                <Typography.Link onClick={() => setSelectedTechCard(techCard)}>
                  {techCard.name}
                  {techCard.menu_items[0]?.menu_item_group.archived_at
                    && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
                </Typography.Link>
              )}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.BruttoUnit")}
              dataIndex="quantity"
              render={(quantity, { value }) => `${quantity} ${UNIT_LABELS_BY_VALUE[value.unit]}`}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.BruttoWeight")}
              dataIndex="bruttoWeight"
              render={(bruttoWeight) => `${(bruttoWeight).toFixed(0)} ${UNIT_LABELS_BY_VALUE.g}`}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.ColdProcessingLoss")}
              dataIndex="cold_loss"
              width="12%"
              render={(cold_loss) => `${cold_loss * 100}%`}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.NettoWeight")}
              dataIndex="nettoWeight"
              render={(nettoWeight) => `${(nettoWeight).toFixed(0)} ${UNIT_LABELS_BY_VALUE.g}`}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.HotProcessingLoss")}
              dataIndex="hot_loss"
              width="12%"
              render={(hot_loss) => `${hot_loss * 100}%`}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.OutputWeight")}
              dataIndex="outputWeight"
              render={(outputWeight) => `${(outputWeight).toFixed(0)} ${UNIT_LABELS_BY_VALUE.g}`}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.Cost")}
              dataIndex="cost"
              render={formatPrice}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.CostPercent.Title")}
              dataIndex="source_tech_card"
              width="15%"
              render={(stc, tc) => t("menu.UsageInTechCardsTable.Columns.CostPercent.Content", {
                percent: parseFloat(((tc.cost / stc.cost) * 100).toFixed(2)),
                totalCost: formatPrice(stc.cost),
              })}
            />
          </Table>
        </div>
      ))}
      {data?.modifier_items.length > 0 && (
        <div>
          <Typography.Text strong>
            {t("menu.UsageInTechCardsTable.UsageInModifierGroups")}
          </Typography.Text>
          <Table
            size="small"
            rowKey="id"
            bordered
            dataSource={data?.modifier_items
              .map((mi) => ({ ...mi, value: mi.tech_card || mi.product }))}
            pagination={false}
          >
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.ModifierGroupName")}
              dataIndex="modifier_group"
              width="20%"
              render={(modifierGroup) => (
                <Typography.Link onClick={() => setSelectedModifierGroup(modifierGroup)}>
                  {modifierGroup.name}
                  {modifierGroup.archived_at
                    && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
                </Typography.Link>
              )}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.Name")}
              dataIndex="name"
              width="20%"
              render={(name, { archived_at }) => (
                <Typography.Text>
                  {name}
                  {archived_at
                    && <Typography.Text type="danger"> ({t("Archived")})</Typography.Text>}
                </Typography.Text>
              )}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.BruttoUnit")}
              dataIndex="quantity"
              render={(quantity, { value }) => `${quantity} ${UNIT_LABELS_BY_VALUE[value.unit]}`}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.Cost")}
              dataIndex="cost"
              render={formatPrice}
            />
            <Table.Column
              title={t("menu.UsageInTechCardsTable.Columns.Price")}
              dataIndex="price"
              render={formatPrice}
            />
          </Table>
        </div>
      )}
      <Drawer
        title={(
          <>
            {selectedModifierGroup?.name}
            &nbsp;
            <Link to={`/menu/modifiers/${selectedModifierGroup?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedModifierGroup}
        onClose={() => setSelectedModifierGroup(null)}
        placement="bottom"
        height="60%"
      >
        {selectedModifierGroup && (
          <ModifierDetailsPanel t={t} modifierGroupId={selectedModifierGroup.id} />
        )}
      </Drawer>
      <Drawer
        title={(
          <>
            {selectedTechCard?.name}
            &nbsp;
            <Link to={selectedTechCard?.menu_items.length === 0
              ? `/menu/prepmeals/${selectedTechCard?.id}`
              : `/menu/tech-cards/${selectedTechCard?.menu_items[0]?.menu_item_group_id}`}
            >
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedTechCard}
        onClose={() => setSelectedTechCard(null)}
        placement="bottom"
        height="60%"
      >
        {selectedTechCard && (selectedTechCard.menu_items.length === 0 ? (
          <PrepmealDetailsPanel t={t} prepmealId={selectedTechCard.id} />
        ) : (
          <TechCardDetailsPanel t={t} techCardId={selectedTechCard.id} />
        ))}
      </Drawer>
    </Space>
  );
};

UsageInTechCardsTable.propTypes = {
  techCardId: PropTypes.number,
  productId: PropTypes.number,
};

export default UsageInTechCardsTable;
