import React from "react";
import { reduxForm, Field, FieldArray } from "redux-form";
import { useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Alert, Button, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { required } from "utils/formValidations";
import {
  renderSelect, renderDatePicker, renderTextarea, errorLayout,
} from "components/FormItem";

import ProductFields from "./ProductFields";

import { SUPPLIER_PRODUCTS_QUERY } from "../deliveryQueries";

const buttonsLayout = {
  wrapperCol: {
    md: { span: 15, offset: 6 },
    xs: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const DeliveryForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  isCompleting,
  change: changeField,
  initialValues = {},
  optionsData,
  error,
  handleComplete,
}) => {
  const client = useApolloClient();

  const handleSupplierSelect = (supplierId) => client.query({
    query: SUPPLIER_PRODUCTS_QUERY, variables: { supplierId },
  }).then(({ data }) => {
    const deliveryProductsValue = data.supplierProducts.map(({ product_id, price }) => {
      const product = optionsData.products.find((p) => p.id === product_id);
      const contentPath = [
        product.category_id ? "ingredients" : "products",
        product.category_id || product.menu_items[0].menu_item_group.category_id,
        product.id,
      ];
      return { price, product_id, product, content_path: contentPath };
    });
    changeField("delivery_products", deliveryProductsValue);
  });

  const suppliersOptions = (optionsData?.suppliers ?? [])
    .map((item) => ({ value: item.id, label: item.name }));

  const locationsOptions = (optionsData?.locations ?? [])
    .map((item) => ({ value: item.id, label: item.name }));

  const financeAccountsOptions = (optionsData?.finance_accounts ?? [])
    .map((item) => ({ value: item.id, label: item.name }));

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="datetime"
        label={t("warehouse.Deliveries.Form.DateAndTime")}
        component={renderDatePicker}
        formItemProps={formItemLayout}
        showTime
        placeholder={t("warehouse.Deliveries.Form.DateAndTime")}
        disabled={!!initialValues.id}
        validate={required}
      />
      <Field
        name="supplier_id"
        label={t("warehouse.Deliveries.Form.Supplier.Label")}
        component={renderSelect}
        style={{ width: 250 }}
        formItemProps={formItemLayout}
        placeholder={t("warehouse.Deliveries.Form.Supplier.Placeholder")}
        options={suppliersOptions}
        disabled={!!initialValues.id}
        showSearch
        validate={required}
        onSelect={(value) => handleSupplierSelect(value)}
      />
      <Field
        name="location_id"
        label={t("warehouse.Deliveries.Form.Location.Label")}
        component={renderSelect}
        style={{ width: 250 }}
        formItemProps={formItemLayout}
        options={locationsOptions}
        placeholder={t("warehouse.Deliveries.Form.Location.Placeholder")}
        disabled={!!initialValues.id}
        validate={required}
      />
      <Field
        name="finance_account_id"
        label={t("warehouse.Deliveries.Form.FinanceAccount.Label")}
        component={renderSelect}
        style={{ width: 250 }}
        formItemProps={formItemLayout}
        options={financeAccountsOptions}
        placeholder={t("warehouse.Deliveries.Form.FinanceAccount.Placeholder")}
        disabled={!!initialValues.id}
      />
      <Field
        name="description"
        label={t("warehouse.Deliveries.Form.Description")}
        component={renderTextarea}
        formItemProps={formItemLayout}
        disabled={initialValues.completed}
      />

      <Form.Item labelCol={{ span: 0 }} wrapperCol={{ xs: 24, xl: 18 }}>
        <FieldArray
          t={t}
          name="delivery_products"
          component={ProductFields}
          changeField={changeField}
          optionsData={optionsData}
          disabled={initialValues.completed}
        />
      </Form.Item>

      {error && (
        <Form.Item {...errorLayout}>
          <Alert message={error} type="error" />
        </Form.Item>
      )}

      <Form.Item {...buttonsLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={pristine || initialValues.completed}
            loading={submitting}
          >
            {t("Save")}
          </Button>
          {initialValues.id && (
            <Button
              type="primary"
              icon={<CheckOutlined />}
              disabled={!pristine || initialValues.completed}
              onClick={handleComplete}
              loading={isCompleting}
            >
              {t("Conduct")}
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

DeliveryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  isCompleting: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  optionsData: PropTypes.object,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  handleComplete: PropTypes.func,
};

export default reduxForm({
  form: "deliveryForm",
  initialValues: {
    delivery_products: [],
  },
  enableReinitialize: true,
})(DeliveryForm);
