import React from "react";
import PropTypes from "prop-types";
import {
  Col, notification, Row, Statistic, Popover, Divider,
} from "antd";
import { formatPrice } from "utils/helpers";
import { useQuery } from "@apollo/client";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";

import colors from "theme/colors";
import { transactionsGroupDate } from "pages/finance/transactions/Transactions";
import TransactionsTable from "pages/finance/transactions/components/TransactionsTable";

import { CASHIER_SHIFT_DETAILS_QUERY } from "../cashierShiftQueries";

const CashierShiftDetails = ({ shift, t }) => {
  const { data, loading } = useQuery(CASHIER_SHIFT_DETAILS_QUERY, {
    error: notification.error,
    fetchPolicy: "cache-and-network",
    variables: { shift_id: shift.id },
  });

  if (loading) return <LoadingOutlined />;

  const {
    cash_revenue,
    card_revenue,
    other_revenue,
    incomes,
    expenses,
    incassation,
    transactions,
  } = data.cashierShiftDetails;

  return (
    <div style={{ marginLeft: 50 }}>
      <Row gutter={16}>
        <Col span={4}>
          <Statistic
            valueStyle={{ color: colors.green }}
            title={t("finance.CashierShifts.Details.CashRevenue")}
            value={formatPrice(cash_revenue)}
          />
        </Col>
        <Col span={4}>
          <Statistic
            valueStyle={{ color: colors.green }}
            title={t("finance.CashierShifts.Details.CardRevenue")}
            value={formatPrice(card_revenue)}
          />
        </Col>
        <Col span={4}>
          <Statistic
            valueStyle={{ color: colors.green }}
            title={t("finance.CashierShifts.Details.OtherRevenue")}
            value={formatPrice(other_revenue.total)}
            suffix={other_revenue.detailed.length > 0 && (
            <Popover
              content={(
                <div style={{ marginRight: 10, marginLeft: 10 }}>
                  {other_revenue.detailed.map((d) => (
                    <>
                      <span>{d.payment_method_name}</span>:{" "}
                      {formatPrice(d.revenue)}
                      <br />
                    </>
                  ))}
                </div>
              )}
              trigger="click"
            >
              <InfoCircleOutlined style={{ color: colors.primaryColor, fontSize: 20 }} />
            </Popover>
            )}
          />
        </Col>
        <Col span={4}>
          <Statistic
            valueStyle={{ color: colors.green }}
            title={t("finance.CashierShifts.Details.Incomes")}
            value={formatPrice(incomes)}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={4}>
          <Statistic
            valueStyle={{ color: colors.red }}
            title={t("finance.CashierShifts.Details.Expenses")}
            value={formatPrice(expenses)}
          />
        </Col>
        <Col span={4}>
          <Statistic
            valueStyle={{ color: colors.red }}
            title={t("finance.CashierShifts.Details.Incassation")}
            value={formatPrice(incassation)}
          />
        </Col>
      </Row>
      <TransactionsTable
        style={{ marginTop: 20 }}
        dataSource={transactionsGroupDate(shift.open_correction_transaction
          ? [...transactions, shift.open_correction_transaction] : transactions)}
        pagination={false}
        t={t}
      />
    </div>
  );
};

CashierShiftDetails.propTypes = {
  t: PropTypes.func,
  shift: PropTypes.object.isRequired,
};

export default CashierShiftDetails;
