import React from "react";
import { reduxForm, Field } from "redux-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Alert, Button, Row, Col } from "antd";

import { required } from "utils/formValidations";
import {
  renderInput,
  renderInputNumber,
  renderSelect,
  renderCheckbox,
} from "components/FormItem";
import { PAYMENT_METHOD_TYPE } from "constants/index";

const StyledForm = styled(Form)`
  margin: 20px 40px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-top: 20px;
`;

const PaymentMethodForm = ({
  initialValues, t, error, handleSubmit, pristine, submitting, financeAccounts,
}) => {
  const financeAccountOptions = financeAccounts.map(({ id, name }) => ({
    value: id, label: name,
  }));
  const cashOrCard = initialValues.type === PAYMENT_METHOD_TYPE.CASH
    || initialValues.type === PAYMENT_METHOD_TYPE.CARD;

  return (
    <StyledForm onSubmit={handleSubmit}>
      {error && <Alert type="error" message={error} />}
      <Row>
        <Col xs={24} md={20} lg={16} xl={12}>
          <Field
            name="name"
            label={t("settings.PaymentMethod.Form.Name.Label")}
            validate={required}
            component={renderInput}
            disabled={cashOrCard}
          />
          {cashOrCard || (
            <Field
              name="account_id"
              label={t("settings.PaymentMethod.Form.FinanceAccount.Label")}
              component={renderSelect}
              options={financeAccountOptions}
            />
          )}
          <Field
            name="processing_fee_percent"
            label={t("settings.PaymentMethod.Form.ProcessingFeePercent.Label")}
            component={renderInputNumber}
            min={0}
          />
          <Field
            name="processing_fee_amount"
            label={t("settings.PaymentMethod.Form.ProcessingFeeAmount.Label")}
            component={renderInputNumber}
            min={0}
          />

          <Field
            name="show_in_pos"
            label={t("settings.PaymentMethod.Form.ShowInPOS.Label")}
            component={renderCheckbox}
          />
          <Field
            name="show_in_callcenter"
            label={t("settings.PaymentMethod.Form.ShowInCallcenter.Label")}
            component={renderCheckbox}
          />
          <StyledFormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              {t("Save")}
            </Button>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
};

PaymentMethodForm.propTypes = {
  initialValues: PropTypes.object,
  financeAccounts: PropTypes.array,
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: "PaymentMethodForm",
})(PaymentMethodForm);
