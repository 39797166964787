import React from "react";
import { Route, Switch } from "react-router-dom";

import Deliveries from "./Deliveries";
import NewDelivery from "./NewDelivery";
import EditDelivery from "./EditDelivery";

const Container = () => (
  <Switch>
    <Route path="/warehouse/deliveries/new" component={NewDelivery} />
    <Route path="/warehouse/deliveries/:id" component={EditDelivery} />
    <Route path="/warehouse/deliveries" component={Deliveries} />
  </Switch>
);

export default Container;
