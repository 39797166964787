import React from "react";
import { Route } from "react-router-dom";

import Orders from "./Orders";

const Container = () => (
  <Route path="/orders/:orderId?" component={Orders} />
);

export default Container;
