import React from "react";
import PropTypes from "prop-types";
import { Card, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import Page from "components/Page";

import WriteOffForm from "./components/WriteOffForm";

import { CREATE_WRITE_OFF_MUTATION } from "./writeOffQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

const NewWriteOff = ({ history }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const [createWriteOff] = useMutation(CREATE_WRITE_OFF_MUTATION);

  if (loading) {
    return <Spin />;
  }

  const handleSubmit = ({ write_off_products, ...values }) =>
    createWriteOff({
      variables: {
        data: {
          ...values,
          write_off_products: write_off_products.map(({ product, content_path, ...mp }) => mp),
        },
      },
    })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("warehouse.WriteOffs.AddPage.Title")}>
      <Card>
        <WriteOffForm t={t} optionsData={data} onSubmit={handleSubmit} />
      </Card>
    </Page>
  );
};

NewWriteOff.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewWriteOff;
