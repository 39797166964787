/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";

import UsageInTechCardsTable from "../../shared/components/UsageInTechCardsTable";
import TechCardProductsTable from "../../shared/components/TechCardProductsTable";

const PrepmealDetailsPanel = ({ t, prepmealId }) => (
  <Tabs size="small" centered>
    <Tabs.TabPane tab={t("menu.Prepmeals.DetailsPanel.IngredientsTab.Title")} key="ingredients">
      <TechCardProductsTable techCardId={prepmealId} />
    </Tabs.TabPane>
    <Tabs.TabPane tab={t("menu.Prepmeals.DetailsPanel.UsageTab.Title")} key="usage">
      <UsageInTechCardsTable techCardId={prepmealId} />
    </Tabs.TabPane>
  </Tabs>
);

PrepmealDetailsPanel.propTypes = {
  prepmealId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default PrepmealDetailsPanel;
