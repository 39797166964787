import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Button, notification, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";
import { CheckCircleOutlined, BarcodeOutlined } from "@ant-design/icons";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import colors from "theme/colors";
import { formatPrice } from "utils/helpers";

import { INVENTORIES_QUERY } from "./inventoriesQueries";

const Inventories = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 30));

  const [getInventories, { data, loading }] = useLazyQuery(INVENTORIES_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  useEffect(_.throttle(() => {
    if (filter?.time) {
      getInventories({
        variables: {
          limit: pageSize,
          offset: page * pageSize - pageSize,
          filter: {
            datetime: { _gte: filter.time.from, _lt: filter.time.to },
            ...(filter.locationId ? { location_id: { _eq: filter.locationId } } : {}),
          },
        },
      });
    }
  }, 200), [page, pageSize, filter]);

  return (
    <Page
      title={t("warehouse.Inventories.Index.Title")}
      subTitle={data?.inventories_aggregate.aggregate.count.toString()}
      extra={(
        <Link to="/warehouse/inventories/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <FilterPanelWithQuery
          initialData={{ time: { value: "this_month" } }}
          locations={data?.locations ?? []}
          onFilter={(values) => { setPage(1); setFilter(values); }}
        />
      </Space>
      <Table
        rowKey="id"
        loading={loading}
        dataSource={data?.inventories}
        pagination={{
          total: data?.inventories_aggregate.aggregate.count,
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
      >
        <Table.Column
          title="#"
          dataIndex="number"
          width={80}
          align="center"
        />
        <Table.Column
          title={t("warehouse.Inventories.Columns.Datetime")}
          dataIndex="datetime"
          render={(datetime) => moment(datetime).format("lll")}
        />
        <Table.Column
          title={t("warehouse.Inventories.Columns.Categories")}
          width="30%"
          dataIndex="inventory_categories"
          render={(categories) => categories.map(({ type, menu_category, product_category }) => {
            switch (type) {
              case "tech_cards": return `${menu_category.name} (${t("ShortTechCard")})`;
              case "prepmeals": return t("Prepmeals");
              default: return menu_category?.name ?? product_category?.name;
            }
          }).join(", ")}
        />
        <Table.Column
          title={t("warehouse.Inventories.Columns.Description")}
          dataIndex="description"
          render={(value) => value || "-"}
        />
        <Table.Column
          title={t("warehouse.Inventories.Columns.Warehouse")}
          dataIndex={["location", "name"]}
        />
        <Table.Column
          title={t("warehouse.Inventories.Columns.Amount")}
          dataIndex="inventory_counts"
          render={(counts) => {
            const amount = counts.reduce((acc, { actual_quantity, expected_quantity, cost }) => (
              acc + cost * (parseFloat(actual_quantity) - parseFloat(expected_quantity))
            ), 0);
            return (
              <span style={{ color: amount >= 0 ? colors.green : colors.red }}>
                {formatPrice(amount)}
              </span>
            );
          }}
        />
        <Table.Column
          title={t("warehouse.Inventories.Columns.Status")}
          dataIndex="completed"
          render={(completed) => (completed ? (
            <Tag icon={<CheckCircleOutlined />} color={colors.green}>{t("Conducted")}</Tag>
          ) : (
            <Tag icon={<BarcodeOutlined />} color={colors.secondaryColor}>{t("Started")}</Tag>
          ))}
        />
        <Table.Column
          dataIndex="id"
          width={100}
          render={(id, { completed }) => (
            <Link to={`/warehouse/inventories/${id}`}>
              <Button type="link" size="small">
                {completed ? t("Details") : t("EditShort")}
              </Button>
            </Link>
          )}
        />
      </Table>
    </Page>
  );
};

Inventories.propTypes = {};

export default Inventories;
