import React from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Alert, Button } from "antd";
import { reduxForm, Field } from "redux-form";

import { required } from "utils/formValidations";
import { renderInput } from "components/FormItem";

const LocationForm = ({ t, handleSubmit, error, pristine, submitting }) => (
  <Form onSubmit={handleSubmit}>
    {error && <Alert message={error} type="error" />}

    <Field
      name="name"
      label={t("onboarding.LocationForm.Location.Label")}
      validate={required}
      component={renderInput}
      formItemProps={{ wrapperCol: { span: 6 } }}
      placeholder={t("onboarding.LocationForm.Location.Placeholder")}
    />

    <Form.Item wrapperCol={{ offset: 10 }}>
      <Button
        disabled={pristine || submitting}
        htmlType="submit"
        type="primary"
      >
        {t("onboarding.Buttons.Next")}
      </Button>
    </Form.Item>
  </Form>
);

LocationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  error: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({ form: "locationForm" })(LocationForm);
