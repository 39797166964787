import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { parseFormErrors } from "utils/formErrors";
import salempos from "../../assets/salempos.png";

import LoginForm from "./components/LoginForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Image = styled.img`
  height: 120px;
  background-size: cover;
  margin-bottom: 32px;
`;

const Login = ({ refetch }) => {
  const { t } = useTranslation();

  const handleSubmit = (values) => axios.post("/auth/admin/login", values)
    .then(() => refetch())
    .catch((err) => {
      const { message } = err.response?.data;
      if (message === "Not Found" || message === "Errors.Auth.InvalidPassword") {
        return parseFormErrors(new Error(t("login.Form.IncorrectLoginOrPassword")));
      }
      return parseFormErrors(err);
    });

  return (
    <Container>
      <Image src={salempos} alt="logo" />
      <LoginForm onSubmit={handleSubmit} t={t} />
    </Container>
  );
};

Login.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default Login;
