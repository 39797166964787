import gql from "graphql-tag";

export const ACCESS_ROLES_QUERY = gql`
  query GetAccessRoles @hasura {
    access_roles {
      id
      name
      access_cashier
      access_finance
      access_location_admin
      access_marketing
      access_menu
      access_statistics
      access_orders
      access_users
      access_warehouse
      access_callcenter
      access_courier
    }
  }
`;

export const EDIT_ACCESS_ROLE_QUERY = gql`
  query EditAccessRoles($id: Int!) @hasura {
    access_roles(where: { id: { _eq: $id } }) {
      id
      name
      access_cashier
      access_finance
      access_location_admin
      access_marketing
      access_menu
      access_statistics
      access_orders
      access_users
      access_warehouse
      access_callcenter
      access_courier
      payroll_hour
      payroll_month
      payroll_shift
      percent_personal_sales
      percent_shift_sales
    }
  }
`;
