import gql from "graphql-tag";

export const INVENTORIES_QUERY = gql`
  query GetInventories($limit: Int!, $offset: Int!, $filter: inventories_bool_exp!) @hasura {
    inventories(limit: $limit, offset: $offset, where: $filter, order_by: {number: desc}) {
      id
      number
      completed
      description
      created_at
      updated_at
      datetime
      location {
        id
        name
      }
      inventory_counts {
        id
        expected_quantity
        actual_quantity
        cost
      }
      inventory_categories {
        id
        type
        menu_category {
          id
          name
        }
        product_category {
          id
          name
        }
      }
    }
    locations(order_by: {id: asc}) {
      id
      name
    }
    inventories_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
`;

export const EDIT_INVENTORY_QUERY = gql`
  query EditInventoryQuery($id: Int!) @hasura {
    inventories_by_pk(id: $id) {
      id
      description
      location_id
      completed
      user_id
      datetime
      inventory_categories {
        id
        type
        category_id
        menu_category_id
      }
      inventory_counts {
        id
        product_id
        cost
        expected_quantity
        actual_quantity
        deliveries
        movings_in 
        product_spendings
        movings_out
        write_offs
        productions
        production_spendings
        product {
          id
          name
          unit
        }
        last_inventory_count {
          actual_quantity
          datetime
        }
      }
    }
  }
`;

export const DELIVERY_QUERY = gql`
  query GetDeliveryProducts (
    $product_id: Int!,
    $location_id: Int!,
    $datetime: timestamptz_comparison_exp!,
  ) @hasura {
    delivery_products (
      where: {
        product_id: { _eq: $product_id }
        delivery: {
          completed: { _eq: true }
          location_id: { _eq: $location_id }
          datetime: $datetime
        }
      }
    ) {
      id
      delivery {
        datetime
        supplier {
          name
        }
        user {
          name
        }
      }
      quantity
      price
      quantity
    }
    moving_products(
      where: {
        product_id: { _eq: $product_id }
        datetime: $datetime
        moving: {
          completed: { _eq: true }
          location_to_id: { _eq: $location_id }
        }
      }
    ) {
      moving {
        datetime
        location_from_id
        location_from {
          name
        }
        user {
          name
        }
      }
      quantity
    }
  }
`;

export const PRODUCT_SPENDINGS_QUERY = gql`
  query GetProductSpendings($filter: order_line_product_spendings_bool_exp) @hasura {
    order_line_product_spendings(where: $filter) {
      id
      quantity
      product_id
      order_line {
        display_name
        count
        order {
          time
          list_user
        }
      }
    }
  }
`;

export const WRITE_OFF_QUERY = gql`
  query GetWriteOffProducts(
    $product_id: Int!,
    $location_id: Int!,
    $datetime: timestamptz_comparison_exp!,
  ) @hasura {
    write_off_products(
      where: {
        product_id: { _eq: $product_id }
        datetime: $datetime
        write_off: {
          location_id: { _eq: $location_id }
        }
      }
    ) {
      id
      write_off {
        datetime
        user {
          name
        }
      }
      quantity
    }
    moving_products(
      where: {
        product_id: { _eq: $product_id }
        datetime: $datetime
        moving: {
          location_from_id: { _eq: $location_id }
        }
      }
    ) {
      moving {
        datetime
        location_to_id
        location_to {
          name
        }
        user {
          name
        }
      }
      quantity
    }
  }
`;

export const CREATE_INVENTORY_MUTATION = gql`
  mutation CreatInventory($data: CreateInventoryInput!) {
    createInventory(data: $data) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_MUTATION = gql`
  mutation UpdateInventory($inventory_id: Int!, $data: UpdateInventoryInput!) {
    updateInventory(inventory_id: $inventory_id, data: $data) {
      id
    }
  }
`;

export const COMPLETE_INVENTORY_MUTATION = gql`
  mutation CompleteInventory($inventory_id: Int!) {
    completeInventory(inventory_id: $inventory_id) {
      id
    }
  }
`;
