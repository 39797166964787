import React from "react";
import PropTypes from "prop-types";
import {
  Form, Button, Alert, Input, Typography,
} from "antd";

import { PHONE_NUMBER_REGEX } from "utils/formValidations";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 13 },
  },
};

const buttonLayout = {
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 14, offset: 8 },
  },
  style: {
    marginTop: 18,
  },
};

const errorLayout = {
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 14, offset: 2 },
  },
  style: {
    marginTop: 24,
  },
};

const SupplierForm = ({ t, onSubmit, initialValues, submitting, error }) => (
  <Form name="supplier" {...formItemLayout} initialValues={initialValues} onFinish={onSubmit}>
    <Form.Item
      name="name"
      label={t("warehouse.Suppliers.Form.Name")}
      rules={[{ required: true, whitespace: true }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="phone_number"
      label={t("warehouse.Suppliers.Form.Phone")}
      rules={[{ required: true, pattern: PHONE_NUMBER_REGEX }]}
    >
      <Input />
    </Form.Item>

    <Form.Item name="address" label={t("warehouse.Suppliers.Form.Address")}>
      <Input.TextArea />
    </Form.Item>

    <Form.Item name="business_number" label={t("warehouse.Suppliers.Form.BusinessNumber")}>
      <Input />
    </Form.Item>

    <Form.Item name="comment" label={t("Description")}>
      <Input.TextArea />
    </Form.Item>

    <Form.Item {...buttonLayout}>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {t("warehouse.Suppliers.Form.BankInformation")}
      </Typography.Title>
      <Typography.Text type="secondary">
        {t("warehouse.Suppliers.Form.BasicBankData")}
      </Typography.Text>
    </Form.Item>

    <Form.Item name="iban" label="IBAN">
      <Input />
    </Form.Item>

    <Form.Item name="bic" label="BIC">
      <Input />
    </Form.Item>

    <Form.Item name="bank_name" label={t("warehouse.Suppliers.Form.BankName")}>
      <Input />
    </Form.Item>

    {error && (
      <Form.Item {...errorLayout}>
        <Alert message={error} type="error" />
      </Form.Item>
    )}

    <Form.Item {...buttonLayout}>
      <Button disabled={submitting} type="primary" htmlType="submit">
        {t("Save")}
      </Button>
    </Form.Item>
  </Form>
);

SupplierForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

export default SupplierForm;
