import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import Page from "components/Page";
import { parseFormErrors } from "utils/formErrors";

import IngredientCategoryForm from "./components/IngredientCategoryForm";
import {
  CREATE_INGREDIENT_CATEGORY_MUTATION,
  INGREDIENT_CATEGORIES_QUERY,
} from "./ingredientCategoryQueries";

const NewIngredientCategory = ({ history }) => {
  const { t } = useTranslation();
  const [createIngredientCategory] = useMutation(CREATE_INGREDIENT_CATEGORY_MUTATION, {
    refetchQueries: [{ query: INGREDIENT_CATEGORIES_QUERY }],
  });

  const handleSubmit = (values) =>
    createIngredientCategory({ variables: { data: values } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("menu.IngredientCategories.AddPage.Title")}>
      <Row>
        <Col xs={24} md={18} lg={14}>
          <Card>
            <IngredientCategoryForm t={t} onSubmit={handleSubmit} />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

NewIngredientCategory.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewIngredientCategory;
