import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "antd";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Page from "components/Page";
import { parseFormErrors } from "utils/formErrors";

import WorkshopForm from "./components/WorkshopForm";
import { CREATE_WORKSHOP_MUTATION, WORKSHOPS_QUERY } from "./workshopQueries";
import { SELECT_OPTIONS } from "../prepmeals/prepmealQueries";
import { SELECT_OPTIONS_QUERY } from "../techCards/techCardQueries";

const NewWorkshop = ({ history }) => {
  const { t } = useTranslation();
  const [createWorkshop] = useMutation(CREATE_WORKSHOP_MUTATION, {
    refetchQueries: [
      { query: WORKSHOPS_QUERY },
      { query: SELECT_OPTIONS_QUERY },
      { query: SELECT_OPTIONS },
    ],
  });

  const handleSubmit = (values) =>
    createWorkshop({
      variables: { data: values },
    })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("menu.Workshops.AddPage.Title")}>
      <Row>
        <Col xs={24} md={18} lg={14}>
          <Card>
            <WorkshopForm t={t} onSubmit={handleSubmit} />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

NewWorkshop.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewWorkshop;
