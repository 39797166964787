import React from "react";
import styled from "styled-components";
import { Table, Row, Col, Button, notification } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { DELIVERY_ZONE_COLUMNS } from "./constants";
import Map from "../../../components/Map";
import { DELIVERY_ZONES_AND_LOCATIONS_QUERY } from "./deliveryZoneQueries";

const Container = styled.div`
  padding: 12px;

  .row-button {
    padding-bottom: 12px;
  }

  .row-map {
    margin: 40px;
  }

  .flex-col {
    display: flex;
    justify-content: flex-end;
  }

  .map-wrapper {
    width: 100%;
    height: 500px;
  }
`;

const DeliveryZones = ({ history }) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(DELIVERY_ZONES_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });

  return (
    <Container>
      <Row className="row-button" type="flex" justify="end">
        <Col className="flex-col" span={4}>
          <Button
            type="primary"
            onClick={() => history.push("/settings/delivery-zones/new")}
          >
            {t("Add")}
          </Button>
        </Col>
      </Row>
      <Table
        rowKey="id"
        columns={DELIVERY_ZONE_COLUMNS}
        dataSource={data ? data.delivery_zones : []}
        loading={loading}
      />
      <Row className="row-map">
        <Col span={24}>
          <div className="map-wrapper">
            <Map dataMap={data ? data.delivery_zones : []} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

DeliveryZones.propTypes = {
  history: PropTypes.object.isRequired,
};

export default DeliveryZones;
