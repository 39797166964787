import React, { useMemo, useState } from "react";
import { Row, Col, Radio, Card } from "antd";
import PropTypes from "prop-types";

import LineChart from "components/LineChart";
import BarCharts from "components/BarCharts";

const Charts = ({ data, t }) => {
  const [periodType, setPeriodType] = useState("day");
  const chartsData = useMemo(
    () =>
      data.menuItemStatsByDay.map((el) => ({ day: el.day, value: el.count })),
    [data],
  );

  return (
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <Card
          title={t("statistics.Sales.LineChart.Revenue")}
          extra={(
            <Radio.Group defaultValue="day" size="small">
              <Radio.Button value="day" onClick={() => setPeriodType("day")}>
                {t("statistics.Sales.LineChart.Day")}
              </Radio.Button>
              <Radio.Button value="week" onClick={() => setPeriodType("week")}>
                {t("statistics.Sales.LineChart.Week")}
              </Radio.Button>
              <Radio.Button value="month" onClick={() => setPeriodType("month")}>
                {t("statistics.Sales.LineChart.Month")}
              </Radio.Button>
            </Radio.Group>
          )}
        >
          <LineChart periodType={periodType} data={chartsData} />
        </Card>
      </Col>

      <Col span={24}>
        <BarCharts
          t={t}
          statsByWeekday={data.menuItemStatsByWeekday}
          statsByHour={data.menuItemStatsByHour}
          type="count"
        />
      </Col>
    </Row>
  );
};

Charts.propTypes = {
  data: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default Charts;
