import React from "react";
import PropTypes from "prop-types";
import { Spin, notification, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";

import { parseFormErrors } from "utils/formErrors";

import InventoryForm from "./components/InventoryForm";
import { CREATE_INVENTORY_MUTATION } from "./inventoriesQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

const NewInventory = ({ history }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const [createInventory] = useMutation(CREATE_INVENTORY_MUTATION);

  if (loading) {
    return <Spin />;
  }

  const handleSubmit = ({ inventory_categories, ...rest }) => {
    const inventoryCategories = inventory_categories.map(JSON.parse);
    return createInventory({
      variables: {
        data: { ...rest, inventory_categories: inventoryCategories },
      },
    })
      .then((res) => res.data.createInventory)
      .then((inventory) => history.replace(`/warehouse/inventories/${inventory.id}`))
      .catch(parseFormErrors);
  };

  return (
    <Page showBackIcon title={t("warehouse.Inventories.CreatePage.Title")}>
      <Card>
        <InventoryForm t={t} optionsData={data} onSubmit={handleSubmit} />
      </Card>
    </Page>
  );
};

NewInventory.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewInventory;
