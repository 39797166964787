import React from "react";
import styled from "styled-components";
import { DateTime } from "luxon";

import { Span } from "theme/globalStyles";
import colors from "theme/colors";

import i18n from "../../../i18n";
import { getAccountName } from "../accounts/constants";
import { getCategoryName } from "../categories/constants";

const Text = styled.span`
  color: ${(props) => props.color};
`;

export const TRANSACTION_TYPES = {
  EXPENSE: "expense",
  INCOME: "income",
  TRANSFER: "transfer",
};

export const CURRENCY_SYMBOLS = {
  USD: "$",
  EUR: "€",
  KZT: "₸",
  RUB: "₽",
  TRY: "₺",
  UZS: "СУМ",
};

export const TRANSACTIONS_COLUMNS = [
  {
    title: i18n.t("finance.Transaction.Columns.Date"),
    dataIndex: "date",
    render: (value) => value && (
      <Span weight="700">{value.toLocaleString(DateTime.DATE_MED)}</Span>
    ),
  },
  {
    title: i18n.t("finance.Transaction.Columns.Category"),
    dataIndex: "category",
    render: (category, record) => {
      if (record.type === TRANSACTION_TYPES.TRANSFER) {
        return i18n.t("finance.Transaction.Transfer");
      }
      return getCategoryName(category?.name || "");
    },
  },
  {
    title: i18n.t("finance.Transaction.Columns.Description"),
    dataIndex: "description",
  },
  {
    title: i18n.t("finance.Transaction.Columns.Amount"),
    dataIndex: "amount",
    render: (amount, record) => {
      const sum = amount;

      if (record.type === TRANSACTION_TYPES.INCOME) {
        return <Text color={colors.green}>+{sum}</Text>;
      }
      if (record.type === TRANSACTION_TYPES.EXPENSE) {
        return <Text color={colors.red}>-{sum}</Text>;
      }

      const sumTo = record.transfer_to_amount;

      return <Text color={colors.grey}>-{sum} → {sumTo}</Text>;
    },
  },
  {
    title: i18n.t("finance.Transaction.Columns.Account"),
    dataIndex: "account",
    render: (account, record) => (record.type === TRANSACTION_TYPES.TRANSFER
      ? `${getAccountName(account)} → ${getAccountName(record.transfer_to_account)}`
      : getAccountName(account)),
  },
  {
    title: i18n.t("finance.Transaction.Columns.Time"),
    dataIndex: "time",
    render: (time) => DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE),
  },
];

export const TRANSACTION_BUTTONS = [
  {
    value: TRANSACTION_TYPES.EXPENSE,
    label: i18n.t("finance.Transaction.Costs"),
  },
  {
    value: TRANSACTION_TYPES.INCOME,
    label: i18n.t("finance.Transaction.Income"),
  },
  {
    value: TRANSACTION_TYPES.TRANSFER,
    label: i18n.t("finance.Transaction.Transfer"),
  },
];
