import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";
import NewIngredientPane from "components/NewIngredientPane";
import NewWorkshopPane from "components/NewWorkshopPane";
import NewCategoryPane from "components/NewCategoryPane";

import TechCardForm from "./components/TechCardForm";
import {
  SELECT_OPTIONS_QUERY, CREATE_TECH_CARD_MUTATION,
} from "./techCardQueries";
import { SELECT_OPTIONS_QUERY as SET_OPTIONS_QUERY } from "../sets/setQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const NewTechCards = ({ history }) => {
  const { t } = useTranslation();
  const [isCreatingIngridient, setCreatingIngridient] = useState(false);
  const [isCreatingWorkshop, setCreatingWorkshop] = useState(false);
  const [isCreatingCategory, setCreatingCategory] = useState(false);

  const { loading, data } = useQuery(SELECT_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [createTechCard] = useMutation(CREATE_TECH_CARD_MUTATION, {
    refetchQueries: [
      { query: SET_OPTIONS_QUERY },
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });

  if (loading) return <Spin />;

  const handleSubmit = ({
    total_cost, output, is_produced, is_weighted, name, tech_card,
    menu_item: { limited_location_availability = [], cost, price, menu_item_modifier_groups },
    ...itemGroup
  }) => {
    const menuItem = {
      name,
      price,
      menu_item_modifier_groups: { data: menu_item_modifier_groups || [] },
      limited_location_availability: limited_location_availability?.length === 0
        ? null : limited_location_availability,
      tech_card: {
        data: {
          ...tech_card,
          name,
          cost,
          is_set: false,
          tech_card_products: {
            data: tech_card.tech_card_products
              .map(({ content, brutto_unit, cold_loss, hot_loss, cost: pCost }) => ({
                ...content, quantity: brutto_unit, cost: pCost, cold_loss, hot_loss,
              })),
          },
          product: is_produced ? {
            data: {
              name,
              cost,
              unit: tech_card.unit,
              unit_weight: tech_card.unit_weight,
            },
          } : undefined,
        },
      },
    };

    return createTechCard({
      variables: { data: { ...itemGroup, menu_items: { data: [menuItem] }, name } },
    })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  return (
    <Page showBackIcon title={t("menu.TechCards.AddPage.Title")}>
      <Card>
        <TechCardForm
          t={t}
          onSubmit={handleSubmit}
          optionsData={data}
          onIngridientCreate={() => setCreatingIngridient(true)}
          onWorkshopCreate={() => setCreatingWorkshop(true)}
          onCategoryCreate={() => setCreatingCategory(true)}
        />
      </Card>
      <NewIngredientPane
        isOpen={isCreatingIngridient}
        onClose={() => setCreatingIngridient(false)}
      />
      <NewWorkshopPane
        isOpen={isCreatingWorkshop}
        onClose={() => setCreatingWorkshop(false)}
      />
      <NewCategoryPane
        isOpen={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
      />
    </Page>
  );
};

NewTechCards.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewTechCards;
