import React, { useState } from "react";
import { Button, Modal, Row, Col } from "antd";
import FoodIcon, { MAP_ICON_NAMES } from "assets/food-icons";
import { render } from "components/FormItem";
import styled, { css } from "styled-components";
import colors from "theme/colors";

const IconWrapper = styled.div`
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 5px;
  transition: 0.1s;
  ${(props) => (props.selected ? css`
    background: ${colors.selectedBlue};
  ` : css`
    :hover {
      background: ${colors.whisper};
    }
  `)};
`;

const IconInput = ({ t, input, ...props }) => {
  const [modalVisible, setModalVisible] = useState();

  return render({ input, ...props }, () => (
    <div style={{ display: "flex", flexDirection: "row", marginLeft: 10 }}>
      <FoodIcon
        name={input.value || "dinner"}
        style={{ height: 32 }}
        stroke="#000000"
      />
      <Button
        type="link"
        onClick={() => setModalVisible(true)}
      >
        {t("menu.MenuCategories.Form.Icon.Button")}
      </Button>
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        title={t("menu.MenuCategories.Form.Icon.Modal.Title")}
        footer={false}
      >
        <Row gutter={[8, 8]}>
          {Object.keys(MAP_ICON_NAMES).map((iconName) => (
            <Col span={4}>
              <IconWrapper
                selected={input.value === iconName}
                onClick={() => {
                  input.onChange(iconName);
                  setModalVisible(false);
                }}
              >
                <FoodIcon
                  name={iconName}
                  stroke={input.value === iconName ? "#FFFFFF" : "#000000"}
                />
              </IconWrapper>
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  ));
};

export default IconInput;
