import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";

import PaymentMethodBar from "./PaymentMethodBar";
import OrderTypeBar from "./OrderTypeBar";

const HorizontalBarCharts = ({ t, data, paymentMethods }) => (
  <Row gutter={16}>
    <Col span={12}>
      <PaymentMethodBar t={t} data={data} paymentMethods={paymentMethods} />
    </Col>
    <Col span={12}>
      <OrderTypeBar t={t} data={data} />
    </Col>
  </Row>
);

HorizontalBarCharts.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
};

export default HorizontalBarCharts;
