import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import moment from "moment-timezone";
import preval from "preval.macro";
import axios from "axios";

import "moment/locale/ru";
import ruRU from "antd/lib/locale/ru_RU";

import GlobalStyle from "theme/globalStyles";
import { store } from "./store";
import AppRouter from "./AppRouter";
import client from "./client";
import "./i18n";

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

const buildTime = preval`module.exports = new Date().toLocaleString([], {timeZoneName: "short"});`;
console.log(`Build time: ${buildTime}`);

const isProd = process.env.NODE_ENV === "production";

Sentry.init({
  dsn: "https://8339b52171bf45b28ae897465e01061f@o558138.ingest.sentry.io/5699231",
  enabled: isProd,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.6,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && !window.location.href.includes("/login")) {
      window.location.assign("/login");
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConfigProvider locale={ruRU} form={{ requiredMark: false }}>
        <GlobalStyle />
        <AppRouter />
      </ConfigProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root"),
);
