import gql from "graphql-tag";

export const FINANCE_TRANSACTIONS_QUERY = gql`
  query GetFinanceTransactions($time: TimeInput, $limit: Int, $offset: Int) {
    financeTransactions(time: $time, limit: $limit, offset: $offset)  {
      id
      type
      transfer_to_account_id
      transfer_to_account {
        name
        location {
          id
          name
        }
      }
      transfer_to_amount
      description
      amount
      time
      category_id
      category {
        name
      }
      account_id
      account {
        name
        location {
          id
          name
        }
      }
      user {
        id
        name
      }
    }
    financeTransactionsCount(time: $time) {
      count
    }
  }
`;

export const FINANCE_TRANSACTION_FORM_DATA_QUERY = gql`
  query TransactionFormData {
    financeCategories {
      id
      name
    }
    financeAccounts {
      id
      name
      location {
        id
        name
      }
    }
  }
`;

export const CREATE_FINANCE_TRANSACTION_QUERY = gql`
  mutation CreateTranstaction($account_id: Int!, $data: FinanceTransactionInput!) {
    createFinanceTransaction(account_id: $account_id, data: $data) {
      id
      type
      transfer_to_amount
      description
      amount
      time
      category_id
      account_id
    }
  }
`;
