import i18n from "../../../i18n";

export const RECEIPT_COLUMNS = [
  {
    title: i18n.t("statistics.Receipts.Columns.Waiter"),
    dataIndex: "waiter",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Open"),
    dataIndex: "open",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Close"),
    dataIndex: "close",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Paid"),
    dataIndex: "paid",
    render: (paid) => `${paid} тг.`,
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Discount"),
    dataIndex: "discount",
    render: (discount) => `${discount} тг.`,
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Profit"),
    dataIndex: "profit",
    render: (profit) => `${profit} тг.`,
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Status"),
    dataIndex: "status",
  },
];

export const EXPANDED_TABLE_HEADER_ACCOUNT = [
  {
    title: i18n.t("statistics.Receipts.Columns.Item"),
    dataIndex: "product",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Price"),
    dataIndex: "price",
    render: (price) => `${price} тг.`,
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Quantity"),
    dataIndex: "quantity",
    render: (quantity) => `${quantity} шт.`,
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Total"),
    dataIndex: "total",
    render: (total) => `${total} тг.`,
  },
];

export const EXPANDED_TABLE_HEADER_HISTORY = [
  {
    title: i18n.t("statistics.Receipts.Columns.Time"),
    dataIndex: "time",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Terminal"),
    dataIndex: "terminal",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Action"),
    dataIndex: "action",
  },
];

export const EXPANDED_TABLE_HEADER_WRITEOFFS = [
  {
    title: i18n.t("statistics.Receipts.Columns.ItemAndIngredients"),
    dataIndex: "product",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Warehouse"),
    dataIndex: "warehouse",
  },
  {
    title: i18n.t("statistics.Receipts.Columns.Quantity"),
    dataIndex: "quantity",
    render: (quantity) => `${quantity} шт.`,
  },
  {
    title: i18n.t("statistics.Receipts.Columns.CostPrice"),
    dataIndex: "costPrice",
    render: (costPrice) => `${costPrice} тг.`,
  },
];
