import React from "react";
import PropTypes from "prop-types";
import {
  Card, Spin, Modal, notification, Typography, Table,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { parseFormErrors } from "utils/formErrors";

import Page from "components/Page";

import ProductionForm from "./components/ProductionForm";

import { TECH_CARDS_PRODUCTS_QUERY, CREATE_PRODUCTION_MUTATION } from "./productionQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../shared/sharedWarehouseQueries";

const NewProduction = ({ history }) => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const { data, loading } = useQuery(PRODUCTS_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });

  const [createProduction] = useMutation(CREATE_PRODUCTION_MUTATION, {
    refetchQueries: [{ query: PRODUCTS_AND_LOCATIONS_QUERY }],
  });

  const handleSubmit = ({ production_products, ...values }) => {
    const techCardIds = production_products.map((pp) => pp.product.tech_card.id);
    const submitData = {
      ...values,
      production_products: production_products
        .map(({ product_id, quantity }) => ({ product_id, quantity })),
    };
    return client.query({
      query: TECH_CARDS_PRODUCTS_QUERY,
      variables: { techCardIds },
      fetchPolicy: "network-only",
    })
      .then(({ data: { getTechCardsProducts: techCardsProducts } }) => {
        const productionSpendings = production_products.map((pp) => {
          const ingredients = techCardsProducts
            .find((tcp) => tcp.tech_card_id === pp.product.tech_card.id).products
            .map(({ product, quantity }) => ({ product, quantity: quantity * pp.quantity }));
          return { ...pp, ingredients };
        });
        return new Promise((resolve, reject) => Modal.confirm({
          title: t("warehouse.Productions.ProductionConfirmation.Title"),
          width: 500,
          content: (
            <>
              <Typography.Text>
                {t("warehouse.Productions.ProductionConfirmation.Description")}
              </Typography.Text>
              <Table
                rowKey="product_id"
                size="small"
                pagination={false}
                showHeader={false}
                dataSource={productionSpendings}
                expandable={{
                  defaultExpandAllRows: true,
                  expandIconColumnIndex: -1,
                  expandedRowRender: ((spending) => (
                    <>
                      <table>
                        <tbody>
                          {spending.ingredients.map(({ product: { id, name, unit }, quantity }) => (
                            <tr key={id}>
                              <td style={{ paddingLeft: 20 }}>{name}</td>
                              <td width="25%">
                                {parseFloat(quantity.toFixed(3))} {UNIT_LABELS_BY_VALUE[unit]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )),
                }}
              >
                <Table.Column
                  dataIndex="quantity"
                  render={(quantity, { product: { name, unit } }) =>
                    <b>{`${name} ${quantity} ${UNIT_LABELS_BY_VALUE[unit]}`}</b>}
                />
              </Table>
            </>
          ),
          onOk: () => createProduction({ variables: { data: submitData } })
            .then(() => {
              history.goBack();
              resolve();
            })
            .catch(reject),
          onCancel: () => resolve(),
        }));
      })
      .catch(parseFormErrors);
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <Page showBackIcon title={t("warehouse.Productions.AddPage.Title")}>
      <Card>
        <ProductionForm t={t} optionsData={data} onSubmit={handleSubmit} />
      </Card>
    </Page>
  );
};

NewProduction.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewProduction;
