import { Modal } from "antd";
import moment from "moment";
import _ from "lodash";

const LocalForCurrency = {
  USD: "en-US",
  EUR: "en-US",
  RUB: "ru-RU",
  TRY: "tr-TR",
};

export const singletonCurrency = {
  priceFormatter: null,
  currency: null,
  getPriceFormatter() {
    return this.priceFormatter;
  },
  getCurrency() {
    return this.currency;
  },
  setCurrency(value) {
    if (value === "KZT") {
      const numberFormatter = new Intl.NumberFormat({}, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.priceFormatter = {
        format: (price) => `${numberFormatter.format(price)} тг.`,
      };
      this.currency = "тг.";
    } else {
      this.priceFormatter = new Intl.NumberFormat(LocalForCurrency[value] || {}, {
        style: "currency",
        currencyDisplay: "symbol",
        currency: value,
      });
      this.currency = this.priceFormatter
        .formatToParts().find((part) => part.type === "currency").value;
    }
  },
};

export const formatPrice = (price = 0) => singletonCurrency.getPriceFormatter().format(price);

export const confirm = (title, content = null) => new Promise((resolve) => {
  Modal.confirm({
    title,
    content,
    onOk() { resolve(true); },
    onCancel() { resolve(false); },
  });
});

export const getPeriods = (dates, type) =>
  dates.map((date, index, array) => (array.length !== index + 1 ? {
    from: date,
    to: moment(array[index + 1]).add(-1, "day").endOf("day"),
  } : {
    from: date,
    to: moment(date).add(1, type).add(-1, "day").endOf("day"),
  }));

export const stringifyModifiers = (items) => {
  const grouped = _.groupBy(items.map((i) => i.list_name));
  return Object.keys(grouped)
    .map((name) => {
      const count = grouped[name].length;
      return count > 1 ? `${name}\u00A0(x${count})` : name;
    })
    .join(", ");
};
