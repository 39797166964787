import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Popover, Button, Spin } from "antd";
import { Table as ExpandedTable, Thead } from "components/Table";
import { useQuery } from "@apollo/client";
import moment from "moment";

import i18n from "i18n";

import { WRITE_OFF_QUERY } from "../inventoriesQueries";

const WRITE_OFF_TABLE_HEADER = [
  i18n.t("warehouse.Inventories.WriteOffPopover.Table.Date"),
  i18n.t("warehouse.Inventories.WriteOffPopover.Table.Quantity"),
  i18n.t("warehouse.Inventories.WriteOffPopover.Table.Username"),
];

const MOVING_TABLE_HEADER = [
  i18n.t("warehouse.Inventories.WriteOffPopover.Table.Date"),
  i18n.t("warehouse.Inventories.WriteOffPopover.Table.Quantity"),
  i18n.t("warehouse.Inventories.WriteOffPopover.Table.LocationTo"),
  i18n.t("warehouse.Inventories.WriteOffPopover.Table.Username"),
];

const Container = styled.div`
  width: 650px;
  max-height: 250px;

  b {
    padding-left: 20px;
    padding-top: 10px;
    display: block;
  }

  table {
    box-shadow: unset;
  }
`;

const StyledButton = styled(Button)`
  border: none;
  background-color: transparent;
  box-shadow: none;

  & > span {
    margin-right: 4px;
  }

  i > svg {
    margin-bottom: -1px;
  }
`;

const Placeholder = styled.div`
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const StyledTable = styled(ExpandedTable)`
  width: 100%;

  tr > th {
    padding: 10px 8px 8px 8px;
  }

  tr:nth-child(odd) > td {
    background-color: #effaff;
  }

  td {
    padding: 2px 0;

    :first-child {
      padding-left: 12px;
    }

    :last-child {
      padding-left: 12px;
    }
  }
`;

const Content = ({ t, inventory, inventoryCount: { product, last_inventory_count } }) => {
  const { data, loading } = useQuery(WRITE_OFF_QUERY, {
    variables: {
      product_id: product.id,
      location_id: inventory.location_id,
      datetime: last_inventory_count
        ? { _gte: last_inventory_count.datetime, _lte: inventory.datetime }
        : { _lte: inventory.datetime },
    },
  });

  if (loading) {
    return (
      <Container>
        <center>
          <Spin />
        </center>
      </Container>
    );
  }

  return (
    <Container>
      {data.write_off_products.length > 0 ? (
        <>
          <b>
            {t("warehouse.Inventories.WriteOffPopover.WriteOffTable.Title")}
          </b>

          <StyledTable index>
            <Thead columns={WRITE_OFF_TABLE_HEADER} />
            <tbody>
              {data.write_off_products.map((writeOffProduct) => (
                <tr key={writeOffProduct.id}>
                  <td width={200}>
                    {moment(writeOffProduct.write_off.datetime).format("lll")}
                  </td>
                  <td width={70} align="center">
                    {writeOffProduct.quantity}
                    &nbsp;{t(`const:unit_type.${product.unit}`)}
                  </td>
                  <td>{writeOffProduct.write_off.user.name}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      ) : (
        <Placeholder>
          {t("warehouse.Inventories.WriteOffPopover.WriteOffTable.Placeholder")}
        </Placeholder>
      )}
      {data.moving_products.length > 0 ? (
        <>
          <b>
            {t("warehouse.Inventories.WriteOffPopover.MovingTable.Title")}
          </b>
          <StyledTable index>
            <Thead columns={MOVING_TABLE_HEADER} />
            <tbody>
              {data.moving_products.map((movingProduct) => (
                <tr key={movingProduct.id}>
                  <td width={200}>
                    {moment(movingProduct.moving.datetime).format("lll")}
                  </td>
                  <td width={70} align="center">
                    {movingProduct.quantity}
                    &nbsp;{t(`const:unit_type.${product.unit}`)}
                  </td>
                  <td width={200}>{t(
                    "warehouse.Inventories.WriteOffPopover.MovingTable.ToLocation", {
                      location: movingProduct.moving.location_to.name,
                    },
                  )}
                  </td>
                  <td>{movingProduct.moving.user.name}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      ) : (
        <Placeholder>
          {t("warehouse.Inventories.WriteOffPopover.MovingTable.Placeholder")}
        </Placeholder>
      )}
    </Container>
  );
};

Content.propTypes = {
  t: PropTypes.func.isRequired,
  inventoryCount: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired,
};

const WriteOffPopover = ({ t, inventoryCount, inventory }) => {
  const [isVisible, setVisible] = useState(false);
  const { write_offs, movings_out, product } = inventoryCount;

  const quantity = write_offs + movings_out;
  const quantityText = quantity === 0
    ? `${quantity} ${t(`const:unit_type.${product.unit}`)}`
    : `-${quantity} ${t(`const:unit_type.${product.unit}`)}`;

  return (
    <Popover
      content={<Content t={t} inventory={inventory} inventoryCount={inventoryCount} />}
      visible={isVisible}
      onVisibleChange={setVisible}
      trigger="click"
      placement="bottom"
    >
      <StyledButton>
        <span>{quantityText}</span>
        <i>
          <svg
            viewBox="0 0 1024 1024"
            focusable="false"
            data-icon="caret-down"
            width="10px"
            height="10px"
            fill="#7f7f7f"
            aria-hidden="true"
          >
            <path
              d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
            />
          </svg>
        </i>
      </StyledButton>
    </Popover>
  );
};

WriteOffPopover.propTypes = {
  inventory: PropTypes.object.isRequired,
  inventoryCount: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default WriteOffPopover;
