import gql from "graphql-tag";

export const WORKSHOPS_QUERY = gql`
  query @hasura {
    workshops(order_by: {id: asc}) {
      id
      name
    }
  }
`;

export const WORKSHOP_QUERY = gql`
  query GetWorkshop($id: Int!) @hasura {
    workshops(where: { id: { _eq: $id } }) {
      id
      name
    }
  }
`;

export const CREATE_WORKSHOP_MUTATION = gql`
  mutation CreateWorkshop($data: workshops_insert_input!) @hasura {
    insert_workshops(objects: [$data]) {
      returning {
        id
        name
      }
    }
  }
`;

export const UPDATE_WORKSHOP_MUTATION = gql`
  mutation UpdateWorkshop($id: Int!, $data: workshops_set_input!) @hasura {
    update_workshops(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        id
        name
      }
    }
  }
`;
