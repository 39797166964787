import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Table, Button, Input, Select, notification, Space, Drawer,
} from "antd";
import { useTranslation } from "react-i18next";
import { useQueryParam, NumberParam, StringParam, withDefault } from "use-query-params";

import Page from "components/Page";
import { formatPrice } from "utils/helpers";
import { sortByValue, sortByAlphabet } from "utils/sorts";
import { UNIT_LABELS_BY_VALUE } from "constants/index";

import IngredientDetailsPanel from "./components/IngredientDetailsPanel";

import { INGREDIENTS_QUERY } from "./ingredientQueries";

const Ingredients = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 20));
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [categoryId, setCategoryId] = useQueryParam("category_id", NumberParam);
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const { data, loading } = useQuery(INGREDIENTS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  const ingredients = useMemo(() => (data?.products ?? [])
    .filter(({ name, category_id }) => {
      if (search && search.length > 0 && !name.toLowerCase().includes(search)) {
        return false;
      }
      return !categoryId || categoryId === category_id;
    }), [data, categoryId, search]);

  return (
    <Page
      title={t("menu.Ingredients.Index.Title")}
      subTitle={data?.products.length.toString()}
      headerContent={t("menu.Ingredients.Index.Description")}
      extra={(
        <Link to="/menu/ingredients/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <Input.Search
          placeholder={t("QuickSearch")}
          style={{ width: 200 }}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase().trim())}
        />
        <Select
          placeholder={t("Category")}
          style={{ width: 200 }}
          onChange={(value) => setCategoryId(value)}
          value={categoryId}
        >
          <Select.Option value={null}>{t("AllCategories")}</Select.Option>
          {data && data.product_categories.map((category) => (
            <Select.Option value={category.id} key={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <Table
        rowKey="id"
        pagination={{
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        showSorterTooltip={false}
        loading={loading}
        dataSource={ingredients}
      >
        <Table.Column
          title={t("menu.Ingredients.Columns.Name")}
          dataIndex="name"
          render={(_, record) => (
            <Link to={`/menu/ingredients/${record.id}`}>
              {record.name}
            </Link>
          )}
          sorter={sortByAlphabet("name")}
        />
        <Table.Column
          title={t("menu.Ingredients.Columns.Category")}
          dataIndex={["product_category", "name"]}
        />
        <Table.Column
          title={t("menu.Ingredients.Columns.Unit")}
          dataIndex="unit"
          render={(unit) => UNIT_LABELS_BY_VALUE[unit]}
        />
        <Table.Column
          title={t("menu.Ingredients.Columns.UnitWeight")}
          dataIndex="unit_weight"
          render={(unitWeight) => `${unitWeight.toFixed(3)} ${UNIT_LABELS_BY_VALUE.kg}`}
          sorter={sortByValue("unit_weight")}
        />
        <Table.Column
          title={t("menu.Ingredients.Columns.Cost")}
          dataIndex="cost"
          render={formatPrice}
          sorter={sortByValue("cost")}
        />
        <Table.Column
          dataIndex="id"
          render={(_, record) => (
            <Button type="link" size="small" onClick={() => setSelectedIngredient(record)}>
              {t("Details")}
            </Button>
          )}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedIngredient?.name}
            &nbsp;
            <Link to={`/menu/ingredients/${selectedIngredient?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedIngredient}
        onClose={() => setSelectedIngredient(null)}
        placement="bottom"
        height="60%"
      >
        {selectedIngredient && (
          <IngredientDetailsPanel t={t} ingredientId={selectedIngredient.id} />
        )}
      </Drawer>
    </Page>
  );
};

Ingredients.propTypes = {};

export default Ingredients;
