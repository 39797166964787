import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Card, notification, Spin, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";

import Page from "components/Page";
import NewIngredientPane from "components/NewIngredientPane";
import NewWorkshopPane from "components/NewWorkshopPane";
import NewCategoryPane from "components/NewCategoryPane";

import TechCardForm from "./components/TechCardForm";
import {
  TECH_CARD_QUERY,
  UPDATE_TECH_CARD_MUTATION,
  SELECT_OPTIONS_QUERY,
} from "./techCardQueries";
import { SELECT_OPTIONS_QUERY as SET_OPTIONS_QUERY } from "../sets/setQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const EditTechCard = ({ history, match }) => {
  const techCardId = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const [isCreatingIngridient, setCreatingIngridient] = useState(false);
  const [isCreatingWorkshop, setCreatingWorkshop] = useState(false);
  const [isCreatingCategory, setCreatingCategory] = useState(false);
  const [toggleArchived, setToggleArchived] = useState(false);

  const { data, loading } = useQuery(TECH_CARD_QUERY, {
    variables: { id: techCardId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });
  const optionsQuery = useQuery(SELECT_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [updateTechCard] = useMutation(UPDATE_TECH_CARD_MUTATION, {
    refetchQueries: [
      { query: SET_OPTIONS_QUERY },
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });

  const mig = data?.menu_item_groups_by_pk;
  const initialTechCard = useMemo(() => mig && optionsQuery.data && {
    ...mig,
    is_produced: !!mig.menu_items[0].tech_card.product_id,
    is_weighted: mig.menu_items[0].tech_card.unit === "kg",
    menu_item: {
      ...mig.menu_items[0],
      cost: mig.menu_items[0].tech_card.cost,
    },
    tech_card: {
      ...mig.menu_items[0].tech_card,
      tech_card_products: mig.menu_items[0].tech_card.tech_card_products.map(({
        product_id, tech_card_id, quantity: brutto_unit, ...rest
      }) => {
        const ingredient = product_id
          ? optionsQuery.data.products.find((p) => p.id === product_id)
          : optionsQuery.data.tech_cards.find((tc) => tc.id === tech_card_id);
        const content = product_id ? { product_id } : { tech_card_id };
        const contentPath = (ingredient.category_id ? ["ingredients"] : [])
          .concat(ingredient.category_id || 0, JSON.stringify(content));
        return { ...rest, brutto_unit, ingredient, content, content_path: contentPath };
      }),
    },
  }, [mig, optionsQuery.data]);

  if (loading || optionsQuery.loading) return <Spin />;

  const handleSubmit = ({
    id: igId, total_cost, output, is_produced, is_weighted, name, archived_at,
    tech_card: { id: tId, product_id, ...tech_card },
    menu_item: {
      id: mId, limited_location_availability = [], cost, price, menu_item_modifier_groups,
    },
    ...itemGroup
  }) => {
    const menuItem = {
      id: mId,
      name,
      price,
      menu_item_modifier_groups: { data: menu_item_modifier_groups || [] },
      limited_location_availability: limited_location_availability?.length === 0
        ? null : limited_location_availability,
      tech_card: {
        data: {
          ...tech_card,
          name,
          cost,
          is_produced,
          tech_card_products: {
            data: tech_card.tech_card_products.map(({
              id, content, brutto_unit, cold_loss, hot_loss, cost: pCost,
            }) => ({
              id,
              product_id: null,
              tech_card_id: null,
              ...content,
              quantity: brutto_unit,
              cost: pCost,
              cold_loss,
              hot_loss,
            })),
          },
        },
      },
    };

    const archiveTechCard = archived_at ? null : moment().format();
    return updateTechCard({
      variables: {
        id: techCardId,
        data: {
          ...itemGroup,
          name,
          menu_items: { data: [menuItem] },
          archived_at: toggleArchived ? archiveTechCard : archived_at,
        },
      },
    })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  const archivingOrUnarchivingButton = (
    <Button
      type="danger"
      htmlType="submit"
      onClick={() => setToggleArchived(true)}
    >
      {initialTechCard?.archived_at ? t("Unarchiving") : t("Archiving")}
    </Button>
  );

  return (
    <Page showBackIcon title={t("menu.TechCards.EditPage.Title")}>
      <Card>
        <TechCardForm
          t={t}
          onSubmit={handleSubmit}
          initialValues={initialTechCard}
          optionsData={optionsQuery.data}
          onIngridientCreate={() => setCreatingIngridient(true)}
          onWorkshopCreate={() => setCreatingWorkshop(true)}
          onCategoryCreate={() => setCreatingCategory(true)}
          archivingOrUnarchivingButton={archivingOrUnarchivingButton}
        />
      </Card>
      <NewIngredientPane
        isOpen={isCreatingIngridient}
        onClose={() => setCreatingIngridient(false)}
      />
      <NewWorkshopPane
        isOpen={isCreatingWorkshop}
        onClose={() => setCreatingWorkshop(false)}
      />
      <NewCategoryPane
        isOpen={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
      />
    </Page>
  );
};

EditTechCard.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditTechCard;
