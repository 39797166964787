import React from "react";
import { Modal, notification } from "antd";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { useQuery } from "@apollo/client";

import TransactionForm from "./TransactionForm";
import { TRANSACTION_TYPES } from "../constants";
import { FINANCE_TRANSACTION_FORM_DATA_QUERY } from "../transactionsQueries";

const NewTransactionModal = ({ onSubmit, t, show, onHide }) => {
  const dispatch = useDispatch();
  const { data } = useQuery(FINANCE_TRANSACTION_FORM_DATA_QUERY, {
    onError: notification.error,
  });

  const handleOk = () => {
    dispatch(submit("transactionForm"));
  };

  return (
    <Modal
      title={t("finance.Transaction.AddPage.Title")}
      visible={show}
      onOk={handleOk}
      onCancel={onHide}
    >
      <TransactionForm
        initialValues={{
          type: TRANSACTION_TYPES.EXPENSE,
        }}
        transactionFormData={data}
        t={t}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

NewTransactionModal.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NewTransactionModal;
