import gql from "graphql-tag";

export const LOCATIONS_QUERY = gql`
  query @hasura {
    locations {
      id
      name
      description
      phone_number
      address
      deleted_at
    }
  }
`;

export const EDIT_LOCATION_QUERY = gql`
  query EditLocation($id: Int!) @hasura {
    locations(where: { id: { _eq: $id }}) {
      id
      name
      description
      phone_number
      address
      incassation_account_id
      card_payment_account_id
      cash_payment_account_id
    }
    tables(where: { location_id: { _eq: $id } }) {
      id
      name
      location_id
    }
    finance_accounts {
      id
      name
    }
  }
`;

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($data: LocationInput!) {
    createLocation(location: $data) {
      id
      name
      description
      phone_number
      address
    }
  }
`;

export const NEW_LOCATION_QUERY = gql`
  query GetLocation @hasura {
    finance_accounts {
      id
      name
    }
  }
`;
