import gql from "graphql-tag";

export const FINANCE_ACCOUNTS_QUERY = gql`
  query GetAccounts {
    financeAccounts {
      id
      name
      start_balance
      current_balance
      location {
        id
        name
      }
    }
  }
`;

export const FINANCE_ACCOUNT_QUERY = gql`
  query GetAccount($id: Int!) {
    financeAccount(account_id: $id) {
      id
      name
      start_balance
      current_balance
      location {
        id
        name
      }
    }
  }
`;

export const CREATE_FINANCE_ACCOUNT_MUTATION = gql`
  mutation CreateFinanceAccount($data: FinanceAccountInput!) {
    createFinanceAccount(data: $data) {
      id
      name
      start_balance
      current_balance
    }
  }
`;

export const UPDATE_FINANCE_ACCOUNT_MUTATION = gql`
  mutation EditAccount($id: Int!, $data: FinanceAccountUpdateInput!) {
    updateFinanceAccount(account_id: $id, data: $data) {
      id
      name
      start_balance
      current_balance
    }
  }
`;
