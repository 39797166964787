import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";

// eslint-disable-next-line import/no-cycle
import SetItemsTable from "./SetItemsTable";

const SetDetailsPanel = ({ t, setId }) => (
  <Tabs size="small" centered>
    <Tabs.TabPane tab={t("menu.Sets.DetailsPanel.ContentsTab.Title")} key="contents">
      <SetItemsTable setId={setId} />
    </Tabs.TabPane>
  </Tabs>
);

SetDetailsPanel.propTypes = {
  setId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default SetDetailsPanel;
