import React from "react";
import styled from "styled-components";
import { PageHeader, notification, Spin } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import LocationForm from "./components/LocationForm";
import { NEW_LOCATION_QUERY, CREATE_LOCATION_MUTATION } from "./locationQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const NewLocation = ({ history }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(NEW_LOCATION_QUERY, {
    onError: notification.error,
  });

  const [createLocation] = useMutation(CREATE_LOCATION_MUTATION);

  const handleSubmit = (values) =>
    createLocation({ variables: { data: values } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  if (loading) return <Loader />;

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("settings.Location.AddPage.Title")}
      />
      <LocationForm
        financeAccounts={data.finance_accounts}
        t={t}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

NewLocation.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewLocation;
