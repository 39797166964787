import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";
import { parseFormErrors } from "utils/formErrors";

import IngredientCategoryForm from "./components/IngredientCategoryForm";
import {
  INGREDIENT_CATEGORY_QUERY,
  UPDATE_INGREDIENT_CATEGORY_MUTATION,
} from "./ingredientCategoryQueries";

const EditIngredientCategory = ({ match, history }) => {
  const ingredientCategoryId = match.params.id;
  const { t } = useTranslation();

  const [updateIngredientCategory] = useMutation(UPDATE_INGREDIENT_CATEGORY_MUTATION);
  const { data, loading } = useQuery(INGREDIENT_CATEGORY_QUERY, {
    variables: { id: ingredientCategoryId },
    onError: notification.error,
  });

  if (loading) {
    return <Spin />;
  }

  const handleSubmit = ({ id, ...values }) =>
    updateIngredientCategory({ variables: { id, data: values } })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <Page showBackIcon title={t("menu.IngredientCategories.EditPage.Title")}>
      <Row>
        <Col xs={24} md={18} lg={14}>
          <Card>
            <IngredientCategoryForm
              t={t}
              initialValues={data.product_categories[0]}
              onSubmit={handleSubmit}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

EditIngredientCategory.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditIngredientCategory;
