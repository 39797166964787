export default {
  primaryColor: "#1853db",
  secondaryColor: "#e7ac24",
  white: "#ffffff",
  borderColor: "#d9d9d9",
  whisper: "#eeeeee",
  secondaryBackground: "#f4f7fb",
  lightGrey: "#d4d1d1",
  grey: "#888888",
  red: "#ff0000",
  green: "#008000",
  yellow: "#e0e000",
  selectedBlue: "#1890ff",
  defaultMenuItemColor: "#4682b4",

  backgroundRed: "#e7707d",
  backgroundGreen: "#83d160",
  backgroundYellow: "#ffce45",
};
