import gql from "graphql-tag";

export const PRODUCTS_QUERY = gql`
  query @hasura {
    menu_item_groups(
      order_by: {id: asc}
      where: {menu_items: {product_id: {_is_null: false}}}
    ) {
      id
      name
      description
      category_id
      color
      category {
        id
        name
      }
      archived_at
      menu_items {
        id
        name
        price
        archived_at
        product {
          id
          name
          cost
          unit
        }
      }
    }
    menu_categories(where: {menu_item_groups: {menu_items: {product_id: {_is_null: false}}}}) {
      id
      name
    }
  }
`;

export const PRODUCT_FORM_DATA_QUERY = gql`
  query @hasura {
    menu_categories {
      id
      name
    }
    locations {
      id
      name
    }
  }
`;

export const PRODUCT_QUERY = gql`
  query GetProduct($id: Int!) @hasura {
    menu_item_groups_by_pk(id: $id) {
      id
      name
      category_id
      description
      archived_at
      color
      menu_items(order_by: {id: asc}) {
        id
        name
        price
        limited_location_availability
        archived_at
        product_id
        product {
          id
          cost
          unit
        }
      }
    }
    location_products_aggregate(
      where: {product: {menu_items: {menu_item_group_id: {_eq: $id}}}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProduct($data: [menu_item_groups_insert_input!]!) @hasura {
    insert_menu_item_groups(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateMenuItemGroup($id: Int!, $data: MenuItemGroupInput!) {
    updateMenuItemGroup(menu_item_group_id: $id, data: $data) {
      id
    }
  }
`;

export const CONVERT_TO_TECH_CARD_MUTATION = gql`
  mutation convertToTechCard($id: Int!, $workshop_id: Int!) {
    convertToTechCard(menu_item_group_id: $id, workshop_id: $workshop_id) {
      id
    }
  }
`;
