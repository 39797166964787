import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { Table, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";

import { formatPrice } from "utils/helpers";
import { UNIT_LABELS_BY_VALUE } from "constants/index";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import { REASON_OPTIONS } from "./constants";
import { WRITE_OFFS_QUERY } from "./writeOffQueries";

const WriteOffProductsTable = ({ t, products }) => (
  <Table
    rowKey="id"
    pagination={false}
    size="small"
    style={{ margin: "10px 100px 10px 50px" }}
    dataSource={products}
  >
    <Table.Column
      title={t("Item")}
      dataIndex={["product", "name"]}
    />
    <Table.Column
      title={t("Quantity")}
      dataIndex="quantity"
      render={(quantity, record) => `${quantity} ${UNIT_LABELS_BY_VALUE[record.product.unit]}`}
    />
    <Table.Column
      title={t("Cost")}
      dataIndex="cost"
      render={formatPrice}
    />
    <Table.Column
      title={t("Amount")}
      dataIndex="cost"
      render={(cost, record) => formatPrice(cost * record.quantity)}
    />
  </Table>
);

WriteOffProductsTable.propTypes = {
  t: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      product: PropTypes.object.isRequired,
    }),
  ),
};

const WriteOffs = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 30));

  const [getWriteOffs, { data, loading }] = useLazyQuery(WRITE_OFFS_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  useEffect(_.throttle(() => {
    if (filter?.time) {
      getWriteOffs({
        variables: {
          limit: pageSize,
          offset: page * pageSize - pageSize,
          filter: {
            datetime: { _gte: filter.time.from, _lt: filter.time.to },
            ...(filter.locationId ? { location_id: { _eq: filter.locationId } } : {}),
          },
        },
      });
    }
  }, 200), [page, pageSize, filter]);

  return (
    <Page
      title={t("warehouse.WriteOffs.Index.Title")}
      subTitle={data?.write_offs_aggregate.aggregate.count.toString()}
      extra={(
        <Link to="/warehouse/write-offs/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        initialData={{ time: { value: "this_month" } }}
        locations={data?.locations ?? []}
        onFilter={(values) => { setPage(1); setFilter(values); }}
      />
      <Table
        rowKey="id"
        dataSource={data?.write_offs}
        loading={loading}
        pagination={{
          total: data?.write_offs_aggregate.aggregate.count,
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        expandable={{
          expandIconColumnIndex: -1,
          expandedRowKeys,
          expandedRowRender: (record) => (
            <WriteOffProductsTable t={t} products={record.write_off_products} />
          ),
        }}
      >
        <Table.Column
          title="#"
          dataIndex="number"
          width={80}
          align="center"
        />
        <Table.Column
          title={t("warehouse.WriteOffs.Columns.Date")}
          dataIndex="datetime"
          render={(datetime) => moment(datetime).format("lll")}
        />
        <Table.Column
          title={t("warehouse.WriteOffs.Columns.Items")}
          dataIndex="write_off_products"
          render={(products) => products.map(({ product: { name } }) => name).join(", ")}
        />
        <Table.Column
          title={t("warehouse.WriteOffs.Columns.Reason")}
          dataIndex="reason"
          render={(reason) => REASON_OPTIONS[reason]}
        />
        <Table.Column
          title={t("warehouse.WriteOffs.Columns.Description")}
          dataIndex="description"
          render={(value) => value || "-"}
        />
        <Table.Column
          title={t("warehouse.WriteOffs.Columns.Warehouse")}
          dataIndex={["location", "name"]}
        />
        <Table.Column
          title={t("warehouse.WriteOffs.Columns.Amount")}
          dataIndex="write_off_products"
          render={(products) => formatPrice(products.reduce(
            (sum, product) => sum + parseFloat(product.cost) * product.quantity, 0,
          ))}
        />
        <Table.Column
          dataIndex="id"
          width={100}
          render={(id) => (
            <Button
              type="link"
              size="small"
              onClick={() => {
                if (expandedRowKeys.includes(id)) {
                  return setExpandedRowKeys(expandedRowKeys.filter((v) => v !== id));
                }
                return setExpandedRowKeys(expandedRowKeys.concat(id));
              }}
            >
              {t("Details")}
            </Button>
          )}
        />
      </Table>
    </Page>
  );
};

WriteOffs.propTypes = {};

export default WriteOffs;
