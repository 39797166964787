import gql from "graphql-tag";

export const PRODUCTS_AND_LOCATIONS_QUERY = gql`
  query ProductsAndLocationsQuery @hasura {
    products(order_by: {id: asc}) {
      id
      name
      unit
      cost
      category_id
      menu_items {
        id
        archived_at
        menu_item_group {
          id
          archived_at
          category_id
        }
      }
      tech_card {
        id
        menu_items {
          id
          archived_at
          menu_item_group {
            id
            archived_at
            category_id
          }
        }
      }
    }
    product_categories {
      id
      name
    }
    menu_categories {
      id
      name
    }
    locations(order_by: {id: asc}) {
      id
      name
    }
  }
`;
