import React from "react";
import { Field, reduxForm, FieldArray, change } from "redux-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form as LegacyForm } from "@ant-design/compatible";
import { Form, Button, Divider, Alert, Space } from "antd";
import { connect } from "react-redux";

import { renderInput, renderTextarea, renderSelect, renderCheckbox } from "components/FormItem";
import { required } from "utils/formValidations";
import ColorInput from "components/ColorInput";

import MenuItemFields from "./MenuItemFields";

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const buttonsLayout = {
  wrapperCol: {
    md: { span: 12, offset: 6 },
    xs: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const ProductForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  menuCategories,
  locations,
  hasDeliveries = false,
  error,
  change: changeField,
  archivingOrUnarchivingButton,
  onCategoryCreate,
  onConvertToTechCard,
}) => {
  const categoryOptions = menuCategories
    .map(({ name, id }) => ({ label: name, value: id }));

  const locationOptions = locations
    .map(({ name, id }) => ({ label: name, value: id }));

  return (
    <LegacyForm onSubmit={handleSubmit}>
      {error && <Alert type="error" message={error} />}
      <Field
        formItemProps={formItemLayout}
        name="name"
        label={t("menu.Products.Form.Name")}
        component={renderInput}
        validate={required}
      />
      <Field
        formItemProps={formItemLayout}
        name="category_id"
        label={t("menu.Products.Form.Category.Label")}
        placeholder={t("menu.Products.Form.Category.Placeholder")}
        component={renderSelect}
        style={{ width: 250 }}
        options={categoryOptions}
        validate={required}
        showSearch
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <LinkContainer onMouseDown={(e) => e.preventDefault()}>
              <Button type="link" onClick={onCategoryCreate}>
                {t("menu.Products.Form.Category.DropdownButton")}
              </Button>
            </LinkContainer>
          </div>
        )}
      />
      <Field
        formItemProps={formItemLayout}
        name="description"
        label={t("Description")}
        component={renderTextarea}
      />
      <Field
        formItemProps={formItemLayout}
        name="color"
        label={t("Color")}
        component={ColorInput}
      />

      <Form.Item
        {...formItemLayout}
        wrapperCol={{ xs: { span: 24 }, lg: { span: 18 } }}
        label={t("menu.Products.Form.Modifications.Label")}
      >
        <FieldArray
          t={t}
          component={MenuItemFields}
          changeField={changeField}
          name="menu_items"
        />
      </Form.Item>

      <Field
        formItemProps={formItemLayout}
        name="limited_location_availability"
        label={t("menu.Products.Form.LocationAvailability.Label")}
        component={renderSelect}
        placeholder={t("menu.Products.Form.LocationAvailability.Placeholder")}
        mode="multiple"
        options={locationOptions}
        extra={t("menu.Products.Form.LocationAvailability.Extra")}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <Button type="link" onClick={() => changeField("limited_location_availability", null)}>
              {t("menu.Products.Form.LocationAvailability.DropdownButton")}
            </Button>
          </div>
        )}
      />

      <Field
        formItemProps={buttonsLayout}
        name="is_weighted"
        disabled={hasDeliveries}
        checkboxLabel={t("menu.Products.Form.IsWeighted.Label")}
        component={renderCheckbox}
        extra={t("menu.Products.Form.IsWeighted.Extra")}
      />

      <Form.Item {...buttonsLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            {t("Save")}
          </Button>
          {archivingOrUnarchivingButton}
          {onConvertToTechCard && (
            <Button
              type="danger"
              disabled={pristine || submitting}
              onClick={onConvertToTechCard}
            >
              {t("menu.Products.Form.ConvertToTechCard")}
            </Button>
          )}
        </Space>
      </Form.Item>
    </LegacyForm>
  );
};

ProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  menuCategories: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  hasDeliveries: PropTypes.bool,
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  archivingOrUnarchivingButton: PropTypes.element,
  onCategoryCreate: PropTypes.func.isRequired,
  onConvertToTechCard: PropTypes.func,
};

export default connect(
  null,
  { change },
)(
  reduxForm({
    form: "productForm",
    initialValues: {
      menu_items: [
        { cost: 0 },
      ],
    },
  })(ProductForm),
);
