import React, { useMemo } from "react";
import { Row, Col, Card } from "antd";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { range, round } from "lodash";

import { CHART_OPTIONS, BAR_CHART } from "constants/index";

const barOptions = {
  ...CHART_OPTIONS(),
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
        },
      },
    ],
  },
};

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const hours = range(0, 24);

const BarCharts = ({ t, type, statsByWeekday, statsByHour }) => {
  const weekBarData = useMemo(
    () => ({
      labels: weekDays,
      data: weekDays.map((el, index) => {
        const foundWeekday = statsByWeekday.find((statWeekday) => (
          statWeekday.weekday === index + 1
        ));
        return foundWeekday ? round(foundWeekday[type], 2) : 0;
      }),
    }),
    [statsByWeekday, type],
  );

  const hourBarData = useMemo(
    () => ({
      labels: hours,
      data: hours.map((hour) => {
        const foundHour = statsByHour.find((statHour) => statHour.hour === hour);
        return foundHour ? round(foundHour[type], 2) : 0;
      }),
    }),
    [statsByHour, type],
  );

  return (
    <Row gutter={16}>
      <Col span={14}>
        <Card title={t("statistics.Sales.BarCharts.ByTime")}>
          <Bar data={BAR_CHART(hourBarData)} options={barOptions} height={50} />
        </Card>
      </Col>
      <Col span={10}>
        <Card title={t("statistics.Sales.BarCharts.ByDayOfWeek")}>
          <Bar data={BAR_CHART(weekBarData)} options={barOptions} height={72} />
        </Card>
      </Col>
    </Row>
  );
};

BarCharts.propTypes = {
  type: PropTypes.string,
  t: PropTypes.func.isRequired,
  statsByHour: PropTypes.array.isRequired,
  statsByWeekday: PropTypes.array.isRequired,
};

export default BarCharts;
