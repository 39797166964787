import gql from "graphql-tag";

export const USERS_QUERY = gql`
  query @hasura{
    users(order_by: {id: asc}) {
      id
      name
      pin_code
      email
      phone_number
      deleted_at
      access_role {
        id
        name
      }
      users_locations {
        id
        location {
          id
          name
        }
      }
    }
  }
`;

export const NEW_USER_QUERY = gql`
  query @hasura{
    access_roles { 
      id
      name
      access_cashier
      access_finance
      access_location_admin
      access_marketing
      access_menu
      access_statistics
      access_orders
      access_users
      access_warehouse
      access_callcenter
      access_courier
    }
    locations {
      id
      name
    }
  }
`;

export const EDIT_USER_QUERY = gql`
  query GetData($id: Int!) @hasura {
    access_roles { 
      id
      name
      access_cashier
      access_finance
      access_location_admin
      access_marketing
      access_menu
      access_statistics
      access_orders
      access_users
      access_warehouse
      access_callcenter
      access_courier
    }
    locations {
      id
      name
    }
    users(where: { id: { _eq: $id } }) {
      id
      name
      pin_code
      email
      phone_number
      deleted_at
      access_role_id
      users_locations {
        id
        location_id
      }
    }
  }
`;
