import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Input, Modal, Drawer } from "antd";

import { parseFormErrors } from "utils/formErrors";

import { API_KEYS_QUERY, CREATE_API_KEY_MUTATION } from "../apiKeysQueries";
import ApiKeyForm from "./ApiKeyForm";

const CreateApiKeyPane = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const copyToClipboard = () => {
    const copyText = document.getElementById("token-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  const [createAPIKey] = useMutation(CREATE_API_KEY_MUTATION, {
    refetchQueries: [{ query: API_KEYS_QUERY }],
  });

  const handleSubmit = (variables) =>
    createAPIKey({ variables })
      .then(({ data: { createAPIKey: { token, apiKey } } }) => {
        onClose();
        Modal.info({
          title: t("access.ApiKeys.CreateApiKeyPane.SuccessAlert.Title", { name: apiKey.name }),
          content: (
            <Input.Search
              id="token-input"
              style={{ marginTop: 15 }}
              value={token}
              onSearch={copyToClipboard}
              enterButton={t("access.ApiKeys.CreateApiKeyPane.SuccessAlert.Copy")}
            />
          ),
        });
      })
      .catch(parseFormErrors);

  return (
    <Drawer
      title={t("access.ApiKeys.CreateApiKeyPane.Title")}
      width="40%"
      onClose={onClose}
      visible={isOpen}
    >
      <ApiKeyForm onSubmit={handleSubmit} t={t} />
    </Drawer>
  );
};

CreateApiKeyPane.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateApiKeyPane;
