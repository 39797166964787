/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import styled from "styled-components";
import { Form } from "@ant-design/compatible";
import { Button, Divider, Alert, Typography } from "antd";
import { useSelector } from "react-redux";

import {
  renderCheckbox, renderInput, renderLabel, renderRadioGroup, renderSelect, renderTextarea,
} from "components/FormItem";
import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { required } from "utils/formValidations";
import { formatPrice } from "utils/helpers";

import TechCardProductsFields from "../../shared/components/TechCardProductsFields";

const buttonsLayout = {
  wrapperCol: {
    md: { span: 12, offset: 6 },
    xs: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
};

const LinkContainer = styled.div`
  padding-left: 4px;
  padding-bottom: 4px;
`;

const PrepmealForm = ({
  t,
  handleSubmit,
  pristine,
  error,
  submitting,
  initialValues,
  optionsData,
  change: changeField,
  onIngridientCreate,
  onWorkshopCreate,
}) => {
  const workshopOptions = (optionsData?.workshops ?? []).map(
    ({ id, name }) => ({ value: id, label: name }),
  );

  const selector = formValueSelector("prepmealForm");
  const output = useSelector((state) => selector(state, "output"));
  const unit = useSelector((state) => selector(state, "unit"));
  const totalCost = useSelector((state) => selector(state, "total_cost"));

  useEffect(() => {
    if (unit === "kg") {
      changeField("unit_weight", 1);
      changeField("output_quantity", output / 1000);
      changeField("cost", totalCost * (output ? 1000 / output : 0));
    } else {
      changeField("unit_weight", output / 1000);
      changeField("output_quantity", 1);
      changeField("cost", totalCost);
    }
  }, [unit, output, totalCost]);

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert message={error} type="error" />}
      <Field
        formItemProps={formItemLayout}
        name="name"
        label={t("menu.Prepmeals.Form.Name")}
        validate={required}
        component={renderInput}
      />
      <Field
        formItemProps={formItemLayout}
        name="workshop_id"
        label={t("menu.Prepmeals.Form.Workshops.Label")}
        placeholder={t("menu.Prepmeals.Form.Workshops.Placeholder")}
        validate={required}
        component={renderSelect}
        style={{ width: 250 }}
        options={workshopOptions}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "2px 0 4px" }} />
            <LinkContainer onMouseDown={(e) => e.preventDefault()}>
              <Button type="link" onClick={onWorkshopCreate}>
                {t("menu.Prepmeals.Form.Workshops.DropdownButton")}
              </Button>
            </LinkContainer>
          </div>
        )}
      />

      <Field
        formItemProps={formItemLayout}
        name="process_description"
        label={t("menu.Prepmeals.Form.ProcessDescription.Label")}
        component={renderTextarea}
      />

      <br />

      <Typography.Title level={5}>{t("menu.Prepmeals.Form.TechCardProducts.Title")}</Typography.Title>
      <FieldArray
        t={t}
        name="tech_card_products"
        changeField={changeField}
        component={TechCardProductsFields}
        optionsData={optionsData}
        onIngridientCreate={onIngridientCreate}
      />

      <br />

      <Field
        formItemProps={formItemLayout}
        name="unit"
        disabled={initialValues.is_produced}
        label={t("menu.Prepmeals.Form.Output.Label")}
        extra={t("menu.Prepmeals.Form.Output.Help")}
        component={renderRadioGroup}
        validate={required}
        items={[
          { value: "pieces", label: t("menu.Prepmeals.Form.Output.Pieces") },
          { value: "kg", label: t("menu.Prepmeals.Form.Output.Kg", { output: output || 0 }) },
        ]}
      />

      <Field
        formItemProps={formItemLayout}
        name="cost"
        label={t("menu.Prepmeals.Form.Cost.Label")}
        format={(value) => `${formatPrice(value)}/${UNIT_LABELS_BY_VALUE[unit]}`}
        component={renderLabel}
      />

      <Field
        formItemProps={buttonsLayout}
        name="is_produced"
        disabled={initialValues.is_produced}
        checkboxLabel={t("menu.Prepmeals.Form.IsProduced.Label")}
        extra={t("menu.Prepmeals.Form.IsProduced.Help")}
        component={renderCheckbox}
      />

      <Form.Item {...buttonsLayout}>
        <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
          {t("Save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

PrepmealForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  error: PropTypes.string,
  optionsData: PropTypes.object,
  onIngridientCreate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onWorkshopCreate: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "prepmealForm",
  initialValues: {
    tech_card_products: [{}],
    unit: "pieces",
    is_produced: false,
  },
})(PrepmealForm);
