/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Table, notification, Typography, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { UNIT_LABELS_BY_VALUE } from "constants/index";
import { formatPrice } from "utils/helpers";

import ProductDetailsPanel from "../../products/components/ProductDetailsPanel";
import TechCardDetailsPanel from "../../techCards/components/TechCardDetailsPanel";
import ModifierDetailsPanel from "../../modifiers/components/ModifierDetailsPanel";

import { SET_COMPONENTS_QUERY } from "../setQueries";

const SetItemsTable = ({ setId }) => {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedTechCard, setSelectedTechCard] = useState();
  const [selectedModifierGroup, setSelectedModifierGroup] = useState();

  const { data, loading } = useQuery(SET_COMPONENTS_QUERY, {
    variables: { id: setId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  const products = data?.tech_card_products.map(({ tech_card, product, ...tcp }) => {
    const value = tech_card || product;
    return {
      ...tcp, value, type: tech_card ? "tech-cards" : "products",
    };
  });

  const totalCost = (products ?? []).reduce((acc, p) => acc + p.cost, 0);
  const totalPrice = (products ?? [])
    .reduce((acc, p) => acc + p.value.menu_items[0].price, 0);

  return (
    <>
      <Table
        size="small"
        rowKey="id"
        bordered
        loading={loading}
        dataSource={products}
        pagination={false}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell>
              {t("menu.Sets.SetItemsTable.Columns.Summary")}
            </Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {formatPrice(totalCost)}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatPrice(totalPrice)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
        footer={data?.menu_item_modifier_group.length > 0 && (() => (
          <div>
            <Typography.Text strong>
              {t("menu.TechCardProductsTable.Modifiers")}:&nbsp;
            </Typography.Text>
            {data.menu_item_modifier_group.map(({ modifier_group: mg }, idx, arr) => (
              <>
                <Typography.Link onClick={() => setSelectedModifierGroup(mg)}>
                  {mg.name}
                </Typography.Link>
                {" "}({mg.modifier_items.map((item) => item.name).join(", ")})
                {idx === arr.length - 1 ? "." : ", "}
              </>
            ))}
          </div>
        ))}
      >
        <Table.Column
          title={t("menu.Sets.SetItemsTable.Columns.Ingredient")}
          dataIndex="value"
          render={(record, { type }) => (
            <Typography.Link
              onClick={() => {
                (type === "tech-cards" ? setSelectedTechCard : setSelectedProduct)(record);
              }}
            >
              {record.name}
            </Typography.Link>
          )}
        />
        <Table.Column
          title={t("menu.Sets.SetItemsTable.Columns.BruttoUnit")}
          dataIndex="quantity"
          render={(quantity, { value }) => `${quantity} ${UNIT_LABELS_BY_VALUE[value.unit]}`}
        />
        <Table.Column
          title={t("menu.Sets.SetItemsTable.Columns.Cost")}
          dataIndex="cost"
          render={formatPrice}
        />
        <Table.Column
          title={t("menu.Sets.SetItemsTable.Columns.Price")}
          dataIndex={["value", "menu_items", 0, "price"]}
          render={formatPrice}
        />
      </Table>
      <Drawer
        title={(
          <>
            {selectedProduct?.name}
            &nbsp;
            <Link to={`/menu/products/${selectedProduct?.menu_items[0].menu_item_group_id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedProduct}
        onClose={() => setSelectedProduct(null)}
        placement="bottom"
        height="60%"
      >
        {selectedProduct && (
          <ProductDetailsPanel t={t} productId={selectedProduct.id} />
        )}
      </Drawer>
      <Drawer
        title={(
          <>
            {selectedTechCard?.name}
            &nbsp;
            <Link to={`/menu/tech-cards/${selectedTechCard?.menu_items[0].menu_item_group_id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedTechCard}
        onClose={() => setSelectedTechCard(null)}
        placement="bottom"
        height="60%"
      >
        {selectedTechCard && (
          <TechCardDetailsPanel t={t} techCardId={selectedTechCard.id} />
        )}
      </Drawer>
      <Drawer
        title={(
          <>
            {selectedModifierGroup?.name}
            &nbsp;
            <Link to={`/menu/modifiers/${selectedModifierGroup?.id}`}>
              <small>{t("Edit")}</small>
            </Link>
          </>
        )}
        visible={!!selectedModifierGroup}
        onClose={() => setSelectedModifierGroup(null)}
        placement="bottom"
        height="60%"
      >
        {selectedModifierGroup && (
          <ModifierDetailsPanel t={t} modifierGroupId={selectedModifierGroup.id} />
        )}
      </Drawer>
    </>
  );
};

SetItemsTable.propTypes = {
  setId: PropTypes.number.isRequired,
};

export default SetItemsTable;
