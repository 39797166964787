import React, { useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import Modal from "react-modal";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import "react-sliding-pane/dist/react-sliding-pane.css";
import { parseFormErrors } from "utils/formErrors";

import { SELECT_OPTIONS_QUERY } from "../pages/menu/techCards/techCardQueries";
import { PRODUCT_FORM_DATA_QUERY } from "../pages/menu/products/productQueries";
import {
  MENU_CATEGORIES_QUERY, CREATE_CATEGORY_MUTATION,
} from "../pages/menu/categories/categoryQueries";
import CategoryForm from "../pages/menu/categories/components/CategoryForm";

const SlidingPaneWithForm = styled(SlidingPane)`
  .slide-pane__content {
    padding: 24px 20px;
  }

  .slide-pane__title {
    font-size: 22px;
    font-weight: 600;
  }
`;

const NewCategoryPane = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [createCategory] = useMutation(CREATE_CATEGORY_MUTATION, {
    refetchQueries: [
      { query: SELECT_OPTIONS_QUERY },
      { query: PRODUCT_FORM_DATA_QUERY },
      { query: MENU_CATEGORIES_QUERY },
    ],
  });

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const handleSubmit = (values) =>
    createCategory({ variables: { object: values } })
      .then(onClose)
      .catch(parseFormErrors);

  return (
    <SlidingPaneWithForm
      closeIcon={<CloseOutlined />}
      isOpen={isOpen}
      width="40%"
      title={t("menu.NewCategoryPane.Title")}
      onRequestClose={onClose}
    >
      <CategoryForm onSubmit={handleSubmit} t={t} />
    </SlidingPaneWithForm>
  );
};

NewCategoryPane.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewCategoryPane;
