import React, { useState } from "react";
import {
  Row, Col, Table, Button, notification, Space, Modal, Input,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";

import Page from "components/Page";

import {
  API_KEYS_QUERY, DELETE_API_KEY_MUTATION, REFRESH_API_KEY_MUTATION,
} from "./apiKeysQueries";
import CreateApiKeyPane from "./components/CreateApiKeyPane";

const ApiKeys = () => {
  const { t } = useTranslation();
  const [isCreatingApiKey, setCreatingApiKey] = useState(false);

  const { loading, data } = useQuery(API_KEYS_QUERY, {
    onError: notification.error,
  });

  const [deleteAPIKey] = useMutation(DELETE_API_KEY_MUTATION, {
    refetchQueries: [{ query: API_KEYS_QUERY }],
    onError: notification.error,
  });

  const copyToClipboard = () => {
    const copyText = document.getElementById("token-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  const [refreshAPIKey] = useMutation(REFRESH_API_KEY_MUTATION, {
    onError: notification.error,
  });

  const handleClickDelete = (key) => {
    Modal.confirm({
      title: t("access.ApiKeys.Index.DeleteModal.Title", { name: key.name }),
      okText: t("Delete"),
      okButtonProps: { danger: true },
      cancelText: t("access.ApiKeys.Index.DeleteModal.CancelButton"),
      onOk: () => deleteAPIKey({ variables: { id: key.id } }),
    });
  };

  const handleClickRefresh = (key) => {
    Modal.confirm({
      title: t("access.ApiKeys.Index.RefreshModal.Title", { name: key.name }),
      okText: t("access.ApiKeys.Index.RefreshModal.OkButton"),
      okButtonProps: { danger: true },
      cancelText: t("access.ApiKeys.Index.RefreshModal.CancelButton"),
      onOk: () => refreshAPIKey({ variables: { id: key.id } })
        .then(({ data: { refreshAPIKey: { apiKey, token } } }) =>
          Modal.info({
            title: t("access.ApiKeys.Index.RefreshModal.SuccessAlert.Title", { name: apiKey.name }),
            content: (
              <Input.Search
                id="token-input"
                style={{ marginTop: 15 }}
                value={token}
                onSearch={copyToClipboard}
                enterButton={t("access.ApiKeys.Index.RefreshModal.SuccessAlert.Copy")}
              />
            ),
          })),
    });
  };

  return (
    <Page
      title={t("access.ApiKeys.Index.Title")}
      subTitle={data?.getAPIKeys.length.toString()}
      extra={(
        <Button
          type="primary"
          onClick={() => setCreatingApiKey(true)}
        >
          {t("Create")}
        </Button>
      )}
    >
      <Row>
        <Col xs={24}>
          <Table
            rowKey="id"
            pagination={false}
            loading={loading}
            dataSource={data?.getAPIKeys}
          >
            <Table.Column
              title={t("access.ApiKeys.Columns.Name")}
              dataIndex="name"
            />
            <Table.Column
              render={(_, key) => (
                <Space>
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => handleClickRefresh(key)}
                  >
                    {t("access.ApiKeys.Index.Refresh")}
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => handleClickDelete(key)}
                  >
                    {t("access.ApiKeys.Index.Delete")}
                  </Button>
                </Space>
              )}
              align="right"
            />
          </Table>
        </Col>
      </Row>
      <CreateApiKeyPane
        isOpen={isCreatingApiKey}
        onClose={() => setCreatingApiKey(false)}
      />
    </Page>
  );
};

ApiKeys.propTypes = {};

export default ApiKeys;
