import gql from "graphql-tag";

export const MENU_ITEMS_QUERY = gql`
  query @hasura {
    menu_items {
      id
      name
    }
  }
`;

export const PROMOTIONS_QUERY = gql`
  query GetPromotions($limit: Int, $offset: Int) @hasura {
    promotions_aggregate {
      aggregate {
        count
      }
    }
    promotions(limit: $limit, offset: $offset) {
      id
      name
      start_date
      end_date
      result_type
    }
  }
`;

export const PROMOTION_QUERY = gql`
  query GetPromotion($id: Int!) @hasura {
    promotions(where: { id: { _eq: $id } }) {
      id
      name
      active_hours
      active_weekdays
      end_date
      discount
      condition_type
      bonus_items_max_count
      result_type
      start_date
      promotion_conditions {
        menu_item_id
        min_count
        min_price
        id
        menu_category_id
      }
      promotion_products {
        id
        menu_item_id
        menu_category_id
      }
    }
    menu_items {
      id
      name
    }
  }
`;

export const CREATE_PROMOTION_MUTATION = gql`
  mutation CreatePromotion($data: promotions_insert_input!) @hasura {
    insert_promotions(objects: [$data]) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_PROMOTION_MUTATION = gql`
  mutation UpdatePromotion($data: UpdatePromotionInput!, $id: Int!) {
    updatePromotion(promotion_id: $id, payload: $data) {
      id
    }
  }
`;

export const DELETE_PROMOTION_MUTATION = gql`
  mutation DeletePromotion($id: Int!) @hasura {
    delete_promotions(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
