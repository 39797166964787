import gql from "graphql-tag";

export const INGREDIENT_CATEGORIES_QUERY = gql`
  query @hasura {
    product_categories(order_by: {id: asc}) {
      id
      name
    }
  }
`;

export const INGREDIENT_CATEGORY_QUERY = gql`
  query GetIngredientCategory($id: Int!) @hasura {
    product_categories(where: { id: { _eq: $id } }) {
      id
      name
    }
  }
`;

export const CREATE_INGREDIENT_CATEGORY_MUTATION = gql`
  mutation CreateIngredientCategory($data: product_categories_insert_input!) @hasura {
    insert_product_categories(objects: [$data]) {
      returning {
        id
        name
      }
    }
  }
`;

export const UPDATE_INGREDIENT_CATEGORY_MUTATION = gql`
  mutation UpdateIngredientCategory($id: Int!, $data: product_categories_set_input!) @hasura {
    update_product_categories(_set: $data, where: {id: {_eq: $id}}) {
      returning {
        id
        name
      }
    }
  }
`;
