import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import Page from "components/Page";
import NewWorkshopPane from "components/NewWorkshopPane";
import NewCategoryPane from "components/NewCategoryPane";

import SetForm from "./components/SetForm";
import { SELECT_OPTIONS_QUERY, CREATE_SET_MUTATION } from "./setQueries";

const NewSets = ({ history }) => {
  const { t } = useTranslation();
  const [isCreatingWorkshop, setCreatingWorkshop] = useState(false);
  const [isCreatingCategory, setCreatingCategory] = useState(false);

  const { loading, data } = useQuery(SELECT_OPTIONS_QUERY, {
    onError: notification.error,
  });
  const [createSet] = useMutation(CREATE_SET_MUTATION);

  if (loading) return <Spin />;

  const handleSubmit = ({
    total_cost, output, name, tech_card,
    menu_item: { limited_location_availability = [], cost, price, menu_item_modifier_groups },
    ...itemGroup
  }) => {
    const menuItem = {
      name,
      price,
      menu_item_modifier_groups: { data: menu_item_modifier_groups || [] },
      limited_location_availability: limited_location_availability?.length === 0
        ? null : limited_location_availability,
      tech_card: {
        data: {
          ...tech_card,
          name,
          cost,
          is_set: true,
          tech_card_products: {
            data: tech_card.tech_card_products.map(({ content, brutto_unit, cost: pCost }) => ({
              ...content, quantity: brutto_unit, cost: pCost, cold_loss: 0, hot_loss: 0,
            })),
          },
        },
      },
    };

    return createSet({
      variables: { data: { ...itemGroup, menu_items: { data: [menuItem] }, name } },
    })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  return (
    <Page showBackIcon title={t("menu.Sets.AddPage.Title")}>
      <Card>
        <SetForm
          t={t}
          onSubmit={handleSubmit}
          optionsData={data}
          onWorkshopCreate={() => setCreatingWorkshop(true)}
          onCategoryCreate={() => setCreatingCategory(true)}
        />
      </Card>
      <NewWorkshopPane
        isOpen={isCreatingWorkshop}
        onClose={() => setCreatingWorkshop(false)}
      />
      <NewCategoryPane
        isOpen={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
      />
    </Page>
  );
};

NewSets.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewSets;
