import React from "react";
import { Route, Switch } from "react-router-dom";

import Prepmeals from "./Prepmeals";
import NewPrepmeal from "./NewPrepmeal";
import EditPrepmeal from "./EditPrepmeal";

const Container = () =>
  (
    <Switch>
      <Route path="/menu/prepmeals/new" component={NewPrepmeal} />
      <Route path="/menu/prepmeals/:id" component={EditPrepmeal} />
      <Route path="/menu/prepmeals" component={Prepmeals} />
    </Switch>
  );

export default Container;
