import React from "react";
import styled from "styled-components";
import { PageHeader, Divider, Spin, notification } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useQuery } from "@apollo/client";

import colors from "theme/colors";
import { parseFormErrors } from "utils/formErrors";

import LocationForm from "./components/LocationForm";
import TablesForm from "./components/TablesForm";
import { EDIT_LOCATION_QUERY } from "./locationQueries";

const StyledDivider = styled(Divider)`
  background-color: ${colors.lightGrey};
`;

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditLocation = ({ history, match }) => {
  const { t } = useTranslation();
  const locationId = parseInt(match.params.id, 10);

  const { data, loading } = useQuery(EDIT_LOCATION_QUERY, {
    variables: { id: locationId },
    onError: notification.error,
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <Loader />;
  }

  const handleSubmitLocations = (values) =>
    axios.put(`/admin_api/locations/${locationId}`, values)
      .then(() => history.goBack())
      .catch(parseFormErrors);

  const handleSubmitTables = (value) =>
    axios.post(`/admin_api/locations/${locationId}/tables`, value)
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("settings.Location.EditPage.Title")}
      />
      <LocationForm
        financeAccounts={data.finance_accounts}
        t={t}
        initialValues={data.locations[0]}
        onSubmit={handleSubmitLocations}
      />

      <StyledDivider />

      <TablesForm
        t={t}
        initialValues={{ tables: data.tables, locationId }}
        onSubmit={handleSubmitTables}
      />
    </div>
  );
};

EditLocation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditLocation;
