export const popularProducts = [
  {
    id: 1,
    product: "Pepsi",
    orders: "509 шт.",
  },
  {
    id: 2,
    product: "Капучино",
    orders: "498 шт.",
  },
  {
    id: 3,
    product: "Бутерброд с семгой",
    orders: "494 шт.",
  },
  {
    id: 4,
    product: "Кольца кальмара",
    orders: "491 шт.",
  },
  {
    id: 5,
    product: "Наполеон",
    orders: "489 шт.",
  },
];

export const dataLine = {
  labels: ["Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь"],
  data: [75000, 62000, 90000, 81000, 56000, 55000, 50000],
};

export const dataWeekBar = [124434, 82333, 95235, 123748, 57238, 42353, 118923];

export const dataTimeBar = {
  labels: [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
  ],
  data: [
    106343,
    104434,
    33440,
    43230,
    9850,
    20856,
    29920,
    0,
    0,
    43223,
    104434,
    34233,
    34244,
    23434,
    34430,
    23440,
    23444,
    23444,
    54360,
    34665,
    34555,
    34550,
    62350,
    34230,
  ],
};

export const shops = [
  {
    id: 1,
    name: "Кафе на Полянке",
    address: "Москва, ул. Б.Полянка 44",
    revenue: "372 025,70 тг.",
    profit: "268 686,77 тг.",
    checks: "705 шт.",
    averagуСheck: "527,70 тг.",
  },
  {
    id: 2,
    name: "Кофейня",
    address: "Львов, пл. Ринок, 11",
    revenue: "73 099,50 тг.",
    profit: "69 152,71 тг.",
    checks: "154 шт.",
    averagуСheck: "474,67 тг.",
  },
];

export const productsData = [
  {
    id: 1,
    name: "Coca-Cola",
    quantity: "50 шт.",
    revenue: "8 500,82 тг",
    profit: "15 425,15",
  },
  {
    id: 2,
    name: "Мохито",
    quantity: "32 шт.",
    revenue: "7 580,41 тг",
    profit: "9 425,15",
  },
  {
    id: 3,
    name: "Пицца с сыром",
    quantity: "60 шт.",
    revenue: "10 945,82 тг",
    profit: "16 575,15",
  },
  {
    id: 4,
    name: "Бутерброд с икрой",
    quantity: "80 шт.",
    revenue: "3 548,12 тг",
    profit: "7 289,24",
  },
  {
    id: 5,
    name: "Пицца с грибами",
    quantity: "15 шт.",
    revenue: "5 821,12 тг",
    profit: "9 289,34 ",
  },
  {
    id: 6,
    name: "Шоколадное мороженое",
    quantity: "19 шт.",
    revenue: "8 921,12 тг",
    profit: "10 289,27",
  },
  {
    id: 7,
    name: "Итальянское мороженное",
    quantity: "25 шт.",
    revenue: "9 821,12 тг",
    profit: "13 259,29",
  },
  {
    id: 8,
    name: "Салат с семгой",
    quantity: "10 шт.",
    revenue: "15 821,12 тг",
    profit: "19 289,24",
  },
  {
    id: 9,
    product_id: 9,
    name: "Pepsi",
    quantity: "10 шт.",
    revenue: "6 821,12 тг",
    profit: "11 289,24",
  },
  {
    id: 10,
    name: "Borjomi",
    quantity: "27 шт.",
    revenue: "7 831,12 тг",
    profit: "12 259,24",
  },
  {
    id: 11,
    name: "Торт 'Наполеон'",
    quantity: "23 шт.",
    revenue: "7 881,12 тг",
    profit: "10 289,24 ",
  },
  {
    id: 12,
    name: "Торт с малиной",
    quantity: "78 шт.",
    revenue: "9 821,12 тг",
    profit: "12 875,14",
  },
];
