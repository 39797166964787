import React from "react";
import PropTypes from "prop-types";
import { Table as ExpandedTable, Thead, Td } from "components/Table";

import { PAYMENT_METHOD_NAME, PAYMENT_METHOD_TYPE } from "constants/index";
import { formatPrice } from "utils/helpers";

const PaymentsDetails = ({ stats, t }) => {
  const ORDER_PAYMENT_TYPE = {
    refund: t("const:order_payment_type.refund"),
    payment: t("const:order_payment_type.payment"),
  };

  return (
    <ExpandedTable index>
      <Thead
        columns={[
          t("statistics.Payments.PaymentDetails.PaymentMethod"),
          t("statistics.Payments.PaymentDetails.Amount"),
        ]}
      />
      <tbody>
        {stats.map(({ amount, type, payment_method }, i) => (
          <tr key={i}>
            <Td>
              {payment_method.type === PAYMENT_METHOD_TYPE.CUSTOM
                ? payment_method.name : PAYMENT_METHOD_NAME[payment_method.name]}
              {type === "refund" && ` (${ORDER_PAYMENT_TYPE[type]})`}
            </Td>
            <Td>{formatPrice(amount)}</Td>
          </tr>
        ))}
      </tbody>
    </ExpandedTable>
  );
};

PaymentsDetails.propTypes = {
  stats: PropTypes.array,
  t: PropTypes.func.isRequired,
};

export default PaymentsDetails;
