import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import axios from "axios";
import { PageHeader, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { parseFormErrors } from "utils/formErrors";

import AccessRoleForm from "./components/AccessRoleForm";
import { EDIT_ACCESS_ROLE_QUERY } from "./accessRolesQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditAccessRole = ({ match, history }) => {
  const accessRoleId = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const { data, loading } = useQuery(EDIT_ACCESS_ROLE_QUERY, {
    variables: { id: accessRoleId },
    onError: notification.error,
  });

  if (loading) {
    return <Loader />;
  }

  const handleSubmit = ({ name, is_owner, ...values }) =>
    axios.put(`/admin_api/access_roles/${accessRoleId}`, {
      ...values,
      ...(is_owner ? {} : { name }),
    })
      .then(() => history.goBack())
      .catch(parseFormErrors);

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("access.AccessRoles.EditPage.Title")}
      />
      <AccessRoleForm
        t={t}
        initialValues={{
          ...data.access_roles[0],
          is_owner: data.access_roles[0].name === "salempos_generated_owner",
          name: data.access_roles[0].name === "salempos_generated_owner"
            ? t("const:owner") : data.access_roles[0].name,
        }}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

EditAccessRole.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditAccessRole;
