import React, { useState } from "react";
import PropTypes from "prop-types";
import { Spin, notification, Card } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Page from "components/Page";
import NewCategoryPane from "components/NewCategoryPane";

import ProductForm from "./components/ProductForm";
import {
  PRODUCT_FORM_DATA_QUERY, CREATE_PRODUCT_MUTATION,
} from "./productQueries";
import { SELECT_OPTIONS_QUERY } from "../sets/setQueries";
import { PRODUCTS_AND_LOCATIONS_QUERY } from "../../warehouse/shared/sharedWarehouseQueries";

const NewProduct = ({ history }) => {
  const { t } = useTranslation();
  const [isCreatingCategory, setCreatingCategory] = useState(false);
  const { loading, data } = useQuery(PRODUCT_FORM_DATA_QUERY, {
    onError: notification.error,
  });
  const [createProduct] = useMutation(CREATE_PRODUCT_MUTATION, {
    refetchQueries: [
      { query: SELECT_OPTIONS_QUERY },
      { query: PRODUCTS_AND_LOCATIONS_QUERY },
    ],
  });

  if (loading) return <Spin />;

  const handleSubmit = ({
    menu_items, limited_location_availability = [], is_weighted, name, ...rest
  }) => {
    const menuItems = {
      data: menu_items.map((menu_item) => ({
        name: menu_items.length > 1 ? menu_item.name : name,
        price: menu_item.price,
        limited_location_availability: limited_location_availability?.length === 0
          ? null : limited_location_availability,
        product: {
          data: {
            name: menu_items.length > 1 ? `${name} ${menu_item.name}` : name,
            cost: menu_item.cost,
            unit: is_weighted ? "kg" : "pieces",
            unit_weight: is_weighted ? 1 : 0,
          },
        },
      })),
    };
    return createProduct({ variables: { data: { menu_items: menuItems, name, ...rest } } })
      .then(() => history.goBack())
      .catch(notification.error);
  };

  return (
    <Page showBackIcon title={t("menu.Products.AddPage.Title")}>
      <Card>
        <ProductForm
          t={t}
          menuCategories={data ? data.menu_categories : []}
          locations={data ? data.locations : []}
          onSubmit={handleSubmit}
          onCategoryCreate={() => setCreatingCategory(true)}
        />
      </Card>
      <NewCategoryPane
        isOpen={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
      />
    </Page>
  );
};

NewProduct.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewProduct;
