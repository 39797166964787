import gql from "graphql-tag";

export const SUPPLIERS_QUERY = gql`
  query GetSuppliers @hasura {
    suppliers(order_by: {id: asc}) {
      id
      name
      phone_number
      address
      comment
    }
  }
`;

export const EDIT_SUPPLIER_QUERY = gql`
  query EditSupplier($id: Int!) @hasura {
    suppliers_by_pk(id: $id) {
      name
      phone_number
      address
      comment
      bic
      bank_name
      iban
      business_number
    }
  }
`;

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation CreateSupplier($data: suppliers_insert_input!) @hasura {
    insert_suppliers_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_SUPPLIER_MUTATION = gql`
  mutation UpdateSupplier($id: Int!, $data: suppliers_set_input!) @hasura {
    update_suppliers_by_pk(pk_columns: {id: $id}, _set: $data) {
      id
      name
      phone_number
      address
      comment
    }
  }
`;
