import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { PageHeader, notification, Spin } from "antd";

import DeliveryZoneForm from "./components/DeliveryZoneForm";
import { DELIVERY_ZONES_AND_LOCATIONS_QUERY, CREATE_DELIVERY_ZONE_MUTATION } from "./deliveryZoneQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const NewDeliveryZone = ({ history }) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(DELIVERY_ZONES_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const [createDeliveryZone] = useMutation(CREATE_DELIVERY_ZONE_MUTATION, {
    refetchQueries: [{ query: DELIVERY_ZONES_AND_LOCATIONS_QUERY }],
  });

  if (loading) return <Loader />;

  const handleSubmit = (values) =>
    createDeliveryZone({ variables: { data: values } })
      .then(() => history.goBack())
      .catch((error) => notification.error(error));

  const titleCreatePoligon = <h2>{t("settings.DeliveryZones.Form.Title")}</h2>;

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("settings.DeliveryZones.AddPage.Title")}
      />
      <DeliveryZoneForm
        t={t}
        onSubmit={handleSubmit}
        locations={data ? data.locations : []}
        dataMap={data ? data.delivery_zones : []}
        titleCreatePoligon={titleCreatePoligon}
      />
    </div>
  );
};

NewDeliveryZone.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewDeliveryZone;
