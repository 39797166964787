import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { PageHeader, notification, Button, Spin } from "antd";
import moment from "moment";

import DeliveryZoneForm from "./components/DeliveryZoneForm";
import {
  DELIVERY_ZONES_AND_LOCATIONS_QUERY,
  DELETE_DELIVERY_ZONE_MUTATION,
  UPDATE_DELIVERY_ZONE_MUTATION,
} from "./deliveryZoneQueries";

const StyledPageHeader = styled(PageHeader)`
  background: none;
  padding: 20px 40px 0px 40px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const EditDeliveryZone = ({ history, match }) => {
  const deliveryZoneId = parseInt(match.params.id, 10);
  const { t } = useTranslation();

  const { loading, data } = useQuery(DELIVERY_ZONES_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const [deleteDeliveryZone] = useMutation(DELETE_DELIVERY_ZONE_MUTATION, {
    refetchQueries: [{ query: DELIVERY_ZONES_AND_LOCATIONS_QUERY }],
  });
  const [updateDeliveryZone] = useMutation(UPDATE_DELIVERY_ZONE_MUTATION, {
    refetchQueries: [{ query: DELIVERY_ZONES_AND_LOCATIONS_QUERY }],
  });

  if (loading) return <Loader />;

  const handleSubmit = ({ id, location, order_sum_for_free_delivery, ...rest }) =>
    updateDeliveryZone({
      variables: {
        id: deliveryZoneId,
        data: {
          order_sum_for_free_delivery: order_sum_for_free_delivery === ""
            ? null : order_sum_for_free_delivery,
          ...rest,
        },
      },
    })
      .then(() => history.goBack())
      .catch((err) => notification.error(err));

  const handleDelete = () =>
    deleteDeliveryZone({ variables: { id: deliveryZoneId, delete_date: moment().format() } })
      .then(() => history.goBack())
      .catch((err) => notification.error(err));

  const getDeliveryZone = (deliveryZones) =>
    deliveryZones.find((deliveryZone) => deliveryZone.id === deliveryZoneId);

  const getDeliveryZones = (deliveryZones) =>
    deliveryZones.filter((delivery_zone) => delivery_zone.id !== deliveryZoneId);

  const deleteButton = (
    <Button
      type="danger"
      className="button-layout"
      onClick={handleDelete}
    >
      {t("Delete")}
    </Button>
  );

  return (
    <div>
      <StyledPageHeader
        onBack={() => history.goBack()}
        title={t("settings.DeliveryZones.EditPage.Title")}
      />
      <DeliveryZoneForm
        t={t}
        onSubmit={handleSubmit}
        locations={data ? data.locations : []}
        dataMap={data ? getDeliveryZones(data.delivery_zones) : []}
        initialValues={data && getDeliveryZone(data.delivery_zones)}
        deleteButton={deleteButton}
      />
    </div>
  );
};

EditDeliveryZone.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditDeliveryZone;
