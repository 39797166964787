import React from "react";
import { Route, Switch } from "react-router-dom";

import Movings from "./Movings";
import NewMoving from "./NewMoving";
import EditMoving from "./EditMoving";

const Container = () => (
  <Switch>
    <Route path="/warehouse/movings/new" component={NewMoving} />
    <Route path="/warehouse/movings/:id" component={EditMoving} />
    <Route path="/warehouse/movings" component={Movings} />
  </Switch>
);
export default Container;
