import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, notification } from "antd";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Page from "components/Page";

import { WORKSHOPS_QUERY } from "./workshopQueries";

const Workshops = () => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(WORKSHOPS_QUERY, {
    onError: notification.error,
  });

  return (
    <Page
      title={t("menu.Workshops.Index.Title")}
      subTitle={data?.workshops.length.toString()}
      extra={(
        <Link to="/menu/workshops/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <Row>
        <Col md={24} lg={18} xl={15}>
          <Table
            rowKey="id"
            pagination={false}
            loading={loading}
            dataSource={data?.workshops}
          >
            <Table.Column
              title={t("menu.Workshops.Columns.Name")}
              dataIndex="name"
            />
            <Table.Column
              dataIndex="id"
              width={100}
              render={(id) => (
                <Link to={`/menu/workshops/${id}`}>{t("EditShort")}</Link>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Page>
  );
};

Workshops.propTypes = {};

export default Workshops;
