import React from "react";
import { Switch, Route } from "react-router-dom";

import Locations from "./Locations";
import NewLocation from "./NewLocation";
import EditLocation from "./EditLocation";

const Container = () => (
  <Switch>
    <Route path="/settings/locations/new" component={NewLocation} />
    <Route path="/settings/locations/:id" component={EditLocation} />
    <Route path="/settings/locations" component={Locations} />
  </Switch>
);

export default Container;
