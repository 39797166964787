import React from "react";
import PropTypes from "prop-types";
import {
  NumberParam, NumericObjectParam, StringParam, useQueryParams, withDefault,
} from "use-query-params";

import FilterPanel from "./FilterPanel";

const FilterPanelWithQuery = ({
  onFilter,
  initialData = {},
  locations,
  hideLocationPicker,
  hideTimePicker,
  sourceOptions,
  style,
}) => {
  const [query, setQuery] = useQueryParams({
    locationId: withDefault(NumberParam, initialData.locationId),
    time: withDefault(NumericObjectParam, initialData.time),
    source: withDefault(StringParam, initialData.source),
  });

  const handleFilter = ({ locationId, time, source }) => {
    const { from, to } = time;
    setQuery({ locationId, time: { from, to }, source }, "replaceIn");
    onFilter({ locationId, time, source });
  };

  return (
    <FilterPanel
      style={style}
      locations={locations}
      hideLocationPicker={hideLocationPicker}
      hideTimePicker={hideTimePicker}
      initialData={query}
      onFilter={handleFilter}
      sourceOptions={sourceOptions}
    />
  );
};

FilterPanelWithQuery.propTypes = {
  locations: PropTypes.array,
  sourceOptions: PropTypes.array,
  initialData: PropTypes.object,
  hideLocationPicker: PropTypes.bool,
  hideTimePicker: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default FilterPanelWithQuery;
