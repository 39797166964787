import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  List, Row, Col, Tag, Typography, Tooltip,
} from "antd";
import moment from "moment";

import { ORDER_TYPE } from "constants/index";
import colors from "theme/colors";
import { formatPrice } from "utils/helpers";

import {
  getDisplayNumber,
  ORDER_SOURCE_ICONS, ORDER_STATUS_COLORS, PAYMENT_TYPE_ICONS,
} from "../constants";

// so we can have in 87 minutes in status tooltip
moment.relativeTimeThreshold("m", 360);

const { Text, Title } = Typography;

const OrderItem = styled(List.Item)`
  cursor: pointer;
  padding: 10px;
  :hover {
    background-color: ${colors.whisper};
  }
  ${(props) => props.$isActive && css`
    background-color: ${colors.whisper};
  `}

  .number {
    display: inline;
  }

  .order-id {
    padding-left: 5px;
    font-size: 0.8em;
  }

  .list-user {
    width: 110px;
    padding-left: 4px;
  }

  .order-type {
    width: 95px;
    display: inline-block;
  }

  .location {
    width: 130px;
    text-align: right;
  }

  .cap-2-line {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .payments .anticon {
    padding-left: 6px;
  }

  .ant-tag {
    margin-right: 0px;
  }
`;

const OrderCard = ({ t, order, isActive, location, history }) => {
  const [paymentColor, paymentMessage, paymentIcons] = useMemo(() => {
    const paid = order.order_payments.reduce((amount, curr) =>
      amount + (curr.type === "payment" ? curr.amount : -curr.amount), 0);

    const icons = _(order.order_payments)
      .map((op) => PAYMENT_TYPE_ICONS[op.payment_method.type])
      .uniq()
      .value();

    if (order.list_price < 0.001) {
      return [colors.white, "", []];
    }
    if (Math.abs(order.list_price - paid) < 0.001) {
      return [colors.green, t("orders.OrderCard.Payment.Paid"), icons];
    }
    if (paid > 0.001) {
      return [colors.yellow, t("orders.OrderCard.Payment.PaidPartially"), icons];
    }
    if (order.order_payments.some((op) => op.type === "refund")) {
      return [colors.red, t("orders.OrderCard.Payment.Refunded"), icons];
    }
    return [colors.lightGrey, t("orders.OrderCard.Payment.NotPaid"), ["credit-card"]];
  }, [order.list_price, order.order_payments]);

  const orderTime = moment(order.status_updated_at).diff(order.time, "minutes");

  return (
    <OrderItem
      $isActive={isActive}
      onClick={() => history.push(`/orders/${order.id}${location.search}`)}
    >
      <Row gutter={[0, 8]} type="flex" justify="space-between">
        <Col>
          <Title level={4} className="number">{getDisplayNumber(order)}</Title>
          <Text type="secondary" code className="order-id">#{order.id}</Text>
        </Col>
        <Col>
          {moment(order.time).format(moment().isSame(order.time, "day") ? "LT" : "L LT")}
        </Col>
      </Row>
      <Row gutter={[0, 8]} type="flex" align="middle">
        <Col>
          <LegacyIcon type={ORDER_SOURCE_ICONS[order.source]} />
        </Col>
        <Col>
          <Text className="list-user cap-2-line">
            {order.list_user}
          </Text>
        </Col>
        <Col>
          <Text className="order-type">
            {t(`const:order_type.${order.type}`)}
          </Text>
        </Col>
        <Col>
          <Text type="secondary" className="location cap-2-line">
            {order.location.name}
          </Text>
        </Col>
      </Row>
      {order.type === ORDER_TYPE.DELIVERY && (
        <Row>
          <Col offset={2}>
            <Text type="secondary">
              {[
                order.delivery_info.address.street,
                order.delivery_info.address.house,
                order.delivery_info.address.flat,
              ].filter((v) => v).join(", ")}
            </Text>
          </Col>
        </Row>
      )}
      <Row gutter={[0, 8]} type="flex" justify="space-between" align="bottom">
        <Col>
          <Text strong>{formatPrice(order.list_price)}</Text>
          <Tooltip title={paymentMessage}>
            <span className="payments" style={{ color: paymentColor }}>
              {paymentIcons.map((icon) =>
                <LegacyIcon key={icon} type={icon} />)}
            </span>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title={t("orders.OrderCard.Duration.Description")}>
            <Tag color={ORDER_STATUS_COLORS[order.status]}>
              {t(`const:status.${order.status}`)}
              &nbsp;
              {t("orders.OrderCard.Duration.Label", { orderTime })}
            </Tag>
          </Tooltip>
        </Col>
      </Row>
    </OrderItem>
  );
};

OrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(OrderCard);
