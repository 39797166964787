import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form } from "@ant-design/compatible";
import { Row, Col, Button } from "antd";

import { renderInput, renderTextarea, renderSelect } from "components/FormItem";
import Map from "components/Map";
import colors from "theme/colors";
import { required } from "utils/formValidations";
import { singletonCurrency } from "utils/helpers";

const StyledForm = styled(Form)`
  padding: 20px 40px;

  .row-buttons-layout {
    margin-top: 30px;
  }

  .button-layout {
    margin-right: 10px;
  }

  .divider {
    background-color: ${colors.lightGrey};
  }
  
  .map-wrapper {
    width: 100%;
    height: 500px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const formItemButtons = {
  wrapperCol: { sm: { offset: 7, span: 17 } },
};

const DeliveryZoneForm = ({
  handleSubmit,
  t,
  pristine,
  submitting,
  locations,
  dataMap,
  deleteButton,
  titleCreatePoligon,
}) => {
  const getLocationsOptions = (values) => values.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledRow>
        <Col xs={24} md={20} lg={16} xl={12}>
          <Field
            name="name"
            label={t("settings.DeliveryZones.Form.Name")}
            component={renderInput}
            validate={required}
          />
          <Field
            name="description"
            label={t("settings.DeliveryZones.Form.Description")}
            component={renderTextarea}
          />
          <Field
            name="location_id"
            label={t("settings.DeliveryZones.Form.Location")}
            component={renderSelect}
            options={getLocationsOptions(locations)}
            validate={required}
          />
          <Field
            name="price"
            label={t("settings.DeliveryZones.Form.DeliveryPrice")}
            component={renderInput}
            addonAfter={singletonCurrency.getCurrency()}
            type="number"
            validate={required}
          />
          <Field
            name="min_order_sum_for_delivery"
            label={t("settings.DeliveryZones.Form.MinOrderSumForDelivery")}
            component={renderInput}
            addonAfter={singletonCurrency.getCurrency()}
            type="number"
            validate={required}
          />
          <Field
            name="order_sum_for_free_delivery"
            label={t("settings.DeliveryZones.Form.OrderSumForFreeDelivery")}
            component={renderInput}
            addonAfter={singletonCurrency.getCurrency()}
            type="number"
          />
        </Col>
      </StyledRow>

      {titleCreatePoligon}

      <Row className="map-wrapper">
        <Field
          name="geo"
          label={t("settings.DeliveryZones.Form.Description")}
          component={Map}
          validate={required}
          dataMap={dataMap}
        />
      </Row>

      <Row className="row-buttons-layout">
        <Col span={14}>
          <Form.Item {...formItemButtons}>
            <Button
              className="button-layout"
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              {t("Save")}
            </Button>
            {deleteButton}
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

DeliveryZoneForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  dataMap: PropTypes.array.isRequired,
  deleteButton: PropTypes.element,
  titleCreatePoligon: PropTypes.element,
};

export default reduxForm({
  form: "deliveryZoneForm",
})(DeliveryZoneForm);
