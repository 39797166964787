import React, { useState } from "react";
import { render } from "components/FormItem";
import styled from "styled-components";
import { CirclePicker } from "react-color";
import { Popover } from "antd";

import colors from "theme/colors";

const dotSizes = { small: 10, medium: 30 };

export const ColorDot = styled.div`
  background: ${(props) => props.color};
  width: ${(props) => dotSizes[props.size || "medium"]}px;
  height: ${(props) => dotSizes[props.size || "medium"]}px;
  border-radius: ${(props) => dotSizes[props.size || "medium"] / 2}px;
`;

const ColorInput = ({ input, ...props }) => {
  const [pickerVisible, setPickerVisible] = useState();

  const handleSelectColor = (hex) => {
    input.onChange(hex);
    setPickerVisible(false);
  };

  return render({ input, ...props }, () => (
    <Popover
      visible={pickerVisible}
      trigger="click"
      onVisibleChange={setPickerVisible}
      placement="bottomLeft"
      content={() => (
        <div style={{ padding: 10 }}>
          <CirclePicker
            color={input.value || colors.defaultMenuItemColor}
            onChange={({ hex }) => handleSelectColor(hex)}
            onChangeComplete={() => setPickerVisible(false)}
          />
        </div>
      )}
    >
      <ColorDot
        color={input.value || colors.defaultMenuItemColor}
        style={{ cursor: "pointer" }}
      />
    </Popover>
  ));
};

export default ColorInput;
