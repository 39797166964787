import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Space, DatePicker, Select } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

import i18n from "i18n";

const getDateOptions = () => [
  {
    value: "today",
    label: i18n.t("filterPanel.DateOptions.Today"),
    from: moment().startOf("day"),
    to: moment().endOf("day"),
  },
  {
    value: "yesterday",
    label: i18n.t("filterPanel.DateOptions.Yesterday"),
    from: moment().startOf("day").subtract(1, "day"),
    to: moment().endOf("day").subtract(1, "day"),
  },
  {
    value: "this_week",
    label: i18n.t("filterPanel.DateOptions.ThisWeek"),
    from: moment().startOf("week"),
    to: moment().endOf("day"),
  },
  {
    value: "this_month",
    label: i18n.t("filterPanel.DateOptions.ThisMonth"),
    from: moment().startOf("month"),
    to: moment().endOf("day"),
  },
  {
    value: "month",
    label: i18n.t("filterPanel.DateOptions.Month"),
    from: moment().startOf("day").subtract(1, "month"),
    to: moment().endOf("day"),
  },
  {
    value: "period",
    label: i18n.t("filterPanel.DateOptions.Period"),
  },
];

const getTimeState = (initialParams, dateOptions) => {
  if (initialParams && initialParams.time) {
    const { time } = initialParams;

    if (time.from && time.to) {
      const fromMoment = moment(time.from);
      const toMoment = moment(time.to);
      const predefined = dateOptions
        .find((o) => fromMoment.isSame(o.from) && toMoment.isSame(o.to));
      return predefined || {
        value: "period",
        label: i18n.t("filterPanel.DateOptions.Period"),
        from: fromMoment,
        to: toMoment,
      };
    }
    return dateOptions.find((o) => o.value === time.value);
  }

  return dateOptions[0];
};

const FilterPanel = ({
  locations,
  sourceOptions,
  initialData,
  hideLocationPicker = false,
  hideTimePicker = false,
  onFilter,
  style,
}) => {
  const { t } = useTranslation();
  const dateOptions = getDateOptions();

  const { timeState, locationIdState, sourceState } = useMemo(() => ({
    timeState: getTimeState(initialData, dateOptions),
    locationIdState: initialData?.locationId,
    sourceState: initialData?.source,
  }), [initialData]);

  const [time, setTime] = useState(timeState);
  const [locationId, setLocationId] = useState(locationIdState);
  const [source, setSource] = useState(sourceState);

  const handlePeriodFilterChange = (value) => {
    const dateOption = dateOptions.find((o) => o.value === value);

    if (dateOption.value === "period") {
      setTime((previous) => ({ ...previous, ...dateOption }));
    } else {
      setTime(dateOption);
    }
  };

  const handleChangeFilterRange = (date) => {
    setTime({
      value: "period",
      from: date[0].startOf("day"),
      to: date[1].endOf("day"),
    });
  };

  useEffect(() => {
    onFilter({ time, locationId, source });
  }, [time, locationId, source]);

  return (
    <Space style={style}>
      {!hideTimePicker && (
        <>
          <Select
            style={{ width: 170 }}
            placeholder={t("filterPanel.Date")}
            value={time.value}
            onChange={handlePeriodFilterChange}
          >
            {dateOptions.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
          {time.value === "period" && (
            <DatePicker.RangePicker
              defaultValue={[time.from, time.to]}
              disabledDate={(current) => moment().endOf("d").isBefore(current)}
              onChange={handleChangeFilterRange}
            />
          )}
        </>
      )}
      {!hideLocationPicker && (
        <Select
          style={{ width: 200 }}
          placeholder={t("filterPanel.Location")}
          value={locationId}
          onChange={setLocationId}
        >
          <Select.Option value={null}>
            {t("filterPanel.AllLocation")}
          </Select.Option>
          {locations.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      )}
      {sourceOptions && sourceOptions.length > 0 && (
        <Select
          style={{ width: 196 }}
          placeholder={t("filterPanel.Source")}
          value={source}
          onChange={setSource}
        >
          <Select.Option value={null}>
            {t("filterPanel.AllSources")}
          </Select.Option>
          {sourceOptions.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      )}
    </Space>
  );
};

FilterPanel.propTypes = {
  locations: PropTypes.array,
  sourceOptions: PropTypes.array,
  initialData: PropTypes.object,
  hideLocationPicker: PropTypes.bool,
  hideTimePicker: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default withRouter(FilterPanel);
