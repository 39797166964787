import React, { useState } from "react";
import styled from "styled-components";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ConnectData from "connect.container";

import colors from "theme/colors";

import { MENU } from "./constants";
import salempos from "../../assets/salempos.png";

const ExtendedSider = styled(Layout.Sider)`
  border-right: 1px solid ${colors.borderColor};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;

  .logo {
    width: ${(props) => (props.collapsed ? "48px" : "120px")};
    height: ${(props) => (props.collapsed ? "48px" : "54px")};
    display: block;
    margin: 8px auto;
    object-fit: contain;
  }

  .ant-menu-inline {
    border-right: none;
  }

  && {
    .ant-menu-item {
      display: flex;
      align-items: center;
      line-height: 1.3;
      white-space: pre-wrap;
    }
  }
`;

const Sidebar = ({ collapsed }) => {
  const { t } = useTranslation();
  const { merchant, currentUser } = ConnectData.useContainer();
  const [defaultOpenKey, defaultSelectedKey] = window.location.pathname.split("/").slice(1, 3);
  const [openKeys, setOpenKeys] = useState([defaultOpenKey]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys.filter((_key, idx) => idx === keys.length - 1));
  };

  return (
    <ExtendedSider
      trigger={null}
      theme="light"
      collapsible
      collapsed={collapsed}
    >
      <Link to="/">
        <img
          className="logo"
          src={merchant.logo_url || salempos}
          alt="logo"
        />
      </Link>
      <Menu
        theme="light"
        mode="inline"
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        defaultSelectedKeys={[`${defaultOpenKey}/${defaultSelectedKey}`]}
      >
        {MENU.filter(
          (item) => currentUser.access_role[item.access_key] === item.access_value,
        ).map((item) => (
          <Menu.SubMenu key={item.key} icon={item.icon} title={t(`sidebar.${item.key}.title`)}>
            {item.subMenu.map((subMenuItem) => (
              <Menu.Item key={`${item.key}/${subMenuItem.key}`}>
                <Link to={subMenuItem.to}>{t(`sidebar.${item.key}.subMenu.${subMenuItem.key}`)}</Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ))}
      </Menu>
    </ExtendedSider>
  );
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default Sidebar;
