import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table, Button, notification, Space, Select, Tag,
} from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";

import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";

import { formatPrice } from "utils/helpers";
import { UNIT_LABELS_BY_VALUE } from "constants/index";
import colors from "theme/colors";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";

import { DELIVERIES_QUERY, DELIVERY_PRODUCTS_QUERY } from "./deliveryQueries";

const DeliveryProductsTable = ({ t, deliveryId }) => {
  const { data, loading } = useQuery(DELIVERY_PRODUCTS_QUERY, {
    variables: { id: deliveryId },
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  return (
    <Table
      rowKey="id"
      loading={loading}
      pagination={false}
      size="small"
      style={{ margin: "10px 100px 10px 50px" }}
      dataSource={data?.delivery_products}
    >
      <Table.Column
        title={t("Item")}
        dataIndex={["product", "name"]}
      />
      <Table.Column
        title={t("Quantity")}
        dataIndex="quantity"
        render={(quantity, record) => `${quantity} ${UNIT_LABELS_BY_VALUE[record.product.unit]}`}
      />
      <Table.Column
        title={t("Price")}
        dataIndex="price"
        render={formatPrice}
      />
      <Table.Column
        title={t("Amount")}
        dataIndex="price"
        render={(price, record) => formatPrice(price * record.quantity)}
      />
    </Table>
  );
};

DeliveryProductsTable.propTypes = {
  t: PropTypes.func.isRequired,
  deliveryId: PropTypes.number.isRequired,
};

const Deliveries = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam("page_size", withDefault(NumberParam, 30));
  const [supplierId, setSupplierId] = useQueryParam("supplier_id", NumberParam);

  const [getDeliveries, { data, loading }] = useLazyQuery(DELIVERIES_QUERY, {
    fetchPolicy: "cache-and-network",
    onError: notification.error,
  });

  useEffect(_.throttle(() => {
    if (filter?.time) {
      getDeliveries({
        variables: {
          limit: pageSize,
          offset: page * pageSize - pageSize,
          filter: {
            datetime: { _gte: filter.time.from, _lt: filter.time.to },
            ...(filter.locationId ? { location_id: { _eq: filter.locationId } } : {}),
            ...(supplierId ? { supplier_id: { _eq: supplierId } } : {}),
          },
        },
      });
    }
  }, 200), [page, pageSize, supplierId, filter]);

  return (
    <Page
      title={t("warehouse.Deliveries.Index.Title")}
      subTitle={data?.deliveries_aggregate.aggregate.count.toString()}
      extra={(
        <Link to="/warehouse/deliveries/new">
          <Button type="primary">{t("Add")}</Button>
        </Link>
      )}
    >
      <Space style={{ marginBottom: 12 }}>
        <FilterPanelWithQuery
          initialData={{ time: { value: "this_month" } }}
          locations={data?.locations ?? []}
          onFilter={(values) => { setPage(1); setFilter(values); }}
        />
        <Select
          placeholder={t("warehouse.Deliveries.Filters.Supplier.Placeholder")}
          style={{ width: 200 }}
          value={supplierId}
          onChange={(value) => setSupplierId(value)}
        >
          <Select.Option value={null}>
            {t("warehouse.Deliveries.Filters.Supplier.AllSuppliers")}
          </Select.Option>
          {data?.suppliers.map((supplier) => (
            <Select.Option value={supplier.id} key={supplier.id}>
              {supplier.name}
            </Select.Option>
          ))}
        </Select>
      </Space>

      <Table
        rowKey="id"
        dataSource={data?.deliveries}
        loading={loading}
        pagination={{
          total: data?.deliveries_aggregate.aggregate.count,
          current: page,
          onChange: setPage,
          pageSize,
          onShowSizeChange: (_c, size) => setPageSize(size),
        }}
        expandable={{
          expandIconColumnIndex: -1,
          expandedRowKeys,
          expandedRowRender: (record) => <DeliveryProductsTable t={t} deliveryId={record.id} />,
        }}
      >
        <Table.Column
          title="#"
          dataIndex="number"
          width={80}
          align="center"
        />
        <Table.Column
          title={t("warehouse.Deliveries.Columns.Date")}
          dataIndex="datetime"
          render={(datetime) => moment(datetime).format("lll")}
        />
        <Table.Column
          title={t("warehouse.Deliveries.Columns.Supplier")}
          dataIndex={["supplier", "name"]}
        />
        <Table.Column
          title={t("warehouse.Deliveries.Columns.Amount")}
          dataIndex="delivery_products"
          render={(products) => formatPrice(products.reduce(
            (sum, product) => sum + parseFloat(product.price) * product.quantity, 0,
          ))}
        />
        <Table.Column
          title={t("warehouse.Deliveries.Columns.Description")}
          dataIndex="description"
          render={(value) => value || "-"}
        />
        <Table.Column
          title={t("warehouse.Deliveries.Columns.Warehouse")}
          dataIndex={["location", "name"]}
        />
        <Table.Column
          title={t("warehouse.Deliveries.Columns.FinanceAccount")}
          dataIndex={["finance_account", "name"]}
          render={(value) => (value || "-")}
        />
        <Table.Column
          title={t("warehouse.Deliveries.Columns.Status")}
          dataIndex="completed"
          render={(completed) => (completed ? (
            <Tag icon={<CheckCircleOutlined />} color={colors.green}>{t("Conducted")}</Tag>
          ) : (
            <Tag icon={<ClockCircleOutlined />} color={colors.secondaryColor}>{t("Created")}</Tag>
          ))}
        />
        <Table.Column
          dataIndex="id"
          width={135}
          render={(id, { completed }) => (
            <Space size="small">
              <Button
                type="link"
                size="small"
                onClick={() => {
                  if (expandedRowKeys.includes(id)) {
                    return setExpandedRowKeys(expandedRowKeys.filter((v) => v !== id));
                  }
                  return setExpandedRowKeys(expandedRowKeys.concat(id));
                }}
              >
                {t("Details")}
              </Button>
              {!completed && (
                <Link to={`/warehouse/deliveries/${id}`}>{t("EditShort")}</Link>
              )}
            </Space>
          )}
        />
      </Table>
    </Page>
  );
};

Deliveries.propTypes = {};

export default Deliveries;
