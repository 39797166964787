import gql from "graphql-tag";

export const SETS_QUERY = gql`
  query GetSets @hasura {
    menu_item_groups(
      where: {menu_items: {tech_card: {is_set: {_eq: true}}}}
      order_by: {id: asc}
    ) {
      id
      name
      description
      category_id
      color
      category {
        id
        name
      }
      archived_at
      menu_items {
        id
        name
        price
        tech_card {
          id
          cost
        }
        archived_at
      }
    }
    menu_categories(where: {menu_item_groups: {menu_items: {tech_card: {is_set: {_eq: true}}}}}) {
      id
      name
    }
  }
`;

export const SET_QUERY = gql`
  query GetSet($id: Int!) @hasura {
    menu_item_groups(where: {menu_items: {tech_card_id: {_is_null: false}}, id: {_eq: $id}}) {
      id
      name
      description
      category_id
      archived_at
      color
      menu_items {
        id
        name
        limited_location_availability
        price
        tech_card_id
        archived_at
        tech_card {
          id
          is_set
          cost
          unit
          unit_weight
          output_quantity
          workshop_id
          tech_card_products(order_by: {id: asc}) {
            id
            product_id
            tech_card_id
            quantity
            cost
          }
        }
        menu_item_modifier_groups {
          modifier_group_id
        }
      }
    }
  }
`;

export const SET_COMPONENTS_QUERY = gql`
  query GetSetComponents($id: Int!) @hasura {
    tech_card_products(
      order_by: {id: asc}
      where: {source_tech_card_id: {_eq: $id}}
    ) {
      id
      product {
        id
        name
        unit
        menu_items {
          id
          price
          menu_item_group_id
        }
      }
      tech_card {
        id
        name
        unit
        menu_items {
          id
          price
          menu_item_group_id
        }
      }
      quantity
      cost
    }
    menu_item_modifier_group(
      order_by: {id: asc}
      where: {menu_item: {tech_card_id: {_eq: $id}}}
    ) {
      modifier_group {
        id
        name
        modifier_items(order_by: {id: asc}, where: {archived_at: {_is_null: true}}) {
          id
          name
        }
      }
    }
  }
`;

export const SELECT_OPTIONS_QUERY = gql`
  query @hasura {
    menu_categories(order_by: {id: asc}) {
      id
      name
      menu_item_groups(
        order_by: {id: asc}
        where: {archived_at: {_is_null: true}}
      ) {
        id
        name
        menu_items(
          order_by: {id: asc}
          where: {
            archived_at: {_is_null: true}
            _not: {tech_card: {is_set: {_eq: true}}}
          }
        ) {
          name
          product {
            id
            name
            cost
            unit
            unit_weight
          }
          tech_card {
            id
            name
            cost
            unit
            unit_weight
            workshop_id
          }
        }
      }
    }
    locations {
      id
      name
    }
    workshops {
      id
      name
    }
    modifier_groups(order_by: {id: asc}, where: { archived_at: { _is_null: true } }) {
      id
      name
      description
      modifier_items(order_by: {id: asc}, where: { archived_at: { _is_null: true } }) {
        id
        name
      }
    }
  }
`;

export const CREATE_SET_MUTATION = gql`
  mutation CreateSet($data: [menu_item_groups_insert_input!]!) @hasura {
    insert_menu_item_groups(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SET_MUTATION = gql`
  mutation UpdateMenuItemGroup($id: Int!, $data: MenuItemGroupInput!) {
    updateMenuItemGroup(menu_item_group_id: $id, data: $data) {
      id
    }
  }
`;
