import gql from "graphql-tag";

export const PRODUCTIONS_QUERY = gql`
  query GetProductions($limit: Int, $offset: Int, $filter: productions_bool_exp) @hasura {
    productions(limit: $limit, offset: $offset, where: $filter, order_by: {id: desc}) {
      id
      number
      datetime
      description
      location {
        id
        name
      }
      production_products(order_by: {id: asc}) {
        id
        cost
        quantity
        product {
          id
          name
        }      
      }
    }
    locations(order_by: {id: asc}) {
      id
      name
    }
    productions_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
`;

export const PRODUCTION_PRODUCTS_QUERY = gql`
  query GetProductionProducts($productionId: Int) @hasura {
    production_products(where: {production_id: {_eq: $productionId}} order_by: {id: asc}) {
      id
      cost
      quantity
      product {
        id
        name
        unit
        tech_card {
          id
          name
          menu_items {
            id
            menu_item_group_id
          }
        }
      }      
    }
  }
`;

export const PRODUCTION_PRODUCT_SPENDINGS_QUERY = gql`
  query GetProductionProductSpendings($productionId: Int) @hasura {
    production_product_spendings(
      where: {production_id: {_eq: $productionId}} order_by: {id: asc}
    ) {
      id
      cost
      quantity
      product {
        id
        name
        unit
        tech_card {
          id
          name
          menu_items {
            id
            menu_item_group_id
          }
        }
      }      
    }
  }
`;

export const TECH_CARDS_PRODUCTS_QUERY = gql`
  query TechCardsProducts($techCardIds: [Int!]!) {
    getTechCardsProducts(techCardIds: $techCardIds, getProductionProducts: true) {
      tech_card_id
      products {
        product_id
        quantity
        product {
          id
          name
          cost
          unit
        }
      }
    }
  }
`;

export const CREATE_PRODUCTION_MUTATION = gql`
  mutation CreateProduction($data: ProductionInput!) {
    createProduction(data: $data) {
      id
    }
  }
`;
