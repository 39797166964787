import React from "react";
import { Route, Switch } from "react-router-dom";

import Products from "./Products";
import NewProduct from "./NewProduct";
import EditProduct from "./EditProduct";

const Container = () => (
  <Switch>
    <Route path="/menu/products/new" component={NewProduct} />
    <Route path="/menu/products/:id" component={EditProduct} />
    <Route path="/menu/products" component={Products} />
  </Switch>
);

export default Container;
