import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Table, Row, Col, Button, notification,
} from "antd";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";

import { LIMIT_PAGE } from "constants/index";

import { PROMOTION_COLUMNS } from "./constants";
import { PROMOTIONS_QUERY } from "./promotionQueries";

const Container = styled.div`
  padding: 12px;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`;

const Promotions = ({ history }) => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [getPromotions, { loading, data }] = useLazyQuery(PROMOTIONS_QUERY, {
    onError: notification.error,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    getPromotions({
      variables: {
        limit: LIMIT_PAGE,
        offset: page * LIMIT_PAGE - LIMIT_PAGE,
      },
    });
  }, [page]);

  return (
    <Container>
      <StyledRow>
        <FlexCol span={4} offset={20}>
          <Button
            type="primary"
            onClick={() => history.push("/marketing/promotions/new")}
          >
            {t("Add")}
          </Button>
        </FlexCol>
      </StyledRow>
      <Table
        rowKey="id"
        pagination={{
          total: data && data.promotions_aggregate.aggregate.count,
          current: page,
          onChange: setPage,
          pageSize: LIMIT_PAGE,
        }}
        columns={PROMOTION_COLUMNS}
        loading={loading}
        dataSource={data && data.promotions}
      />
    </Container>
  );
};

Promotions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Promotions;
