import React from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { Row, Col, notification, Spin } from "antd";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Page from "components/Page";
import FilterPanelWithQuery from "components/FilterPanelWithQuery";
import Charts from "components/Charts";

import AreaChartBySource from "./components/AreaChartBySource";
import TodayStatistics from "./components/TodayStatistics";
import PopularProductsTable from "./components/PopularProductsTable";
import HorizontalBarCharts from "./components/HorizontalBarCharts";
import {
  SALES_QUERY,
  TODAYS_SALES_QUERY,
  SALES_PAGE_QUERY,
  USER_QUERY,
} from "./salesQueries";

const LIMIT = 14;

const Sales = ({ match }) => {
  const userId = parseInt(match.params.userId, 10);
  const { t } = useTranslation();

  const [getTodaysSales, todaysSales] = useLazyQuery(TODAYS_SALES_QUERY, {
    onError: notification.error,
  });

  const [getSales, sales] = useLazyQuery(SALES_QUERY, {
    onError: notification.error,
  });

  const userPageDataQuery = useQuery(USER_QUERY, {
    variables: {
      user_id: userId,
    },
    onError: notification.error,
  });

  const salesPageDataQuery = useQuery(SALES_PAGE_QUERY, {
    onError: notification.error,
  });

  const popularItems = salesPageDataQuery.data && sales.data && sales.data.salesStatsByMenuItem
    .map((sale) => {
      const foundMenuItem = salesPageDataQuery.data.menu_items
        .find((menuItem) => sale.menu_item_id === menuItem.id);
      return {
        id: foundMenuItem.id,
        name: foundMenuItem.menu_item_group.name === foundMenuItem.name
          ? foundMenuItem.menu_item_group.name
          : `${foundMenuItem.menu_item_group.name} ${foundMenuItem.name}`,
        count: sale.count,
      };
    });

  const todaysData = todaysSales.data
    && todaysSales.data.salesStatsByDay.length !== 0
    ? {
      income: todaysSales.data.salesStatsByDay[0].revenue,
      orderCount: todaysSales.data.salesStatsByDay[0].orders_count,
      avgPrice: todaysSales.data.salesStatsByDay[0].revenue
      / todaysSales.data.salesStatsByDay[0].orders_count,
    }
    : {};

  const handleFilter = ({ time, locationId }) => {
    getTodaysSales({
      variables: {
        filters: {
          from: moment().startOf("day").format(),
          to: moment().endOf("day").format(),
          location_id: locationId,
        },
      },
    });
    getSales({
      variables: {
        filters: {
          from: time.from.format(),
          to: time.to.format(),
          location_id: locationId,
        },
        limit: LIMIT,
        user_id: userId,
      },
    });
  };

  return (
    <Page
      showBackIcon={match.params.userId}
      title={match.params.userId && userPageDataQuery.data
        && `${t("statistics.Sales.UserTitle")} - ${userPageDataQuery.data.users[0].name}`}
    >
      <FilterPanelWithQuery
        style={{ marginBottom: 12 }}
        onFilter={handleFilter}
        locations={salesPageDataQuery.data ? salesPageDataQuery.data.locations : []}
        initialData={{ time: { value: "month" } }}
      />

      {!userId && <TodayStatistics loading={todaysSales.loading} data={todaysData} t={t} />}

      {sales.data ? (
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Charts data={sales.data} t={t} />
          </Col>
          <Col span={24}>
            <AreaChartBySource data={sales.data} t={t} />
          </Col>
          {sales.data.paymentMethodStats.length > 0 && (
            <Col span={24}>
              <HorizontalBarCharts
                t={t}
                data={sales.data}
                paymentMethods={salesPageDataQuery.data
                  ? salesPageDataQuery.data.payment_methods : []}
              />
            </Col>
          )}
          {popularItems && (
            <Col span={24}>
              <PopularProductsTable
                data={popularItems}
                loading={salesPageDataQuery.loading}
                t={t}
              />
            </Col>
          )}
        </Row>
      ) : (
        <Spin />
      )}
    </Page>
  );
};

Sales.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Sales;
